import { purchaseOrderFiltersMenu, purchaseOrderIndividualMenu, purchaseOrderInsightsMenu } from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router } from "../../router";
import { handlePurchaseOrderFilters } from "./purchaseorders.filters";
import { handlePurchaseOrderInsights } from "./purchaseorders.insights";
import { handleIndividualPurchaseOrder } from "./individual";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(purchaseOrderFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, purchaseOrderFiltersMenu)) {
            handlePurchaseOrderFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(purchaseOrderInsightsMenu.href, async (ctx) => {
        if (await interceptPage(ctx, purchaseOrderInsightsMenu)) {
            handlePurchaseOrderInsights(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(purchaseOrderIndividualMenu.href, async (ctx) => {
        if (await interceptPage(ctx, purchaseOrderFiltersMenu)) {
            handleIndividualPurchaseOrder(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}
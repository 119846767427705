import * as scailo from "@kernelminds/scailo-sdk";
import { convertCentsToMoney, randomId, toSelectableDate, toTitleCase } from "./utilities";
import { protoInt64 } from "@bufbuild/protobuf";
import { getTransport } from "./clients";
import { formSection } from "./fetches";

export type colspan = 1 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 10 | 12
export type inputDataType = "string" | "number" | "date" | "money_in_cents" | "timestamp" | "bigint" | "bigint_in_cents"
export type inputType = "text" | "date" | "number" | "email" | "tel"

export const fieldValidatorClass = "_field-validator";

/**Displays the completion percentage with an appropriate background color */
export function colorizeCompletionPercentage(completionPercentage: bigint): HTMLSpanElement {
    let completionPercentageSpan = document.createElement("span");
    completionPercentageSpan.classList.add("p-4");
    completionPercentageSpan.classList.add("badge");
    completionPercentageSpan.classList.add("text-white");
    if (completionPercentage > (100 * 100)) {
        completionPercentageSpan.classList.add("badge-secondary");
    } else if (completionPercentage == protoInt64.parse(100 * 100)) {
        completionPercentageSpan.classList.add("badge-accent");
    } else if (completionPercentage >= (100 * 50)) {
        completionPercentageSpan.classList.add("badge-primary");
    } else {
        completionPercentageSpan.classList.add("badge-error");
    }
    completionPercentageSpan.innerText = convertCentsToMoney(completionPercentage);
    return completionPercentageSpan;
}

/**Render the STANDARD LIFECYCLE STATUS in a badge */
export function colorizeStandardLifecycleStatus(status: scailo.STANDARD_LIFECYCLE_STATUS): HTMLSpanElement {
    let span = document.createElement("span");
    span.classList.add("p-4");
    span.classList.add("badge");
    span.classList.add("text-white");
    if (status == scailo.STANDARD_LIFECYCLE_STATUS.DRAFT || status == scailo.STANDARD_LIFECYCLE_STATUS.REVISION) {
        span.classList.add("badge-primary");
        if (status == scailo.STANDARD_LIFECYCLE_STATUS.DRAFT) {
            span.innerText = "Draft";
        } else if (status == scailo.STANDARD_LIFECYCLE_STATUS.REVISION) {
            span.innerText = "Revision";
        }
    } else if (status == scailo.STANDARD_LIFECYCLE_STATUS.VERIFIED) {
        span.classList.add("badge-secondary");
        span.innerText = "Verified";
    } else if (status == scailo.STANDARD_LIFECYCLE_STATUS.STANDING) {
        span.classList.add("badge-accent");
        span.innerText = "Standing";
    } else if (status == scailo.STANDARD_LIFECYCLE_STATUS.PREVERIFY) {
        span.classList.add("badge-info");
        span.innerText = "Preverify";
    } else if (status == scailo.STANDARD_LIFECYCLE_STATUS.COMPLETED) {
        span.classList.add("badge-success");
        span.innerText = "Completed";
    } else if (status == scailo.STANDARD_LIFECYCLE_STATUS.HALTED) {
        span.classList.add("badge-warning");
        span.innerText = "Halted";
    } else if (status == scailo.STANDARD_LIFECYCLE_STATUS.DISCARDED) {
        span.classList.add("badge-error");
        span.innerText = "Discarded";
    }

    return span;
}

/**Render the QC SAMPLE LIFECYCLE STATUS in a badge */
export function colorizeQCSampleLifecycleStatus(status: scailo.QC_SAMPLE_LIFECYCLE): HTMLSpanElement {
    let span = document.createElement("span");
    span.classList.add("p-4");
    span.classList.add("badge");
    span.classList.add("text-white");
    if (status == scailo.QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_OPEN) {
        span.classList.add("badge-primary");
        span.innerText = "Open";
    } else if (status == scailo.QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_FINISHED) {
        span.classList.add("badge-secondary");
        span.innerText = "Finished";
    } else if (status == scailo.QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_ACCEPTED_WITH_DEVIATION) {
        span.classList.add("badge-accent");
        span.innerText = "Accepted (deviation)";
    } else if (status == scailo.QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_ACCEPTED) {
        span.classList.add("badge-success");
        span.innerText = "Accepted";
    } else if (status == scailo.QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_CANCELLED) {
        span.classList.add("badge-warning");
        span.innerText = "Cancelled";
    } else if (status == scailo.QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_REJECTED) {
        span.classList.add("badge-error");
        span.innerText = "Rejected";
    }

    return span;
}

/**Renders the primary subsection of each filter page */
export function renderFilterPrimarySubSection({ subsectionTitle, titleMdColSpan }: { subsectionTitle: string, titleMdColSpan: colspan }): {
    grid: HTMLDivElement,
    contentGrid: HTMLDivElement
} {
    let div = document.createElement("div");
    div.className = "grid grid-cols-12";

    let titleDiv = document.createElement("div");
    titleDiv.className = `col-span-12 md:col-span-${titleMdColSpan} flex justify-center items-center pl-4 text-lg`;
    titleDiv.innerText = subsectionTitle;
    div.appendChild(titleDiv);

    let contentDiv = document.createElement("div");
    contentDiv.className = `col-span-12 md:col-span-${12 - titleMdColSpan} pl-4`;
    div.appendChild(contentDiv);

    let contentGrid = document.createElement("div");
    contentGrid.className = "grid grid-cols-12";
    contentDiv.appendChild(contentGrid);

    return {
        grid: div,
        contentGrid: contentGrid
    };
}

/**Renders the filter page title */
export function renderPageTitleSection({ title }: { title: string }): HTMLDivElement {
    let div = document.createElement("div");
    div.className = "flex flex-wrap items-center px-4 py-2";

    let innerDiv = document.createElement("div");
    innerDiv.className = "relative w-full max-w-full flex-grow flex-1";

    let h3 = document.createElement("h3");
    h3.className = "font-semibold text-center text-xl text-gray-90";
    h3.innerHTML = title;

    innerDiv.appendChild(h3);

    div.appendChild(innerDiv);
    return div;
}

/**Renders an empty div that can be used as a line break */
export function emptyDiv(): HTMLDivElement {
    let div = document.createElement("div");
    div.classList.add("col-span-12")
    return div;
}

/**Renders an input element for filters */
export function renderInput({ id, label, inputType, dataMapper, dataType, value, mdColSpan, helpText, dataRegex, readonly }: { id: string, label: string, inputType: inputType, dataMapper: string, dataType: inputDataType, value: string, mdColSpan: colspan, helpText: string, dataRegex: string, readonly?: boolean }): HTMLDivElement {
    let div = document.createElement("div");
    div.className = `col-span-12 md:col-span-${mdColSpan} pl-4`;
    if (readonly === undefined || readonly === null) {
        readonly = false;
    }

    let labelEl = document.createElement("label");
    labelEl.htmlFor = id;
    labelEl.className = "text-sm font-medium leading-6 text-gray-900";
    labelEl.innerText = label;
    div.appendChild(labelEl);

    let tooltipSpan = document.createElement("span");
    tooltipSpan.className = "tooltip float-left";
    tooltipSpan.setAttribute("data-tip", helpText);
    div.appendChild(tooltipSpan);

    let tooltipIcon = document.createElement("i");
    tooltipIcon.className = "bx bx-help-circle mr-3 text-lg";
    tooltipSpan.appendChild(tooltipIcon);

    let inputDiv = document.createElement("div");
    inputDiv.className = "mt-2";

    let input = document.createElement("input");
    input.type = inputType;
    if (readonly) {
        input.readOnly = true;
    }

    input.setAttribute("data-mapper", dataMapper);
    input.setAttribute("data-type", dataType);
    input.setAttribute("data-regex", dataRegex);
    input.setAttribute("data-alert", helpText);
    input.defaultValue = value;
    input.id = id;
    input.className = `block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${fieldValidatorClass}`;
    inputDiv.appendChild(input);

    div.appendChild(inputDiv);

    return div;
}

/**Renders an textarea element for filters */
export function renderTextarea({ id, label, dataMapper, value, mdColSpan, helpText, dataRegex, readonly }: { id: string, label: string, dataMapper: string, value: string, mdColSpan: colspan, helpText: string, dataRegex: string, readonly?: boolean }): HTMLDivElement {
    let div = document.createElement("div");
    div.className = `col-span-12 md:col-span-${mdColSpan} pl-4`;
    if (readonly === undefined || readonly === null) {
        readonly = false;
    }

    let labelEl = document.createElement("label");
    labelEl.htmlFor = id;
    labelEl.className = "text-sm font-medium leading-6 text-gray-900";
    labelEl.innerText = label;
    div.appendChild(labelEl);

    let tooltipSpan = document.createElement("span");
    tooltipSpan.className = "tooltip float-left";
    tooltipSpan.setAttribute("data-tip", helpText);
    div.appendChild(tooltipSpan);

    let tooltipIcon = document.createElement("i");
    tooltipIcon.className = "bx bx-help-circle mr-3 text-lg";
    tooltipSpan.appendChild(tooltipIcon);

    let textareaDiv = document.createElement("div");
    textareaDiv.className = "mt-2";

    let textarea = document.createElement("textarea");
    if (readonly) {
        textarea.readOnly = true;
    }
    textarea.rows = 5;

    textarea.setAttribute("data-mapper", dataMapper);
    textarea.defaultValue = value;
    textarea.setAttribute("data-regex", dataRegex);
    textarea.setAttribute("data-alert", helpText);
    textarea.id = id;
    textarea.className = `block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${fieldValidatorClass}`;
    textareaDiv.appendChild(textarea);

    div.appendChild(textareaDiv);

    return div;
}

/**Renders an span element for filters */
export function renderSpan({ id, label, value, mdColSpan, helpText }: { id: string, label: string, value: string, mdColSpan: colspan, helpText: string }): HTMLDivElement {
    let div = document.createElement("div");
    div.className = `col-span-12 md:col-span-${mdColSpan} pl-4`;

    let labelEl = document.createElement("label");
    labelEl.htmlFor = id;
    labelEl.className = "text-sm font-medium leading-6 text-gray-900";
    labelEl.innerText = label;
    div.appendChild(labelEl);

    let tooltipSpan = document.createElement("span");
    tooltipSpan.className = "tooltip float-left";
    tooltipSpan.setAttribute("data-tip", helpText);
    div.appendChild(tooltipSpan);

    let tooltipIcon = document.createElement("i");
    tooltipIcon.className = "bx bx-help-circle mr-3 text-lg";
    tooltipSpan.appendChild(tooltipIcon);

    let spanDiv = document.createElement("div");
    spanDiv.className = "mt-2 overflow-x-scroll";

    let span = document.createElement("span");

    span.innerText = value;
    span.id = id;
    span.className = `block w-full py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${fieldValidatorClass}`;
    spanDiv.appendChild(span);

    div.appendChild(spanDiv);

    return div;
}

/**Renders a select element for filters */
export function renderSelect({ id, label, dataMapper, dataType, value, mdColSpan, helpText, dataRegex, options, readonly }: { id: string, label: string, dataMapper: string, dataType: inputDataType, value: string, mdColSpan: colspan, helpText: string, dataRegex: string, options: HTMLOptionElement[], readonly: boolean }) {
    let div = document.createElement("div");
    div.className = `col-span-12 md:col-span-${mdColSpan} pl-4`;
    let labelEl = document.createElement("label");
    labelEl.htmlFor = id;
    labelEl.className = "text-sm font-medium leading-6 text-gray-900";
    labelEl.innerText = label;
    div.appendChild(labelEl);

    let tooltipSpan = document.createElement("span");
    tooltipSpan.className = "tooltip float-left";
    tooltipSpan.setAttribute("data-tip", helpText);
    div.appendChild(tooltipSpan);

    let tooltipIcon = document.createElement("i");
    tooltipIcon.className = "bx bx-help-circle mr-3 text-lg";
    tooltipSpan.appendChild(tooltipIcon);

    let selectDiv = document.createElement("div");
    selectDiv.className = "mt-2";

    let select = document.createElement("select");
    select.setAttribute("data-mapper", dataMapper);
    select.setAttribute("data-type", dataType);
    select.setAttribute("data-regex", dataRegex);
    select.setAttribute("data-alert", helpText);
    select.id = id;
    select.className = `flex w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${fieldValidatorClass}`;

    if (readonly) {
        select.disabled = true;
    }

    // Add options here
    options.forEach(opt => {
        select.appendChild(opt);
    });

    selectDiv.appendChild(select);
    div.appendChild(selectDiv);
    return div;
}

/**Returns a select element wrapped in a div of a particular size with the given parameters for the given full enum */
export function renderSelectForEntireEnum({
    id, label, dataMapper, dataType, value, mdColSpan, helpText, dataRegex,
    enumObject, nameSplitsAt, readonly, excludeZeroethValuedEnum,
}: {
    id: string, label: string, dataMapper: string, dataType: inputDataType, value: string, mdColSpan: colspan, helpText: string, dataRegex: string,
    enumObject: Object, nameSplitsAt: string, readonly: boolean, excludeZeroethValuedEnum?: boolean
}) {

    return renderSelect({
        id: id,
        label: label,
        dataMapper: dataMapper,
        dataType: dataType,
        value: value,
        mdColSpan: mdColSpan,
        helpText: helpText,
        dataRegex,
        options: renderAllOptsForEnum({ enumObject, nameSplitsAt, excludeZeroethValuedEnum }),
        readonly,
    })
}

/**Returns all the possible options for the given enum */
export function renderAllOptsForEnum({ enumObject, nameSplitsAt, excludeZeroethValuedEnum }: { enumObject: Object, nameSplitsAt: string, excludeZeroethValuedEnum?: boolean }) {
    let values = <number[]>[];
    Object.values(enumObject).forEach(v => {
        if (typeof (v) == "number") {
            values.push(v);
        }
    });

    return renderOptsForEnumOpts({ values, enumObject, nameSplitsAt, excludeZeroethValuedEnum });
}

/**Returns a select element wrapped in a div of a particular size with the given parameters for the given partial enum */
export function renderSelectForPartialEnum({
    id, label, dataMapper, dataType, value, mdColSpan, helpText, dataRegex,
    enumValues,
    enumObject, nameSplitsAt, readonly, excludeZeroethValuedEnum,
}: {
    id: string, label: string, dataMapper: string, dataType: inputDataType, value: string, mdColSpan: colspan, helpText: string, dataRegex: string,
    enumValues: number[],
    enumObject: Object, nameSplitsAt: string, readonly: boolean, excludeZeroethValuedEnum?: boolean
}) {

    return renderSelect({
        id: id,
        label: label,
        dataMapper: dataMapper,
        dataType: dataType,
        value: value,
        mdColSpan: mdColSpan,
        helpText: helpText,
        dataRegex,
        options: renderOptsForEnumOpts({ values: enumValues, enumObject, nameSplitsAt, excludeZeroethValuedEnum }),
        readonly
    })
}

/**Returns the list of HTML Option elements for the given values from the given enum */
export function renderOptsForEnumOpts({ values, enumObject, nameSplitsAt, excludeZeroethValuedEnum }: {
    values: number[], enumObject: Object, nameSplitsAt: string, excludeZeroethValuedEnum?: boolean
}) {
    let arrayItemCountDuringSplit = 1;
    if (nameSplitsAt.trim() == "") {
        arrayItemCountDuringSplit = 0;
    }
    if (excludeZeroethValuedEnum === undefined || excludeZeroethValuedEnum === null) {
        excludeZeroethValuedEnum = false;
    }

    let list = values;
    let filteredList = <number[]>[];
    list.forEach(l => {
        if (!excludeZeroethValuedEnum) {
            filteredList.push(l);
        } else if (l > 0) {
            filteredList.push(l);
        }
    });

    let opts = filteredList.map(l => {
        let opt = document.createElement("option");
        opt.value = l.toString();
        opt.innerText = toTitleCase((<string>enumObject[l]).split(nameSplitsAt)[arrayItemCountDuringSplit].split("_").join(" ").replace(" UNSPECIFIED", "").replace("Id", "ID"));
        return opt;
    });

    return opts;
}

/**Renders an span element for filters */
export function renderImage({ id, label, link, mdColSpan, helpText }: { id: string, label: string, link: string, mdColSpan: colspan, helpText: string }): HTMLDivElement {
    let div = document.createElement("div");
    div.className = `col-span-12 md:col-span-${mdColSpan} pl-4`;

    let labelEl = document.createElement("label");
    labelEl.htmlFor = id;
    labelEl.className = "text-sm font-medium leading-6 text-gray-900";
    labelEl.innerText = label;
    div.appendChild(labelEl);

    let tooltipSpan = document.createElement("span");
    tooltipSpan.className = "tooltip float-left";
    tooltipSpan.setAttribute("data-tip", helpText);
    div.appendChild(tooltipSpan);

    let tooltipIcon = document.createElement("i");
    tooltipIcon.className = "bx bx-help-circle mr-3 text-lg";
    tooltipSpan.appendChild(tooltipIcon);

    let spanDiv = document.createElement("div");
    spanDiv.className = "mt-2 overflow-x-scroll";

    let img = document.createElement("img");

    img.src = link;
    img.id = id;
    img.className = `block w-full py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`;
    spanDiv.appendChild(img);

    div.appendChild(spanDiv);

    return div;
}

/**Returns a searchable HTML Element */
function renderSearchable() {
    return `
    <div class="col-span-12 md:col-span-3 pl-4">
        <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">First name</label>
        <div class="mt-2">
            <input type="text" name="first-name" id="first-name" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <div class="bg-gray-70 border rounded text-gray-600 text-sm font-medium pl-2 overflow-y-scroll max-h-8">
                <div class="hover:text-blue-500 cursor-pointer">Create landing page</div>
                <div class="hover:text-blue-500 cursor-pointer">Create landing page</div>
                <div class="hover:text-blue-500 cursor-pointer">Create landing page</div>
                <div class="hover:text-blue-500 cursor-pointer">Create landing page</div>
            </div>
        </div>
    </div>
    `
}


/**Renders the status filters filter section */
export function renderStatusFiltersSection() {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Status Filters", titleMdColSpan: 3 });

    contentGrid.appendChild(renderSelectForEntireEnum({
        id: randomId(),
        label: "Status",
        nameSplitsAt: " ",
        enumObject: scailo.STANDARD_LIFECYCLE_STATUS,
        dataMapper: "status",
        dataType: "number",
        value: "",
        mdColSpan: 6,
        helpText: "Filter by record status. Set it to 'Any' to ignore this field.",
        excludeZeroethValuedEnum: false,
        readonly: false,
        dataRegex: ".*"
    }));

    contentGrid.appendChild(renderSelectForEntireEnum({
        id: randomId(),
        label: "Is Active",
        nameSplitsAt: "BOOL_FILTER_",
        enumObject: scailo.BOOL_FILTER,
        dataMapper: "isActive",
        dataType: "number",
        value: "",
        mdColSpan: 6,
        helpText: "Filter by active status. Set it to 'Any' to ignore this field. Set it to 'True' to only show active records. Set it to 'False' to only show inactive records.",
        excludeZeroethValuedEnum: false,
        readonly: false,
        dataRegex: ".*"
    }));

    return grid
}

/**Renders the time filters filter section */
export function renderTimeFiltersSection() {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Time Filters", titleMdColSpan: 3 });

    contentGrid.appendChild(renderInput({ id: randomId(), label: "Start Creation Date", inputType: "date", dataMapper: "creationTimestampStart", dataType: "timestamp", value: "", mdColSpan: 6, helpText: "Start Creation Date is the minimum creation date to be considered. If set, all records from this creation date are returned. Leave it empty to ignore this field.", dataRegex: ".*" }));
    contentGrid.appendChild(renderInput({ id: randomId(), label: "End Creation Date", inputType: "date", dataMapper: "creationTimestampEnd", dataType: "timestamp", value: "", mdColSpan: 6, helpText: "End Creation Date is the maximum creation date to be considered. If set, all records until this creation date are returned. Leave it empty to ignore this field.", dataRegex: ".*" }));

    contentGrid.appendChild(renderInput({ id: randomId(), label: "Start Modification Date", inputType: "date", dataMapper: "modificationTimestampStart", dataType: "timestamp", value: "", mdColSpan: 6, helpText: "Start Modification Date is the minimum modification date to be considered. If set, all records from this modification date are returned. Leave it empty to ignore this field.", dataRegex: ".*" }));
    contentGrid.appendChild(renderInput({ id: randomId(), label: "End Modification Date", inputType: "date", dataMapper: "modificationTimestampEnd", dataType: "timestamp", value: "", mdColSpan: 6, helpText: "End Modification Date is the maximum modification date to be considered. If set, all records until this modification date are returned. Leave it empty to ignore this field.", dataRegex: ".*" }));

    contentGrid.appendChild(renderInput({ id: randomId(), label: "Start Approval Date", inputType: "date", dataMapper: "approvedOnStart", dataType: "timestamp", value: "", mdColSpan: 6, helpText: "Start Approval Date is the minimum approval date to be considered. If set, all records from this approval date are returned. Leave it empty to ignore this field.", dataRegex: ".*" }));
    contentGrid.appendChild(renderInput({ id: randomId(), label: "End Approval Date", inputType: "date", dataMapper: "approvedOnEnd", dataType: "timestamp", value: "", mdColSpan: 6, helpText: "End Approval Date is the maximum approval date to be considered. If set, all records until this approval date are returned. Leave it empty to ignore this field.", dataRegex: ".*" }));

    return grid
}

/**Renders the sort filters filter section */
export function renderSortFiltersSection() {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Sort Filters", titleMdColSpan: 3 })

    contentGrid.appendChild(renderSelectForPartialEnum({
        id: randomId(),
        label: "Sort Order",
        nameSplitsAt: " ",
        enumObject: scailo.SORT_ORDER,
        enumValues: [
            scailo.SORT_ORDER.DESCENDING,
            scailo.SORT_ORDER.ASCENDING_UNSPECIFIED,
        ],
        dataMapper: "sortOrder",
        dataType: "number",
        value: "",
        mdColSpan: 4,
        helpText: "Sort response in either ascending or descending order",
        excludeZeroethValuedEnum: false,
        readonly: false,
        dataRegex: ".*"
    }));

    contentGrid.appendChild(renderSelect({
        id: randomId(),
        label: "Count",
        dataMapper: "count",
        dataType: "number",
        value: "",
        mdColSpan: 4,
        helpText: "The number of records to be returned.",
        options: [5, 10, 20, 50, 100].map(count => {
            let opt = document.createElement("option");
            opt.value = count.toString();
            opt.innerText = count.toString();
            return opt;
        }),
        readonly: false,
        dataRegex: ".*"
    }));

    contentGrid.appendChild(renderInput({ id: randomId(), label: "Offset", inputType: "number", dataMapper: "offset", dataType: "number", value: "0", mdColSpan: 4, helpText: "The number of records to be skipped from the beginning. Leave it at 0 to ignore this field. Negative values have no effect.", dataRegex: ".*" }));

    return { grid, contentGrid }
}

/** This class name can be used for both checkboxes and radio buttons */
export const formValidationCheckboxClass = "__form-checkbox";
/**This class name is used for displaying a box with the historical changes of that particular field */
export const onClickShowHistory = "__show-history";
export const formValidationElementClass = "__form-value";
export const sectionValidationElementClass = "__section-value";
export const datePickerClass = "__datepicker";

/**Sorts in the increasing order of the 'id' value present in the object */
function __sortByID(a: any, b: any): number {
    if (a.id < b.id) {
        return -1
    } else if (a.id > b.id) {
        return 1
    }
    return 0
}

/**Sorts in the increasing order of the 'section_id' preent in the object */
function __sortBySectionID(a: scailo.FormFieldDatum, b: scailo.FormFieldDatum): number {
    if (a.formField?.sectionId! < b.formField?.sectionId!) {
        return -1
    } else if (a.formField?.sectionId! > b.formField?.sectionId!) {
        return 1
    }
    return 0
}

/**Stores the class name that is applied to all input fields in a dynamic form. This will be used to validate the fields */
export const formFieldClass = "__form-field-dynamic-form";
/**Stores the class name that is applied to the dynamic form duv */
export const formDynamicFormClass = "__form-dynamic-form";

function removeFieldValidatorClassAndAddFormFieldClass(container: HTMLDivElement, inputType: "input" | "textarea" | "select", formFieldId: bigint) {
    if (inputType === "input") {
        let el = (<HTMLInputElement>container.getElementsByTagName("input")[0]);
        el.classList.remove(fieldValidatorClass);
        el.classList.add(formFieldClass);
        el.setAttribute("data-form-field-id", formFieldId.toString());
    } else if (inputType === "textarea") {
        let el = (<HTMLTextAreaElement>container.getElementsByTagName("textarea")[0]);
        el.classList.remove(fieldValidatorClass);
        el.classList.add(formFieldClass);
        el.setAttribute("data-form-field-id", formFieldId.toString());
    } else if (inputType === "select") {
        let el = (<HTMLSelectElement>container.getElementsByTagName("select")[0]);
        el.classList.remove(fieldValidatorClass);
        el.classList.add(formFieldClass);
        el.setAttribute("data-form-field-id", formFieldId.toString());
    }
    return container;
}

/** Creates a Form with the list of provided FormElements */
export async function returnFormFromFields(formsList: scailo.FormFieldDatum[], formName:
    "clients" | "users" | "contractors" | "locations" | "bankaccounts" | "vendors" | "projects" | "sales-orders" | "sales-quotations" |
    "expenses" | "purchases-orders" | "supply-offers" | "purchases-indents" | "purchases-enquiries" | "sales-enquiries" | "sales-invoices" | "goods-dispatches" | "vendor-invoices" | "goods-receipts" | "work-orders" | "forms" | "quotations-requests" |
    "quotations-responses" | "productionplans" | "production-indents" | "replaceable-indents" | "asset-indents" | "purchases-returns" | "sales-returns" | "debit-notes" | "credit-notes" |
    "outward-jobs" | "outward-jobs-free-issue-materials" | "outward-jobs-free-issue-materials-returns" |
    "inward-jobs" | "inward-jobs-free-issue-materials" | "inward-jobs-free-issue-materials-returns" |
    "stock-issuances" | "stock-audits" | "stock-returns" |
    "leaves-requests" | "overtimes" | "attendances" | "absences" | "onduties" | "attendancesamendments" | "visitations" | "salaries" | "skills-params" | "skills-groups" | "goals" |
    "qc-groups" | "meetings",

    historySymbolRequired: boolean, readonly: boolean, individialFormIdentifier?: number | bigint, individialFormUUID?: string): Promise<HTMLDivElement> {
    if (individialFormUUID === undefined || individialFormUUID === null) {
        individialFormUUID = "";
    }
    let form = document.createElement("div");
    form.classList.add(formDynamicFormClass);
    // Segregate formsList into headerForms and footerForms, and sort each of them by the ascending IDs

    let sectionIDs = <bigint[]>[];

    formsList.sort(__sortBySectionID);

    // Hold the unique section ids and their forms
    let sectionsMap: Map<bigint, scailo.FormFieldDatum[]> = new Map();
    formsList.forEach(form => {
        if (sectionsMap.get(form.formField?.sectionId!) === undefined) {
            sectionsMap.set(form.formField?.sectionId!, []);
        }
        sectionsMap.get(form.formField?.sectionId!)?.push(form);
    });

    sectionsMap.forEach((value, key) => {
        sectionIDs.push(key);
    });

    sectionIDs.sort();

    // Fetch all the sections here
    const formSectionClient = scailo.getClientForFormsSectionsService(getTransport());
    let sectionsReqs = sectionIDs.map(id => {
        return formSection(id, formSectionClient);
    });

    let sections = await Promise.all(sectionsReqs);
    sections.sort(__sortByID);

    sections.forEach(section => {
        if (!section.metadata?.isActive) {
            return
        }

        let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: section.name, titleMdColSpan: 2 });

        let formFieldsInSection = sectionsMap.get(section.metadata.id);
        // console.log(formFieldsInSection);
        formFieldsInSection?.forEach(formEl => {
            if (formEl.formField?.element == scailo.FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_INPUT) {
                let inputDiv = renderInput({ id: randomId(), readonly: readonly, label: formEl.formField?.name, inputType: "text", dataMapper: "", dataType: "string", value: formEl.value, mdColSpan: parseInt(formEl.formField.width) as colspan, helpText: formEl.formField?.name, dataRegex: formEl.formField?.regex });
                contentGrid.appendChild(removeFieldValidatorClassAndAddFormFieldClass(inputDiv, "input", formEl.formField?.metadata?.id!));
            } else if (formEl.formField?.element == scailo.FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_DATE) {
                let inputDiv = renderInput({ id: randomId(), readonly: readonly, label: formEl.formField?.name, inputType: "date", dataMapper: "", dataType: "string", value: formEl.value.length > 0 ? toSelectableDate(formEl.value) : formEl.value, mdColSpan: parseInt(formEl.formField.width) as colspan, helpText: formEl.formField?.name, dataRegex: formEl.formField?.regex });
                contentGrid.appendChild(removeFieldValidatorClassAndAddFormFieldClass(inputDiv, "input", formEl.formField?.metadata?.id!));
            } else if (formEl.formField?.element == scailo.FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_EMAIL) {
                let inputDiv = renderInput({ id: randomId(), readonly: readonly, label: formEl.formField?.name, inputType: "email", dataMapper: "", dataType: "string", value: formEl.value, mdColSpan: parseInt(formEl.formField.width) as colspan, helpText: formEl.formField?.name, dataRegex: formEl.formField?.regex });
                contentGrid.appendChild(removeFieldValidatorClassAndAddFormFieldClass(inputDiv, "input", formEl.formField?.metadata?.id!));
            } else if (formEl.formField?.element == scailo.FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_PHONE) {
                let inputDiv = renderInput({ id: randomId(), readonly: readonly, label: formEl.formField?.name, inputType: "tel", dataMapper: "", dataType: "string", value: formEl.value, mdColSpan: parseInt(formEl.formField.width) as colspan, helpText: formEl.formField?.name, dataRegex: formEl.formField?.regex });
                contentGrid.appendChild(removeFieldValidatorClassAndAddFormFieldClass(inputDiv, "input", formEl.formField?.metadata?.id!));
            } else if (formEl.formField?.element == scailo.FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_TEXTAREA) {
                let textareaDiv = renderTextarea({ id: randomId(), readonly: readonly, label: formEl.formField?.name, dataMapper: "", value: formEl.value, mdColSpan: parseInt(formEl.formField.width) as colspan, helpText: formEl.formField?.name, dataRegex: formEl.formField?.regex });
                contentGrid.appendChild(removeFieldValidatorClassAndAddFormFieldClass(textareaDiv, "textarea", formEl.formField?.metadata?.id!));
            } else if (
                formEl.formField?.element == scailo.FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_SELECT
            ) {
                let opts = <HTMLOptionElement[]>[];
                let disabledOption = document.createElement("option");
                disabledOption.selected = true;
                opts.push(disabledOption);
                formEl.formField?.definedValues.forEach(optionVal => {
                    let localOption = document.createElement("option");
                    localOption.value = optionVal;
                    localOption.innerText = optionVal;
                    if (formEl.value == optionVal) {
                        localOption.setAttribute("selected", "");
                    }
                    opts.push(localOption);
                });
                let selectDiv = renderSelect({ id: randomId(), readonly: readonly, label: formEl.formField?.name, dataMapper: "", dataType: "string", value: formEl.value, mdColSpan: parseInt(formEl.formField.width) as colspan, helpText: formEl.formField?.name, options: opts, dataRegex: formEl.formField?.regex });
                contentGrid.appendChild(removeFieldValidatorClassAndAddFormFieldClass(selectDiv, "select", formEl.formField?.metadata?.id!));
            } else if (formEl.formField?.element == scailo.FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_CHECKBOX || formEl.formField?.element == scailo.FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_RADIO) {
                // console.log(formEl.formField?.element);


                //                 <div class="form-control">
                //   <label class="label cursor-pointer">
                //     <span class="label-text">Red pill</span>
                //     <input type="radio" name="radio-10" class="radio checked:bg-red-500" checked="checked" />
                //   </label>
                // </div>
                // <div class="form-control">
                //   <label class="label cursor-pointer">
                //     <span class="label-text">Blue pill</span>
                //     <input type="radio" name="radio-10" class="radio checked:bg-blue-500" checked="checked" />
                //   </label>
                // </div>
            }
        });

        form.appendChild(grid);
        let hr = document.createElement("hr");
        hr.classList.add("m-5");
        form.appendChild(hr);
    });

    return form
}

export function redirectTo(url: string, clearContent: boolean) {

    // Clear the container
    if (clearContent) {
        let content = <HTMLDivElement>document.getElementById("central-content");
        while (content.firstChild) {
            content.removeChild(content.firstChild);
        }
    }

    (<any>window).routeTo(url);
}

export function getLinkForVendorStream(vendorstreamUuid: string) {
    return `/ui/streams/i/${vendorstreamUuid}`
}

export function getLinkForPurchaseOrder(purchaseorderUuid: string) {
    return `/ui/purchase-orders/i/${purchaseorderUuid}`
}

export function getLinkForVendorInvoice(vendorinvoiceUuid: string) {
    return `/ui/vendor-invoices/i/${vendorinvoiceUuid}`
}

export function getLinkForGoodsReceipt(goodsreceiptUuid: string) {
    return `/ui/goods-receipts/i/${goodsreceiptUuid}`
}

export function getLinkForDebitNote(debitnoteUuid: string) {
    return `/ui/debit-notes/i/${debitnoteUuid}`
}

export function getLinkForPurchaseReturn(purchasereturnUuid: string) {
    return `/ui/purchase-returns/i/${purchasereturnUuid}`
}

export function getLinkForOutwardJob(outwardjobUuid: string) {
    return `/ui/outward-jobs/i/${outwardjobUuid}`
}

export function getLinkForOutwardJobFreeIssueMaterial(outwardjobFIMUuid: string) {
    return `/ui/outward-jobs-free-issue-materials/i/${outwardjobFIMUuid}`
}

export function getLinkForOutwardJobFreeIssueMaterialReturn(outwardjobFIMRUuid: string) {
    return `/ui/outward-jobs-free-issue-materials-returns/i/${outwardjobFIMRUuid}`
}

export function getLinkForSupplyOffer(supplyofferUuid: string) {
    return `/ui/supply-offers/i/${supplyofferUuid}`
}

export function getLinkForQASample(qaSampleUuid: string) {
    return `/ui/qa-samples/i/${qaSampleUuid}`
}
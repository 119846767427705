import { 
    streamCreateMenu,
    streamFiltersMenu,
    streamIndividualMenu,
    streamOpenMenu, 
    // streamInsightsMenu 
} from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router, context } from "../../router";
import { handleStreamFilters } from "./streams.filters";
import { handleStreamOpen } from "./opens";
import { handleIndividualStream } from "./individual";
import { handleCreateVendorStream } from "./create";
// import { handleStreamInsights } from "./streams.insights";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(streamFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, streamFiltersMenu)) {
            handleStreamFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    // r.add(streamInsightsMenu.href, async (ctx) => {
    //     if (await interceptPage(ctx, streamInsightsMenu)) {
    //         handleStreamInsights(ctx);
    //     } else {
    //         handlePageForInvalidPermissions(ctx);
    //     }
    // });
    r.add(streamOpenMenu.href, async (ctx) => {
        if (await interceptPage(ctx, streamOpenMenu)) {
            handleStreamOpen(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(streamIndividualMenu.href, async (ctx) => {
        if (await interceptPage(ctx, streamOpenMenu)) {
            handleIndividualStream(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(streamCreateMenu.href, async (ctx) => {
        if (await interceptPage(ctx, streamCreateMenu)) {
            handleCreateVendorStream(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}
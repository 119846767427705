import { context } from "./../../router";
import { FORM_TYPE, FormFieldDatum, SupplyOffersService, SupplyOffersServiceCreateRequest, PermissionSupplyOfferDrafts, PermissionSupplyOfferNew, Role, getClientForSupplyOffersService, getClientForPurchasesOrdersService, getClientForCurrenciesService, getClientForLocationsService } from "@kernelminds/scailo-sdk";
import { emptyDiv, getLinkForSupplyOffer, redirectTo, renderFilterPrimarySubSection, renderInput, renderPageTitleSection, renderSelect, returnFormFromFields } from "../../ui";
import { checkForAnyPerm, createObjectFromForm, getDynamicFormData, randomId, showFailureAlert, showSuccessAlert, validateForm } from "../../utilities";
import { PromiseClient, Transport } from "@connectrpc/connect";
import { getTransport, getWriteTransport } from "../../clients";
import { getFormsFields, roleSelf } from "../../fetches";
import { setupCurrencySearchable, setupLocationSearchable, setupPurchaseOrderSearchable } from "../../searchables";

export async function handleCreateSupplyOffer(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    const transport = getTransport();
    const accessClient = getClientForSupplyOffersService(transport);
    const currenciesClient = getClientForCurrenciesService(transport);
    const locationsClient = getClientForLocationsService(transport);

    document.title = `Create Supply Offer`;

    let container = document.createElement("div");
    container.className = "overflow-x-auto";
    content.appendChild(container);

    let [userRole] = await Promise.all([
        roleSelf(transport),
    ]);

    const { formGrid, nextButtonId, resetButtonId, buyerLocationId, consigneeLocationId, currencyReferenceId } = await getForm(accessClient, userRole, transport);

    container.appendChild(formGrid);

    // Setup searchable
    setupLocationSearchable(buyerLocationId, locationsClient, {});
    setupLocationSearchable(consigneeLocationId, locationsClient, {});
    setupCurrencySearchable(currencyReferenceId, currenciesClient, {});

    (<HTMLButtonElement>document.getElementById(resetButtonId)).addEventListener("click", async evt => {
        evt.preventDefault();
        handleCreateSupplyOffer(ctx);
    });

    const nextButton = (<HTMLButtonElement>document.getElementById(nextButtonId));

    if (checkForAnyPerm(userRole, [PermissionSupplyOfferNew, PermissionSupplyOfferDrafts])) {
        nextButton.addEventListener("click", async evt => {
            evt.preventDefault();

            // Validate all the fields
            if (!validateForm(formGrid.id)) {
                return;
            }
            nextButton.disabled = true;
            nextButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;

            // Create the object

            const writeClient = getClientForSupplyOffersService(getWriteTransport());
            let obj = <SupplyOffersServiceCreateRequest>createObjectFromForm(formGrid.id);
            obj.formData = getDynamicFormData();

            // consigneeLocationId
            // buyerLocationId
            // currencyId
            // paymentCycleInDays

            try {
                let resp = await writeClient.draft(obj)
                showSuccessAlert(`Supply Offer - ${obj.referenceId} has been created. Redirecting you to the edit page in 1 second...`);
                setTimeout(() => {
                    redirectTo(getLinkForSupplyOffer(resp.uuid), true);
                }, 1000)
            } catch (e) {
                nextButton.disabled = false;
                nextButton.innerText = `Next`;
                showFailureAlert("Something went wrong. Try again.");
            }
        });
    }
}

function renderReferencesSection(accessClient: PromiseClient<typeof SupplyOffersService>) {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "References", titleMdColSpan: 3 });

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: false, label: "Initial Reference", inputType: "text", dataMapper: "referenceId", dataType: "string", value: "", mdColSpan: 6, helpText: "Initial Reference of the record." }));
    contentGrid.appendChild(emptyDiv());
    
    const buyerLocationId = randomId();
    contentGrid.appendChild(renderSelect({ id: buyerLocationId, readonly: false, label: "Buyer Location", dataMapper: "buyerLocationId", dataType: "bigint", value: "", mdColSpan: 4, helpText: "The location of the buyer.", options: [] }));    
    
    const consigneeLocationId = randomId();
    contentGrid.appendChild(renderSelect({ id: consigneeLocationId, readonly: false, label: "Consignee Location", dataMapper: "consigneeLocationId", dataType: "bigint", value: "", mdColSpan: 4, helpText: "The location of the consignee.", options: [] }));    
    
    const currencyReferenceId = randomId();
    contentGrid.appendChild(renderSelect({ id: currencyReferenceId, readonly: false, label: "Currency", dataMapper: "currencyId", dataType: "bigint", value: "", mdColSpan: 4, helpText: "Select the Currency of the Supply Offer.", options: [] }));    

    contentGrid.appendChild(emptyDiv());
    
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: false, label: "Payment Cycle In Days", inputType: "number", dataMapper: "paymentCycleInDays", dataType: "number", value: "", mdColSpan: 6, helpText: "The payment cycle of this Supply Offer." }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: false, label: "Advance Payment", inputType: "number", dataMapper: "paymentAdvance", dataType: "bigint_in_cents", value: "", mdColSpan: 6, helpText: "The advance payment needed for this Supply Offer." }));

    return {
        grid,
        buyerLocationId,
        consigneeLocationId,
        currencyReferenceId
    };
}

async function renderDynamicFormsSection(accessClient: PromiseClient<typeof SupplyOffersService>, transport: Transport): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    let allForms = (await getFormsFields(FORM_TYPE.FORM_TYPE_SUPPLY_OFFER_FORM, transport)).map(f => {
        let t = new FormFieldDatum({ formField: f })
        return t
    });
    if (allForms.length == 0) {
        return container
    }

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Other Details` }));
    container.appendChild(containerTitle);

    let formFields = await returnFormFromFields(allForms, "supply-offers", true, false);
    container.appendChild(formFields);
    return container;
}

async function getForm(accessClient: PromiseClient<typeof SupplyOffersService>, userRole: Role, transport: Transport) {
    let formGrid = document.createElement("div");
    formGrid.className = "grid grid-cols-1 gap-6 mb-6";
    formGrid.id = randomId()

    const [
        referencesSection,
        dynamicFormsSection,
    ] = await Promise.all([
        renderReferencesSection(accessClient),
        renderDynamicFormsSection(accessClient, transport),
    ]);

    let buttonContainer = document.createElement("div");
    buttonContainer.classList.add("col-span-12", "flex", "justify-end");


    // let buttonsDiv = document.createElement("div");
    // buttonsDiv.className = `col-span-12 pl-4 float-right`;
    let nextButtonId = randomId();
    if (checkForAnyPerm(userRole, [PermissionSupplyOfferNew, PermissionSupplyOfferDrafts])) {
        let nextButton = document.createElement("button");
        nextButton.id = nextButtonId;
        nextButton.className = "btn btn-outline btn-success btn-md mr-4";
        nextButton.innerText = `Next`;
        buttonContainer.append(nextButton);
    }

    let resetButtonId = randomId();
    let resetButton = document.createElement("button");
    resetButton.id = resetButtonId;
    resetButton.className = "btn btn-outline btn-info btn-md mr-4";
    resetButton.innerText = "Reset";
    buttonContainer.append(resetButton);

    formGrid.innerHTML = `
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
            <div class="rounded-t mb-0 px-0 border-0">
                ${renderPageTitleSection({ title: document.title }).outerHTML}
            </div>
            <form>
                <hr class="m-5">
                ${referencesSection.grid.outerHTML}
                <hr class="m-5">
            </form>
        </div>
        ${dynamicFormsSection.childElementCount > 0 ?
            `<div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
                ${dynamicFormsSection.outerHTML}
            </div>`
            : ''}
        <div class="rounded-t mb-0 px-0 border-0">
            ${buttonContainer.outerHTML}
        </div>
    `;

    return { 
        formGrid, nextButtonId, resetButtonId, 
        buyerLocationId: referencesSection.buyerLocationId,
        consigneeLocationId: referencesSection.consigneeLocationId,
        currencyReferenceId: referencesSection.currencyReferenceId 
    };
}
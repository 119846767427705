import { protoInt64 } from "@bufbuild/protobuf";
import { adminMenu, associateFiltersMenu, dashboardMenu, debitNoteFiltersMenu, debitNoteIndividualMenu, debitNoteInsightsMenu, goodsReceiptCreateMenu, goodsReceiptFiltersMenu, goodsReceiptIndividualMenu, goodsReceiptInsightsMenu, mainMenusClassifications, materialAllFiltersMenu, materialRequiredFiltersMenu, MenuItem, ordersMenu, outwardJobFiltersMenu, outwardJobFIMFiltersMenu, outwardJobFIMIndividualMenu, outwardJobFIMInsightsMenu, outwardJobFIMRFiltersMenu, outwardJobFIMRIndividualMenu, outwardJobFIMRInsightsMenu, outwardJobIndividualMenu, outwardJobInsightsMenu, profileMenu, purchaseEnquiryFiltersMenu, purchaseOrderFiltersMenu, purchaseOrderIndividualMenu, purchaseOrderInsightsMenu, purchasePaymentFiltersMenu, purchasePaymentInsightsMenu, purchaseReturnFiltersMenu, purchaseReturnIndividualMenu, purchaseReturnInsightsMenu, qaSampleFiltersMenu, qaSampleIndividualMenu, qaSampleInsightsMenu, quotationRequestFiltersMenu, quotationResponseFiltersMenu, secondaryMenusList, streamCreateMenu, streamFiltersMenu, streamIndividualMenu, streamOpenMenu, supplyOfferCreateMenu, supplyOfferFiltersMenu, supplyOfferIndividualMenu, supplyOfferInsightsMenu, tertiaryMenusList, vaultMenu, vendorInvoiceCreateMenu, vendorInvoiceFiltersMenu, vendorInvoiceIndividualMenu, vendorInvoiceInsightsMenu } from "./menus";
import { context } from "./router";
import { fieldValidatorClass, formDynamicFormClass, formFieldClass, inputDataType } from "./ui";
import * as papaparse from "papaparse";
import { Associate, FORM_FIELD_ELEMENT, FormFieldDatumCreateRequest, getClientForVaultService, PermissionGoodsReceiptDrafts, PermissionGoodsReceiptNew, PermissionNewAssociate, PermissionsAssociates, PermissionsDebitNotes, PermissionsGoodsReceipts, PermissionsOutwardJobs, PermissionsOutwardJobsFreeIssueMaterials, PermissionsOutwardJobsFreeIssueMaterialsReturns, PermissionsPurchases, PermissionsPurchasesEnquiries, PermissionsPurchasesPayments, PermissionsPurchasesReturns, PermissionsQcSamples, PermissionsQuotationsRequests, PermissionsQuotationsResponses, PermissionsSupplyOffers, PermissionSupplyOfferDrafts, PermissionSupplyOfferNew, PermissionsVendorInvoices, PermissionsVendorStreams, PermissionVendorInvoiceDrafts, PermissionVendorInvoiceNew, PermissionVendorStreamNew, QC_SAMPLE_LIFECYCLE, Role, RoleAccess, STANDARD_LIFECYCLE_STATUS, User, USER_TYPE, VaultService, VENDOR_STREAM_LIFECYCLE, VENDOR_STREAM_REF_FROM } from "@kernelminds/scailo-sdk";

import EditorJS, { OutputData } from '@editorjs/editorjs';
import NestedList from '@editorjs/nested-list';
// import Paragraph from '@editorjs/paragraph';
// @ts-ignore
const Paragraph = require('editorjs-paragraph-with-alignment');
const FontSizeTool = require('editorjs-inline-font-size-tool');
const ColorPlugin = require('editorjs-text-color-plugin');
import Undo from 'editorjs-undo';
// import Header from '@editorjs/header';
import Table from '@editorjs/table'
// import Marker from '@editorjs/marker';
import Delimiter from '@editorjs/delimiter';
import Checklist from '@editorjs/checklist'
import Underline from '@editorjs/underline';
import ChangeCase from 'editorjs-change-case';
import Strikethrough from '@sotaproject/strikethrough';
import Quote from '@editorjs/quote';
import edjsParser from 'editorjs-html';
import Alert from 'editorjs-alert';
import AttachesTool from '@editorjs/attaches';
import { getTransport } from "./clients";
import { PromiseClient } from "@connectrpc/connect";
import { formValidationLogic } from "./validation";

/**Stores the name of the class with which download buttons are identifier */
export const downloadButtonClass = "__download";
export const editorJSIdentifierClass = "_editorjs";

/**Stores the nil UUID */
export const uuidNil = "00000000-0000-0000-0000-000000000000";

/**Stores the default background color */
export const bgColor = `bg-[#f8f4f3]`;

let userRole = new Role();

export function setUserRole(role: Role) {
    userRole = role;
}

export function getUserRole() {
    return userRole;
}

function alert(text: string) {
    let div = document.createElement("div");
    div.role = "alert";
    div.className = `alert`;
    div.innerHTML = `
    <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 shrink-0 stroke-current"
        fill="none"
        viewBox="0 0 24 24">
        <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
    <span>${text}</span>  
    `;
    return div;
}

/**Returns a random identifier */
export function randomId() {
    return Math.random().toString(36).substring(2, 15);
}

function showAlert(text: string, className: "alert-error" | "alert-success", opts?: { timeoutInMs?: number }) {
    let toDisplay = alert(text);
    toDisplay.id = randomId();
    if (opts === undefined || opts === null) {
        opts = {};
    }

    if (opts.timeoutInMs === undefined || opts.timeoutInMs === null) {
        opts.timeoutInMs = 5000;
    }
    if (className == "alert-error") {
        toDisplay.classList.add("alert-error");
    } else if (className == "alert-success") {
        toDisplay.classList.add("alert-success");
    }

    toDisplay.classList.add("relative");
    toDisplay.classList.add("top-10");
    toDisplay.classList.add("w-auto");
    toDisplay.classList.add("right-10");
    toDisplay.classList.add("m-2");

    let section = document.getElementById("alerts-section")!;
    section.appendChild(toDisplay);

    toDisplay.addEventListener("click", async (evt) => {
        try {
            section.removeChild(toDisplay);
        } catch (e) { }
    });

    setTimeout(() => {
        try {
            section.removeChild(toDisplay);
        } catch (e) { }
    }, opts.timeoutInMs);
    return toDisplay.id;

}

export function showFailureAlert(text: string, opts?: { timeoutInMs?: number }) {
    return showAlert(text, "alert-error", opts);
}

export function showSuccessAlert(text: string, opts?: { timeoutInMs?: number }) {
    return showAlert(text, "alert-success", opts);
}

export function destroyAlert(id: string) {
    try {
        document.body.removeChild(document.getElementById(id)!);
    } catch (e) { }
}

/**Shows a confirmation message */
export function showConfirmation(text: string, className: "alert-error" | "alert-success", onYes: (alertId: string) => void, onNo: (alertId: string) => void) {
    let toDisplay = alert(text);
    toDisplay.id = randomId();
    if (className == "alert-error") {
        toDisplay.classList.add("alert-error");
    } else if (className == "alert-success") {
        toDisplay.classList.add("alert-success");
    }

    toDisplay.classList.add("relative");
    toDisplay.classList.add("top-10");
    toDisplay.classList.add("w-auto");
    toDisplay.classList.add("right-10");
    toDisplay.classList.add("m-2");

    let yesButton = document.createElement("button");
    yesButton.className = "btn btn-warning btn-sm";
    yesButton.innerHTML = "Yes";
    toDisplay.appendChild(yesButton);

    let noButton = document.createElement("button");
    noButton.className = "btn btn-warning btn-sm";
    noButton.innerHTML = "No";
    toDisplay.appendChild(noButton);

    let section = document.getElementById("alerts-section")!;
    section.appendChild(toDisplay);

    yesButton.addEventListener("click", () => {
        onYes(toDisplay.id);
    });
    noButton.addEventListener("click", () => {
        onNo(toDisplay.id);
    });

    toDisplay.addEventListener("click", async (evt) => {
        try {
            section.removeChild(toDisplay);
        } catch (e) { }
    });

    return toDisplay.id;
}

/**Renders all the menus on the sidebar */
export function setupSidebarMenus(ctx: context) {
    let mainMenusListEl = <HTMLUListElement>document.getElementById("main-menus-list");
    while (mainMenusListEl.firstChild) {
        mainMenusListEl.removeChild(mainMenusListEl.firstChild);
    }

    // -------------------------------------------------------------------------------------
    // Add any default menus here
    mainMenusListEl.appendChild(renderMainMenu(adminMenu));
    [dashboardMenu, profileMenu, vaultMenu].forEach(submenu => {
        let li = document.createElement("li");
        li.className = "mb-1 group";
        li.appendChild(renderSecondaryMenu(submenu));
        mainMenusListEl.appendChild(li);

        if (ctx.pathname == dashboardMenu.href && submenu.menu_uid == dashboardMenu.menu_uid) {
            // Highlight dashboard menu
            li.classList.add("selected");
        } else if (ctx.pathname == profileMenu.href && submenu.menu_uid == profileMenu.menu_uid) {
            li.classList.add("selected");
        } else if (ctx.pathname == vaultMenu.href && submenu.menu_uid == vaultMenu.menu_uid) {
            li.classList.add("selected");
        }
    });
    // -------------------------------------------------------------------------------------

    // -------------------------------------------------------------------------------------
    // Add role based conditional menus here
    mainMenusClassifications.forEach(menu => {
        let mainMenuAdded = false;
        // For each menu, render the submenus
        let submenus = secondaryMenusList.filter(a => {
            if (a.parent_menu?.menu_uid === menu.menu_uid) {
                return a
            }
        });
        if (submenus.length > 0) {
            submenus.forEach(submenu => {
                let subMenuAdded = false;
                let li = document.createElement("li");
                li.className = "mb-1 group";

                tertiaryMenusList.sort((a, b) => {
                    if (a.menu_name < b.menu_name) {
                        return -1
                    }
                    return 1
                })

                // For each submenu, render the menu items
                let childMenus = tertiaryMenusList.filter(a => {
                    if (
                        a.parent_menu?.menu_uid === submenu.menu_uid && a.visibleOnUI
                        && checkIfUserHasScailoPermissions(ctx, a)
                    ) {
                        return a
                    }
                });

                if (childMenus.length > 0) {
                    if (!mainMenuAdded && menu.menu_uid != adminMenu.menu_uid) {
                        mainMenuAdded = true;
                        mainMenusListEl.appendChild(renderMainMenu(menu));
                    }
                    if (!subMenuAdded) {
                        subMenuAdded = true;
                        mainMenusListEl.appendChild(li);
                    }
                    li.appendChild(renderSecondaryMenu(submenu));
                    let ul = document.createElement("ul");
                    ul.className = "pl-7 mt-2 hidden group-[.selected]:block";
                    li.appendChild(ul);

                    childMenus.forEach(childMenu => {
                        ul.appendChild(renderTertiaryMenu(childMenu));
                        // if (childMenu.href == ctx.pathname) {
                        //     li.classList.add("selected");
                        // }
                    });
                }
            });
        }
    });
    // -------------------------------------------------------------------------------------


    setupSidebarToggles();
}

function setupSidebarToggles() {
    const sidebarToggle = <HTMLElement>document.querySelector('.sidebar-toggle')
    const sidebarOverlay = <HTMLElement>document.querySelector('.sidebar-overlay')
    const sidebarMenu = <HTMLElement>document.querySelector('.sidebar-menu')
    const main = <HTMLElement>document.querySelector('.main')

    if (sidebarToggle.getAttribute("data-setup") != "yes") {
        sidebarToggle.setAttribute("data-setup", "yes");
        sidebarToggle.addEventListener('click', function (e) {
            e.preventDefault()
            main.classList.toggle('active')
            sidebarOverlay.classList.toggle('hidden')
            sidebarMenu.classList.toggle('-translate-x-full')
        })
    }
    if (sidebarOverlay.getAttribute("data-setup") != "yes") {
        sidebarOverlay.setAttribute("data-setup", "yes");
        sidebarOverlay.addEventListener('click', function (e) {
            e.preventDefault()
            main.classList.add('active')
            sidebarOverlay.classList.add('hidden')
            sidebarMenu.classList.add('-translate-x-full')
        })
    }


    document.querySelectorAll('.sidebar-dropdown-toggle').forEach(function (item) {
        if (item.getAttribute("data-setup") != "yes") {
            item.setAttribute("data-setup", "yes");
            item.addEventListener('click', function (e) {
                e.preventDefault()
                const parent = <HTMLElement>item.parentElement;
                if (parent.classList.contains('selected')) {
                    parent.classList.remove('selected')
                } else {
                    document.querySelectorAll('.sidebar-dropdown-toggle').forEach(function (i) {
                        (<HTMLElement>i.closest('.group')).classList.remove('selected')
                    })
                    parent.classList.add('selected')
                }
            })
        }
    })
}

/**Highlights the parent menu on the sidebar */
export function highlightParentMenu(ctx: context, uiMenu: MenuItem) {
    let menusList = document.getElementById("main-menus-list")?.getElementsByTagName("li");
    for (let i = 0; i < menusList?.length!; i++) {
        let menu = menusList![i];
        if (menu.classList.contains("selected")) {
            menu.classList.remove("selected");
        }
        let children = menu.getElementsByTagName("a");
        let found = false;
        for (let j = 0; j < children.length; j++) {
            let child = children[j];
            if (child.getAttribute("href") == uiMenu.href) {
                menu.classList.add("selected");
                found = true;
                break
            }
        }
        if (found) {
            menu.scrollIntoView({ behavior: "smooth", block: "center" });
            break;
        }
    }
}

function renderMainMenu(menu: MenuItem) {
    let el = document.createElement("span");
    el.classList.add("text-gray-400");
    el.classList.add("font-bold");
    el.innerText = menu.menu_name.toUpperCase();
    return el
}

function renderSecondaryMenu(menu: MenuItem) {
    let el = document.createElement("a");
    // el.href = "";
    el.className = "flex font-semibold items-center py-2 px-4 text-gray-900 hover:bg-gray-950 hover:text-gray-100 rounded-md group-[.active]:bg-gray-800 group-[.active]:text-white group-[.selected]:bg-gray-950 group-[.selected]:text-gray-100";
    if (menu.menu_uid != dashboardMenu.menu_uid && menu.menu_uid != profileMenu.menu_uid && menu.menu_uid != vaultMenu.menu_uid) {
        el.classList.add("sidebar-dropdown-toggle");
        el.setAttribute("data-setup", "no")
    } else {
        // Add href only to dashboard
        el.href = menu.href;
    }

    el.innerHTML = `
        <i class='bx ${menu.menu_icon} mr-3 text-lg'></i>                
                    <span class="text-sm">${menu.menu_name}</span>
    `;
    if (menu.menu_uid != dashboardMenu.menu_uid && menu.menu_uid != profileMenu.menu_uid && menu.menu_uid != vaultMenu.menu_uid) {
        el.innerHTML += `<i class="ri-arrow-right-s-line ml-auto group-[.selected]:rotate-90"></i>`;
    }
    return el;
}

function renderTertiaryMenu(menu: MenuItem) {
    let el = document.createElement("a");
    el.href = menu.href;
    el.className = "text-gray-900 text-sm flex items-center hover:text-[#f84525] before:contents-[''] before:w-1 before:h-1";
    el.innerHTML = `
        <i class='bx ${menu.menu_icon} mr-3 text-lg'></i>
        <span class="text-sm">${menu.menu_name}</span>
    `;
    return el
}

function checkIfUserHasScailoPermissions(ctx: context, pageMenu: MenuItem) {
    if ([dashboardMenu.menu_uid, profileMenu.menu_uid, vaultMenu.menu_uid, materialAllFiltersMenu.menu_uid, materialRequiredFiltersMenu.menu_uid].indexOf(pageMenu.menu_uid) != -1) {
        // If the pageMenu is dashboard or profile, then return true
        return true;
    }

    let userRole = getUserRole();
    let roleAccessMap: Map<string, RoleAccess> = new Map();
    userRole.accessList.forEach(a => {
        roleAccessMap.set(a.menuUid, a);
    });
    if (pageMenu.menu_uid == associateFiltersMenu.menu_uid) {
        for (let i = 0; i < PermissionsAssociates.length; i++) {
            if (roleAccessMap.get(PermissionsAssociates[i].Uid)?.isAccessible) {
                return true;
            }
        }
    }

    // -------------------------------------------------------------------------------------------
    // Streams others
    if ([streamOpenMenu.menu_uid, streamIndividualMenu.menu_uid, streamFiltersMenu.menu_uid].indexOf(pageMenu.menu_uid) != -1) {
        for (let i = 0; i < PermissionsVendorStreams.length; i++) {
            if (roleAccessMap.get(PermissionsVendorStreams[i].Uid)?.isAccessible) {
                return true;
            }
        }
    }
    // Streams new
    if (pageMenu.menu_uid == streamCreateMenu.menu_uid) {
        if (roleAccessMap.get(PermissionVendorStreamNew.Uid)?.isAccessible) {
            return true;
        }
    }
    // -------------------------------------------------------------------------------------------


    // Orders

    // Purchase Orders
    if ([purchaseOrderFiltersMenu.menu_uid, purchaseOrderInsightsMenu.menu_uid, purchaseOrderIndividualMenu.menu_uid].indexOf(pageMenu.menu_uid) != -1) {
        for (let i = 0; i < PermissionsPurchases.length; i++) {
            if (roleAccessMap.get(PermissionsPurchases[i].Uid)?.isAccessible) {
                return true;
            }
        }
    }

    // Vendor Invoices
    if ([vendorInvoiceFiltersMenu.menu_uid, vendorInvoiceInsightsMenu.menu_uid, vendorInvoiceIndividualMenu.menu_uid].indexOf(pageMenu.menu_uid) != -1) {
        for (let i = 0; i < PermissionsVendorInvoices.length; i++) {
            if (roleAccessMap.get(PermissionsVendorInvoices[i].Uid)?.isAccessible) {
                return true;
            }
        }
    }
    if (pageMenu.menu_uid == vendorInvoiceCreateMenu.menu_uid) {
        if (roleAccessMap.get(PermissionVendorInvoiceNew.Uid)?.isAccessible || roleAccessMap.get(PermissionVendorInvoiceDrafts.Uid)?.isAccessible) {
            return true;
        }
    }

    // Outward Jobs
    if ([outwardJobFiltersMenu.menu_uid, outwardJobInsightsMenu.menu_uid, outwardJobIndividualMenu.menu_uid].indexOf(pageMenu.menu_uid) != -1) {
        for (let i = 0; i < PermissionsOutwardJobs.length; i++) {
            if (roleAccessMap.get(PermissionsOutwardJobs[i].Uid)?.isAccessible) {
                return true;
            }
        }
    }

    // Goods Receipts
    if ([goodsReceiptFiltersMenu.menu_uid, goodsReceiptInsightsMenu.menu_uid, goodsReceiptIndividualMenu.menu_uid].indexOf(pageMenu.menu_uid) != -1) {
        for (let i = 0; i < PermissionsGoodsReceipts.length; i++) {
            if (roleAccessMap.get(PermissionsGoodsReceipts[i].Uid)?.isAccessible) {
                return true;
            }
        }
    }
    if (pageMenu.menu_uid == goodsReceiptCreateMenu.menu_uid) {
        if (roleAccessMap.get(PermissionGoodsReceiptNew.Uid)?.isAccessible || roleAccessMap.get(PermissionGoodsReceiptDrafts.Uid)?.isAccessible) {
            return true;
        }
    }

    // Outward Jobs FIM
    if ([outwardJobFIMFiltersMenu.menu_uid, outwardJobFIMInsightsMenu.menu_uid, outwardJobFIMIndividualMenu.menu_uid].indexOf(pageMenu.menu_uid) != -1) {
        for (let i = 0; i < PermissionsOutwardJobsFreeIssueMaterials.length; i++) {
            if (roleAccessMap.get(PermissionsOutwardJobsFreeIssueMaterials[i].Uid)?.isAccessible) {
                return true;
            }
        }
    }

    // Outward Jobs FIMR
    if ([outwardJobFIMRFiltersMenu.menu_uid, outwardJobFIMRInsightsMenu.menu_uid, outwardJobFIMRIndividualMenu.menu_uid].indexOf(pageMenu.menu_uid) != -1) {
        for (let i = 0; i < PermissionsOutwardJobsFreeIssueMaterialsReturns.length; i++) {
            if (roleAccessMap.get(PermissionsOutwardJobsFreeIssueMaterialsReturns[i].Uid)?.isAccessible) {
                return true;
            }
        }
    }

    // Purchase Returns
    if ([purchaseReturnFiltersMenu.menu_uid, purchaseReturnInsightsMenu.menu_uid, purchaseReturnIndividualMenu.menu_uid].indexOf(pageMenu.menu_uid) != -1) {
        for (let i = 0; i < PermissionsPurchasesReturns.length; i++) {
            if (roleAccessMap.get(PermissionsPurchasesReturns[i].Uid)?.isAccessible) {
                return true;
            }
        }
    }

    // Debit Notes
    if ([debitNoteFiltersMenu.menu_uid, debitNoteInsightsMenu.menu_uid, debitNoteIndividualMenu.menu_uid].indexOf(pageMenu.menu_uid) != -1) {
        for (let i = 0; i < PermissionsDebitNotes.length; i++) {
            if (roleAccessMap.get(PermissionsDebitNotes[i].Uid)?.isAccessible) {
                return true;
            }
        }
    }

    // Purchase Payments
    if ([purchasePaymentFiltersMenu.menu_uid, purchasePaymentInsightsMenu.menu_uid].indexOf(pageMenu.menu_uid) != -1) {
        for (let i = 0; i < PermissionsPurchasesPayments.length; i++) {
            if (roleAccessMap.get(PermissionsPurchasesPayments[i].Uid)?.isAccessible) {
                return true;
            }
        }
    }

    // Quotation Requests
    if ([quotationRequestFiltersMenu.menu_uid].indexOf(pageMenu.menu_uid) != -1) {
        for (let i = 0; i < PermissionsQuotationsRequests.length; i++) {
            if (roleAccessMap.get(PermissionsQuotationsRequests[i].Uid)?.isAccessible) {
                return true;
            }
        }
    }

    // Quotation Responses
    if ([quotationResponseFiltersMenu.menu_uid].indexOf(pageMenu.menu_uid) != -1) {
        for (let i = 0; i < PermissionsQuotationsResponses.length; i++) {
            if (roleAccessMap.get(PermissionsQuotationsResponses[i].Uid)?.isAccessible) {
                return true;
            }
        }
    }

    // Purchase Enquiries
    if ([purchaseEnquiryFiltersMenu.menu_uid].indexOf(pageMenu.menu_uid) != -1) {
        for (let i = 0; i < PermissionsPurchasesEnquiries.length; i++) {
            if (roleAccessMap.get(PermissionsPurchasesEnquiries[i].Uid)?.isAccessible) {
                return true;
            }
        }
    }

    // Supply Offers
    if ([supplyOfferFiltersMenu.menu_uid, supplyOfferInsightsMenu.menu_uid, supplyOfferIndividualMenu.menu_uid].indexOf(pageMenu.menu_uid) != -1) {
        for (let i = 0; i < PermissionsSupplyOffers.length; i++) {
            if (roleAccessMap.get(PermissionsSupplyOffers[i].Uid)?.isAccessible) {
                return true;
            }
        }
    }
    if (pageMenu.menu_uid == supplyOfferCreateMenu.menu_uid) {
        if (roleAccessMap.get(PermissionSupplyOfferNew.Uid)?.isAccessible || roleAccessMap.get(PermissionSupplyOfferDrafts.Uid)?.isAccessible) {
            return true;
        }
    }

    // QA Samples
    if ([qaSampleFiltersMenu.menu_uid, qaSampleInsightsMenu.menu_uid, qaSampleIndividualMenu.menu_uid].indexOf(pageMenu.menu_uid) != -1) {
        for (let i = 0; i < PermissionsQcSamples.length; i++) {
            if (roleAccessMap.get(PermissionsQcSamples[i].Uid)?.isAccessible) {
                return true;
            }
        }
    }

    return false;
}

function acceptPagePermission(ctx: context, pageMenu: MenuItem, highlightMenu: MenuItem) {
    setupSidebarMenus(ctx);
    document.title = toTitleCase(pageMenu.menu_uid.split("-").join(" "));
    highlightParentMenu(ctx, highlightMenu);

    return true
}

export async function interceptPage(ctx: context, pageMenu: MenuItem, optionalHighlightPageMenu?: MenuItem): Promise<boolean> {
    if (optionalHighlightPageMenu == undefined || optionalHighlightPageMenu == null) {
        optionalHighlightPageMenu = pageMenu;
    }
    if ([dashboardMenu.menu_uid, profileMenu.menu_uid, vaultMenu.menu_uid].indexOf(pageMenu.menu_uid) != -1) {
        // If the pageMenu is dashboard or profile, then return true
        return acceptPagePermission(ctx, pageMenu, optionalHighlightPageMenu);
    }
    const hasPerm = checkIfUserHasScailoPermissions(ctx, pageMenu);
    if (hasPerm) {
        return acceptPagePermission(ctx, pageMenu, optionalHighlightPageMenu);
    }

    return false
}

export function handlePageForInvalidPermissions(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    content.innerHTML = "Invalid page";
}

/**Converts the string to a title */
export function toTitleCase(str: string) {
    return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
}

/**Returns the user's badge */
export function returnUserBadge(user: User): HTMLSpanElement {
    let outerSpan = document.createElement("span");
    outerSpan.classList.add("tooltip");
    outerSpan.setAttribute("data-tip", `Username: ${user.username}, Email: ${user.email}, Phone: ${user.phone}`);

    let div = document.createElement("div");
    div.classList.add("p-4", "m-1");
    div.classList.add("badge");
    div.classList.add("text-white");
    div.classList.add("badge-outline");
    if (user.userType == USER_TYPE.USER_TYPE_EMPLOYEE) {
        div.classList.add("badge-info");
    } else {
        div.classList.add("badge-primary");
    }
    div.innerHTML = `
        <a class="mr-2" href="tel:${user.phone}"><i class='bx bxs-phone'></i></a> 
        ${user.name}
    `;

    let avatarDiv = document.createElement("div");
    avatarDiv.classList.add("avatar");
    avatarDiv.classList.add("w-full");
    avatarDiv.classList.add("justify-center");
    avatarDiv.innerHTML = `
        <div class="w-12 rounded-full">
            <img src="/avatar/${user.metadata?.uuid}" alt="${user.name} Image" />
        </div>
    `;

    outerSpan.appendChild(avatarDiv);
    outerSpan.appendChild(div);
    return outerSpan;
}

/**Returns the associate's badge */
export function returnAssociateBadge(contact: Associate): HTMLSpanElement {
    let outerSpan = document.createElement("span");
    outerSpan.classList.add("tooltip");
    outerSpan.classList.add("col-span-3");
    outerSpan.setAttribute("data-tip", `Name: ${contact.firstName} ${contact.lastName}, Email: ${contact.workEmail}, Phone: ${contact.workPhone}`);

    let span = document.createElement("span");
    span.classList.add("p-4", "m-1");
    span.classList.add("badge");
    span.classList.add("text-white");
    span.classList.add("badge-primary");
    span.innerHTML = `
        <a class="mr-2" href="tel:${contact.workPhone}"><i class='bx bxs-phone'></i></a> 
        ${contact.firstName} ${contact.lastName}
    `;

    outerSpan.appendChild(span);
    return outerSpan;
}

/**Returns a number divided by 100 -> converts cents to money, and returns it to 2 decimal places */
export function convertCentsToMoney(num: number | bigint): string {
    let n = 0;
    if (typeof (num) == "number") {
        n = num;
    } else if (typeof (num) == "bigint") {
        n = parseInt(String(num));
    }
    if (n === undefined || n === null || isNaN(n)) {
        return "0.00";
    }
    return round(n / 100);
}

/**Returns a colorized amount */
export function colorizeInvertedCentsToMoney(num: bigint, additionalText?: string): string {
    let span = document.createElement("span");
    span.classList.add("p-4");
    span.classList.add("badge");
    span.classList.add("text-white");
    if (num == protoInt64.zero) {
        span.classList.add("badge-success");
    } else if (num < protoInt64.zero) {
        span.classList.add("badge-info");
    } else {
        span.classList.add("badge-error");
    }
    span.innerText = convertCentsToMoney(num);
    if (additionalText) {
        span.innerText += " " + additionalText;
    }
    return span.outerHTML;
}

/**Returns a colorized number */
export function convertAndColorizeBigintInverted(num: bigint): string {
    let span = document.createElement("span");
    span.classList.add("p-4");
    span.classList.add("badge");
    span.classList.add("text-white");
    if (num == protoInt64.zero) {
        span.classList.add("badge-success");
    } else if (num < protoInt64.zero) {
        span.classList.add("badge-info");
    } else {
        span.classList.add("badge-error");
    }
    span.innerText = num.toString();
    return span.outerHTML;
}

export function convertAndColorizeBigint(num: bigint): string {
    let span = document.createElement("span");
    span.classList.add("p-4");
    span.classList.add("badge");
    span.classList.add("text-white");
    if (num <= protoInt64.zero) {
        span.classList.add("badge-error");
    } else {
        span.classList.add("badge-success");
    }
    span.innerText = num.toString();
    return span.outerHTML;
}

/**Returns the file size in human readable format */
export function convertBytesToHumanReadbleFileSize(size: number): string {
    if (size < Math.pow(10, 3)) {
        return `${size} B`;
    }
    if (size < Math.pow(10, 6)) {
        return `${(size / Math.pow(10, 3)).toFixed(2)} kB`;
    }
    if (size < Math.pow(10, 9)) {
        return `${(size / Math.pow(10, 6)).toFixed(2)} MB`;
    }
    if (size < Math.pow(10, 12)) {
        return `${(size / Math.pow(10, 9)).toFixed(2)} GB`;
    }
    if (size < Math.pow(10, 15)) {
        return `${(size / Math.pow(10, 12)).toFixed(2)} TB`;
    }
    return ""
}

/**Returns the number using the internationalized version for currency amounts. Input is in float or number (and not in cents) */
export function internationalizeMoney(amount: number): string {
    return new Intl.NumberFormat(Intl.DateTimeFormat().resolvedOptions().locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(parseFloat(round(amount)))
}

/**Rounds the number and brings it to parity with the number represented by the server  */
export function round(num: number): string {
    let fraction = parseFloat((num - Math.trunc(num)).toFixed(3));
    if (fraction == 0.125 || fraction == 0.425 || fraction == 0.625) {
        num -= 1 / 1000;
    }
    return num.toFixed(2);
}

/**Returns the date from the UNIX timestamp. This is needed when the timestamp is encoded as a bigint */
export function convertBigIntTimestampToDate(timestamp: bigint): string {
    let parsedTimestamp = parseInt(String(timestamp));
    if (parsedTimestamp == 0) {
        return "-";
    }
    return new Date(parsedTimestamp * 1000).toDateString();
}

/** Returns the date from UNIX timestamp. This is needed when the timestamp is encoded as a bigint */
export function convertBigIntTimestampToDateTime(timestamp: bigint): string {
    let parsedTimestamp = parseInt(String(timestamp));
    if (parsedTimestamp == 0) {
        return "-";
    }
    let dt = new Date(parsedTimestamp * 1000);
    return dt.toDateString() + " " + dt.toLocaleTimeString();
}

export function removeCommasFromMoney(val: string) {
    return val.replace(/,/g, "");
}

function convertToDatatype(val: string, dataType: inputDataType) {
    if (val.trim().length == 0) {
        return null;
    };

    if (dataType == "number") {
        return parseInt(removeCommasFromMoney(val));
    } else if (dataType == "money_in_cents") {
        return _returnInCents(parseFloat(removeCommasFromMoney(val)));
    } else if (dataType == "date") {
        // Server also accepts YYYY-MM-DD format
        return val;
    } else if (dataType == "timestamp") {
        return ((new Date(val).getTime()) / 1000);
    } else if (dataType == "string") {
        return val;
    } else if (dataType == "bigint") {
        return protoInt64.parse(removeCommasFromMoney(val));
    } else if (dataType == "bigint_in_cents") {
        return protoInt64.parse(_returnInCents(parseFloat(removeCommasFromMoney(val))));
    }
    return val;
}

export function createObjectFromForm(formId: string) {
    let form = <HTMLDivElement>document.getElementById(formId);
    let elements = form.getElementsByClassName(fieldValidatorClass);
    let formObject: Object = {};
    for (let i = 0; i < elements.length; i++) {
        let el = <HTMLElement>elements[i];

        let convertedVal;
        if (el.nodeName.toLowerCase() == "input") {
            convertedVal = convertToDatatype((<HTMLInputElement>el).value, el.getAttribute("data-type") as inputDataType);
        } else if (el.nodeName.toLowerCase() == "select") {
            convertedVal = convertToDatatype((<HTMLSelectElement>el).value, el.getAttribute("data-type") as inputDataType);
        }
        if (convertedVal != null) {
            formObject[el.getAttribute("data-mapper") || ""] = convertedVal;
        }
    }
    return formObject;
}

/**Returns the list of dynamic form data */
export function getDynamicFormData(): FormFieldDatumCreateRequest[] {
    let list = <FormFieldDatumCreateRequest[]>[];

    let form = document.getElementsByClassName(formDynamicFormClass);
    if (form.length == 0) {
        return list;
    }
    let f = <HTMLDivElement>form[0];

    let elements = f.getElementsByClassName(formFieldClass);
    for (let i = 0; i < elements.length; i++) {
        let el = <HTMLElement>elements[i];
        let val = "";
        if (el.nodeName.toLowerCase() == "input") {
            let e = <HTMLInputElement>el;
            if (el.getAttribute("type") == "date" && e.value.trim().length) {
                val = (new Date(e.value)).toDateString();
            } else {
                val = e.value;
            }
        } else if (el.nodeName.toLowerCase() == "select") {
            val = (<HTMLSelectElement>el).value;
        } else if (el.nodeName.toLowerCase() == "textarea") {
            val = (<HTMLTextAreaElement>el).value;
        }

        list.push(new FormFieldDatumCreateRequest({
            formFieldId: protoInt64.parse(el.getAttribute("data-form-field-id")!),
            value: val,
            selectedValues: <string[]>[],
        }));
    }

    return list;
}

/**Returns the value multiplied by 100, after it is truncated to 2 digits */
export function _returnInCents(num: number): number {
    // Convert to string
    let numInStr = num.toFixed(3);

    if (numInStr.includes(".")) {
        let fractional = numInStr.split(".");
        let characteristic = fractional[0];
        let mantissa = fractional[1];
        numInStr = characteristic + "." + mantissa.substring(0, 2);
        num = parseFloat(numInStr);
    }

    return Math.round(num * 1000) / 10;
}

/** Returns the table div (enclosed in a responsive div) with the provided inputs, along with the headers and rows that could be used to generate a CSV file */
export function renderTableWithCSVHeadersAndRows({ title, headers_array, rows_array, responsive, highlightRowIndex }: { title: string, headers_array: string[], rows_array: string[][], responsive?: boolean, highlightRowIndex?: number }): { table: HTMLDivElement, headers: string[], rows: string[][] } {
    let parentContainer = document.createElement("div");
    parentContainer.id = randomId();

    if (responsive == undefined || responsive == null) {
        responsive = true;
    }
    if (highlightRowIndex == undefined || highlightRowIndex == null) {
        highlightRowIndex = -1;
    }

    let serialNumberHeaderFound = false;

    if (headers_array.length > 0) {
        let firstHeader = headers_array[0].toLowerCase().split(" ").join("");
        if (firstHeader.indexOf("s.no") > -1) {
            serialNumberHeaderFound = true;
        }
    }

    if (!serialNumberHeaderFound) {
        headers_array.splice(0, 0, "S. No.");
    }

    let table = document.createElement("table");
    table.style.width = "100%";
    table.id = (Math.random() + 1).toString(36).substring(7);
    table.className = "table";

    // Create the header here
    let thead = document.createElement("thead");
    thead.classList.add("text-primary");
    thead.classList.add("text-center");
    thead.classList.add("sticky", "top-0", bgColor);
    let head_row = document.createElement("tr");
    for (let i = 0; i < headers_array.length; i++) {
        let th = document.createElement("th");
        th.innerHTML = headers_array[i];
        head_row.appendChild(th);
    }

    thead.appendChild(head_row);

    // Create the table body here
    let tbody = document.createElement("tbody");
    tbody.classList.add("overflow-y-auto", "max-h-96");
    tbody.style.textAlign = "center";
    for (let i = 0; i < rows_array.length; i++) {
        let trow = document.createElement("tr");

        if (i == highlightRowIndex) {
            trow.style.backgroundColor = "#EEEEEE";
            trow.style.color = "#008a00";
        }

        let row = rows_array[i];
        if (!serialNumberHeaderFound) {
            row.splice(0, 0, `${i + 1}.`);
        }

        for (let j = 0; j < row.length; j++) {
            let td = document.createElement("td");
            td.innerHTML = row[j];
            trow.appendChild(td);
        }
        tbody.appendChild(trow);
    }

    table.appendChild(thead);
    table.appendChild(tbody);

    let responsiveDiv = document.createElement("div");
    responsiveDiv.classList.add("block", "max-h-96");
    if (responsive) {
        responsiveDiv.classList.add("table-responsive-lg");
    }
    if (title.length > 0) {
        let titleDiv = document.createElement("div");
        titleDiv.className = "text-center text-xl mb-2";
        titleDiv.innerText = title;
        parentContainer.appendChild(titleDiv);
    }

    responsiveDiv.appendChild(table);

    let sNo = 1;
    for (let i = 0; i < rows_array.length; i++) {
        let row = rows_array[i];
        row[0] = `${sNo}.`;
        for (let j = 0; j < row.length; j++) {
            let parsedText = "";
            if (j == 0) {
                continue;
            } else {
                let item = row[j];
                let t_ = document.createElement("span");
                t_.innerHTML = item;
                if (t_.children.length == 0) {
                    // This is not an HTML item
                    parsedText = item;
                } else {
                    // This is a HTML item
                    parsedText = (<HTMLElement>t_.children[0]).innerText;
                }
            }
            rows_array[i][j] = parsedText;
        }
        sNo++;
    }
    parentContainer.appendChild(responsiveDiv);

    return {
        table: parentContainer,
        headers: headers_array,
        rows: rows_array
    };
}

/**Returns a generic button with the provided parameters */
export function downloadButtonFunction(buttonID: string, buttonText: string, btnClass: "success" | "danger" | "warning" | "info" | "primary" | "secondary", extraClasses?: string): HTMLButtonElement {
    if (buttonID.length == 0) {
        buttonID = (Math.random() + 1).toString(36).substring(7);
    }
    let button = document.createElement("button");
    button.id = buttonID;
    button.type = "button";
    button.className = `btn btn-sm btn-${btnClass} ${extraClasses !== undefined && extraClasses !== null ? extraClasses : ""} text-white mb-4`;
    button.innerHTML = `<i class='bx bx-cloud-download'></i> ${buttonText}`;
    return button;
}

/**Setup the download CSV button for the records that are generated in a corresponding table */
export function setupDownloadForFilters(recordsDiv: { table: HTMLDivElement, headers: string[], rows: string[][] }, contentDiv: HTMLElement, fileName: string, numberOfColsToIgnore: number) {
    let buttonsDiv = document.createElement("div");
    buttonsDiv.className = `col-span-12 pl-4 float-right`;
    contentDiv.appendChild(buttonsDiv);

    let downloadButton = downloadButtonFunction("", "Download CSV", "success", "pull-left");
    // Attach the button before the title, since the close button is appended to the first child of the table
    buttonsDiv.appendChild(downloadButton);
    contentDiv.appendChild(recordsDiv.table);

    downloadButton.addEventListener("click", async evt => {
        evt.preventDefault();
        const len = recordsDiv.headers.length;
        const headers = recordsDiv.headers.filter((item, i) => {
            if (i < len - numberOfColsToIgnore) {
                return item
            }
        });
        let rows = <string[][]>[];
        for (let i = 0; i < recordsDiv.rows.length; i++) {
            let newRow = <string[]>[];
            let oldRow = recordsDiv.rows[i];
            for (let j = 0; j < oldRow.length; j++) {
                if (j < len - numberOfColsToIgnore) {
                    newRow.push(oldRow[j]);
                }
            }
            rows.push(newRow);
        }

        downloadAsCSV(headers, rows, fileName + ` - ${new Date().toLocaleString()}`);
    });

    try {
        // Setup smooth scrolling to the table
        document.getElementById(recordsDiv.table.id)?.scrollIntoView({
            behavior: "smooth",
            // block: "center"
        });
    } catch (e) {}
}

export function downloadData(content: any, exportType: "csv" | "pdf", fileNameWithoutExtension: string) {
    let a = document.createElement('a');
    if (exportType == "csv") {
        let blob = new Blob([content], { type: "text/csv" });// change resultByte to bytes
        let objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = `${fileNameWithoutExtension}.csv`;
    } else if (exportType == "pdf") {
        let blob = new Blob([content], { type: "application/pdf" });
        let objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = `${fileNameWithoutExtension}.pdf`;
    }

    // else if (exportType == "excel") {
    //     var blob = new Blob([content], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    //     var objectUrl = URL.createObjectURL(blob);
    //     a.href = objectUrl;
    //     a.download = `${fileName}.xlsx`;
    // }

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

// Download the given headers and rows as a CSV with the given file name
export function downloadAsCSV(headers: string[], rows: string[][], fileNameWithoutExtension: string) {
    let allRows = <string[][]>[];
    allRows.push(headers);
    rows.forEach(row => {
        allRows.push(row);
    });
    let csv = papaparse.unparse(allRows);
    downloadData(csv, "csv", fileNameWithoutExtension);
}

/**Returns the decoded vendor stream status */
export function decodeVendorStreamStatus(status: VENDOR_STREAM_LIFECYCLE): "open" | "completed" | "cancelled" | "" {
    if (status == VENDOR_STREAM_LIFECYCLE.VENDOR_STREAM_LIFECYCLE_OPEN) {
        return "open"
    } else if (status == VENDOR_STREAM_LIFECYCLE.VENDOR_STREAM_LIFECYCLE_COMPLETED) {
        return "completed"
    } else if (status == VENDOR_STREAM_LIFECYCLE.VENDOR_STREAM_LIFECYCLE_CANCELLED) {
        return "cancelled"
    }
    return ""
}

/**Returns the decoded vendor stream ref from */
export function decodeVendorStreamRefFrom(refFrom: VENDOR_STREAM_REF_FROM) {
    if (refFrom == VENDOR_STREAM_REF_FROM.VENDOR_STREAM_REF_FROM_PURCHASE_ORDER) {
        return "purchase-order";
    } else if (refFrom == VENDOR_STREAM_REF_FROM.VENDOR_STREAM_REF_FROM_GOODS_RECEIPT) {
        return "goods-receipt";
    } else if (refFrom == VENDOR_STREAM_REF_FROM.VENDOR_STREAM_REF_FROM_VENDOR_INVOICE) {
        return "vendor-invoice";
    } else if (refFrom == VENDOR_STREAM_REF_FROM.VENDOR_STREAM_REF_FROM_PURCHASE_RETURN) {
        return "purchase-return";
    } else if (refFrom == VENDOR_STREAM_REF_FROM.VENDOR_STREAM_REF_FROM_DEBIT_NOTE) {
        return "debit-note";
    } else if (refFrom == VENDOR_STREAM_REF_FROM.VENDOR_STREAM_REF_FROM_PURCHASE_PAYMENT) {
        return "purchase-payment";
    } else if (refFrom == VENDOR_STREAM_REF_FROM.VENDOR_STREAM_REF_FROM_SUPPLY_OFFER) {
        return "supply-offer";
    }
    return ""
}

/**Attaches editor.js to divs */
export function attachEditor(editorClass: string, opts: {
    vaultFolderUuid: string
}) {
    const vaultAccessClient = getClientForVaultService(getTransport());
    let allDivs = document.getElementsByClassName(editorClass);
    for (let i = 0; i < allDivs.length; i++) {
        let div = <HTMLDivElement>allDivs[i];
        if (div.getAttribute("data-editor-added") == "true") {
            continue
        }

        let initialContentStr = div.getAttribute("data-value") || "";
        let initialContentJSON = <OutputData>{};
        try {
            initialContentJSON = JSON.parse(decodeURIComponent(initialContentStr));
            // Resetting the value since the original value is URI encoded, but createObjectFromForm expects this to be a JSON object
            div.setAttribute("data-value", JSON.stringify(initialContentJSON));
        } catch { }
        const readonly = div.getAttribute("data-readonly") == "true" ? true : false;
        const editor = new EditorJS({
            holder: div,
            hideToolbar: false,
            onReady: () => {
                new Undo({ editor });
            },
            onChange: async (api, event) => {
                if (!readonly) {
                    const content = await api.saver.save();
                    div.setAttribute("data-value", JSON.stringify(content));
                }
            },
            readOnly: readonly,
            data: initialContentJSON,
            tools: {
                Color: {
                    class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
                    config: {
                        colorCollections: ['#EC7878', '#9C27B0', '#673AB7', '#3F51B5', '#0070FF', '#03A9F4', '#00BCD4', '#4CAF50', '#8BC34A', '#CDDC39', '#FFF'],
                        defaultColor: '#FF1300',
                        type: 'text',
                        customPicker: true // add a button to allow selecting any colour  
                    }
                },
                list: {
                    class: <any>NestedList,
                    inlineToolbar: true,
                    config: {
                        defaultStyle: 'unordered'
                    },
                },
                paragraph: {
                    class: Paragraph,
                    inlineToolbar: true,
                },
                fontSize: FontSizeTool,
                // Header is not required, since paragraph can be used, with adjusted font size
                // header: Header,
                table: {
                    class: <any>Table,
                    inlineToolbar: true,
                    config: {
                        rows: 2,
                        cols: 3,
                        // withHeadings: true,
                    },
                },
                Marker: {
                    class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
                    config: {
                        defaultColor: '#FFBF00',
                        type: 'marker',
                        // icon: `<svg fill="#000000" height="200px" width="200px" version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M17.6,6L6.9,16.7c-0.2,0.2-0.3,0.4-0.3,0.6L6,23.9c0,0.3,0.1,0.6,0.3,0.8C6.5,24.9,6.7,25,7,25c0,0,0.1,0,0.1,0l6.6-0.6 c0.2,0,0.5-0.1,0.6-0.3L25,13.4L17.6,6z"></path> <path d="M26.4,12l1.4-1.4c1.2-1.2,1.1-3.1-0.1-4.3l-3-3c-0.6-0.6-1.3-0.9-2.2-0.9c-0.8,0-1.6,0.3-2.2,0.9L19,4.6L26.4,12z"></path> </g> <g> <path d="M28,29H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h24c0.6,0,1,0.4,1,1S28.6,29,28,29z"></path> </g> </g></svg>`
                    }
                },
                delimiter: Delimiter,
                checklist: {
                    class: Checklist,
                    inlineToolbar: true,
                },
                underline: Underline,
                changeCase: {
                    class: ChangeCase,
                    config: {
                        showLocaleOption: true, // enable locale case options
                    }
                },
                strikethrough: Strikethrough,
                quote: Quote,
                alert: Alert,
                attaches: {
                    class: AttachesTool,
                    config: {
                        uploader: {
                            uploadByFile: async function (file: File) {
                                if (opts.vaultFolderUuid.length == 0) {
                                    showAlert("File upload is not possible here", "alert-error");
                                    return {
                                        success: 0,
                                        file: {}
                                    };
                                }
                                if (opts.vaultFolderUuid.length != 36) {
                                    showAlert("File upload is not possible since Vault Folder has not been configured yet. Kindly configure the folder and try again.", "alert-error");
                                    return {
                                        success: 0,
                                        file: {}
                                    };
                                }
                                try {
                                    let uploadedFileResp = await _addFileFunctionality(opts.vaultFolderUuid, file, true, vaultAccessClient);
                                    return {
                                        success: 1,
                                        file: {
                                            url: `/vault/downloads/version?uuid=` + uploadedFileResp.versionUUID,
                                            name: file.name,
                                            title: file.name,
                                            size: file.size,
                                            readonly: true,
                                            fileUuid: uploadedFileResp.fileUUID,
                                            versionUuid: uploadedFileResp.versionUUID
                                        }
                                    }
                                } catch (e) {
                                    showAlert("Something went wrong", "alert-error");
                                    return {
                                        success: 0,
                                        file: {}
                                    };
                                }
                            },
                        }
                    }
                }
            }
        });
        div.setAttribute("data-editor-added", "true");
    }
}

export function convertEditorJSContentToHTML(content: string) {
    const local = edjsParser({
        // Header is not required, since paragraph can be used, with adjusted font size
        // header: Header,
        table: (block: any) => {
            const rows = block.data.content
                .map((row, rowIndex) => `<tr>${row
                    .map(cell => {
                        const cellTag = (block.data.withHeadings && rowIndex === 0) ? 'th' : 'td'
                        return `<${cellTag}>${cell}</${cellTag}>`
                    })
                    .join('')
                    }</tr>`)
                .join('')
            return `<table>${rows}</table>`
        },
        checklist: (block: any) => {
            const rows = block.data.items
                .map((row, rowIndex) => {
                    let div = document.createElement("div");

                    let checkbox = document.createElement("input");
                    checkbox.type = "checkbox";
                    checkbox.disabled = true;
                    if (row.checked) {
                        checkbox.setAttribute("checked", row.checked);
                    }

                    let label = document.createElement("label");
                    label.innerHTML = row.text;
                    label.style.paddingLeft = "3px";

                    div.appendChild(checkbox);
                    div.appendChild(label);

                    return div.outerHTML;
                }).join('');
            return `<div>${rows}</div>`;
        },
        quote: (block: any) => {
            let blockquote = document.createElement("blockquote");
            blockquote.classList.add("text-gray-50", "italic", "border-l-4", "border-gray-500", "pl-4", "my-4", "text-small");
            blockquote.innerHTML = block.data.text;
            return blockquote.outerHTML;
        },
        alert: (block: any) => {
            return `
                <div class="cdx-alert cdx-alert-${block.data.type} cdx-alert-align-${block.data.align}">
                    <div class="cdx-alert__message" contenteditable="false" data-placeholder="Type here...">
                        ${block.data.message}
                    </div>
                </div>
            `;
        },
        attaches: (block: any) => {
            let fileExt = "";
            let splitFileName = block.data.file.name.split(".");
            if (splitFileName.length > 1) {
                fileExt = splitFileName[splitFileName.length - 1];
            }

            // File's fileUuid and versionUuid are also being stored inside block.data
            // This could be used to determine the current name of the file, for example, especially if the name has changed

            return `
                <div class="cdx-block">
                    <div class="cdx-attaches cdx-attaches--with-file">
                        <div class="cdx-attaches__file-icon">
                            <div class="cdx-attaches__file-icon-background" style="background-color: rgb(219, 47, 47);"></div>
                            <div class="cdx-attaches__file-icon-label" title="${fileExt}" style="background-color: rgb(219, 47, 47);">${fileExt}</div>
                        </div>
                    <div class="cdx-attaches__file-info">
                        <div class="cdx-attaches__title" contenteditable="false" data-placeholder="File title">${block.data.file.name}</div>
                        <div class="cdx-attaches__size">${convertBytesToHumanReadbleFileSize(block.data.file.size)}</div>
                    </div>
                    <a class="cdx-attaches__download-button" href="${block.data.file.url}" target="_blank" rel="nofollow noindex noreferrer"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M7 10L11.8586 14.8586C11.9367 14.9367 12.0633 14.9367 12.1414 14.8586L17 10"></path></svg></a>
                </div>
            `;
        }
    });
    try {
        return local.parse(JSON.parse(content))
    } catch (e) {
        return [];
    }
}

function _handleFileAlreadyExists(folderUUID: string, file: File, fileType: string, vaultAccessClient: PromiseClient<typeof VaultService>) {
    let fileUUID = "";
    showConfirmation(`File: ${file.name} already exists in this folder. Do you want to create a new version of the same file?`, "alert-error", 
        (alertId: string) => { 
            fileReader(file, fileType, folderUUID, vaultAccessClient);
            destroyAlert(alertId);
        }, (alertId: string) => { 
            destroyAlert(alertId);
    });

    // This might return an empty string
    return {
        fileUUID,
        versionUUID: "",
    };
}

/**Checks if a file with the given parameters exists in the specified folder */
export async function __checkIfFileExists(fileName: string, folderUUID: string, vaultAccessClient: PromiseClient<typeof VaultService>): Promise<boolean> {
    return (await vaultAccessClient.doesFileExist({
        name: fileName,
        folderUuid: folderUUID,
    })).value;
}

/**Handles the add file functionality */
export async function _addFileFunctionality(folderUUID: string, file: File, forceUploadNewVersionIfAlreadyExists: boolean, vaultAccessClient: PromiseClient<typeof VaultService>) {
    let fileType = file.type;
    if (fileType.length == 0) {
        fileType = "application/octet-stream";
    }
    // Check if the file already exists in this folder
    let exists = await __checkIfFileExists(file.name, folderUUID, vaultAccessClient);
    if (exists && !forceUploadNewVersionIfAlreadyExists) {
        // If yes, display a notification, along with a prompt asking if a new version should be created. If the user says yes, then proceed; otherwise, cancel.
        return _handleFileAlreadyExists(folderUUID, file, fileType, vaultAccessClient);
    } else {
        return fileReader(file, fileType, folderUUID, vaultAccessClient);
    }
}

/**Creates a reader that stores the file content in base64 and stores in a span tag with fileContentIdentificationClassName */
export async function fileReader(f: File, fileType: string, folderUUID: string, vaultAccessClient: PromiseClient<typeof VaultService>): Promise<{
    fileUUID: string,
    versionUUID: string,
}> {
    let reader = new FileReader();

    let fType = fileType;
    if (fileType == "application/zip" || fileType == "application/x-zip-compressed") {
        fType = "application/zip";
    }

    if (f.name.endsWith(".gix")) {
        // This is Genesis application
        fType = "application/gix";
    }

    let notification = showSuccessAlert(`Uploading ${f.name}...`, { timeoutInMs: -1 });

    let initiateFileResp = (await vaultAccessClient.initiateFile({
        name: f.name,
        type: fType,
        folderUuid: folderUUID,
    }));

    let versions = (await vaultAccessClient.viewFileVersions({ uuid: initiateFileResp.uuid })).list;

    reader.onload = async function (e) {

        let buffer = <ArrayBuffer>(<any>e.target).result;
        let dataArray = new Uint8Array(buffer);

        setTimeout(async function () {
            await uploadFileChunks(initiateFileResp.uuid, dataArray, parseInt(String(initiateFileResp.chunkSize)), vaultAccessClient);
            await completeFileUpload(initiateFileResp.uuid, vaultAccessClient);
            destroyAlert(notification);
            showSuccessAlert(`Successfully uploaded ${f.name}`, { timeoutInMs: 5000 });
        }, 10);
    }
    reader.readAsArrayBuffer(f);
    return {
        fileUUID: initiateFileResp.uuid,
        versionUUID: versions[0]?.metadata!.uuid,
    };
}

/**Uploads the file contents as chunks */
export async function uploadFileChunks(fileUUID: string, dataArray: Uint8Array, maxChunkSize: number, vaultAccessClient: PromiseClient<typeof VaultService>) {
    let startIndex = 0;
    let chunkSize = 0;
    let sequenceID = 1;
    while (chunkSize < dataArray.length) {
        startIndex = chunkSize;
        chunkSize = startIndex + Math.min(maxChunkSize, dataArray.length - chunkSize);
        await vaultAccessClient.addFileChunk({
            uuid: fileUUID,
            sequenceId: protoInt64.parse(sequenceID),
            chunk: dataArray.slice(startIndex, chunkSize)
        });

        sequenceID++;
    }
}

export async function completeFileUpload(fileUUID: string, vaultAccessClient: PromiseClient<typeof VaultService>) {
    // console.log("File upload has finished");
    await vaultAccessClient.completeFile({ uuid: fileUUID });
}

/**Handles the upload CSV functionality */
export function handleCSVFileUpload(onFileRead: (fileData: Uint8Array) => void) {
    // Show a file picker and allow selection of file
    let up = document.createElement("input");
    up.type = "file";
    up.accept = ".csv";
    up.style.display = "none";
    document.body.appendChild(up);
    up.addEventListener("change", evt => {
        let files = <File[]>(<any>evt.target).files;
        if (files.length == 0) {
            showFailureAlert("No file selected");
            return
        }
        if (files.length > 1) {
            showFailureAlert("Kindly select only 1 CSV file");
            return
        }
        let file = files[0];
        readCSVFile(file, onFileRead);
    });
    up.click();
}

/**Reads the CSV file and calls the callback function with the Uint8Array */
function readCSVFile(file: File, onFileRead: (fileData: Uint8Array) => void) {
    let reader = new FileReader();

    // Diplay a notification here that the file is being prepared for upload
    let nID = showSuccessAlert(`${file.name} is being prepared for upload`);

    reader.onload = async function (e) {
        // Close the notification
        destroyAlert(nID);

        let buffer = <ArrayBuffer>(<any>e.target).result;
        let dataArray = new Uint8Array(buffer);

        let fileType = file.type;
        // This is to normalize on Windows, since Windows marks CSV files also as Excel files (don't ask me why)
        // This might not be reliable, but looks like this is the best that we can do at the moment
        if (file.name.endsWith(".csv") && fileType == "application/vnd.ms-excel") {
            fileType = "text/csv";
        }

        if (fileType != "text/csv") {
            showFailureAlert("Invalid file - can only upload CSV file");
            return;
        }

        onFileRead(dataArray);
    }
    reader.readAsArrayBuffer(file);
}

/**Converts a query string of type foo=bar&foo2=bar2 to { 'foo' : 'bar', 'foo2' : 'bar2' } */
export function convertQueryStringToObject(queryString: string): Object {
    // console.log(queryString);
    let splitQuery = queryString.split("&")
    let obj: Object = {};
    splitQuery.map(query => {
        let parts = query.split("=");
        if (parts.length == 2) {
            let key = parts[0];
            if (key.includes("?", 0)) {
                key = key.replace("?", "");
            }
            obj[key] = parts[1];
        }
    });
    return obj;
}

// Returns the standard lifecycle enum from the given status string
export function encodeSLC(status: "preverify" | "draft" | "verified" | "standing" | "revision" | "halted" | "completed" | "discarded" | "any"): STANDARD_LIFECYCLE_STATUS {
    if (status == "preverify") {
        return STANDARD_LIFECYCLE_STATUS.PREVERIFY;
    } else if (status == "draft") {
        return STANDARD_LIFECYCLE_STATUS.DRAFT;
    } else if (status == "verified") {
        return STANDARD_LIFECYCLE_STATUS.VERIFIED;
    } else if (status == "standing") {
        return STANDARD_LIFECYCLE_STATUS.STANDING;
    } else if (status == "revision") {
        return STANDARD_LIFECYCLE_STATUS.REVISION;
    } else if (status == "halted") {
        return STANDARD_LIFECYCLE_STATUS.HALTED;
    } else if (status == "completed") {
        return STANDARD_LIFECYCLE_STATUS.COMPLETED;
    } else if (status == "discarded") {
        return STANDARD_LIFECYCLE_STATUS.DISCARDED;
    } else if (status == "any") {
        return STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    }
    return STANDARD_LIFECYCLE_STATUS.PREVERIFY;
}

// Returns the stringified version of the standard lifecycle status, useful for inserting into the database
export function decodeSLC(status: STANDARD_LIFECYCLE_STATUS): "preverify" | "draft" | "verified" | "standing" | "revision" | "halted" | "completed" | "discarded" | "any" {
    if (typeof (status) == "string") {
        return status;
    }
    if (status == STANDARD_LIFECYCLE_STATUS.PREVERIFY) {
        return "preverify";

    } else if (status == STANDARD_LIFECYCLE_STATUS.DRAFT) {
        return "draft";

    } else if (status == STANDARD_LIFECYCLE_STATUS.VERIFIED) {
        return "verified";

    } else if (status == STANDARD_LIFECYCLE_STATUS.STANDING) {
        return "standing";

    } else if (status == STANDARD_LIFECYCLE_STATUS.REVISION) {
        return "revision";

    } else if (status == STANDARD_LIFECYCLE_STATUS.HALTED) {
        return "halted";

    } else if (status == STANDARD_LIFECYCLE_STATUS.COMPLETED) {
        return "completed";

    } else if (status == STANDARD_LIFECYCLE_STATUS.DISCARDED) {
        return "discarded";

    } else if (status == STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED) {
        return "any";
    }
    return "preverify"
}

// Returns the stringified version of the qc lifecycle status, useful for inserting into the database
export function decodeQCSampleLifecycle(status: QC_SAMPLE_LIFECYCLE): "open" | "finished" | "accepted" | "accepted-with-deviation" | "cancelled" | "rejected" | "" {
    if (typeof (status) == "string") {
        return status;
    }

    if (status == QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_OPEN) {
        return "open";
    } else if (status == QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_FINISHED) {
        return "finished";
    } else if (status == QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_ACCEPTED_WITH_DEVIATION) {
        return "accepted-with-deviation";
    } else if (status == QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_ACCEPTED) {
        return "accepted";
    } else if (status == QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_CANCELLED) {
        return "cancelled";
    } else if (status == QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_REJECTED) {
        return "rejected"
    }
    return "";
}

// Encodes the form field element string into a usable FORM_FIELD_ELEMENT
export function encodeFormFieldElement(formFieldElement: string): FORM_FIELD_ELEMENT {
    if (formFieldElement == "input") {
        return FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_INPUT
    } else if (formFieldElement == "radio") {
        return FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_RADIO
    } else if (formFieldElement == "checkbox") {
        return FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_CHECKBOX
    } else if (formFieldElement == "select") {
        return FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_SELECT
    } else if (formFieldElement == "textarea") {
        return FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_TEXTAREA
    } else if (formFieldElement == "date") {
        return FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_DATE
    } else if (formFieldElement == "email") {
        return FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_EMAIL
    } else if (formFieldElement == "phone") {
        return FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_PHONE
    }

    return FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_ANY_UNSPECIFIED
}

// Decodes the FORM_FIELD_ELEMENT enum into a string usable by the database
export function decodeFormFieldElement(formFieldElement: FORM_FIELD_ELEMENT): string {
    if (formFieldElement == FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_INPUT) {
        return "input"
    } else if (formFieldElement == FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_RADIO) {
        return "radio"
    } else if (formFieldElement == FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_CHECKBOX) {
        return "checkbox"
    } else if (formFieldElement == FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_SELECT) {
        return "select"
    } else if (formFieldElement == FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_TEXTAREA) {
        return "textarea"
    } else if (formFieldElement == FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_DATE) {
        return "date"
    } else if (formFieldElement == FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_EMAIL) {
        return "email"
    } else if (formFieldElement == FORM_FIELD_ELEMENT.FORM_FIELD_ELEMENT_PHONE) {
        return "phone"
    }
    return ""
}

/**Returns the date string that can be used by a date input from the given date string (from Thu Jun 18 2020 to 2020-06-18) */
export function toSelectableDate(dateStr: string) {
    let a = new Date(dateStr);
    return new Date(a.getTime() - a.getTimezoneOffset() * 60 * 1000).toISOString().split("T")[0];
}

/**Converts YYYY-MM-DD to Day Mon DD YYYY */
export function dateToStr(date: string) {
    return new Date(date).toDateString();
}

/**Checks if the user has access to the given permission */
export function checkForPerm(userRole: Role, perm: string) {
    for (let i = 0; i < userRole.accessList.length; i++) {
        if (userRole.accessList[i].menuUid == perm && userRole.accessList[i].isAccessible) {
            return true;
        }
    }
    return false;
}

/**Checks if the user has access to any of the given permissions */
export function checkForAnyPerm(userRole: Role, perms: string[]) {
    for (let i = 0; i < userRole.accessList.length; i++) {
        for (let j = 0; j < perms.length; j++) {
            if (userRole.accessList[i].menuUid == perms[j] && userRole.accessList[i].isAccessible) {
                return true;
            }
        }
    }
    return false;
}

/**Validates the form and returns the errors */
export function validateForm(formId: string) {
    let form = <HTMLDivElement>document.getElementById(formId);

    let errors: string[] = [];

    let dynamicFormElements = form.getElementsByClassName(formFieldClass);
    for (let i = 0; i < dynamicFormElements.length; i++) {

    }
    let standardFormElements = form.getElementsByClassName(fieldValidatorClass);
    for (let i = 0; i < standardFormElements.length; i++) {
        let el = <HTMLElement>standardFormElements[i];
        let val = "";
        if (el.nodeName.toLowerCase() == "input") {
            val = (<HTMLInputElement>el).value;
        } else if (el.nodeName.toLowerCase() == "select") {
            val = (<HTMLSelectElement>el).value;
        } else if (el.nodeName.toLowerCase() == "textarea") {
            val = (<HTMLTextAreaElement>el).value;
        }
        if (val == "") {
            errors.push(el.getAttribute("data-error") || "");
        }
    }

    let validated1 = formValidationLogic(formId, formFieldClass, "", true);
    let validated2 = formValidationLogic(formId, fieldValidatorClass, "", true);

    if (validated1 && validated2) {
        return true;
    }

    return false
}
import { context } from "./../../router";
import { FORM_TYPE, FormFieldDatum, GoodsReceiptsService, GoodsReceiptsServiceCreateRequest, PermissionGoodsReceiptDrafts, PermissionGoodsReceiptNew, Role, getClientForGoodsReceiptsService, getClientForPurchasesOrdersService } from "@kernelminds/scailo-sdk";
import { emptyDiv, getLinkForGoodsReceipt, redirectTo, renderFilterPrimarySubSection, renderInput, renderPageTitleSection, renderSelect, returnFormFromFields } from "../../ui";
import { bgColor, checkForAnyPerm, createObjectFromForm, getDynamicFormData, getUserRole, randomId, showFailureAlert, showSuccessAlert, validateForm } from "../../utilities";
import { PromiseClient, Transport } from "@connectrpc/connect";
import { getTransport, getWriteTransport } from "../../clients";
import { getFormsFields, roleSelf } from "../../fetches";
import { setupPurchaseOrderSearchable } from "../../searchables";

export async function handleCreateGoodsReceipt(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    const transport = getTransport();
    const accessClient = getClientForGoodsReceiptsService(transport);
    const purchaseOrderClient = getClientForPurchasesOrdersService(transport);

    document.title = `Create Goods Receipt`;

    let container = document.createElement("div");
    container.className = "overflow-x-auto";
    content.appendChild(container);

    let [userRole] = await Promise.all([
        getUserRole(),
    ]);

    const { formGrid, nextButtonId, resetButtonId, purchaseOrderReferenceId } = await getForm(accessClient, userRole, transport);

    container.appendChild(formGrid);

    // Setup searchable
    setupPurchaseOrderSearchable(purchaseOrderReferenceId, purchaseOrderClient, {});

    (<HTMLButtonElement>document.getElementById(resetButtonId)).addEventListener("click", async evt => {
        evt.preventDefault();
        handleCreateGoodsReceipt(ctx);
    });

    const nextButton = (<HTMLButtonElement>document.getElementById(nextButtonId));

    if (checkForAnyPerm(userRole, [PermissionGoodsReceiptNew.Uid, PermissionGoodsReceiptDrafts.Uid])) {
        nextButton.addEventListener("click", async evt => {
            evt.preventDefault();

            // Validate all the fields
            if (!validateForm(formGrid.id)) {
                return;
            }
            nextButton.disabled = true;
            nextButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;

            // Create the object

            const writeClient = getClientForGoodsReceiptsService(getWriteTransport());
            let obj = <GoodsReceiptsServiceCreateRequest>createObjectFromForm(formGrid.id);
            obj.refFrom = "purchase-order";
            obj.formData = getDynamicFormData();
            try {
                let resp = await writeClient.draft(obj)
                showSuccessAlert(`Goods Receipt - ${obj.referenceId} has been created. Redirecting you to the edit page in 1 second...`);
                setTimeout(() => {
                    redirectTo(getLinkForGoodsReceipt(resp.uuid), true);
                }, 1000)
            } catch (e) {
                nextButton.disabled = false;
                nextButton.innerText = `Next`;
                showFailureAlert("Something went wrong. Try again.");
            }
        });
    }
}

function renderReferencesSection(accessClient: PromiseClient<typeof GoodsReceiptsService>) {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "References", titleMdColSpan: 3 });

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: false, label: "Initial Reference", inputType: "text", dataMapper: "referenceId", dataType: "string", value: "", mdColSpan: 6, helpText: "Initial Reference of the record.", dataRegex: ".+" }));
    contentGrid.appendChild(emptyDiv());

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: false, label: "Vendor Bill No", inputType: "text", dataMapper: "vendorBillNo", dataType: "string", value: "", mdColSpan: 4, helpText: "Vendor Bill No is the sequence number that was generated by the vendor.", dataRegex: ".+" }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: false, label: "Vendor Bill Date", inputType: "date", dataMapper: "vendorBillDate", dataType: "string", value: "", mdColSpan: 4, helpText: "Vendor Bill Date is the date on which the bill has been generated.", dataRegex: ".+" }));

    contentGrid.appendChild(emptyDiv());
    
    const purchaseOrderReferenceId = randomId();    
    contentGrid.appendChild(renderSelect({ id: purchaseOrderReferenceId, readonly: false, label: "Purchase Order Reference", dataMapper: "refId", dataType: "bigint", value: "", mdColSpan: 6, helpText: "Reference of the Purchase Order.", options: [], dataRegex: ".+" }));    

    return {
        grid,
        purchaseOrderReferenceId
    };
}

async function renderDynamicFormsSection(accessClient: PromiseClient<typeof GoodsReceiptsService>, transport: Transport): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    let allForms = (await getFormsFields(FORM_TYPE.FORM_TYPE_GOODS_RECEIPT_FORM, transport)).map(f => {
        let t = new FormFieldDatum({ formField: f })
        return t
    });
    if (allForms.length == 0) {
        return container
    }

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Other Details` }));
    container.appendChild(containerTitle);
    
    let formFields = await returnFormFromFields(allForms, "goods-receipts", true, false);
    container.appendChild(formFields);
    return container;
}

async function getForm(accessClient: PromiseClient<typeof GoodsReceiptsService>, userRole: Role, transport: Transport) {
    let formGrid = document.createElement("div");
    formGrid.className = "grid grid-cols-1 gap-6 mb-6";
    formGrid.id = randomId()

    const [
        referencesSection,
        dynamicFormsSection,
    ] = await Promise.all([
        renderReferencesSection(accessClient),
        renderDynamicFormsSection(accessClient, transport),
    ]);

    let buttonContainer = document.createElement("div");
    buttonContainer.classList.add("col-span-12", "flex", "justify-end");


    // let buttonsDiv = document.createElement("div");
    // buttonsDiv.className = `col-span-12 pl-4 float-right`;
    let nextButtonId = randomId();
    if (checkForAnyPerm(userRole, [PermissionGoodsReceiptNew.Uid, PermissionGoodsReceiptDrafts.Uid])) {
        let nextButton = document.createElement("button");
        nextButton.id = nextButtonId;
        nextButton.className = "btn btn-outline btn-success btn-md mr-4";
        nextButton.innerText = `Next`;
        buttonContainer.append(nextButton);
    }

    let resetButtonId = randomId();
    let resetButton = document.createElement("button");
    resetButton.id = resetButtonId;
    resetButton.className = "btn btn-outline btn-info btn-md mr-4";
    resetButton.innerText = "Reset";
    buttonContainer.append(resetButton);

    formGrid.innerHTML = `
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words ${bgColor} w-full shadow-lg rounded">
            <div class="rounded-t mb-0 px-0 border-0">
                ${renderPageTitleSection({ title: document.title }).outerHTML}
            </div>
            <form>
                <hr class="m-5">
                ${referencesSection.grid.outerHTML}
                <hr class="m-5">
            </form>
        </div>
        ${dynamicFormsSection.childElementCount > 0 ?
            `<div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words ${bgColor} w-full shadow-lg rounded">
                ${dynamicFormsSection.outerHTML}
            </div>`
            : ''}
        <div class="rounded-t mb-0 px-0 border-0">
            ${buttonContainer.outerHTML}
        </div>
    `;

    return { formGrid, nextButtonId, resetButtonId, purchaseOrderReferenceId: referencesSection.purchaseOrderReferenceId };
}
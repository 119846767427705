import { _returnInCents, bgColor, createObjectFromForm, downloadButtonClass, downloadData, randomId, round, setupDownloadForFilters, showFailureAlert, showSuccessAlert } from "../../utilities";
import { context } from "../../router";
import { BOOL_FILTER, getClientForVendorsService, VendorItemsSearchRequest } from "@kernelminds/scailo-sdk";
import { viewVendorRequiredMaterials } from "../../tables";
import { _renderPageFilters, setupVendorMaterialHistory } from "./searchcomponents";
import { getTransport } from "../../clients";

const historyButtonClass = "_history-btn-class";

export function handleRequiredMaterialFilters(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    let { html, formId, resetButtonId, getButtonId } = _renderPageFilters("Records", "required");
    let filtersHtml = document.createElement("div");
    filtersHtml.innerHTML = html

    content.appendChild(filtersHtml);

    let tableContainer = document.createElement("div");
    content.appendChild(tableContainer);

    (<HTMLButtonElement>document.getElementById(resetButtonId)).addEventListener("click", async evt => {
        evt.preventDefault();
        handleRequiredMaterialFilters(ctx);
    });

    let getButton = (<HTMLButtonElement>document.getElementById(getButtonId));
    const transport = getTransport();
    let client = getClientForVendorsService(transport);

    getButton.addEventListener("click", async evt => {
        evt.preventDefault();
        while (tableContainer.firstChild) {
            tableContainer.removeChild(tableContainer.firstChild);
        }

        getButton.disabled = true;
        getButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;

        let req = new VendorItemsSearchRequest(createObjectFromForm(formId));
        req.isActive = BOOL_FILTER.BOOL_FILTER_TRUE;

        let [filterResp] = await Promise.all([
            client.searchRequiredItemsWithPagination(req),
        ]);

        getButton.disabled = false;
        getButton.innerText = `Get Records`;

        const filterRecords = filterResp.payload;

        if (!filterRecords.length) {
            showFailureAlert("No Records Found");
            return;
        }

        showSuccessAlert(`${filterResp.total} records match the filter criteria`);
        
        let tableDiv = document.createElement("div");
        tableDiv.className = `overflow-x-auto p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words ${bgColor} w-full shadow-lg rounded`;
        tableContainer.appendChild(tableDiv);

        const recordsDiv = await viewVendorRequiredMaterials(filterRecords, historyButtonClass);
        setupDownloadForFilters(recordsDiv, tableDiv, "Materials", 1);

        setupVendorMaterialHistory(historyButtonClass, client);
    });
}
import { BOOL_FILTER, CurrenciesService, Currency, FamiliesService, getClientForCurrenciesService, getClientForUnitsOfMaterialsService, UnitOfMaterial, GOODS_RECEIPT_ITEM_STATUS, GoodsReceipt, GoodsReceiptItem, GoodsReceiptsService } from "@kernelminds/scailo-sdk";
import ApexCharts from 'apexcharts';
import { convertBigIntTimestampToDate, convertCentsToMoney, dateToStr, decodeSLC, destroyAlert, internationalizeMoney, randomId, renderTableWithCSVHeadersAndRows, round, showFailureAlert, showSuccessAlert, toTitleCase } from "../../utilities";
import { PromiseClient, Transport } from "@connectrpc/connect";
import { protoInt64 } from "@bufbuild/protobuf";
import { getLinkForGoodsReceipt } from "../../ui";

export async function renderGoodsReceiptItemTrends(familyId: bigint, familyUuid: string, accessClient: PromiseClient<typeof GoodsReceiptsService>, familiesAccessClient: PromiseClient<typeof FamiliesService>, transport: Transport) {
    let notificationId = showSuccessAlert(`Gathering trends`, { timeoutInMs: 100000 });
    let records = (await accessClient.searchItemsWithPagination({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        count: protoInt64.parse(-1),
        status: GOODS_RECEIPT_ITEM_STATUS.GOODS_RECEIPT_ITEM_STATUS_ANY_UNSPECIFIED,
        familyId: familyId,
    })).payload;

    let goodsReceipts = await Promise.all(Array.from(new Set(records.map(r => r.goodsReceiptUuid))).map(r => {
        return accessClient.viewByUUID({ uuid: r });
    }));
    const unitOfMaterialAccessClient = getClientForUnitsOfMaterialsService(transport);
    const uoms = (await unitOfMaterialAccessClient.viewFromIDs({ list: Array.from(new Set(records.map(r => r.vendorUomId))) })).list;

    let goodsReceiptsMap = new Map(goodsReceipts.map(r => [r.metadata?.uuid!, r]));
    let unitsMap = new Map(uoms.map(r => [r.metadata?.id!, r]));
    let family = await familiesAccessClient.viewEssentialByUUID({ uuid: familyUuid });

    destroyAlert(notificationId);

    // Table
    const headers = ["S.No.", "Goods Receipt", "Status", "Internal Qty", "Vendor UoM", "Vendor Qty", "Created On"];
    let rows = records.map((m, i) => {
        let goodsreceipt = goodsReceiptsMap.get(m.goodsReceiptUuid) || new GoodsReceipt();
        let vendorUoM = unitsMap.get(m.vendorUomId) || new UnitOfMaterial();
        let goodsReceiptLink = document.createElement("a");
        goodsReceiptLink.target = "_blank";
        goodsReceiptLink.className = `btn btn-wide btn-outline btn-primary text-white truncate`;
        goodsReceiptLink.innerHTML = `<i class='bx bx-door-open'></i> ${goodsreceipt.approvalMetadata?.approvedOn! > 0 ? goodsreceipt.finalRefNumber : goodsreceipt.referenceId}`;
        goodsReceiptLink.href = getLinkForGoodsReceipt(goodsreceipt.metadata?.uuid!);
        goodsReceiptLink.innerText = goodsreceipt.approvalMetadata?.approvedOn! > 0 ? goodsreceipt.finalRefNumber : goodsreceipt.referenceId;

        return [
            `${i+1}.`,
            goodsReceiptLink.outerHTML,
            toTitleCase(decodeSLC(goodsreceipt.status)),
            convertCentsToMoney(m.internalQuantity),
            `(${vendorUoM.symbol}) ${vendorUoM.name}`,
            convertCentsToMoney(m.vendorQuantity),
            convertBigIntTimestampToDate(m.metadata?.createdAt!),
        ];
    });

    let table = renderTableWithCSVHeadersAndRows({ title: `Trends for (${family.code}) ${family.name}`, headers_array: headers, rows_array: rows, responsive: false });

    if (!records.length) {
        showFailureAlert("No Records Found");
        return;
    }

    const chartsDivId = randomId();

    // Render the content inside a dialog
    let dialog = document.createElement("dialog");
    dialog.className = "modal";
    dialog.id = randomId();
    dialog.innerHTML = `
    <div class="max-w-full modal-box text-gray-900 bg-gray-200">
        <form method="dialog" class="m-3">
            <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
        </form>
        <div class="overflow-y-auto max-h-96">${table.table.outerHTML}</div>
        
        <div id="${chartsDivId}" class="mt-8"></div>
    </div>
    `;

    document.body.appendChild(dialog);
    dialog.showModal();
    dialog.addEventListener("close", () => {
        document.body.removeChild(dialog);
    });

    renderChart(records, goodsReceiptsMap, unitsMap, document.getElementById(chartsDivId) as HTMLDivElement);
}

function renderChart(
    records: GoodsReceiptItem[], 
    goodsReceiptsMap: Map<string, GoodsReceipt>,
    unitsMap: Map<bigint, UnitOfMaterial>,
    chartsDiv: HTMLDivElement,
) {
    if (records.length == 0) {
        return;
    }

    let localChartDiv = document.createElement("div");
    localChartDiv.classList.add("col-span-12");
    chartsDiv.appendChild(localChartDiv);

    const commonTheme = {
        palette: 'palette2',
    };

    let cumulativeVendorQtySeriesData = <number[]>[];
    let incrementingVendorQty = 0;

    const divisor = 100;
    
    records.forEach(record => {
        incrementingVendorQty += parseInt(String(record.vendorQuantity))/100;
        cumulativeVendorQtySeriesData.push(incrementingVendorQty);
    });

    let options = {
        title: {
            text: `Total Records: ${records.length}`,
            align: "left",
            margin: 10,
            offsetX: 0,
            offsetY: -10,
            floating: true,
            style: {
                fontSize: "20px",
                fontWeight: "light",
                color: "#263238"
            },
        },
        theme: commonTheme,
        series: [
            // Area Series
            {
                name: "Cumulative Qty",
                type: "area",
                data: cumulativeVendorQtySeriesData
            },
            // Line Series
            {
                name: "Individual Qty",
                type: "line",
                data: records.map(record => parseFloat(round(parseInt(String((record.vendorQuantity)))/divisor)))
            }
        ],
        chart: {
            height: 500,
            type: "line",
        },
        stroke: {
            curve: "smooth"
        },
        fill: {
            type: "solid",
            opacity: [0.25, 1],
        },
        labels: records.map(record => {
            let goodsReceipt = goodsReceiptsMap.get(record.goodsReceiptUuid);
            return goodsReceipt?.approvalMetadata?.approvedOn! > 0 ? goodsReceipt?.finalRefNumber : goodsReceipt?.referenceId;
        }),
        markers: {
            size: 2
        },
        legend: {
            position: "top"
        },
        yaxis: [
            {
                title: {
                    text: "Cumulative Value",
                },
            },
            {
                opposite: true,
                title: {
                    text: "Individual Record Value",
                },
            },
        ],

        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                let record = records[dataPointIndex];
                let goodsReceipt = goodsReceiptsMap.get(record.goodsReceiptUuid);
                let uom = unitsMap.get(record.vendorUomId!);
                return `
                    <ul style='background-color: #424242; color: #F5F5F5; padding: 20px; margin: 0px;'>
                        <li>Index: ${dataPointIndex + 1}</li>
                        <li>Goods Receipt: ${goodsReceipt?.approvalMetadata?.approvedOn! > 0 ? goodsReceipt?.finalRefNumber : goodsReceipt?.referenceId}</li>
                        <li>Item Qty: ${internationalizeMoney(parseInt(String(record.vendorQuantity))/100)} ${uom?.symbol}</li>
                        <li>Approved Date: ${convertBigIntTimestampToDate(record.approvalMetadata!.approvedOn)}</li>
                        <li>Cumulative Qty: ${internationalizeMoney(cumulativeVendorQtySeriesData[dataPointIndex])} ${uom?.symbol}</li>
                    </ul>
                `;
            }
        }
    };

    let chart = new ApexCharts(localChartDiv, options);
    chart.render();
}
import * as ui from "../../ui";
import { randomId } from "../../utilities";
import { SORT_ORDER, VAULT_REF_FOR, VAULT_SORT_KEY } from "@kernelminds/scailo-sdk";

function renderPrimaryFiltersSection() {
    let { grid, contentGrid } = ui.renderFilterPrimarySubSection({ subsectionTitle: "Primary Filters", titleMdColSpan: 3 });

    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Search Key", inputType: "text", dataMapper: "searchKey", dataType: "string", value: "", mdColSpan: 8, helpText: "Enter the search key. Filter records by the value of this field. Leave it empty to ignore this field.", dataRegex: ".*" }));
    contentGrid.appendChild(ui.renderSelectForEntireEnum({
            id: randomId(),
            label: "Ref For",
            nameSplitsAt: "VAULT_REF_FOR_",
            enumObject: VAULT_REF_FOR,
            dataMapper: "refFor",
            dataType: "number",
            value: "",
            mdColSpan: 4,
            helpText: "Filter by Ref For. Set it to 'Any' to ignore this field. Set it to 'File' to limit search to only files. Set it to 'Folder' to limit search to only folders.",
            excludeZeroethValuedEnum: false,
            readonly: false,
            dataRegex: ".*"
        }));

    return { grid }
}

/**Renders the time filters filter section */
function renderTimeFiltersSection() {
    let { grid, contentGrid } = ui.renderFilterPrimarySubSection({ subsectionTitle: "Time Filters", titleMdColSpan: 3 });

    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Start Creation Date", inputType: "date", dataMapper: "creationTimestampStart", dataType: "timestamp", value: "", mdColSpan: 6, helpText: "Start Creation Date is the minimum creation date to be considered. If set, all records from this creation date are returned. Leave it empty to ignore this field.", dataRegex: ".*" }));
    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "End Creation Date", inputType: "date", dataMapper: "creationTimestampEnd", dataType: "timestamp", value: "", mdColSpan: 6, helpText: "End Creation Date is the maximum creation date to be considered. If set, all records until this creation date are returned. Leave it empty to ignore this field.", dataRegex: ".*" }));

    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Start Modification Date", inputType: "date", dataMapper: "modificationTimestampStart", dataType: "timestamp", value: "", mdColSpan: 6, helpText: "Start Modification Date is the minimum modification date to be considered. If set, all records from this modification date are returned. Leave it empty to ignore this field.", dataRegex: ".*" }));
    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "End Modification Date", inputType: "date", dataMapper: "modificationTimestampEnd", dataType: "timestamp", value: "", mdColSpan: 6, helpText: "End Modification Date is the maximum modification date to be considered. If set, all records until this modification date are returned. Leave it empty to ignore this field.", dataRegex: ".*" }));

    return grid
}

/**Renders the sort filters filter section */
function renderSortFiltersSection() {
    let { grid, contentGrid } = ui.renderFilterPrimarySubSection({ subsectionTitle: "Sort Filters", titleMdColSpan: 3 })

    contentGrid.appendChild(ui.renderSelectForPartialEnum({
        id: randomId(),
        label: "Sort Order",
        nameSplitsAt: " ",
        enumObject: SORT_ORDER,
        enumValues: [
            SORT_ORDER.DESCENDING,
            SORT_ORDER.ASCENDING_UNSPECIFIED,
        ],
        dataMapper: "sortOrder",
        dataType: "number",
        value: "",
        mdColSpan: 4,
        helpText: "Sort response in either ascending or descending order",
        excludeZeroethValuedEnum: false,
        readonly: false,
        dataRegex: ".*"
    }));

    contentGrid.appendChild(ui.renderSelect({
        id: randomId(),
        label: "Count",
        dataMapper: "count",
        dataType: "number",
        value: "",
        mdColSpan: 4,
        helpText: "The number of records to be returned. If 'Ref For' is set to 'Any', then this field corresponds to the number of records to be returned for files and folders individually. If 'Ref For' is set to 'File' or 'Folder', then this field corresponds to the number of records to be returned for the 'Ref For' exclusively.",
        options: [5, 10, 20, 50, 100].map(count => {
            let opt = document.createElement("option");
            opt.value = count.toString();
            opt.innerText = count.toString();
            return opt;
        }),
        readonly: false,
        dataRegex: ".*"
    }));

    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Offset", inputType: "number", dataMapper: "offset", dataType: "number", value: "0", mdColSpan: 4, helpText: "The number of records to be skipped from the beginning. Leave it at 0 to ignore this field. Negative values have no effect.", dataRegex: ".*" }));

    return { grid, contentGrid }
}

export function _renderPageFilters(context: "Records" | "Insights") {
    let primaryFiltersSection = renderPrimaryFiltersSection();
    let timeFiltersSection = renderTimeFiltersSection();
    let sortFiltersSection = renderSortFiltersSection();

    // Attach specific sort key
    sortFiltersSection.contentGrid.prepend(ui.renderSelectForPartialEnum({
        id: randomId(),
        label: "Sort By",
        nameSplitsAt: "VAULT_SORT_KEY_",
        enumObject: VAULT_SORT_KEY,
        enumValues: [
            VAULT_SORT_KEY.VAULT_SORT_KEY_CREATED_AT,
            VAULT_SORT_KEY.VAULT_SORT_KEY_MODIFIED_AT,
            VAULT_SORT_KEY.VAULT_SORT_KEY_NAME,
        ],
        dataMapper: "sortKey",
        dataType: "number",
        value: "",
        mdColSpan: 12,
        helpText: "Sort the response with the selected field.",
        excludeZeroethValuedEnum: false,
        readonly: false,
        dataRegex: ".*",
    }));

    let buttonsDiv = document.createElement("div");
    buttonsDiv.className = `col-span-12 pl-4 float-right`;

    let getButtonId = randomId();
    let getButton = document.createElement("button");
    getButton.id = getButtonId;
    getButton.className = "btn btn-outline btn-success btn-md mr-4";
    getButton.innerText = `Get ${context}`;
    buttonsDiv.append(getButton);

    let resetButtonId = randomId();
    let resetButton = document.createElement("button");
    resetButton.id = resetButtonId;
    resetButton.className = "btn btn-outline btn-info btn-md mr-4";
    resetButton.innerText = "Reset";
    buttonsDiv.append(resetButton);

    const formId = randomId();
    const tableDivId = randomId();

    return {
        html: `
            <div class="grid grid-cols-1 gap-6 mb-6">
                <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
                    <div class="rounded-t mb-0 px-0 border-0">
                        ${ui.renderPageTitleSection({ title: "Vault Search" }).outerHTML}
                    </div>
                    <form id="${formId}" method="dialog">
                        <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                        ${primaryFiltersSection.grid.outerHTML}
                        <hr class="m-5">
                        ${timeFiltersSection.outerHTML}
                        <hr class="m-5">
                        ${sortFiltersSection.grid.outerHTML}
                        <hr class="m-5">
                        ${buttonsDiv.outerHTML}
                    </form>
                    <div class="overflow-auto" id="${tableDivId}"></div>
                </div>
            </div>
        `,
        formId,
        resetButtonId,
        getButtonId,
        tableDivId
    }
}
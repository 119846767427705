import { _returnInCents, convertBigIntTimestampToDate, convertCentsToMoney, createObjectFromForm, internationalizeMoney, round, showFailureAlert } from "../../utilities";
import { context } from "./../../router";
import { Family, getClientForLocationsService, getClientForOutwardJobsFreeIssueMaterialsService, getClientForOutwardJobsService, OutwardJobFreeIssueMaterial, OutwardJobsFreeIssueMaterialsServiceFilterReq, STANDARD_LIFECYCLE_STATUS } from "@kernelminds/scailo-sdk";
import { _renderPageFilters } from "./searchcomponents";
import { protoInt64 } from "@bufbuild/protobuf";
import ApexCharts from 'apexcharts';
import { familiesListFromIDs } from "../../fetches";
import { getTransport } from "../../clients";
import { setupLocationSearchable, setupOutwardJobSearchable } from "../../searchables";

export function handleOutwardJobFreeIssueMaterialInsights(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    let { html, formId, resetButtonId, getButtonId, refIdElementId, locationIdElementId } = _renderPageFilters("Insights");
    let insightsHtml = document.createElement("div");
    insightsHtml.innerHTML = html

    content.appendChild(insightsHtml);

    // Setup searchable
    const transport = getTransport();
    const outwardJobsClient = getClientForOutwardJobsService(transport);
    const locationsClient = getClientForLocationsService(transport);
    setupOutwardJobSearchable(refIdElementId, outwardJobsClient, { status: STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED });
    setupLocationSearchable(locationIdElementId, locationsClient, {});

    let chartsContainer = document.createElement("div");
    // chartsContainer.className = "grid grid-cols-12";
    content.appendChild(chartsContainer);

    (<HTMLButtonElement>document.getElementById(resetButtonId)).addEventListener("click", async evt => {
        evt.preventDefault();
        handleOutwardJobFreeIssueMaterialInsights(ctx);
    });

    let getButton = (<HTMLButtonElement>document.getElementById(getButtonId));

    getButton.addEventListener("click", async evt => {
        evt.preventDefault();
        while (chartsContainer.firstChild) {
            chartsContainer.removeChild(chartsContainer.firstChild);
        }

        getButton.disabled = true;
        getButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;

        let client = getClientForOutwardJobsFreeIssueMaterialsService(transport);
        let [filterResp] = await Promise.all([
            client.filter(new OutwardJobsFreeIssueMaterialsServiceFilterReq(createObjectFromForm(formId))),
        ]);

        getButton.disabled = false;
        getButton.innerText = `Get Insights`;

        const insightRecords = filterResp.list;

        if (!insightRecords.length) {
            showFailureAlert("No Records Found");
            return;
        }
        
        let chartsDiv = document.createElement("div");
        chartsDiv.className = "overflow-x-auto p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded grid grid-cols-12";
        chartsContainer.appendChild(chartsDiv);

        const commonTheme = {
            palette: 'palette2',
        };
        // Display all the insights here
        displayAllRelevantCharts(insightRecords, chartsDiv, commonTheme);
    });
}

/**Displays all the charts relevant to outward job free issue materials */
export function displayAllRelevantCharts(outwardjobsfreeissuematerials: OutwardJobFreeIssueMaterial[], chartsDiv: HTMLDivElement, commonTheme: Object) {
    let cumulativeSeriesData = <number[]>[];
    let incrementingTotalCount = 0;
    let totalCount = 0;
    outwardjobsfreeissuematerials.forEach(outwardjobfreeissuematerial => {
        incrementingTotalCount += outwardjobfreeissuematerial.list.length;
        cumulativeSeriesData.push(parseFloat(round(incrementingTotalCount)));
        totalCount += outwardjobfreeissuematerial.list.length;
    });

    let options = {
        title: {
            text: `Total Receipts: ${outwardjobsfreeissuematerials.length}, Total Line Item Count: ${new Intl.NumberFormat(Intl.DateTimeFormat().resolvedOptions().locale, { maximumSignificantDigits: 2 }).format(parseFloat(round(totalCount)))}`,
            align: "left",
            margin: 10,
            offsetX: 0,
            offsetY: -10,
            floating: true,
            style: {
                fontSize: "20px",
                fontWeight: "light",
                color: "#263238"
            },
        },
        theme: commonTheme,
        series: [
            // Area Series
            {
                name: "Cumulative Outward Job Free Issue Material Line Item Count",
                type: "area",
                data: cumulativeSeriesData
            },
            // Line Series
            {
                name: "Individual Outward Job Free Issue Material Line Item Count",
                type: "line",
                data: outwardjobsfreeissuematerials.map(outwardjobfreeissuematerial => parseFloat(round(outwardjobfreeissuematerial.list.length)))
            }
        ],
        chart: {
            height: 500,
            type: "line",
        },
        stroke: {
            curve: "smooth"
        },
        fill: {
            type: "solid",
            opacity: [0.10, 1],
        },
        labels: outwardjobsfreeissuematerials.map(outwardjobfreeissuematerial => convertBigIntTimestampToDate(outwardjobfreeissuematerial.approvalMetadata!.approvedOn)),
        markers: {
            size: 2
        },
        legend: {
            position: "top"
        },
        yaxis: [
            {
                title: {
                    text: "Cumulative Outward Job Free Issue Material Line Item Count",
                },
            },
            {
                opposite: true,
                title: {
                    text: "Individual Outward Job Free Issue Material Line Item Count",
                },
            },
        ],

        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                let outwardjobfreeissuematerial = outwardjobsfreeissuematerials[dataPointIndex];
                return `
                    <ul style='background-color: #424242; color: #F5F5F5; padding: 20px; margin: 0px;'>
                        <li>Outward Job Free Issue Material Number: ${outwardjobfreeissuematerial.approvalMetadata!.approvedOn > 0 ? outwardjobfreeissuematerial.finalRefNumber : outwardjobfreeissuematerial.referenceId}</li>
                        <li>Outward Job Free Issue Material Line Item Count: ${parseFloat(round(outwardjobfreeissuematerial.list.length))}</li>
                        <li>Cumulative Line Item Count: ${cumulativeSeriesData[dataPointIndex]}</li>
                        <li>Approved Date: ${convertBigIntTimestampToDate(outwardjobfreeissuematerial.approvalMetadata!.approvedOn)}</li>
                        <li>Index: ${dataPointIndex + 1}</li>
                    </ul>
                `;
            }
        }
    };

    let chart = new ApexCharts(chartsDiv, options);
    chart.render();

    renderTopMaterials(outwardjobsfreeissuematerials, chartsDiv, commonTheme);
}

interface outwardjobfreeissuematerialFamilyForChart {
    family_id: bigint
    internal_quantity: bigint
}

async function renderTopMaterials(outwardjobsfreeissuematerials: OutwardJobFreeIssueMaterial[], chartsDiv: HTMLDivElement, commonTheme: Object) {
    let localChartDiv = document.createElement("div");
    localChartDiv.classList.add("col-span-12");

    const innerGrid = document.createElement("div");
    innerGrid.classList.add("grid", "grid-cols-12");
    localChartDiv.appendChild(innerGrid);

    chartsDiv.appendChild(localChartDiv);

    // Map between family ID and cumulative quantity
    let byQuantityMap: Map<bigint, outwardjobfreeissuematerialFamilyForChart> = new Map();

    // Fill up the maps with the values
    outwardjobsfreeissuematerials.forEach(outwardjobfreeissuematerial => {
        outwardjobfreeissuematerial.list.forEach(item => {
            // Set the quantity here
            if (byQuantityMap.has(item.familyId)) {
                let mapItem = byQuantityMap.get(item.familyId) as outwardjobfreeissuematerialFamilyForChart;
                mapItem.internal_quantity += item.internalQuantity;
                byQuantityMap.set(item.familyId, mapItem);
            } else {
                byQuantityMap.set(item.familyId, {
                    family_id: item.familyId,
                    internal_quantity: item.internalQuantity,
                });
            }
        });
    });

    // Create the array here
    let byQuantityList = Array.from(byQuantityMap.values());

    // Sort
    byQuantityList.sort((a, b) => parseInt(String(b.internal_quantity)) - parseInt(String(a.internal_quantity)));

    const topCount = 10;

    // Slice the arrays
    if (byQuantityList.length > topCount) {
        byQuantityList = byQuantityList.slice(0, topCount);
    }

    // Retrieve applicable families here (need to concat family IDs from both lists, and then create a Set)
    let familiesList = await familiesListFromIDs(Array.from(new Set(byQuantityList.map(b => b.family_id))));
    let familiesMap: Map<bigint, Family> = new Map();
    familiesList.forEach(fam => {
        familiesMap.set(fam.metadata!.id, fam);
    });

    let chartByQuantityDiv = document.createElement("div");
    chartByQuantityDiv.classList.add("col-span-12");
    chartByQuantityDiv.classList.add("md:col-span-12");
    innerGrid.appendChild(chartByQuantityDiv);

    let chartByQuantity = new ApexCharts(chartByQuantityDiv, {
        title: {
            text: `Top ${byQuantityList.length} Materials By Quantity`,
            align: "center",
            margin: 10,
            offsetX: 0,
            offsetY: -10,
            floating: true,
            style: {
                fontSize: "20px",
                fontWeight: "light",
                color: "#263238"
            },
        },
        theme: commonTheme,
        series: [
            {
                data: byQuantityList.map(s => {
                    return parseFloat(convertCentsToMoney(s.internal_quantity)).toFixed(2)
                })
            },
        ],
        chart: {
            height: 500,
            type: "bar",
        },
        legend: {
            position: "top"
        },
        plotOptions: {
            bar: {
                horizontal: true,
            }
        },
        dataLabels: {
            enabled: true
        },
        xaxis: {
            categories: byQuantityList.map(s => {
                return familiesMap.get(s.family_id)!.code;
            }),
            labels: {
                show: false,
                rotate: -45,
            }
        },
        grid: {
            xaxis: {
                lines: {
                    show: false
                }
            }
        },
        yaxis: {
            reversed: false,
            axisTicks: {
                show: false
            }
        },

        tooltip: {
            fixed: {
                enabled: true,
                position: "topLeft",
            },
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                let item = byQuantityList[dataPointIndex];
                let family = familiesMap.get(item.family_id) as Family;

                return `
                    <ul style='background-color: #424242; color: #F5F5F5; padding: 20px; margin: 0px;'>
                        <li>Ranking: ${dataPointIndex + 1}</li>
                        <li>Family: (${family.code}) ${family.name}</li>
                        <li>Total Quantity: ${internationalizeMoney(parseInt(String(item.internal_quantity / protoInt64.parse(100))))}</li>
                    </ul>
                `;
            }
        }
    });
    chartByQuantity.render();
}
import { goodsReceiptFiltersMenu, goodsReceiptIndividualMenu, goodsReceiptInsightsMenu, goodsReceiptCreateMenu } from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router, context } from "../../router";
import { handleGoodsReceiptFilters } from "./goodsreceipts.filters";
import { handleGoodsReceiptInsights } from "./goodsreceipts.insights";
import { handleIndividualGoodsReceipt } from "./individual";
import { handleCreateGoodsReceipt } from "./create";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(goodsReceiptFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, goodsReceiptFiltersMenu)) {
            handleGoodsReceiptFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(goodsReceiptInsightsMenu.href, async (ctx) => {
        if (await interceptPage(ctx, goodsReceiptInsightsMenu)) {
            handleGoodsReceiptInsights(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(goodsReceiptIndividualMenu.href, async (ctx) => {
        if (await interceptPage(ctx, goodsReceiptFiltersMenu)) {
            handleIndividualGoodsReceipt(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });

    r.add(goodsReceiptCreateMenu.href, async (ctx) => {
        if (await interceptPage(ctx, goodsReceiptCreateMenu)) {
            handleCreateGoodsReceipt(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}
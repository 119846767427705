import { getClientForGeneralSettingsService } from "@kernelminds/scailo-sdk";
import { getTransport } from "./clients";
import { Router, context } from "./router";
import { showFailureAlert } from "./utilities";
import { PostData } from "./fetches";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add("/", (ctx) => {
        Login(ctx);
    });
}

export async function setupNavbarVariables() {
    let usernameEl = <HTMLElement>document.getElementById("username");
    usernameEl.innerText = "Hi " + localStorage.getItem("username") || "";
    
    let orgNameEl = <HTMLElement>document.getElementById("org-name");
    const generalSettings = await getClientForGeneralSettingsService(getTransport()).viewSettings({});
    orgNameEl.innerText = generalSettings.companyName;
}

function Login(ctx: context) {
    let usernameEl = <HTMLInputElement>document.getElementById("username");
    let passwordEl = <HTMLInputElement>document.getElementById("password");
    let otpEl = <HTMLInputElement>document.getElementById("otp");
    let loginEl = <HTMLButtonElement>document.getElementById("login");

    loginEl.addEventListener("click", async evt => {
        evt.preventDefault();
        let obj = {
            "username": usernameEl.value,
            "plainTextPassword": passwordEl.value,
            "otp": otpEl.value,
        };
        let resp = <string | undefined | null>await PostData("/login", obj).catch(err => {
            showFailureAlert("Invalid Login Credentials");
            return;
        });

        if (resp !== undefined && resp !== null) {
            localStorage.setItem("vendor_uuid", resp["vendor_uuid"]);
            localStorage.setItem("username", usernameEl.value);
            localStorage.setItem("expires_at", resp["expires_at"]);
            location.href = "/ui/dash";
        }

    });
}

export function setupLogoutHandler() {
    let logoutEl = <HTMLButtonElement>document.getElementById("logout");
    logoutEl.addEventListener("click", async evt => {
        evt.preventDefault();
        let resp = <string | undefined | null>await PostData("/logout", {}).catch(err => {
            console.error(err);
            showFailureAlert("Couldn't logout");
            return;
        });

        if (resp !== undefined && resp !== null) {
            localStorage.clear();
            location.href = "/";
        }
    });
}


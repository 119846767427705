import { _returnInCents, bgColor, colorizeInvertedCentsToMoney, convertCentsToMoney, createObjectFromForm, downloadButtonClass, downloadData, randomId, renderTableWithCSVHeadersAndRows, round, setupDownloadForFilters, showFailureAlert, showSuccessAlert } from "../../utilities";
import { context } from "./../../router";
import { Family, getClientForCurrenciesService, getClientForLocationsService, getClientForSupplyOffersService, SupplyOffersService, SupplyOffersServiceCountReq, SupplyOffersServiceFilterReq } from "@kernelminds/scailo-sdk";
import { failureIcon, successIcon, viewSupplyOffers } from "../../tables";
import { _renderPageFilters } from "./searchcomponents";
import { PromiseClient } from "@connectrpc/connect";
import { familiesListFromIDs } from "../../fetches";
import { protoInt64 } from "@bufbuild/protobuf";
import { getTransport } from "../../clients";
import { setupCurrencySearchable, setupLocationSearchable } from "../../searchables";

export function handleSupplyOfferFilters(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    let { html, formId, resetButtonId, getButtonId, consigneeLocationId, buyerLocationId, currencyReferenceId } = _renderPageFilters("Records");
    let filtersHtml = document.createElement("div");
    filtersHtml.innerHTML = html

    content.appendChild(filtersHtml);

    // Setup searchable
    const transport = getTransport();
    const currenciesClient = getClientForCurrenciesService(transport);
    const locationsClient = getClientForLocationsService(transport);
    setupLocationSearchable(buyerLocationId, locationsClient, {});
    setupLocationSearchable(consigneeLocationId, locationsClient, {});
    setupCurrencySearchable(currencyReferenceId, currenciesClient, {});

    let tableContainer = document.createElement("div");
    content.appendChild(tableContainer);

    (<HTMLButtonElement>document.getElementById(resetButtonId)).addEventListener("click", async evt => {
        evt.preventDefault();
        handleSupplyOfferFilters(ctx);
    });

    let getButton = (<HTMLButtonElement>document.getElementById(getButtonId));

    getButton.addEventListener("click", async evt => {
        evt.preventDefault();
        while (tableContainer.firstChild) {
            tableContainer.removeChild(tableContainer.firstChild);
        }

        getButton.disabled = true;
        getButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;

        let client = getClientForSupplyOffersService(transport);
        let [filterResp, countResp] = await Promise.all([
            client.filter(new SupplyOffersServiceFilterReq(createObjectFromForm(formId))),
            client.count(new SupplyOffersServiceCountReq(createObjectFromForm(formId)))
        ]);

        getButton.disabled = false;
        getButton.innerText = `Get Records`;

        const filterRecords = filterResp.list;

        if (!filterRecords.length) {
            showFailureAlert("No Records Found");
            return;
        }

        showSuccessAlert(`${countResp.count} records match the filter criteria`);

        let tableDiv = document.createElement("div");
        tableDiv.className = `overflow-x-auto p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words ${bgColor} w-full shadow-lg rounded`;
        tableContainer.appendChild(tableDiv);

        const recordsDiv = await viewSupplyOffers(filterRecords);
        setupDownloadForFilters(recordsDiv, tableDiv, "Supply Offers", 3);

        // Setup PDF downloads
        let pdfDownloadButtons = tableDiv.getElementsByClassName(downloadButtonClass);
        for (let i = 0; i < pdfDownloadButtons.length; i++) {
            let btn = <HTMLButtonElement>pdfDownloadButtons[i];
            btn.addEventListener("click", async evt => {
                evt.preventDefault();

                const originalButtonHTML = btn.innerHTML;
                btn.disabled = true;
                btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
                let file = await client.downloadByUUID({ uuid: btn.getAttribute("data-uuid") || "" });

                btn.disabled = false;
                btn.innerHTML = originalButtonHTML;

                downloadData(file.content, "pdf", file.name.replace(".pdf", ""));
            });
        }
    });
}

function renderFilters() {
    return `
    <div class="grid grid-cols-1 gap-6 mb-6">
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-gray-50 w-full shadow-lg rounded">
            <div class="rounded-t mb-0 px-0 border-0">
                <form class="">
                    <div class="grid grid-cols-12 place-items-center">
                        <div class="col-span-12 md:col-span-9 pl-4">
                            <div class="grid grid-cols-12">
                                <div class="col-span-12 md:col-span-3 pl-4">
                                    <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">First name</label>
                                    <div class="mt-2">
                                        <input type="text" name="first-name" id="first-name" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <div class="bg-gray-70 border rounded text-gray-600 text-sm font-medium pl-2 overflow-y-scroll max-h-8">
                                            <div class="hover:text-blue-500 cursor-pointer">Create landing page</div>
                                            <div class="hover:text-blue-500 cursor-pointer">Create landing page</div>
                                            <div class="hover:text-blue-500 cursor-pointer">Create landing page</div>
                                            <div class="hover:text-blue-500 cursor-pointer">Create landing page</div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    `
}
import { context } from "./../../router";
import { OutwardJob, OutwardJobsService, OutwardJobAncillaryParameters, Currency, Family, UnitOfMaterial, TaxGroup, getClientForOutwardJobsService, getClientForUnitsOfMaterialsService, getClientForLocationsService, getClientForAssociatesService, PermissionOutwardJobVerify, PermissionOutwardJobApprove, STANDARD_LIFECYCLE_STATUS, getClientForFamiliesService } from "@kernelminds/scailo-sdk";
import { emptyDiv, renderFilterPrimarySubSection, renderInput, renderPageTitleSection, renderSpan, returnFormFromFields } from "../../ui";
import { bgColor, checkForAnyPerm, convertBigIntTimestampToDate, convertCentsToMoney, decodeSLC, downloadData, getUserRole, internationalizeMoney, randomId, returnAssociateBadge, showFailureAlert, toTitleCase } from "../../utilities";
import { PromiseClient, Transport } from "@connectrpc/connect";
import { familiesListFromIDs, roleSelf } from "../../fetches";
import { getTransport, getWriteTransport } from "../../clients";
import { protoInt64 } from "@bufbuild/protobuf";
import { renderOutwardJobInwardItemTrends, renderOutwardJobOutwardItemTrends } from "./trends";

const downloadPDFButtonClass = "__download-pdf-btn";
const downloadOutwardCSVButtonClass = "__download-outward-csv-btn";
const trendsOutwardLineItemButtonClass = "__trends-outward-line-item-btn";
const downloadInwardCSVButtonClass = "__download-inward-csv-btn";
const trendsInwardLineItemButtonClass = "__trends-inward-line-item-btn";

export async function handleIndividualOutwardJob(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    const transport = getTransport();
    const accessClient = getClientForOutwardJobsService(transport);
    const writeClient = getClientForOutwardJobsService(getWriteTransport());
    const [outwardjob, ancillaryParams, userRole] = await Promise.all([
        accessClient.viewByUUID({ uuid: ctx.params.uuid }),
        accessClient.viewAncillaryParametersByUUID({ uuid: ctx.params.uuid }),
        getUserRole(),
    ]);

    document.title = outwardjob.approvalMetadata?.approvedOn! > 0 ? outwardjob.finalRefNumber : outwardjob.referenceId;

    let container = document.createElement("div");
    container.className = "overflow-x-auto";
    content.appendChild(container);

    const readonly = true;
    let { formGrid, buttonContainer } = await getForm(outwardjob, ancillaryParams, readonly, accessClient, transport);

    container.appendChild(formGrid);

    // Setup PDF downloads
    let pdfDownloadButtons = container.getElementsByClassName(downloadPDFButtonClass);
    for (let i = 0; i < pdfDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>pdfDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await accessClient.downloadByUUID({ uuid: btn.getAttribute("data-uuid") || "" });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "pdf", file.name.replace(".pdf", ""));
        });
    }

    // Setup CSV downloads
    let outwardCSVDownloadButtons = container.getElementsByClassName(downloadOutwardCSVButtonClass);
    for (let i = 0; i < outwardCSVDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>outwardCSVDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await accessClient.downloadOutwardItemsCSVTemplate({ uuid: btn.getAttribute("data-uuid") || "" });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "csv", file.name.replace(".csv", ""));
        });
    }

    let inwardCSVDownloadButtons = container.getElementsByClassName(downloadInwardCSVButtonClass);
    for (let i = 0; i < inwardCSVDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>inwardCSVDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await accessClient.downloadInwardItemsCSVTemplate({ uuid: btn.getAttribute("data-uuid") || "" });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "csv", file.name.replace(".csv", ""));
        });
    }

    const familiesAccessClient = getClientForFamiliesService(transport);

    let trendsOutwardLineItemButtons = container.getElementsByClassName(trendsOutwardLineItemButtonClass);
    for (let i = 0; i < trendsOutwardLineItemButtons.length; i++) {
        let btn = <HTMLButtonElement>trendsOutwardLineItemButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();
            try {
                renderOutwardJobOutwardItemTrends(protoInt64.parse(btn.getAttribute("data-family-id") || "0"), btn.getAttribute("data-family-uuid") || "", accessClient, familiesAccessClient, transport);
            } catch (e) {
                showFailureAlert("Something went wrong. Try again.");
            }
        });
    }

    let trendsInwardLineItemButtons = container.getElementsByClassName(trendsInwardLineItemButtonClass);
    for (let i = 0; i < trendsInwardLineItemButtons.length; i++) {
        let btn = <HTMLButtonElement>trendsInwardLineItemButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();
            try {
                renderOutwardJobInwardItemTrends(protoInt64.parse(btn.getAttribute("data-family-id") || "0"), btn.getAttribute("data-family-uuid") || "", accessClient, familiesAccessClient, transport);
            } catch (e) {
                showFailureAlert("Something went wrong. Try again.");
            }
        });
    }

    let showRequestRevisionButton = false;

    buttonContainer = <HTMLDivElement>document.getElementById(buttonContainer.id);

    if (checkForAnyPerm(userRole, [PermissionOutwardJobVerify.Uid]) && outwardjob.status == STANDARD_LIFECYCLE_STATUS.PREVERIFY) {
        // Display the verify button here if the user has the verification permission
        let verifyButton = document.createElement("button");
        verifyButton.id = randomId();
        verifyButton.className = `btn btn-primary btn-outline btn-sm mr-4`;
        verifyButton.innerText = "Verify";
        verifyButton.setAttribute("data-uuid", outwardjob.metadata?.uuid!);
        verifyButton.setAttribute("data-id", outwardjob.metadata!.id.toString());
        verifyButton.setAttribute("data-name", outwardjob.approvalMetadata?.approvedOn! > 0 ? outwardjob.finalRefNumber : outwardjob.referenceId);
        buttonContainer.appendChild(verifyButton);

        verifyButton.addEventListener("click", async evt => {
            evt.preventDefault();
            const originalButtonHTML = verifyButton.innerHTML;
            verifyButton.disabled = true;
            verifyButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            await writeClient.verify({ uuid: verifyButton.getAttribute("data-uuid") || "" });
            verifyButton.disabled = false;
            verifyButton.innerHTML = originalButtonHTML;
        });

        // Display the request revision button
        showRequestRevisionButton = true;
    }

    if (checkForAnyPerm(userRole, [PermissionOutwardJobApprove.Uid]) && outwardjob.status == STANDARD_LIFECYCLE_STATUS.VERIFIED) {
        // Display the approve button here if the user has the approval permission
        let approveButton = document.createElement("button");
        approveButton.id = randomId();
        approveButton.className = `btn btn-primary btn-outline btn-sm mr-4`;
        approveButton.innerText = "Approve";
        approveButton.setAttribute("data-uuid", outwardjob.metadata?.uuid!);
        approveButton.setAttribute("data-id", outwardjob.metadata!.id.toString());
        approveButton.setAttribute("data-name", outwardjob.approvalMetadata?.approvedOn! > 0 ? outwardjob.finalRefNumber : outwardjob.referenceId);
        buttonContainer.appendChild(approveButton);

        approveButton.addEventListener("click", async evt => {
            evt.preventDefault();
            const originalButtonHTML = approveButton.innerHTML;
            approveButton.disabled = true;
            approveButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            await writeClient.approve({ uuid: approveButton.getAttribute("data-uuid") || "" });
            approveButton.disabled = false;
            approveButton.innerHTML = originalButtonHTML;
        });

        // Display the request revision button
        showRequestRevisionButton = true;
    }

    if (showRequestRevisionButton) {
        let requestRevisionButton = document.createElement("button");
        requestRevisionButton.id = randomId();
        requestRevisionButton.className = `btn btn-warning btn-outline btn-sm mr-4`;
        requestRevisionButton.innerText = "Request Revision";
        requestRevisionButton.setAttribute("data-uuid", outwardjob.metadata?.uuid!);
        requestRevisionButton.setAttribute("data-id", outwardjob.metadata!.id.toString());
        requestRevisionButton.setAttribute("data-name", outwardjob.approvalMetadata?.approvedOn! > 0 ? outwardjob.finalRefNumber : outwardjob.referenceId);
        buttonContainer.appendChild(requestRevisionButton);

        requestRevisionButton.addEventListener("click", async evt => {
            evt.preventDefault();
            const originalButtonHTML = requestRevisionButton.innerHTML;
            requestRevisionButton.disabled = true;
            requestRevisionButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            await writeClient.sendForRevision({ uuid: requestRevisionButton.getAttribute("data-uuid") || "" });
            requestRevisionButton.disabled = false;
            requestRevisionButton.innerHTML = originalButtonHTML;
        });
    }
}

async function renderReferencesSection(outwardjob: OutwardJob, ancillaryParams: OutwardJobAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof OutwardJobsService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "References", titleMdColSpan: 3 });

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Initial Reference", inputType: "text", dataMapper: "referenceId", dataType: "string", value: outwardjob.referenceId, mdColSpan: 6, helpText: "Initial Reference of the record.", dataRegex: ".+" }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Final Reference", inputType: "text", dataMapper: "finalRefNumber", dataType: "string", value: outwardjob.finalRefNumber, mdColSpan: 6, helpText: "Final Reference of the record that is automatically generated.", dataRegex: ".+" }));
    // Display status as well
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Status", inputType: "text", dataMapper: "status", dataType: "string", value: toTitleCase(decodeSLC(outwardjob.status)), mdColSpan: 3, helpText: "Status of the record.", dataRegex: ".+" }));

    return grid;
}

async function renderBuyerAndConsigneeSection(outwardjob: OutwardJob, ancillaryParams: OutwardJobAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof OutwardJobsService>, transport: Transport): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Consignee Information", titleMdColSpan: 3 });

    // Get buyer and consignee
    const locationsAccessClient = getClientForLocationsService(transport);
    const [consignee] = await Promise.all([
        locationsAccessClient.viewByUUID({ uuid: ancillaryParams.consigneeLocationUuid }),
    ]);

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Consignee", inputType: "text", dataMapper: "consigneeLocationId", dataType: "string", value: `${consignee.code.length > 0 ? `(${consignee.code})` : ''} ${consignee.name}`, mdColSpan: 6, helpText: "The information of the consignee.", dataRegex: ".+" }));

    return grid;
}

async function renderDatesSection(outwardjob: OutwardJob, ancillaryParams: OutwardJobAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof OutwardJobsService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Important Dates", titleMdColSpan: 3 });

    // Dates (creation and approval)
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Created On", inputType: "text", dataMapper: "createdAt", dataType: "string", value: convertBigIntTimestampToDate(outwardjob.metadata?.createdAt!), mdColSpan: 3, helpText: "The creation date of this record.", dataRegex: ".*" }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Approved On", inputType: "text", dataMapper: "approvedOn", dataType: "string", value: convertBigIntTimestampToDate(outwardjob.approvalMetadata?.approvedOn!), mdColSpan: 3, helpText: "The approval date of this record.", dataRegex: ".*" }));

    return grid;
}

async function renderContactsSection(outwardjob: OutwardJob, readonly: boolean, accessClient: PromiseClient<typeof OutwardJobsService>, transport: Transport): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Contacts", titleMdColSpan: 3 });

    const associatesClient = getClientForAssociatesService(transport);

    let contacts = (await accessClient.viewOutwardJobContacts({ uuid: outwardjob.metadata?.uuid })).list;
    let outwardJobAssociates = await Promise.all(contacts.map(c => c.associateUuid).map(uuid => {
        return associatesClient.viewByUUID({ uuid });
    }));

    outwardJobAssociates.forEach(contact => {
        if (contact) {
            contentGrid.appendChild(returnAssociateBadge(contact));
        }
    });

    return grid;
}

async function renderDynamicFormsSection(outwardjob: OutwardJob, readonly: boolean, accessClient: PromiseClient<typeof OutwardJobsService>): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    if (outwardjob.formData.length == 0) {
        return container;
    }

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Other Details` }));
    container.appendChild(containerTitle);

    let formFields = await returnFormFromFields(outwardjob.formData, "outward-jobs", true, readonly);

    container.appendChild(formFields);

    return container;
}

async function renderOutwardFamiliesSection(outwardjob: OutwardJob, familiesMap: Map<bigint, Family>, uomsMap: Map<bigint, UnitOfMaterial>, readonly: boolean, accessClient: PromiseClient<typeof OutwardJobsService>): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Outward Line Items` }));

    container.appendChild(containerTitle);

    outwardjob.outwardItemsList.forEach((item, index) => {
        let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: `${index + 1}.`, titleMdColSpan: 1 });

        let fam = familiesMap.get(item.familyId) || new Family();

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Material Code", value: `${fam.code}`, mdColSpan: 3, helpText: `The code of the material` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Material Name", value: `${fam.name}`, mdColSpan: 9, helpText: `The name of the material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Buyer Quantity & UoM", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(item.internalQuantity)))} (${uomsMap.get(fam.uomId)?.symbol}) ${uomsMap.get(fam.uomId)?.name}`, mdColSpan: 3, helpText: `The quantity of the material in Buyer's unit of material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "HSN/SAC Code", value: `${fam.hsnSacCode}`, mdColSpan: 3, helpText: `The HSN/SAC code of the material` }));

        if (item.specifications.length > 0) {
            contentGrid.appendChild(renderSpan({ id: randomId(), label: "Specifications", value: `${item.specifications}`, mdColSpan: 12, helpText: `Any additional specifications that are provided to the Vendor` }));
        }

        let buttonsDiv = document.createElement("div");
        buttonsDiv.className = "col-span-12 pl-4";
        contentGrid.appendChild(buttonsDiv);
        contentGrid.appendChild(emptyDiv());

        // Trends button here
        let trendsItemButton = document.createElement("button");
        trendsItemButton.type = "button";
        trendsItemButton.className = `btn btn-sm btn-primary btn-outline text-white float-right mr-3 ${trendsOutwardLineItemButtonClass}`;
        trendsItemButton.innerHTML = `<i class='bx bx-line-chart'></i> Trends`;
        trendsItemButton.setAttribute("data-id", item.metadata?.id.toString()!);
        trendsItemButton.setAttribute("data-family-id", item.familyId?.toString()!);
        trendsItemButton.setAttribute("data-family-uuid", item.familyUuid!);

        buttonsDiv.appendChild(trendsItemButton);

        container.appendChild(grid);

        let hr = document.createElement("hr");
        hr.classList.add("m-5");
        container.appendChild(hr);
    });

    return container;
}

async function renderInwardFamiliesSection(outwardjob: OutwardJob, familiesMap: Map<bigint, Family>, uomsMap: Map<bigint, UnitOfMaterial>, readonly: boolean, accessClient: PromiseClient<typeof OutwardJobsService>): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Inward Line Items` }));

    container.appendChild(containerTitle);

    outwardjob.inwardItemsList.forEach((item, index) => {
        let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: `${index + 1}.`, titleMdColSpan: 1 });

        let fam = familiesMap.get(item.familyId) || new Family();

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Material Code", value: `${fam.code}`, mdColSpan: 3, helpText: `The code of the material` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Material Name", value: `${fam.name}`, mdColSpan: 9, helpText: `The name of the material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Buyer Quantity & UoM", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(item.internalQuantity)))} (${uomsMap.get(fam.uomId)?.symbol}) ${uomsMap.get(fam.uomId)?.name}`, mdColSpan: 3, helpText: `The quantity of the material in Buyer's unit of material` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Delivery Date", value: `${item.deliveryDate}`, mdColSpan: 3, helpText: `The delivery date of this material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "HSN/SAC Code", value: `${fam.hsnSacCode}`, mdColSpan: 3, helpText: `The HSN/SAC code of the material` }));

        if (item.specifications.length > 0) {
            contentGrid.appendChild(renderSpan({ id: randomId(), label: "Specifications", value: `${item.specifications}`, mdColSpan: 12, helpText: `Any additional specifications that are provided to the Vendor` }));
        }

        let buttonsDiv = document.createElement("div");
        buttonsDiv.className = "col-span-12 pl-4";
        contentGrid.appendChild(buttonsDiv);
        contentGrid.appendChild(emptyDiv());

        // Trends button here
        let trendsItemButton = document.createElement("button");
        trendsItemButton.type = "button";
        trendsItemButton.className = `btn btn-sm btn-primary btn-outline text-white float-right mr-3 ${trendsInwardLineItemButtonClass}`;
        trendsItemButton.innerHTML = `<i class='bx bx-line-chart'></i> Trends`;
        trendsItemButton.setAttribute("data-id", item.metadata?.id.toString()!);
        trendsItemButton.setAttribute("data-family-id", item.familyId?.toString()!);
        trendsItemButton.setAttribute("data-family-uuid", item.familyUuid!);

        buttonsDiv.appendChild(trendsItemButton);

        container.appendChild(grid);

        let hr = document.createElement("hr");
        hr.classList.add("m-5");
        container.appendChild(hr);
    });

    return container;
}

async function getForm(outwardjob: OutwardJob, ancillaryParams: OutwardJobAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof OutwardJobsService>, transport: Transport) {
    let formGrid = document.createElement("div");
    formGrid.className = "grid grid-cols-1 gap-6 mb-6";
    const formId = randomId();

    let familiesMap: Map<bigint, Family> = new Map();
    (await familiesListFromIDs(Array.from(new Set(outwardjob.inwardItemsList.map(p => p.familyId).concat(outwardjob.outwardItemsList.map(p => p.familyId)))))).forEach(family => {
        familiesMap.set(family.metadata?.id!, family);
    });

    const uomClient = getClientForUnitsOfMaterialsService(transport);
    let unitsList = <bigint[]>[];
    familiesMap.forEach(family => {
        unitsList.push(family.uomId);
    });

    const uomsList = (await uomClient.viewFromIDs({ list: Array.from(new Set(unitsList)) })).list;
    let uomsMap: Map<bigint, UnitOfMaterial> = new Map();

    uomsList.forEach(uom => {
        uomsMap.set(uom.metadata!.id, uom);
    });


    const [
        referencesSection,
        buyerAndConsigneeSection,
        datesSection,
        contactsSection,
        dynamicFormsSection,
        outwardFamiliesSection,
        inwardFamiliesSection,
    ] = await Promise.all([
        renderReferencesSection(outwardjob, ancillaryParams, readonly, accessClient),
        renderBuyerAndConsigneeSection(outwardjob, ancillaryParams, readonly, accessClient, transport),
        renderDatesSection(outwardjob, ancillaryParams, readonly, accessClient),
        renderContactsSection(outwardjob, readonly, accessClient, transport),
        renderDynamicFormsSection(outwardjob, readonly, accessClient),
        renderOutwardFamiliesSection(outwardjob, familiesMap, uomsMap, readonly, accessClient),
        renderInwardFamiliesSection(outwardjob, familiesMap, uomsMap, readonly, accessClient)
    ]);

    let buttonContainer = document.createElement("div");
    buttonContainer.id = randomId();
    buttonContainer.classList.add("col-span-12", "flex", "justify-start", "md:justify-center", "overflow-x-auto");

    // Download PDF button
    let downloadPDFButton = document.createElement("button");
    downloadPDFButton.id = randomId();
    downloadPDFButton.className = `btn btn-success btn-outline btn-sm ${downloadPDFButtonClass} mr-4`;
    downloadPDFButton.innerText = "Download PDF";
    downloadPDFButton.setAttribute("data-uuid", outwardjob.metadata?.uuid!);
    downloadPDFButton.setAttribute("data-name", outwardjob.approvalMetadata?.approvedOn! > 0 ? outwardjob.finalRefNumber : outwardjob.referenceId);
    buttonContainer.appendChild(downloadPDFButton);

    // Download CSV button
    let downloadOutwardCSVButton = document.createElement("button");
    downloadOutwardCSVButton.id = randomId();
    downloadOutwardCSVButton.className = `btn btn-success btn-outline btn-sm ${downloadOutwardCSVButtonClass} mr-4`;
    downloadOutwardCSVButton.innerText = "Download Outward Items CSV";
    downloadOutwardCSVButton.setAttribute("data-uuid", outwardjob.metadata?.uuid!);
    downloadOutwardCSVButton.setAttribute("data-name", outwardjob.approvalMetadata?.approvedOn! > 0 ? outwardjob.finalRefNumber : outwardjob.referenceId);
    buttonContainer.appendChild(downloadOutwardCSVButton);

    let downloadInwardCSVButton = document.createElement("button");
    downloadInwardCSVButton.id = randomId();
    downloadInwardCSVButton.className = `btn btn-success btn-outline btn-sm ${downloadInwardCSVButtonClass} mr-4`;
    downloadInwardCSVButton.innerText = "Download Inward Items CSV";
    downloadInwardCSVButton.setAttribute("data-uuid", outwardjob.metadata?.uuid!);
    downloadInwardCSVButton.setAttribute("data-name", outwardjob.approvalMetadata?.approvedOn! > 0 ? outwardjob.finalRefNumber : outwardjob.referenceId);
    buttonContainer.appendChild(downloadInwardCSVButton);

    formGrid.innerHTML = `
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words ${bgColor} w-full shadow-lg rounded">
            <div class="rounded-t mb-0 px-0 border-0">
                ${renderPageTitleSection({ title: `Outward Job: ` + document.title }).outerHTML}
                ${buttonContainer.outerHTML}
            </div>
            <form id="${formId}">
                <hr class="m-5">
                ${referencesSection.outerHTML}
                <hr class="m-5">
                ${buyerAndConsigneeSection.outerHTML}
                <hr class="m-5">
                ${datesSection.outerHTML}
                <hr class="m-5">
                ${contactsSection.outerHTML}
                <hr class="m-5">
            </form>
        </div>
        ${dynamicFormsSection.childElementCount > 0 ?
            `<div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words ${bgColor} w-full shadow-lg rounded">
                ${dynamicFormsSection.outerHTML}
            </div>`
            : ''}
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words ${bgColor} w-full shadow-lg rounded">
            ${outwardFamiliesSection.outerHTML}
        </div>
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words ${bgColor} w-full shadow-lg rounded">
            ${inwardFamiliesSection.outerHTML}
        </div>
    `;

    return { formGrid, buttonContainer }
}

"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file goods_receipts.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoodsReceiptsServiceSearchAllReq = exports.GoodsReceiptsServiceCountReq = exports.GoodsReceiptsServiceFilterReq = exports.GoodsReceiptsServicePaginationResponse = exports.GoodsReceiptsServicePaginationReq = exports.GoodsReceiptsServiceAlreadyAddedQuantityForSourceRequest = exports.GoodsReceiptsServicePaginatedItemsResponse = exports.GoodsReceiptItemsSearchRequest = exports.GoodsReceiptItemProspectiveInfoRequest = exports.GoodsReceiptItemHistoryRequest = exports.GoodsReceiptsItemsList = exports.GoodsReceiptsList = exports.GoodsReceiptItem = exports.GoodsReceiptsServiceItemUpdateRequest = exports.GoodsReceiptsServiceItemCreateRequest = exports.GoodsReceipt = exports.GoodsReceiptAncillaryParameters = exports.GoodsReceiptsServiceAutofillRequest = exports.GoodsReceiptsServiceUpdateRequest = exports.GoodsReceiptsServiceCreateRequest = exports.GOODS_RECEIPT_BILLING_STATUS = exports.GOODS_RECEIPT_SORT_KEY = exports.GOODS_RECEIPT_ITEM_STATUS = exports.GOODS_RECEIPT_ITEM_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.GOODS_RECEIPT_ITEM_SORT_KEY
 */
var GOODS_RECEIPT_ITEM_SORT_KEY;
(function (GOODS_RECEIPT_ITEM_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: GOODS_RECEIPT_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    GOODS_RECEIPT_ITEM_SORT_KEY[GOODS_RECEIPT_ITEM_SORT_KEY["GOODS_RECEIPT_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "GOODS_RECEIPT_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: GOODS_RECEIPT_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    GOODS_RECEIPT_ITEM_SORT_KEY[GOODS_RECEIPT_ITEM_SORT_KEY["GOODS_RECEIPT_ITEM_SORT_KEY_CREATED_AT"] = 1] = "GOODS_RECEIPT_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: GOODS_RECEIPT_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    GOODS_RECEIPT_ITEM_SORT_KEY[GOODS_RECEIPT_ITEM_SORT_KEY["GOODS_RECEIPT_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "GOODS_RECEIPT_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: GOODS_RECEIPT_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    GOODS_RECEIPT_ITEM_SORT_KEY[GOODS_RECEIPT_ITEM_SORT_KEY["GOODS_RECEIPT_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "GOODS_RECEIPT_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: GOODS_RECEIPT_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    GOODS_RECEIPT_ITEM_SORT_KEY[GOODS_RECEIPT_ITEM_SORT_KEY["GOODS_RECEIPT_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "GOODS_RECEIPT_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: GOODS_RECEIPT_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    GOODS_RECEIPT_ITEM_SORT_KEY[GOODS_RECEIPT_ITEM_SORT_KEY["GOODS_RECEIPT_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "GOODS_RECEIPT_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the family ID
     *
     * @generated from enum value: GOODS_RECEIPT_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    GOODS_RECEIPT_ITEM_SORT_KEY[GOODS_RECEIPT_ITEM_SORT_KEY["GOODS_RECEIPT_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "GOODS_RECEIPT_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch ordered results by the internal quantity
     *
     * @generated from enum value: GOODS_RECEIPT_ITEM_SORT_KEY_INTERNAL_QUANTITY = 11;
     */
    GOODS_RECEIPT_ITEM_SORT_KEY[GOODS_RECEIPT_ITEM_SORT_KEY["GOODS_RECEIPT_ITEM_SORT_KEY_INTERNAL_QUANTITY"] = 11] = "GOODS_RECEIPT_ITEM_SORT_KEY_INTERNAL_QUANTITY";
    /**
     * Fetch ordered results by the vendor unit of material ID
     *
     * @generated from enum value: GOODS_RECEIPT_ITEM_SORT_KEY_VENDOR_UOM_ID = 12;
     */
    GOODS_RECEIPT_ITEM_SORT_KEY[GOODS_RECEIPT_ITEM_SORT_KEY["GOODS_RECEIPT_ITEM_SORT_KEY_VENDOR_UOM_ID"] = 12] = "GOODS_RECEIPT_ITEM_SORT_KEY_VENDOR_UOM_ID";
    /**
     * Fetch ordered results by the vendor quantity
     *
     * @generated from enum value: GOODS_RECEIPT_ITEM_SORT_KEY_VENDOR_QUANTITY = 13;
     */
    GOODS_RECEIPT_ITEM_SORT_KEY[GOODS_RECEIPT_ITEM_SORT_KEY["GOODS_RECEIPT_ITEM_SORT_KEY_VENDOR_QUANTITY"] = 13] = "GOODS_RECEIPT_ITEM_SORT_KEY_VENDOR_QUANTITY";
})(GOODS_RECEIPT_ITEM_SORT_KEY || (exports.GOODS_RECEIPT_ITEM_SORT_KEY = GOODS_RECEIPT_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(GOODS_RECEIPT_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(GOODS_RECEIPT_ITEM_SORT_KEY, "Scailo.GOODS_RECEIPT_ITEM_SORT_KEY", [
    { no: 0, name: "GOODS_RECEIPT_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "GOODS_RECEIPT_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "GOODS_RECEIPT_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "GOODS_RECEIPT_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "GOODS_RECEIPT_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "GOODS_RECEIPT_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "GOODS_RECEIPT_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "GOODS_RECEIPT_ITEM_SORT_KEY_INTERNAL_QUANTITY" },
    { no: 12, name: "GOODS_RECEIPT_ITEM_SORT_KEY_VENDOR_UOM_ID" },
    { no: 13, name: "GOODS_RECEIPT_ITEM_SORT_KEY_VENDOR_QUANTITY" },
]);
/**
 *
 * Describes the applicable statuses of goods receipt items
 *
 * @generated from enum Scailo.GOODS_RECEIPT_ITEM_STATUS
 */
var GOODS_RECEIPT_ITEM_STATUS;
(function (GOODS_RECEIPT_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: GOODS_RECEIPT_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    GOODS_RECEIPT_ITEM_STATUS[GOODS_RECEIPT_ITEM_STATUS["GOODS_RECEIPT_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "GOODS_RECEIPT_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the goods receipt items must have been approved
     *
     * @generated from enum value: GOODS_RECEIPT_ITEM_STATUS_APPROVED = 1;
     */
    GOODS_RECEIPT_ITEM_STATUS[GOODS_RECEIPT_ITEM_STATUS["GOODS_RECEIPT_ITEM_STATUS_APPROVED"] = 1] = "GOODS_RECEIPT_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the goods receipt items must be waiting for approval
     *
     * @generated from enum value: GOODS_RECEIPT_ITEM_STATUS_UNAPPROVED = 2;
     */
    GOODS_RECEIPT_ITEM_STATUS[GOODS_RECEIPT_ITEM_STATUS["GOODS_RECEIPT_ITEM_STATUS_UNAPPROVED"] = 2] = "GOODS_RECEIPT_ITEM_STATUS_UNAPPROVED";
})(GOODS_RECEIPT_ITEM_STATUS || (exports.GOODS_RECEIPT_ITEM_STATUS = GOODS_RECEIPT_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(GOODS_RECEIPT_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(GOODS_RECEIPT_ITEM_STATUS, "Scailo.GOODS_RECEIPT_ITEM_STATUS", [
    { no: 0, name: "GOODS_RECEIPT_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "GOODS_RECEIPT_ITEM_STATUS_APPROVED" },
    { no: 2, name: "GOODS_RECEIPT_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.GOODS_RECEIPT_SORT_KEY
 */
var GOODS_RECEIPT_SORT_KEY;
(function (GOODS_RECEIPT_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: GOODS_RECEIPT_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    GOODS_RECEIPT_SORT_KEY[GOODS_RECEIPT_SORT_KEY["GOODS_RECEIPT_SORT_KEY_ID_UNSPECIFIED"] = 0] = "GOODS_RECEIPT_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: GOODS_RECEIPT_SORT_KEY_CREATED_AT = 1;
     */
    GOODS_RECEIPT_SORT_KEY[GOODS_RECEIPT_SORT_KEY["GOODS_RECEIPT_SORT_KEY_CREATED_AT"] = 1] = "GOODS_RECEIPT_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: GOODS_RECEIPT_SORT_KEY_MODIFIED_AT = 2;
     */
    GOODS_RECEIPT_SORT_KEY[GOODS_RECEIPT_SORT_KEY["GOODS_RECEIPT_SORT_KEY_MODIFIED_AT"] = 2] = "GOODS_RECEIPT_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: GOODS_RECEIPT_SORT_KEY_APPROVED_ON = 3;
     */
    GOODS_RECEIPT_SORT_KEY[GOODS_RECEIPT_SORT_KEY["GOODS_RECEIPT_SORT_KEY_APPROVED_ON"] = 3] = "GOODS_RECEIPT_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: GOODS_RECEIPT_SORT_KEY_APPROVED_BY = 4;
     */
    GOODS_RECEIPT_SORT_KEY[GOODS_RECEIPT_SORT_KEY["GOODS_RECEIPT_SORT_KEY_APPROVED_BY"] = 4] = "GOODS_RECEIPT_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: GOODS_RECEIPT_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    GOODS_RECEIPT_SORT_KEY[GOODS_RECEIPT_SORT_KEY["GOODS_RECEIPT_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "GOODS_RECEIPT_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: GOODS_RECEIPT_SORT_KEY_COMPLETED_ON = 6;
     */
    GOODS_RECEIPT_SORT_KEY[GOODS_RECEIPT_SORT_KEY["GOODS_RECEIPT_SORT_KEY_COMPLETED_ON"] = 6] = "GOODS_RECEIPT_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: GOODS_RECEIPT_SORT_KEY_REFERENCE_ID = 10;
     */
    GOODS_RECEIPT_SORT_KEY[GOODS_RECEIPT_SORT_KEY["GOODS_RECEIPT_SORT_KEY_REFERENCE_ID"] = 10] = "GOODS_RECEIPT_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: GOODS_RECEIPT_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    GOODS_RECEIPT_SORT_KEY[GOODS_RECEIPT_SORT_KEY["GOODS_RECEIPT_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "GOODS_RECEIPT_SORT_KEY_FINAL_REF_NUMBER";
    /**
     * Fetch ordered results by the vendor bill no
     *
     * @generated from enum value: GOODS_RECEIPT_SORT_KEY_VENDOR_BILL_NO = 12;
     */
    GOODS_RECEIPT_SORT_KEY[GOODS_RECEIPT_SORT_KEY["GOODS_RECEIPT_SORT_KEY_VENDOR_BILL_NO"] = 12] = "GOODS_RECEIPT_SORT_KEY_VENDOR_BILL_NO";
    /**
     * Fetch ordered results by the vendor bill date
     *
     * @generated from enum value: GOODS_RECEIPT_SORT_KEY_VENDOR_BILL_DATE = 13;
     */
    GOODS_RECEIPT_SORT_KEY[GOODS_RECEIPT_SORT_KEY["GOODS_RECEIPT_SORT_KEY_VENDOR_BILL_DATE"] = 13] = "GOODS_RECEIPT_SORT_KEY_VENDOR_BILL_DATE";
})(GOODS_RECEIPT_SORT_KEY || (exports.GOODS_RECEIPT_SORT_KEY = GOODS_RECEIPT_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(GOODS_RECEIPT_SORT_KEY)
protobuf_1.proto3.util.setEnumType(GOODS_RECEIPT_SORT_KEY, "Scailo.GOODS_RECEIPT_SORT_KEY", [
    { no: 0, name: "GOODS_RECEIPT_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "GOODS_RECEIPT_SORT_KEY_CREATED_AT" },
    { no: 2, name: "GOODS_RECEIPT_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "GOODS_RECEIPT_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "GOODS_RECEIPT_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "GOODS_RECEIPT_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "GOODS_RECEIPT_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "GOODS_RECEIPT_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "GOODS_RECEIPT_SORT_KEY_FINAL_REF_NUMBER" },
    { no: 12, name: "GOODS_RECEIPT_SORT_KEY_VENDOR_BILL_NO" },
    { no: 13, name: "GOODS_RECEIPT_SORT_KEY_VENDOR_BILL_DATE" },
]);
/**
 *
 * Describes the available billing statuses
 *
 * @generated from enum Scailo.GOODS_RECEIPT_BILLING_STATUS
 */
var GOODS_RECEIPT_BILLING_STATUS;
(function (GOODS_RECEIPT_BILLING_STATUS) {
    /**
     * Any billing status
     *
     * @generated from enum value: GOODS_RECEIPT_BILLING_STATUS_ANY_UNSPECIFIED = 0;
     */
    GOODS_RECEIPT_BILLING_STATUS[GOODS_RECEIPT_BILLING_STATUS["GOODS_RECEIPT_BILLING_STATUS_ANY_UNSPECIFIED"] = 0] = "GOODS_RECEIPT_BILLING_STATUS_ANY_UNSPECIFIED";
    /**
     * Goods Receipt is billed
     *
     * @generated from enum value: GOODS_RECEIPT_BILLING_STATUS_BILLED = 1;
     */
    GOODS_RECEIPT_BILLING_STATUS[GOODS_RECEIPT_BILLING_STATUS["GOODS_RECEIPT_BILLING_STATUS_BILLED"] = 1] = "GOODS_RECEIPT_BILLING_STATUS_BILLED";
    /**
     * Goods Receipt is unbilled
     *
     * @generated from enum value: GOODS_RECEIPT_BILLING_STATUS_UNBILLED = 2;
     */
    GOODS_RECEIPT_BILLING_STATUS[GOODS_RECEIPT_BILLING_STATUS["GOODS_RECEIPT_BILLING_STATUS_UNBILLED"] = 2] = "GOODS_RECEIPT_BILLING_STATUS_UNBILLED";
})(GOODS_RECEIPT_BILLING_STATUS || (exports.GOODS_RECEIPT_BILLING_STATUS = GOODS_RECEIPT_BILLING_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(GOODS_RECEIPT_BILLING_STATUS)
protobuf_1.proto3.util.setEnumType(GOODS_RECEIPT_BILLING_STATUS, "Scailo.GOODS_RECEIPT_BILLING_STATUS", [
    { no: 0, name: "GOODS_RECEIPT_BILLING_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "GOODS_RECEIPT_BILLING_STATUS_BILLED" },
    { no: 2, name: "GOODS_RECEIPT_BILLING_STATUS_UNBILLED" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.GoodsReceiptsServiceCreateRequest
 */
class GoodsReceiptsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the goods receipt
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The vendor's bill number
     *
     * @generated from field: string vendor_bill_no = 14;
     */
    vendorBillNo = "";
    /**
     * The date on which the bill was raised by the vendor
     *
     * @generated from field: string vendor_bill_date = 15;
     */
    vendorBillDate = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsReceiptsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "vendor_bill_no", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "vendor_bill_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsReceiptsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsReceiptsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsReceiptsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsReceiptsServiceCreateRequest, a, b);
    }
}
exports.GoodsReceiptsServiceCreateRequest = GoodsReceiptsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.GoodsReceiptsServiceUpdateRequest
 */
class GoodsReceiptsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the goods receipt
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The vendor's bill number
     *
     * @generated from field: string vendor_bill_no = 14;
     */
    vendorBillNo = "";
    /**
     * The date on which the bill was raised by the vendor
     *
     * @generated from field: string vendor_bill_date = 15;
     */
    vendorBillDate = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsReceiptsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "vendor_bill_no", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "vendor_bill_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsReceiptsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsReceiptsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsReceiptsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsReceiptsServiceUpdateRequest, a, b);
    }
}
exports.GoodsReceiptsServiceUpdateRequest = GoodsReceiptsServiceUpdateRequest;
/**
 *
 * Describes the parameters necessary to perform an autofill request
 *
 * @generated from message Scailo.GoodsReceiptsServiceAutofillRequest
 */
class GoodsReceiptsServiceAutofillRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The UUID of the record that needs to be updated
     *
     * @generated from field: string uuid = 2;
     */
    uuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsReceiptsServiceAutofillRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsReceiptsServiceAutofillRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsReceiptsServiceAutofillRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsReceiptsServiceAutofillRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsReceiptsServiceAutofillRequest, a, b);
    }
}
exports.GoodsReceiptsServiceAutofillRequest = GoodsReceiptsServiceAutofillRequest;
/**
 *
 * Stores the UUID references of the record
 *
 * @generated from message Scailo.GoodsReceiptAncillaryParameters
 */
class GoodsReceiptAncillaryParameters extends protobuf_1.Message {
    /**
     * The UUID of the ref_id (the UUID of the associated ref_id)
     *
     * @generated from field: string ref_uuid = 213;
     */
    refUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsReceiptAncillaryParameters";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 213, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsReceiptAncillaryParameters().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsReceiptAncillaryParameters().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsReceiptAncillaryParameters().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsReceiptAncillaryParameters, a, b);
    }
}
exports.GoodsReceiptAncillaryParameters = GoodsReceiptAncillaryParameters;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.GoodsReceipt
 */
class GoodsReceipt extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this goods receipt
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this goods receipt
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this goods receipt
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this goods receipt was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the goods receipt
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The vendor's bill number
     *
     * @generated from field: string vendor_bill_no = 14;
     */
    vendorBillNo = "";
    /**
     * The date on which the bill was raised by the vendor
     *
     * @generated from field: string vendor_bill_date = 15;
     */
    vendorBillDate = "";
    /**
     * The list of associated goods receipt items
     *
     * @generated from field: repeated Scailo.GoodsReceiptItem list = 20;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsReceipt";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "vendor_bill_no", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "vendor_bill_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "list", kind: "message", T: GoodsReceiptItem, repeated: true },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsReceipt().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsReceipt().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsReceipt().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsReceipt, a, b);
    }
}
exports.GoodsReceipt = GoodsReceipt;
/**
 *
 * Describes the parameters required to add an item to a goods receipt
 *
 * @generated from message Scailo.GoodsReceiptsServiceItemCreateRequest
 */
class GoodsReceiptsServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the goods receipt ID
     *
     * @generated from field: uint64 goods_receipt_id = 10;
     */
    goodsReceiptId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being admitted in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 13;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being admitted in vendor's unit of material
     *
     * @generated from field: uint64 vendor_quantity = 14;
     */
    vendorQuantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsReceiptsServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "goods_receipt_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "vendor_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsReceiptsServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsReceiptsServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsReceiptsServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsReceiptsServiceItemCreateRequest, a, b);
    }
}
exports.GoodsReceiptsServiceItemCreateRequest = GoodsReceiptsServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a goods receipt
 *
 * @generated from message Scailo.GoodsReceiptsServiceItemUpdateRequest
 */
class GoodsReceiptsServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being admitted in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 13;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being admitted in vendor's unit of material
     *
     * @generated from field: uint64 vendor_quantity = 14;
     */
    vendorQuantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsReceiptsServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "vendor_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsReceiptsServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsReceiptsServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsReceiptsServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsReceiptsServiceItemUpdateRequest, a, b);
    }
}
exports.GoodsReceiptsServiceItemUpdateRequest = GoodsReceiptsServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a goods receipt
 *
 * @generated from message Scailo.GoodsReceiptItem
 */
class GoodsReceiptItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this goods receipt
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the goods receipt ID
     *
     * @generated from field: uint64 goods_receipt_id = 10;
     */
    goodsReceiptId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being admitted in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 13;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being admitted in vendor's unit of material
     *
     * @generated from field: uint64 vendor_quantity = 14;
     */
    vendorQuantity = protobuf_1.protoInt64.zero;
    /**
     * ----------------------------------------
     * The UUID of the associated goods receipt
     *
     * @generated from field: string goods_receipt_uuid = 110;
     */
    goodsReceiptUuid = "";
    /**
     * The UUID of the associated family
     *
     * ----------------------------------------
     *
     * @generated from field: string family_uuid = 111;
     */
    familyUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsReceiptItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "goods_receipt_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "vendor_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 110, name: "goods_receipt_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 111, name: "family_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsReceiptItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsReceiptItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsReceiptItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsReceiptItem, a, b);
    }
}
exports.GoodsReceiptItem = GoodsReceiptItem;
/**
 *
 * Describes the message consisting of the list of goods receipts
 *
 * @generated from message Scailo.GoodsReceiptsList
 */
class GoodsReceiptsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.GoodsReceipt list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsReceiptsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: GoodsReceipt, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsReceiptsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsReceiptsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsReceiptsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsReceiptsList, a, b);
    }
}
exports.GoodsReceiptsList = GoodsReceiptsList;
/**
 *
 * Describes the message consisting of the list of goods receipt items
 *
 * @generated from message Scailo.GoodsReceiptsItemsList
 */
class GoodsReceiptsItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.GoodsReceiptItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsReceiptsItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: GoodsReceiptItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsReceiptsItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsReceiptsItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsReceiptsItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsReceiptsItemsList, a, b);
    }
}
exports.GoodsReceiptsItemsList = GoodsReceiptsItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.GoodsReceiptItemHistoryRequest
 */
class GoodsReceiptItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the goods receipt ID
     *
     * @generated from field: uint64 goods_receipt_id = 10;
     */
    goodsReceiptId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsReceiptItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "goods_receipt_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsReceiptItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsReceiptItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsReceiptItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsReceiptItemHistoryRequest, a, b);
    }
}
exports.GoodsReceiptItemHistoryRequest = GoodsReceiptItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective goods receipt item
 *
 * @generated from message Scailo.GoodsReceiptItemProspectiveInfoRequest
 */
class GoodsReceiptItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the goods receipt ID
     *
     * @generated from field: uint64 goods_receipt_id = 10;
     */
    goodsReceiptId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsReceiptItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "goods_receipt_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsReceiptItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsReceiptItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsReceiptItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsReceiptItemProspectiveInfoRequest, a, b);
    }
}
exports.GoodsReceiptItemProspectiveInfoRequest = GoodsReceiptItemProspectiveInfoRequest;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.GoodsReceiptItemsSearchRequest
 */
class GoodsReceiptItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.GOODS_RECEIPT_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = GOODS_RECEIPT_ITEM_SORT_KEY.GOODS_RECEIPT_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.GOODS_RECEIPT_ITEM_STATUS status = 7;
     */
    status = GOODS_RECEIPT_ITEM_STATUS.GOODS_RECEIPT_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the goods receipt
     *
     * @generated from field: uint64 goods_receipt_id = 20;
     */
    goodsReceiptId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 22;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 24;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    /**
     * ------------------------------------------
     * Extra filters
     * The ID of the associated vendor
     *
     * ------------------------------------------
     *
     * @generated from field: uint64 vendor_id = 200;
     */
    vendorId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsReceiptItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(GOODS_RECEIPT_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(GOODS_RECEIPT_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "goods_receipt_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 200, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsReceiptItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsReceiptItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsReceiptItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsReceiptItemsSearchRequest, a, b);
    }
}
exports.GoodsReceiptItemsSearchRequest = GoodsReceiptItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.GoodsReceiptsServicePaginatedItemsResponse
 */
class GoodsReceiptsServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.GoodsReceiptItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsReceiptsServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: GoodsReceiptItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsReceiptsServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsReceiptsServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsReceiptsServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsReceiptsServicePaginatedItemsResponse, a, b);
    }
}
exports.GoodsReceiptsServicePaginatedItemsResponse = GoodsReceiptsServicePaginatedItemsResponse;
/**
 *
 * Describes the request payload to retrieve the quantity that has already been added for the specific ref_from, ref_id and family_id
 *
 * @generated from message Scailo.GoodsReceiptsServiceAlreadyAddedQuantityForSourceRequest
 */
class GoodsReceiptsServiceAlreadyAddedQuantityForSourceRequest extends protobuf_1.Message {
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 1;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 2;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The associated family ID
     *
     * @generated from field: uint64 family_id = 3;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsReceiptsServiceAlreadyAddedQuantityForSourceRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsReceiptsServiceAlreadyAddedQuantityForSourceRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsReceiptsServiceAlreadyAddedQuantityForSourceRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsReceiptsServiceAlreadyAddedQuantityForSourceRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsReceiptsServiceAlreadyAddedQuantityForSourceRequest, a, b);
    }
}
exports.GoodsReceiptsServiceAlreadyAddedQuantityForSourceRequest = GoodsReceiptsServiceAlreadyAddedQuantityForSourceRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.GoodsReceiptsServicePaginationReq
 */
class GoodsReceiptsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.GOODS_RECEIPT_SORT_KEY sort_key = 5;
     */
    sortKey = GOODS_RECEIPT_SORT_KEY.GOODS_RECEIPT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this goods receipt
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsReceiptsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(GOODS_RECEIPT_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsReceiptsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsReceiptsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsReceiptsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsReceiptsServicePaginationReq, a, b);
    }
}
exports.GoodsReceiptsServicePaginationReq = GoodsReceiptsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.GoodsReceiptsServicePaginationResponse
 */
class GoodsReceiptsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.GoodsReceipt payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsReceiptsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: GoodsReceipt, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsReceiptsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsReceiptsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsReceiptsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsReceiptsServicePaginationResponse, a, b);
    }
}
exports.GoodsReceiptsServicePaginationResponse = GoodsReceiptsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.GoodsReceiptsServiceFilterReq
 */
class GoodsReceiptsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.GOODS_RECEIPT_SORT_KEY sort_key = 5;
     */
    sortKey = GOODS_RECEIPT_SORT_KEY.GOODS_RECEIPT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this goods receipt
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the goods receipt
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The vendor's bill number
     *
     * @generated from field: string vendor_bill_no = 24;
     */
    vendorBillNo = "";
    /**
     * The start date of vendor bills
     *
     * @generated from field: string vendor_bill_date_start = 25;
     */
    vendorBillDateStart = "";
    /**
     * The end date of vendor bills
     *
     * @generated from field: string vendor_bill_date_end = 26;
     */
    vendorBillDateEnd = "";
    /**
     * The exact vendor bill date which should be considered
     *
     * @generated from field: string vendor_bill_date_exact = 27;
     */
    vendorBillDateExact = "";
    /**
     * The ID of the associated vendor
     *
     * @generated from field: uint64 vendor_id = 30;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 31;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The status of the goods receipt bill
     *
     * @generated from field: Scailo.GOODS_RECEIPT_BILLING_STATUS billing_status = 50;
     */
    billingStatus = GOODS_RECEIPT_BILLING_STATUS.GOODS_RECEIPT_BILLING_STATUS_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsReceiptsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(GOODS_RECEIPT_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "vendor_bill_no", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 25, name: "vendor_bill_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 26, name: "vendor_bill_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 27, name: "vendor_bill_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 31, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "billing_status", kind: "enum", T: protobuf_1.proto3.getEnumType(GOODS_RECEIPT_BILLING_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsReceiptsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsReceiptsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsReceiptsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsReceiptsServiceFilterReq, a, b);
    }
}
exports.GoodsReceiptsServiceFilterReq = GoodsReceiptsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.GoodsReceiptsServiceCountReq
 */
class GoodsReceiptsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this goods receipt
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the goods receipt
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The vendor's bill number
     *
     * @generated from field: string vendor_bill_no = 24;
     */
    vendorBillNo = "";
    /**
     * The start date of vendor bills
     *
     * @generated from field: string vendor_bill_date_start = 25;
     */
    vendorBillDateStart = "";
    /**
     * The end date of vendor bills
     *
     * @generated from field: string vendor_bill_date_end = 26;
     */
    vendorBillDateEnd = "";
    /**
     * The exact vendor bill date which should be considered
     *
     * @generated from field: string vendor_bill_date_exact = 27;
     */
    vendorBillDateExact = "";
    /**
     * The ID of the associated vendor
     *
     * @generated from field: uint64 vendor_id = 30;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 31;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The status of the goods receipt bill
     *
     * @generated from field: Scailo.GOODS_RECEIPT_BILLING_STATUS billing_status = 50;
     */
    billingStatus = GOODS_RECEIPT_BILLING_STATUS.GOODS_RECEIPT_BILLING_STATUS_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsReceiptsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "vendor_bill_no", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 25, name: "vendor_bill_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 26, name: "vendor_bill_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 27, name: "vendor_bill_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 31, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "billing_status", kind: "enum", T: protobuf_1.proto3.getEnumType(GOODS_RECEIPT_BILLING_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsReceiptsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsReceiptsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsReceiptsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsReceiptsServiceCountReq, a, b);
    }
}
exports.GoodsReceiptsServiceCountReq = GoodsReceiptsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.GoodsReceiptsServiceSearchAllReq
 */
class GoodsReceiptsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.GOODS_RECEIPT_SORT_KEY sort_key = 5;
     */
    sortKey = GOODS_RECEIPT_SORT_KEY.GOODS_RECEIPT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The status of the goods receipt bill
     *
     * @generated from field: Scailo.GOODS_RECEIPT_BILLING_STATUS billing_status = 40;
     */
    billingStatus = GOODS_RECEIPT_BILLING_STATUS.GOODS_RECEIPT_BILLING_STATUS_ANY_UNSPECIFIED;
    /**
     * The ID of the associated vendor
     *
     * @generated from field: uint64 vendor_id = 50;
     */
    vendorId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsReceiptsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(GOODS_RECEIPT_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "billing_status", kind: "enum", T: protobuf_1.proto3.getEnumType(GOODS_RECEIPT_BILLING_STATUS) },
        { no: 50, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsReceiptsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsReceiptsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsReceiptsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsReceiptsServiceSearchAllReq, a, b);
    }
}
exports.GoodsReceiptsServiceSearchAllReq = GoodsReceiptsServiceSearchAllReq;

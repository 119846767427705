import * as ui from "../../ui";
import { _returnInCents, bgColor, randomId } from "../../utilities";
import { PURCHASE_ORDER_SORT_KEY } from "@kernelminds/scailo-sdk";

function renderPrimaryFiltersSection() {
    let { grid, contentGrid } = ui.renderFilterPrimarySubSection({ subsectionTitle: "Primary Filters", titleMdColSpan: 3 });

    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Reference Id", inputType: "text", dataMapper: "referenceId", dataType: "string", value: "", mdColSpan: 6, helpText: "Reference Id is the primary identifier that is created manually. Filter records by the value of this field. Leave it empty to ignore this field.", dataRegex: ".*" }));
    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Final Ref Number", inputType: "text", dataMapper: "finalRefNumber", dataType: "string", value: "", mdColSpan: 6, helpText: "Final Ref Number is an automatically assigned sequence number that is created when the record is approved. Filter records by the value of this field. Leave it empty to ignore this field.", dataRegex: ".*" }));


    const consigneeLocationElementId = randomId();
    contentGrid.appendChild(ui.renderSelect({ id: consigneeLocationElementId, readonly: false, label: "Consignee Location", dataMapper: "consigneeLocationId", dataType: "bigint", value: "", mdColSpan: 6, helpText: "Select the Consignee Location.", options: [], dataRegex: ".*", }));

    const buyerLocationElementId = randomId();
    contentGrid.appendChild(ui.renderSelect({ id: buyerLocationElementId, readonly: false, label: "Buyer Location", dataMapper: "buyerLocationId", dataType: "bigint", value: "", mdColSpan: 6, helpText: "Select the Buyer Location.", options: [], dataRegex: ".*", }));

    const currencyReferenceId = randomId();
    contentGrid.appendChild(ui.renderSelect({ id: currencyReferenceId, readonly: false, label: "Currency", dataMapper: "currencyId", dataType: "bigint", value: "", mdColSpan: 4, helpText: "Select the Currency of the Purchase Order.", options: [], dataRegex: ".*", }));
    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Min Total Amount", inputType: "number", dataMapper: "totalValueMin", dataType: "money_in_cents", value: "", mdColSpan: 4, helpText: "Minimum total amount to be considered. If set, all records that have a minimum of the entered amount are returned. Leave it empty to ignore this field.", dataRegex: ".*" }));
    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Max Total Amount", inputType: "number", dataMapper: "totalValueMax", dataType: "money_in_cents", value: "", mdColSpan: 4, helpText: "Maximum total amount to be considered. If set, all records that have a maximum of the entered amount are returned. Leave it empty to ignore this field.", dataRegex: ".*" }));

    return { grid, consigneeLocationElementId, buyerLocationElementId, currencyReferenceId }
}

function renderDeliveryDatesSection() {
    let { grid, contentGrid } = ui.renderFilterPrimarySubSection({ subsectionTitle: "Delivery Dates", titleMdColSpan: 3 });

    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Start Delivery Date", inputType: "date", dataMapper: "deliveryDateStart", dataType: "string", value: "", mdColSpan: 4, helpText: "Start Delivery Date is the minimum delivery date to be considered. If set, all records from this delivery date are returned. Leave it empty to ignore this field.", dataRegex: ".*" }));
    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "End Delivery Date", inputType: "date", dataMapper: "deliveryDateEnd", dataType: "string", value: "", mdColSpan: 4, helpText: "End Delivery Date is the maximum delivery date to be considered. If set, all records until this delivery date are returned. Leave it empty to ignore this field.", dataRegex: ".*" }));
    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Exact Delivery Date", inputType: "date", dataMapper: "deliveryDateExact", dataType: "string", value: "", mdColSpan: 4, helpText: "Exact Delivery Date is the exact delivery date to be considered. If set, records with this delivery date are returned. Leave it empty to ignore this field.", dataRegex: ".*" }));
    return grid
}

export function _renderPageFilters(context: "Records" | "Insights") {
    let primaryFiltersSection = renderPrimaryFiltersSection();
    let deliveryDatesSection = renderDeliveryDatesSection();
    let statusFiltersSection = ui.renderStatusFiltersSection();
    let timeFiltersSection = ui.renderTimeFiltersSection();
    let sortFiltersSection = ui.renderSortFiltersSection();

    // Attach specific sort key
    sortFiltersSection.contentGrid.prepend(ui.renderSelectForPartialEnum({
        id: randomId(),
        label: "Sort By",
        nameSplitsAt: "PURCHASE_ORDER_SORT_KEY_",
        enumObject: PURCHASE_ORDER_SORT_KEY,
        enumValues: [
            PURCHASE_ORDER_SORT_KEY.PURCHASE_ORDER_SORT_KEY_CREATED_AT,
            PURCHASE_ORDER_SORT_KEY.PURCHASE_ORDER_SORT_KEY_MODIFIED_AT,
            PURCHASE_ORDER_SORT_KEY.PURCHASE_ORDER_SORT_KEY_APPROVED_ON,
            PURCHASE_ORDER_SORT_KEY.PURCHASE_ORDER_SORT_KEY_COMPLETED_ON,
            PURCHASE_ORDER_SORT_KEY.PURCHASE_ORDER_SORT_KEY_REFERENCE_ID,
            PURCHASE_ORDER_SORT_KEY.PURCHASE_ORDER_SORT_KEY_FINAL_REF_NUMBER,
            PURCHASE_ORDER_SORT_KEY.PURCHASE_ORDER_SORT_KEY_TOTAL_VALUE,
        ],
        dataMapper: "sortKey",
        dataType: "number",
        value: "",
        mdColSpan: 12,
        helpText: "Sort the response with the selected field.",
        excludeZeroethValuedEnum: false,
        readonly: false,
        dataRegex: ".*",
    }));

    let buttonsDiv = document.createElement("div");
    buttonsDiv.className = `col-span-12 pl-4 float-right`;

    let getButtonId = randomId();
    let getButton = document.createElement("button");
    getButton.id = getButtonId;
    getButton.className = "btn btn-outline btn-success btn-md mr-4";
    getButton.innerText = `Get ${context}`;
    buttonsDiv.append(getButton);

    let resetButtonId = randomId();
    let resetButton = document.createElement("button");
    resetButton.id = resetButtonId;
    resetButton.className = "btn btn-outline btn-info btn-md mr-4";
    resetButton.innerText = "Reset";
    buttonsDiv.append(resetButton);

    const formId = randomId();

    return {
        html: `
            <div class="grid grid-cols-1 gap-6 mb-6">
                <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words ${bgColor} w-full shadow-lg rounded">
                    <div class="rounded-t mb-0 px-0 border-0">
                        ${ui.renderPageTitleSection({ title: "Search for Purchase Orders" }).outerHTML}
                    </div>
                    <form id="${formId}">
                        ${primaryFiltersSection.grid.outerHTML}
                        <hr class="m-5">
                        ${deliveryDatesSection.outerHTML}
                        <hr class="m-5">
                        ${statusFiltersSection.outerHTML}
                        <hr class="m-5">
                        ${timeFiltersSection.outerHTML}
                        <hr class="m-5">
                        ${sortFiltersSection.grid.outerHTML}
                        <hr class="m-5">
                        ${buttonsDiv.outerHTML}
                    </form>
                </div>
            </div>
        `,
        formId,
        resetButtonId,
        getButtonId,
        consigneeLocationId: primaryFiltersSection.consigneeLocationElementId,
        buyerLocationId: primaryFiltersSection.buyerLocationElementId,
        currencyReferenceId: primaryFiltersSection.currencyReferenceId
    }
}
import { _returnInCents, convertBigIntTimestampToDate, convertCentsToMoney, createObjectFromForm, internationalizeMoney, round, showFailureAlert } from "../../utilities";
import { context } from "./../../router";
import { Family, getClientForLocationsService, getClientForOutwardJobsService, OutwardJob, OutwardJobsServiceFilterReq } from "@kernelminds/scailo-sdk";
import { _renderPageFilters } from "./searchcomponents";
import { protoInt64 } from "@bufbuild/protobuf";
import ApexCharts from 'apexcharts';
import { familiesListFromIDs } from "../../fetches";
import { getTransport } from "../../clients";
import { setupLocationSearchable } from "../../searchables";

export function handleOutwardJobInsights(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    let { html, formId, resetButtonId, getButtonId, consigneeLocationId } = _renderPageFilters("Insights");
    let insightsHtml = document.createElement("div");
    insightsHtml.innerHTML = html

    content.appendChild(insightsHtml);

    // Setup searchable
    const transport = getTransport();
    const locationsClient = getClientForLocationsService(transport);
    setupLocationSearchable(consigneeLocationId, locationsClient, {});

    let chartsContainer = document.createElement("div");
    // chartsContainer.className = "grid grid-cols-12";
    content.appendChild(chartsContainer);

    (<HTMLButtonElement>document.getElementById(resetButtonId)).addEventListener("click", async evt => {
        evt.preventDefault();
        handleOutwardJobInsights(ctx);
    });

    let getButton = (<HTMLButtonElement>document.getElementById(getButtonId));

    getButton.addEventListener("click", async evt => {
        evt.preventDefault();
        while (chartsContainer.firstChild) {
            chartsContainer.removeChild(chartsContainer.firstChild);
        }

        getButton.disabled = true;
        getButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;

        let client = getClientForOutwardJobsService(transport);
        let [filterResp] = await Promise.all([
            client.filter(new OutwardJobsServiceFilterReq(createObjectFromForm(formId))),
        ]);

        getButton.disabled = false;
        getButton.innerText = `Get Insights`;

        const insightRecords = filterResp.list;

        if (!insightRecords.length) {
            showFailureAlert("No Records Found");
            return;
        }
        
        let chartsDiv = document.createElement("div");
        chartsDiv.className = "overflow-x-auto p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded grid grid-cols-12";
        chartsContainer.appendChild(chartsDiv);

        const commonTheme = {
            palette: 'palette2',
        };
        // Display all the insights here
        displayAllRelevantCharts(insightRecords, chartsDiv, commonTheme);
    });
}

/**Displays all the charts relevant to outward jobs */
export function displayAllRelevantCharts(outwardjobs: OutwardJob[], chartsDiv: HTMLDivElement, commonTheme: Object) {
    let cumulativeOutwardSeriesData = <number[]>[];
    let incrementingTotalOutwardCount = 0;
    let totalOutwardCount = 0;

    let cumulativeInwardSeriesData = <number[]>[];
    let incrementingTotalInwardCount = 0;
    let totalInwardCount = 0;

    outwardjobs.forEach(outwardjob => {
        incrementingTotalOutwardCount += outwardjob.outwardItemsList.length;
        cumulativeOutwardSeriesData.push(parseFloat(round(incrementingTotalOutwardCount)));
        totalOutwardCount += outwardjob.outwardItemsList.length;

        incrementingTotalInwardCount += outwardjob.inwardItemsList.length;
        cumulativeInwardSeriesData.push(parseFloat(round(incrementingTotalInwardCount)));
        totalInwardCount += outwardjob.inwardItemsList.length;
    });


    let options = {
        title: {
            text: `Total Outward Jobs: ${outwardjobs.length}, Total Outward Item Count: ${new Intl.NumberFormat(Intl.DateTimeFormat().resolvedOptions().locale, { maximumSignificantDigits: 2 }).format(parseFloat(round(totalOutwardCount)))}, Total Inward Item Count: ${new Intl.NumberFormat(Intl.DateTimeFormat().resolvedOptions().locale, { maximumSignificantDigits: 2 }).format(parseFloat(round(totalInwardCount)))}`,
            align: "left",
            margin: 30,
            offsetX: 0,
            offsetY: -10,
            floating: true,
            style: {
                fontSize: "20px",
                fontWeight: "light",
                color: "#263238"
            },
        },
        theme: commonTheme,
        series: [
            // Area Series
            {
                name: "Cumulative Outward Jobs Outward Item Count",
                type: "area",
                data: cumulativeOutwardSeriesData
            },
            // Line Series
            {
                name: "Individual Outward Job Outward Item Count",
                type: "line",
                data: outwardjobs.map(outwardjob => parseFloat(round(outwardjob.outwardItemsList.length)))
            },
            // Area Series
            {
                name: "Cumulative Outward Jobs Inward Item Count",
                type: "area",
                data: cumulativeInwardSeriesData
            },
            // Line Series
            {
                name: "Individual Outward Job Inward Item Count",
                type: "line",
                data: outwardjobs.map(outwardjob => parseFloat(round(outwardjob.inwardItemsList.length)))
            }
        ],
        chart: {
            height: 500,
            type: "line",
        },
        stroke: {
            curve: "smooth"
        },
        fill: {
            type: "solid",
            opacity: [0.10, 1],
        },
        labels: outwardjobs.map(outwardjob => convertBigIntTimestampToDate(outwardjob.approvalMetadata!.approvedOn)),
        markers: {
            size: 2
        },
        legend: {
            position: "top"
        },
        yaxis: [
            {
                title: {
                    text: "Cumulative Outward Jobs Outward Item Count",
                },
            },
            {
                opposite: true,
                title: {
                    text: "Individual Outward Job Outward Item Count",
                },
            },
        ],

        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                let outwardjob = outwardjobs[dataPointIndex];
                return `
                    <ul style='background-color: #424242; color: #F5F5F5; padding: 20px; margin: 0px;'>
                        <li>Outward Job Number: ${outwardjob.approvalMetadata!.approvedOn > 0 ? outwardjob.finalRefNumber : outwardjob.referenceId}</li>
                        <li>Outward Job Line Outward Count: ${parseFloat(round(outwardjob.outwardItemsList.length))}</li>
                        <li>Cumulative Line Outward Count: ${cumulativeOutwardSeriesData[dataPointIndex]}</li>
                        <li>Outward Job Line Inward Count: ${parseFloat(round(outwardjob.inwardItemsList.length))}</li>
                        <li>Cumulative Line Inward Count: ${cumulativeInwardSeriesData[dataPointIndex]}</li>
                        <li>Approved Date: ${convertBigIntTimestampToDate(outwardjob.approvalMetadata!.approvedOn)}</li>
                        <li>Index: ${dataPointIndex + 1}</li>
                    </ul>
                `;
            }
        }
    };

    let chart = new ApexCharts(chartsDiv, options);
    chart.render();
}
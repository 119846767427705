import { PartialMessage, protoInt64 } from '@bufbuild/protobuf';
import { PromiseClient } from '@connectrpc/connect';
import { BOOL_FILTER, CurrenciesService, CurrenciesServiceSearchAllReq, Currency, CURRENCY_SORT_KEY, DEBIT_NOTE_SORT_KEY, DebitNote, DebitNotesService, DebitNotesServiceSearchAllReq, EmployeeMetadata, GOODS_RECEIPT_SORT_KEY, GoodsReceipt, GoodsReceiptsService, GoodsReceiptsServiceSearchAllReq, Location, LOCATION_SORT_KEY, LocationsService, LocationsServiceSearchAllReq, OUTWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY, OUTWARD_JOB_FREE_ISSUE_MATERIAL_SORT_KEY, OUTWARD_JOB_SORT_KEY, OutwardJob, OutwardJobFreeIssueMaterial, OutwardJobFreeIssueMaterialReturn, OutwardJobsFreeIssueMaterialsReturnsService, OutwardJobsFreeIssueMaterialsReturnsServiceSearchAllReq, OutwardJobsFreeIssueMaterialsService, OutwardJobsFreeIssueMaterialsServiceSearchAllReq, OutwardJobsService, OutwardJobsServiceSearchAllReq, PURCHASE_ENQUIRY_SORT_KEY, PURCHASE_ORDER_SORT_KEY, PURCHASE_PAYMENT_SORT_KEY, PURCHASE_RETURN_SORT_KEY, PurchaseEnquiry, PurchaseOrder, PurchasePayment, PurchaseReturn, PurchasesEnquiriesService, PurchasesEnquiriesServiceSearchAllReq, PurchasesOrdersService, PurchasesOrdersServiceSearchAllReq, PurchasesPaymentsService, PurchasesPaymentsServiceSearchAllReq, PurchasesReturnsService, PurchasesReturnsServiceSearchAllReq, QC_SAMPLE_LIFECYCLE, QC_SAMPLE_SORT_KEY, QCSample, QCSamplesService, QCSamplesServiceSearchAllReq, QCSampleWithMetadata, QUOTATION_REQUEST_SORT_KEY, QUOTATION_RESPONSE_SORT_KEY, QuotationRequest, QuotationResponse, QuotationsRequestsService, QuotationsRequestsServiceSearchAllReq, QuotationsResponsesService, QuotationsResponsesServiceSearchAllReq, SORT_ORDER, STANDARD_LIFECYCLE_STATUS, SUPPLY_OFFER_SORT_KEY, SupplyOffer, SupplyOffersService, SupplyOffersServiceSearchAllReq, VENDOR_INVOICE_SORT_KEY, VendorInvoice, VendorInvoicesService, VendorInvoicesServiceSearchAllReq } from '@kernelminds/scailo-sdk';
import Choices, { Options } from 'choices.js';

function choicesOpts(): Partial<Options> {
    return {
        removeItemButton: true,
        itemSelectText: 'Select',
        searchFloor: 1,
        searchResultLimit: -1,
        noChoicesText: 'Kindly enter a search key',
    }
}

export function setupPurchaseOrderSearchable(elementId: string, accessClient: PromiseClient<typeof PurchasesOrdersService>, filter: PartialMessage<PurchasesOrdersServiceSearchAllReq>,) {
    let purchaseOrderReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(purchaseOrderReference, choicesOpts());
    purchaseOrderReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: PURCHASE_ORDER_SORT_KEY.PURCHASE_ORDER_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new PurchaseOrder({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });

    // choice.setChoices([
    //     {value: '1', label: '1', disabled: false},
    // ]);
    // choice.setChoiceByValue('1');

    purchaseOrderReference.addEventListener('choice', async (e) => { });
}

export function setupVendorInvoiceSearchable(elementId: string, accessClient: PromiseClient<typeof VendorInvoicesService>, filter: PartialMessage<VendorInvoicesServiceSearchAllReq>,) {
    let vendorInvoiceReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(vendorInvoiceReference, choicesOpts());
    vendorInvoiceReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: VENDOR_INVOICE_SORT_KEY.VENDOR_INVOICE_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new VendorInvoice({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });
}

export function setupGoodsReceiptSearchable(elementId: string, accessClient: PromiseClient<typeof GoodsReceiptsService>, filter: PartialMessage<GoodsReceiptsServiceSearchAllReq>, triggerInitSearch: boolean) {
    let goodsReceiptReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(goodsReceiptReference, choicesOpts());
    goodsReceiptReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: GOODS_RECEIPT_SORT_KEY.GOODS_RECEIPT_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new GoodsReceipt({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });

    if (triggerInitSearch) {
        goodsReceiptReference.dispatchEvent(new CustomEvent('search', { detail: { value: '' } }));
    }
}

export function setupPurchaseReturnSearchable(elementId: string, accessClient: PromiseClient<typeof PurchasesReturnsService>, filter: PartialMessage<PurchasesReturnsServiceSearchAllReq>,) {
    let purchaseReturnReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(purchaseReturnReference, choicesOpts());
    purchaseReturnReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: PURCHASE_RETURN_SORT_KEY.PURCHASE_RETURN_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new PurchaseReturn({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });
}

export function setupOutwardJobSearchable(elementId: string, accessClient: PromiseClient<typeof OutwardJobsService>, filter: PartialMessage<OutwardJobsServiceSearchAllReq>) {
    let outwardJobReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(outwardJobReference, choicesOpts());
    outwardJobReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: OUTWARD_JOB_SORT_KEY.OUTWARD_JOB_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new OutwardJob({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });
}

export function setupDebitNoteSearchable(elementId: string, accessClient: PromiseClient<typeof DebitNotesService>, filter: PartialMessage<DebitNotesServiceSearchAllReq>) {
    let debitNoteReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(debitNoteReference, choicesOpts());
    debitNoteReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: DEBIT_NOTE_SORT_KEY.DEBIT_NOTE_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new DebitNote({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });
}

export function setupPurchasePaymentSearchable(elementId: string, accessClient: PromiseClient<typeof PurchasesPaymentsService>, filter: PartialMessage<PurchasesPaymentsServiceSearchAllReq>) {
    let purchasePaymentReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(purchasePaymentReference, choicesOpts());
    purchasePaymentReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: PURCHASE_PAYMENT_SORT_KEY.PURCHASE_PAYMENT_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new PurchasePayment({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });
}

export function setupQCSampleSearchable(elementId: string, accessClient: PromiseClient<typeof QCSamplesService>, filter: PartialMessage<QCSamplesServiceSearchAllReq>,) {
    let qcSampleReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(qcSampleReference, choicesOpts());
    qcSampleReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: QC_SAMPLE_SORT_KEY.QC_SAMPLE_SORT_KEY_MODIFIED_AT,
            status: QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_ANY_UNSPECIFIED,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new QCSampleWithMetadata({ qcSample: new QCSample({ metadata: new EmployeeMetadata({ id: protoInt64.zero }) }) }));

        choice.setChoices(results.list.map(r => {
            return { value: r.qcSample?.metadata?.id, label: `${r.qcSample?.name}`, disabled: false };
        }));
    });
}


export function setupPurchaseEnquirySearchable(elementId: string, accessClient: PromiseClient<typeof PurchasesEnquiriesService>, filter: PartialMessage<PurchasesEnquiriesServiceSearchAllReq>,) {
    let purchaseEnquiryReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(purchaseEnquiryReference, choicesOpts());
    purchaseEnquiryReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: PURCHASE_ENQUIRY_SORT_KEY.PURCHASE_ENQUIRY_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new PurchaseEnquiry({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });
}

export function setupQuotationRequestSearchable(elementId: string, accessClient: PromiseClient<typeof QuotationsRequestsService>, filter: PartialMessage<QuotationsRequestsServiceSearchAllReq>,) {
    let quotationRequestReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(quotationRequestReference, choicesOpts());
    quotationRequestReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: QUOTATION_REQUEST_SORT_KEY.QUOTATION_REQUEST_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new QuotationRequest({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });
}

export function setupQuotationResponseSearchable(elementId: string, accessClient: PromiseClient<typeof QuotationsResponsesService>, filter: PartialMessage<QuotationsResponsesServiceSearchAllReq>,) {
    let quotationResponseReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(quotationResponseReference, choicesOpts());
    quotationResponseReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: QUOTATION_RESPONSE_SORT_KEY.QUOTATION_RESPONSE_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new QuotationResponse({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });
}

export function setupSupplyOfferSearchable(elementId: string, accessClient: PromiseClient<typeof SupplyOffersService>, filter: PartialMessage<SupplyOffersServiceSearchAllReq>,) {
    let supplyOfferReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(supplyOfferReference, choicesOpts());
    supplyOfferReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: SUPPLY_OFFER_SORT_KEY.SUPPLY_OFFER_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new SupplyOffer({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });
}

export function setupOutwardJobFreeIssueMaterialSearchable(elementId: string, accessClient: PromiseClient<typeof OutwardJobsFreeIssueMaterialsService>, filter: PartialMessage<OutwardJobsFreeIssueMaterialsServiceSearchAllReq>,) {
    let outwardJobFreeIssueMaterialReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(outwardJobFreeIssueMaterialReference, choicesOpts());
    outwardJobFreeIssueMaterialReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: OUTWARD_JOB_FREE_ISSUE_MATERIAL_SORT_KEY.OUTWARD_JOB_FREE_ISSUE_MATERIAL_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new OutwardJobFreeIssueMaterial({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });
}

export function setupOutwardJobFreeIssueMaterialReturnSearchable(elementId: string, accessClient: PromiseClient<typeof OutwardJobsFreeIssueMaterialsReturnsService>, filter: PartialMessage<OutwardJobsFreeIssueMaterialsReturnsServiceSearchAllReq>,) {
    let outwardJobFreeIssueMaterialReturnReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(outwardJobFreeIssueMaterialReturnReference, choicesOpts());
    outwardJobFreeIssueMaterialReturnReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: OUTWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY.OUTWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new OutwardJobFreeIssueMaterialReturn({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });
}

export function setupFamilySearchable(elementId: string, onSearch: (searchKey: string, choice: Choices) => void, onSelect: (value: string) => void) {
    let familyReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(familyReference, choicesOpts());
    familyReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;
        onSearch(searchKey, choice);
    });
    familyReference.addEventListener('choice', async (e) => {
        const selectedValue = (<any>e).detail.value;
        onSelect(selectedValue);
    });

    // Trigger the first default value
    onSearch('', choice);
}

export function setupCurrencySearchable(elementId: string, accessClient: PromiseClient<typeof CurrenciesService>, filter: PartialMessage<CurrenciesServiceSearchAllReq>,) {
    let currencyReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(currencyReference, choicesOpts());
    currencyReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(-1),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: CURRENCY_SORT_KEY.CURRENCY_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new Currency({ metadata: new EmployeeMetadata({ id: protoInt64.zero }) }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.symbol.length > 0 ? `(${r.symbol})` : ''} ${r.name}`, disabled: false };
        }));
    });

    currencyReference.dispatchEvent(new CustomEvent('search', { detail: { value: '' } }));
}

export function setupLocationSearchable(elementId: string, accessClient: PromiseClient<typeof LocationsService>, filter: PartialMessage<LocationsServiceSearchAllReq>,) {
    let locationReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(locationReference, choicesOpts());
    locationReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(-1),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: LOCATION_SORT_KEY.LOCATION_SORT_KEY_NAME,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new Location({ metadata: new EmployeeMetadata({ id: protoInt64.zero }) }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.code.length > 0 ? `(${r.code}) ` : ''} ${r.name}`, disabled: false };
        }));
    });

    locationReference.dispatchEvent(new CustomEvent('search', { detail: { value: '' } }));
}
import { BOOL_FILTER, CurrenciesService, Currency, FamiliesService, getClientForCurrenciesService, getClientForUnitsOfMaterialsService, UnitOfMaterial, SUPPLY_OFFER_ITEM_STATUS, SupplyOffer, SupplyOfferItem, SupplyOffersService } from "@kernelminds/scailo-sdk";
import ApexCharts from 'apexcharts';
import { convertBigIntTimestampToDate, convertCentsToMoney, dateToStr, decodeSLC, destroyAlert, internationalizeMoney, randomId, renderTableWithCSVHeadersAndRows, round, showFailureAlert, showSuccessAlert, toTitleCase } from "../../utilities";
import { PromiseClient, Transport } from "@connectrpc/connect";
import { protoInt64 } from "@bufbuild/protobuf";
import { getLinkForSupplyOffer } from "../../ui";

export async function renderSupplyOfferItemTrends(familyId: bigint, familyUuid: string, accessClient: PromiseClient<typeof SupplyOffersService>, familiesAccessClient: PromiseClient<typeof FamiliesService>, currenciesAccessClient: PromiseClient<typeof CurrenciesService>, transport: Transport) {
    let notificationId = showSuccessAlert(`Gathering trends`, { timeoutInMs: 100000 });
    let records = (await accessClient.searchItemsWithPagination({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        count: protoInt64.parse(-1),
        status: SUPPLY_OFFER_ITEM_STATUS.SUPPLY_OFFER_ITEM_STATUS_ANY_UNSPECIFIED,
        familyId: familyId,
    })).payload;

    let supplyOffers = await Promise.all(Array.from(new Set(records.map(r => r.supplyOfferUuid))).map(r => {
        return accessClient.viewByUUID({ uuid: r });
    }));
    const unitOfMaterialAccessClient = getClientForUnitsOfMaterialsService(transport);
    let uoms = await Promise.all(Array.from(new Set(records.map(r => r.vendorUomId))).map(r => {
        return unitOfMaterialAccessClient.viewByID({ id: r });
    }));
    let currencies = await Promise.all(Array.from(new Set(supplyOffers.map(r => r.currencyId))).map(r => {
        return currenciesAccessClient.viewByID({ id: r });
    }));

    let supplyOffersMap = new Map(supplyOffers.map(r => [r.metadata?.uuid!, r]));
    let unitsMap = new Map(uoms.map(r => [r.metadata?.id!, r]));
    let currenciesMap = new Map(currencies.map(r => [r.metadata?.id!, r]));
    let family = await familiesAccessClient.viewEssentialByUUID({ uuid: familyUuid });

    destroyAlert(notificationId);

    // Table
    const headers = ["S.No.", "Supply Offer", "Status", "Internal Qty", "Vendor UoM", "Vendor Qty", "Unit Price", "Net Price", "Created On"];
    let rows = records.map((m, i) => {
        let supplyoffer = supplyOffersMap.get(m.supplyOfferUuid) || new SupplyOffer();
        let vendorUoM = unitsMap.get(m.vendorUomId) || new UnitOfMaterial();
        let supplyOfferLink = document.createElement("a");
        supplyOfferLink.target = "_blank";
        supplyOfferLink.className = `btn btn-wide btn-outline btn-primary text-white truncate`;
        supplyOfferLink.innerHTML = `<i class='bx bx-door-open'></i> ${supplyoffer.approvalMetadata?.approvedOn! > 0 ? supplyoffer.finalRefNumber : supplyoffer.referenceId}`;
        supplyOfferLink.href = getLinkForSupplyOffer(supplyoffer.metadata?.uuid!);
        supplyOfferLink.innerText = supplyoffer.approvalMetadata?.approvedOn! > 0 ? supplyoffer.finalRefNumber : supplyoffer.referenceId;

        const localTotalValue = parseInt(String((m.vendorUnitPrice * m.vendorQuantity)))/100/100;

        return [
            `${i+1}.`,
            supplyOfferLink.outerHTML,
            toTitleCase(decodeSLC(supplyoffer.status)),
            convertCentsToMoney(m.internalQuantity),
            `(${vendorUoM.symbol}) ${vendorUoM.name}`,
            convertCentsToMoney(m.vendorQuantity),
            `${currenciesMap.get(supplyoffer.currencyId)?.symbol} ${convertCentsToMoney(m.vendorUnitPrice)}`,
            `${currenciesMap.get(supplyoffer.currencyId)?.symbol} ${internationalizeMoney(localTotalValue)}`,
            convertBigIntTimestampToDate(m.metadata?.createdAt!),
        ];
    });

    let table = renderTableWithCSVHeadersAndRows({ title: `Trends for (${family.code}) ${family.name}`, headers_array: headers, rows_array: rows, responsive: false });

    if (!records.length) {
        showFailureAlert("No Records Found");
        return;
    }

    const chartsDivId = randomId();

    // Render the content inside a dialog
    let dialog = document.createElement("dialog");
    dialog.className = "modal";
    dialog.id = randomId();
    dialog.innerHTML = `
    <div class="max-w-full modal-box text-gray-900 bg-gray-200">
        <form method="dialog" class="m-3">
            <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
        </form>
        <div class="overflow-y-auto max-h-96">${table.table.outerHTML}</div>
        
        <div id="${chartsDivId}" class="mt-8"></div>
    </div>
    `;

    document.body.appendChild(dialog);
    dialog.showModal();

    renderChart(records, supplyOffersMap, unitsMap, currenciesMap, document.getElementById(chartsDivId) as HTMLDivElement);
}

function renderChart(
    records: SupplyOfferItem[], 
    supplyOffersMap: Map<string, SupplyOffer>,
    unitsMap: Map<bigint, UnitOfMaterial>,
    currenciesMap: Map<bigint, Currency>,
    chartsDiv: HTMLDivElement,
) {
    if (records.length == 0) {
        return;
    }

    let localChartDiv = document.createElement("div");
    localChartDiv.classList.add("col-span-12");
    chartsDiv.appendChild(localChartDiv);

    const commonTheme = {
        palette: 'palette2',
    };

    let cumulativeSeriesData = <number[]>[];
    let incrementingTotalVal = 0;
    let totalValue = 0;
    let cumulativeVendorQtySeriesData = <number[]>[];
    let incrementingVendorQty = 0;

    const divisor = 100*100;
    
    records.forEach(record => {
        const localTotalValue = parseInt(String((record.vendorUnitPrice * record.vendorQuantity)))/divisor;
        incrementingTotalVal += localTotalValue;
        cumulativeSeriesData.push(parseFloat(round(incrementingTotalVal)));
        totalValue += localTotalValue;
        incrementingVendorQty += parseInt(String(record.vendorQuantity))/100;
        cumulativeVendorQtySeriesData.push(incrementingVendorQty);
    });

    let options = {
        title: {
            text: `Total Records: ${records.length}, Total Value: ${internationalizeMoney(totalValue)}`,
            align: "left",
            margin: 10,
            offsetX: 0,
            offsetY: -10,
            floating: true,
            style: {
                fontSize: "20px",
                fontWeight: "light",
                color: "#263238"
            },
        },
        theme: commonTheme,
        series: [
            // Area Series
            {
                name: "Cumulative Value",
                type: "area",
                data: cumulativeSeriesData
            },
            // Line Series
            {
                name: "Individual Record Value",
                type: "line",
                data: records.map(record => parseFloat(round(parseInt(String((record.vendorUnitPrice * record.vendorQuantity)))/divisor)))
            }
        ],
        chart: {
            height: 500,
            type: "line",
        },
        stroke: {
            curve: "smooth"
        },
        fill: {
            type: "solid",
            opacity: [0.25, 1],
        },
        labels: records.map(record => {
            let supplyOffer = supplyOffersMap.get(record.supplyOfferUuid);
            return supplyOffer?.approvalMetadata?.approvedOn! > 0 ? supplyOffer?.finalRefNumber : supplyOffer?.referenceId;
        }),
        markers: {
            size: 2
        },
        legend: {
            position: "top"
        },
        yaxis: [
            {
                title: {
                    text: "Cumulative Value",
                },
            },
            {
                opposite: true,
                title: {
                    text: "Individual Record Value",
                },
            },
        ],

        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                let record = records[dataPointIndex];
                let supplyOffer = supplyOffersMap.get(record.supplyOfferUuid);
                let recordValue = parseFloat(round(parseInt(String((record.vendorUnitPrice * record.vendorQuantity)))/divisor));
                let currency = currenciesMap.get(supplyOffer?.currencyId!);
                let uom = unitsMap.get(record.vendorUomId!);
                return `
                    <ul style='background-color: #424242; color: #F5F5F5; padding: 20px; margin: 0px;'>
                        <li>Index: ${dataPointIndex + 1}</li>
                        <li>Supply Offer: ${supplyOffer?.approvalMetadata?.approvedOn! > 0 ? supplyOffer?.finalRefNumber : supplyOffer?.referenceId}</li>
                        <li>Item Qty: ${internationalizeMoney(parseInt(String(record.vendorQuantity))/100)} ${uom?.symbol}</li>
                        <li>Unit Price: ${currency?.symbol} ${internationalizeMoney(parseInt(String(record.vendorUnitPrice))/100)}</li>
                        <li>Item Value: ${currency?.symbol} ${internationalizeMoney(recordValue)}</li>
                        <li>Approved Date: ${convertBigIntTimestampToDate(record.approvalMetadata!.approvedOn)}</li>
                        <li>Cumulative Value: ${currency?.symbol} ${internationalizeMoney(cumulativeSeriesData[dataPointIndex])}</li>
                        <li>Cumulative Qty: ${internationalizeMoney(cumulativeVendorQtySeriesData[dataPointIndex])} ${uom?.symbol}</li>
                    </ul>
                `;
            }
        }
    };

    let chart = new ApexCharts(localChartDiv, options);
    chart.render();
}
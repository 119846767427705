import { supplyOfferCreateMenu, supplyOfferFiltersMenu, supplyOfferIndividualMenu, supplyOfferInsightsMenu } from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router } from "../../router";
import { handleSupplyOfferFilters } from "./supplyoffers.filters";
import { handleSupplyOfferInsights } from "./supplyoffers.insights";
import { handleIndividualSupplyOffer } from "./individual";
import { handleCreateSupplyOffer } from "./create";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(supplyOfferFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, supplyOfferFiltersMenu)) {
            handleSupplyOfferFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(supplyOfferInsightsMenu.href, async (ctx) => {
        if (await interceptPage(ctx, supplyOfferInsightsMenu)) {
            handleSupplyOfferInsights(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(supplyOfferIndividualMenu.href, async (ctx) => {
        if (await interceptPage(ctx, supplyOfferFiltersMenu)) {
            handleIndividualSupplyOffer(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });

    r.add(supplyOfferCreateMenu.href, async (ctx) => {
        if (await interceptPage(ctx, supplyOfferCreateMenu)) {
            handleCreateSupplyOffer(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}
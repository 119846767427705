import { outwardJobFIMFiltersMenu, outwardJobFIMIndividualMenu, outwardJobFIMInsightsMenu } from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router } from "../../router";
import { handleOutwardJobFreeIssueMaterialFilters } from "./freeissues.filters";
import { handleOutwardJobFreeIssueMaterialInsights } from "./freeissues.insights";
import { handleIndividualOutwardJobFreeIssueMaterial } from "./individual";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(outwardJobFIMFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, outwardJobFIMFiltersMenu)) {
            handleOutwardJobFreeIssueMaterialFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(outwardJobFIMInsightsMenu.href, async (ctx) => {
        if (await interceptPage(ctx, outwardJobFIMInsightsMenu)) {
            handleOutwardJobFreeIssueMaterialInsights(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(outwardJobFIMIndividualMenu.href, async (ctx) => {
        if (await interceptPage(ctx, outwardJobFIMFiltersMenu)) {
            handleIndividualOutwardJobFreeIssueMaterial(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}
import { BOOL_FILTER, getClientForPurchasesOrdersService, getClientForVendorInvoicesService, PURCHASE_ORDER_SORT_KEY, PurchasesOrdersServiceCountReq, PurchasesOrdersServiceFilterReq, SORT_ORDER, STANDARD_LIFECYCLE_STATUS, VENDOR_INVOICE_SORT_KEY, VendorInvoicesServiceCountReq, VendorInvoicesServiceFilterReq } from "@kernelminds/scailo-sdk";
import { dashboardMenu } from "../menus";
import { Router, context } from "./../router";
import { downloadButtonClass, downloadData, handlePageForInvalidPermissions, interceptPage, internationalizeMoney, randomId, round } from "./../utilities";
import { protoInt64 } from "@bufbuild/protobuf";
import { viewPurchasesOrders, viewVendorInvoices } from "../tables";
import { handlePurchaseOrderInventoryMatch } from "../orders/purchaseorders/purchaseorders.filters";
import { getTransport } from "../clients";
import { Transport } from "@connectrpc/connect";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(dashboardMenu.href, async (ctx) => {
        if (await interceptPage(ctx, dashboardMenu)) {
            handleDashboardPage(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}

const today = protoInt64.parse(Math.round((new Date()).getTime() / 1000));
const lastYearToday = protoInt64.parse(Math.round((new Date(new Date().setFullYear(new Date().getFullYear() - 1))).getTime() / 1000));
const last2YearToday = protoInt64.parse(Math.round((new Date(new Date().setFullYear(new Date().getFullYear() - 2))).getTime() / 1000));
const lastMonthToday = protoInt64.parse(Math.round((new Date(new Date().setMonth(new Date().getMonth() - 1))).getTime() / 1000));
const last2MonthToday = protoInt64.parse(Math.round((new Date(new Date().setMonth(new Date().getMonth() - 2))).getTime() / 1000));


async function handleDashboardPage(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }

    let purchaseOrderSection = document.createElement("div");
    content.appendChild(purchaseOrderSection);

    let vendorInvoiceSection = document.createElement("div");
    vendorInvoiceSection.className = "mt-10";
    content.appendChild(vendorInvoiceSection);

    const transport = getTransport();

    renderPurchaseOrderInsights(purchaseOrderSection, transport);
    renderVendorInvoiceInsights(vendorInvoiceSection, transport);
}

async function renderPurchaseOrderInsights(primaryGrid: HTMLDivElement, transport: Transport) {
    const purchaseOrderClient = getClientForPurchasesOrdersService(transport);
    let section = document.createElement("div");
    section.className = "grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 mb-6";
    primaryGrid.appendChild(section);

    const purchaseOrderYearToDateQuery = new PurchasesOrdersServiceCountReq({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        approvedOnStart: lastYearToday,
        approvedOnEnd: today,
    });
    const purchaseOrderPrevYearCycleQuery = new PurchasesOrdersServiceCountReq({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        approvedOnStart: last2YearToday,
        approvedOnEnd: lastYearToday - protoInt64.parse(86400),
    });

    const purchaseOrderMonthToDateQuery = new PurchasesOrdersServiceCountReq({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        approvedOnStart: lastMonthToday,
        approvedOnEnd: today,
    });
    const purchaseOrderPrevMonthCycleQuery = new PurchasesOrdersServiceCountReq({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        approvedOnStart: last2MonthToday,
        approvedOnEnd: lastMonthToday - protoInt64.parse(86400),
    });

    // Get lifetime
    const purchaseOrderLifetimeCountQuery = new PurchasesOrdersServiceCountReq({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
    });

    const purchaseOrderYearToDateFilterQuery = new PurchasesOrdersServiceFilterReq({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        sortKey: PURCHASE_ORDER_SORT_KEY.PURCHASE_ORDER_SORT_KEY_APPROVED_ON,
        sortOrder: SORT_ORDER.DESCENDING,
        approvedOnStart: lastYearToday,
        approvedOnEnd: today,
        status: STANDARD_LIFECYCLE_STATUS.STANDING,
        count: protoInt64.parse(-1),
    });

    let [
        purchaseOrderAccruedValueYearToDate,
        purchaseOrderCountYearToDate,
        purchaseOrderAccruedValuePrevYearCycle,
        purchaseOrderCountPrevYearCycle,

        purchaseOrderAccruedValueMonthToDate,
        purchaseOrderCountMonthToDate,
        purchaseOrderAccruedValuePrevMonthCycle,
        purchaseOrderCountPrevMonthCycle,

        purchaseOrderLifetimeAccruedValue,
        purchaseOrderLifetimeCount,


        purchaseOrderFilterYearToDate,
    ] = await Promise.all([
        purchaseOrderClient.accruedValue(purchaseOrderYearToDateQuery),
        purchaseOrderClient.count(purchaseOrderYearToDateQuery),
        purchaseOrderClient.accruedValue(purchaseOrderPrevYearCycleQuery),
        purchaseOrderClient.count(purchaseOrderPrevYearCycleQuery),

        purchaseOrderClient.accruedValue(purchaseOrderMonthToDateQuery),
        purchaseOrderClient.count(purchaseOrderMonthToDateQuery),
        purchaseOrderClient.accruedValue(purchaseOrderPrevMonthCycleQuery),
        purchaseOrderClient.count(purchaseOrderPrevMonthCycleQuery),

        purchaseOrderClient.accruedValue(purchaseOrderLifetimeCountQuery),
        purchaseOrderClient.count(purchaseOrderLifetimeCountQuery),

        purchaseOrderClient.filter(purchaseOrderYearToDateFilterQuery),
    ]);


    section.appendChild(renderCard({
        valueSection: {
            currentValue: purchaseOrderLifetimeAccruedValue.sum,
            currentValueTooltip: `Lifetime Purchase Order Value`,
            internationalizeCurrentValue: true,
            previousValue: 0,
            showPreviousValue: false,
            previousValueTooltip: ``,
            showPreviousValueTooltip: false,
            title: "Lifetime Accrued Purchase Order Value",
        },
        countSection: {
            currentValue: parseInt(String(purchaseOrderLifetimeCount.count)),
            currentValueTooltip: `Lifetime Purchase Order Count`,
            internationalizeCurrentValue: false,
            previousValue: 0,
            showPreviousValue: false,
            previousValueTooltip: ``,
            showPreviousValueTooltip: false,
            title: "Lifetime Accrued Purchase Order Count",
        }
    }));

    section.appendChild(renderCard({
        valueSection: {
            currentValue: purchaseOrderAccruedValueYearToDate.sum,
            currentValueTooltip: `Total Order Value from ${new Date(parseInt(String(purchaseOrderYearToDateQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(purchaseOrderYearToDateQuery.approvedOnEnd)) * 1000).toDateString()}`,
            internationalizeCurrentValue: true,
            previousValue: purchaseOrderAccruedValuePrevYearCycle.sum,
            showPreviousValue: true,
            previousValueTooltip: `Order Value was ${internationalizeMoney(purchaseOrderAccruedValuePrevYearCycle.sum)} from ${new Date(parseInt(String(purchaseOrderPrevYearCycleQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(purchaseOrderPrevYearCycleQuery.approvedOnEnd)) * 1000).toDateString()}`,
            showPreviousValueTooltip: true,
            title: "Accrued Order Value Year To Date",
        },
        countSection: {
            currentValue: parseInt(String(purchaseOrderCountYearToDate.count)),
            currentValueTooltip: `Total Order Count from ${new Date(parseInt(String(purchaseOrderYearToDateQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(purchaseOrderYearToDateQuery.approvedOnEnd)) * 1000).toDateString()}`,
            internationalizeCurrentValue: false,
            previousValue: parseInt(String(purchaseOrderCountPrevYearCycle.count)),
            showPreviousValue: true,
            previousValueTooltip: `Order Count was ${parseInt(internationalizeMoney(parseInt(String(purchaseOrderCountPrevYearCycle.count))))} from ${new Date(parseInt(String(purchaseOrderPrevYearCycleQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(purchaseOrderPrevYearCycleQuery.approvedOnEnd)) * 1000).toDateString()}`,
            showPreviousValueTooltip: true,
            title: "Accrued Order Count Year To Date",
        }

    }));

    section.appendChild(renderCard({
        valueSection: {
            currentValue: purchaseOrderAccruedValueMonthToDate.sum,
            currentValueTooltip: `Total Order Value from ${new Date(parseInt(String(purchaseOrderMonthToDateQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(purchaseOrderMonthToDateQuery.approvedOnEnd)) * 1000).toDateString()}`,
            internationalizeCurrentValue: true,
            previousValue: purchaseOrderAccruedValuePrevMonthCycle.sum,
            showPreviousValue: true,
            previousValueTooltip: `Order Value was ${internationalizeMoney(purchaseOrderAccruedValuePrevMonthCycle.sum)} from ${new Date(parseInt(String(purchaseOrderPrevMonthCycleQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(purchaseOrderPrevMonthCycleQuery.approvedOnEnd)) * 1000).toDateString()}`,
            showPreviousValueTooltip: true,
            title: "Accrued Order Value Month To Date",
        },
        countSection: {
            currentValue: parseInt(String(purchaseOrderCountMonthToDate.count)),
            currentValueTooltip: `Total Order Count from ${new Date(parseInt(String(purchaseOrderMonthToDateQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(purchaseOrderMonthToDateQuery.approvedOnEnd)) * 1000).toDateString()}`,
            internationalizeCurrentValue: false,
            previousValue: parseInt(String(purchaseOrderCountPrevMonthCycle.count)),
            showPreviousValue: true,
            previousValueTooltip: `Order Count was ${parseInt(internationalizeMoney(parseInt(String(purchaseOrderCountPrevMonthCycle.count))))} from ${new Date(parseInt(String(purchaseOrderPrevMonthCycleQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(purchaseOrderPrevMonthCycleQuery.approvedOnEnd)) * 1000).toDateString()}`,
            showPreviousValueTooltip: true,
            title: "Accrued Order Count Month To Date",
        }
    }));

    let tableDiv = document.createElement("div");
    tableDiv.className = "overflow-x-auto p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded";
    primaryGrid.appendChild(tableDiv);

    const inventoryMatchClass = randomId();

    const recordsDiv = await viewPurchasesOrders(purchaseOrderFilterYearToDate.list, { inventoryMatchClass });
    tableDiv.appendChild(recordsDiv.table);

    // Setup PDF downloads
    let pdfDownloadButtons = tableDiv.getElementsByClassName(downloadButtonClass);
    for (let i = 0; i < pdfDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>pdfDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await purchaseOrderClient.downloadByUUID({ uuid: btn.getAttribute("data-uuid") || "" });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "pdf", file.name.replace(".pdf", ""));
        });
    }

    // Setup inventory match
    handlePurchaseOrderInventoryMatch(inventoryMatchClass, tableDiv, purchaseOrderClient);
}

async function renderVendorInvoiceInsights(primaryGrid: HTMLDivElement, transport: Transport) {
    const vendorInvoiceClient = getClientForVendorInvoicesService(transport);
    let section = document.createElement("div");
    section.className = "grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 mb-6";
    primaryGrid.appendChild(section);

    const vendorInvoiceYearToDateQuery = new VendorInvoicesServiceCountReq({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        approvedOnStart: lastYearToday,
        approvedOnEnd: today,
    });
    const vendorInvoicePrevYearCycleQuery = new VendorInvoicesServiceCountReq({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        approvedOnStart: last2YearToday,
        approvedOnEnd: lastYearToday - protoInt64.parse(86400),
    });

    const vendorInvoiceMonthToDateQuery = new VendorInvoicesServiceCountReq({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        approvedOnStart: lastMonthToday,
        approvedOnEnd: today,
    });
    const vendorInvoicePrevMonthCycleQuery = new VendorInvoicesServiceCountReq({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        approvedOnStart: last2MonthToday,
        approvedOnEnd: lastMonthToday - protoInt64.parse(86400),
    });

    // Get lifetime
    const vendorInvoiceLifetimeCountQuery = new VendorInvoicesServiceCountReq({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
    });

    const vendorInvoiceYearToDateFilterQuery = new VendorInvoicesServiceFilterReq({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        sortKey: VENDOR_INVOICE_SORT_KEY.VENDOR_INVOICE_SORT_KEY_APPROVED_ON,
        sortOrder: SORT_ORDER.DESCENDING,
        approvedOnStart: lastYearToday,
        approvedOnEnd: today,
        status: STANDARD_LIFECYCLE_STATUS.STANDING,
        count: protoInt64.parse(-1),
    });

    let [
        vendorInvoiceAccruedValueYearToDate,
        vendorInvoiceCountYearToDate,
        vendorInvoiceAccruedValuePrevYearCycle,
        vendorInvoiceCountPrevYearCycle,

        vendorInvoiceAccruedValueMonthToDate,
        vendorInvoiceCountMonthToDate,
        vendorInvoiceAccruedValuePrevMonthCycle,
        vendorInvoiceCountPrevMonthCycle,

        vendorInvoiceLifetimeAccruedValue,
        vendorInvoiceLifetimeCount,


        vendorInvoiceFilterYearToDate,
    ] = await Promise.all([
        vendorInvoiceClient.accruedValue(vendorInvoiceYearToDateQuery),
        vendorInvoiceClient.count(vendorInvoiceYearToDateQuery),
        vendorInvoiceClient.accruedValue(vendorInvoicePrevYearCycleQuery),
        vendorInvoiceClient.count(vendorInvoicePrevYearCycleQuery),

        vendorInvoiceClient.accruedValue(vendorInvoiceMonthToDateQuery),
        vendorInvoiceClient.count(vendorInvoiceMonthToDateQuery),
        vendorInvoiceClient.accruedValue(vendorInvoicePrevMonthCycleQuery),
        vendorInvoiceClient.count(vendorInvoicePrevMonthCycleQuery),

        vendorInvoiceClient.accruedValue(vendorInvoiceLifetimeCountQuery),
        vendorInvoiceClient.count(vendorInvoiceLifetimeCountQuery),

        vendorInvoiceClient.filter(vendorInvoiceYearToDateFilterQuery),
    ]);


    section.appendChild(renderCard({
        valueSection: {
            currentValue: vendorInvoiceLifetimeAccruedValue.sum,
            currentValueTooltip: `Lifetime Vendor Invoice Value`,
            internationalizeCurrentValue: true,
            previousValue: 0,
            showPreviousValue: false,
            previousValueTooltip: ``,
            showPreviousValueTooltip: false,
            title: "Lifetime Accrued Vendor Invoice Value",
        },
        countSection: {
            currentValue: parseInt(String(vendorInvoiceLifetimeCount.count)),
            currentValueTooltip: `Lifetime Vendor Invoice Count`,
            internationalizeCurrentValue: false,
            previousValue: 0,
            showPreviousValue: false,
            previousValueTooltip: ``,
            showPreviousValueTooltip: false,
            title: "Lifetime Accrued Vendor Invoice Count",
        }
    }));

    section.appendChild(renderCard({
        valueSection: {
            currentValue: vendorInvoiceAccruedValueYearToDate.sum,
            currentValueTooltip: `Total Invoice Value from ${new Date(parseInt(String(vendorInvoiceYearToDateQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(vendorInvoiceYearToDateQuery.approvedOnEnd)) * 1000).toDateString()}`,
            internationalizeCurrentValue: true,
            previousValue: vendorInvoiceAccruedValuePrevYearCycle.sum,
            showPreviousValue: true,
            previousValueTooltip: `Invoice Value was ${internationalizeMoney(vendorInvoiceAccruedValuePrevYearCycle.sum)} from ${new Date(parseInt(String(vendorInvoicePrevYearCycleQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(vendorInvoicePrevYearCycleQuery.approvedOnEnd)) * 1000).toDateString()}`,
            showPreviousValueTooltip: true,
            title: "Accrued Invoice Value Year To Date",
        },
        countSection: {
            currentValue: parseInt(String(vendorInvoiceCountYearToDate.count)),
            currentValueTooltip: `Total Invoice Count from ${new Date(parseInt(String(vendorInvoiceYearToDateQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(vendorInvoiceYearToDateQuery.approvedOnEnd)) * 1000).toDateString()}`,
            internationalizeCurrentValue: false,
            previousValue: parseInt(String(vendorInvoiceCountPrevYearCycle.count)),
            showPreviousValue: true,
            previousValueTooltip: `Invoice Count was ${parseInt(internationalizeMoney(parseInt(String(vendorInvoiceCountPrevYearCycle.count))))} from ${new Date(parseInt(String(vendorInvoicePrevYearCycleQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(vendorInvoicePrevYearCycleQuery.approvedOnEnd)) * 1000).toDateString()}`,
            showPreviousValueTooltip: true,
            title: "Accrued Invoice Count Year To Date",
        }

    }));

    section.appendChild(renderCard({
        valueSection: {
            currentValue: vendorInvoiceAccruedValueMonthToDate.sum,
            currentValueTooltip: `Total Invoice Value from ${new Date(parseInt(String(vendorInvoiceMonthToDateQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(vendorInvoiceMonthToDateQuery.approvedOnEnd)) * 1000).toDateString()}`,
            internationalizeCurrentValue: true,
            previousValue: vendorInvoiceAccruedValuePrevMonthCycle.sum,
            showPreviousValue: true,
            previousValueTooltip: `Invoice Value was ${internationalizeMoney(vendorInvoiceAccruedValuePrevMonthCycle.sum)} from ${new Date(parseInt(String(vendorInvoicePrevMonthCycleQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(vendorInvoicePrevMonthCycleQuery.approvedOnEnd)) * 1000).toDateString()}`,
            showPreviousValueTooltip: true,
            title: "Accrued Invoice Value Month To Date",
        },
        countSection: {
            currentValue: parseInt(String(vendorInvoiceCountMonthToDate.count)),
            currentValueTooltip: `Total Invoice Count from ${new Date(parseInt(String(vendorInvoiceMonthToDateQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(vendorInvoiceMonthToDateQuery.approvedOnEnd)) * 1000).toDateString()}`,
            internationalizeCurrentValue: false,
            previousValue: parseInt(String(vendorInvoiceCountPrevMonthCycle.count)),
            showPreviousValue: true,
            previousValueTooltip: `Invoice Count was ${parseInt(internationalizeMoney(parseInt(String(vendorInvoiceCountPrevMonthCycle.count))))} from ${new Date(parseInt(String(vendorInvoicePrevMonthCycleQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(vendorInvoicePrevMonthCycleQuery.approvedOnEnd)) * 1000).toDateString()}`,
            showPreviousValueTooltip: true,
            title: "Accrued Invoice Count Month To Date",
        }
    }));

    let tableDiv = document.createElement("div");
    tableDiv.className = "overflow-x-auto p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded";
    primaryGrid.appendChild(tableDiv);

    const recordsDiv = await viewVendorInvoices(vendorInvoiceFilterYearToDate.list);
    tableDiv.appendChild(recordsDiv.table);

    // Setup PDF downloads
    let pdfDownloadButtons = tableDiv.getElementsByClassName(downloadButtonClass);
    for (let i = 0; i < pdfDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>pdfDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await vendorInvoiceClient.downloadByUUID({ uuid: btn.getAttribute("data-uuid") || "" });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "pdf", file.name.replace(".pdf", ""));
        });
    }
}

function renderCard(opts: {
    valueSection: {
        currentValue: number,
        currentValueTooltip: string,
        internationalizeCurrentValue: boolean,
        showPreviousValue: boolean,
        previousValue: number,
        showPreviousValueTooltip: boolean,
        previousValueTooltip: string,
        title: string,
    },
    countSection: {
        currentValue: number,
        currentValueTooltip: string,
        internationalizeCurrentValue: boolean,
        showPreviousValue: boolean,
        previousValue: number,
        showPreviousValueTooltip: boolean,
        previousValueTooltip: string,
        title: string,
    }
}) {

    if (opts.valueSection.previousValue == 0) {
        opts.valueSection.previousValue = 1;
    }
    if (opts.countSection.previousValue == 0) {
        opts.countSection.previousValue = 1;
    }

    let valueChange = (opts.valueSection.currentValue > opts.valueSection.previousValue ? "+" : "") + round(((opts.valueSection.currentValue - opts.valueSection.previousValue) / opts.valueSection.previousValue) * 100) + "%";
    let valueChangeClass = opts.valueSection.currentValue > opts.valueSection.previousValue ? "text-emerald-500" : "text-rose-500";

    let countChange = (opts.countSection.currentValue > opts.countSection.previousValue ? "+" : "") + round(((opts.countSection.currentValue - opts.countSection.previousValue) / opts.countSection.previousValue) * 100) + "%";
    let countChangeClass = opts.countSection.currentValue > opts.countSection.previousValue ? "text-emerald-500" : "text-rose-500";

    let card = document.createElement("div");
    card.className = "bg-white rounded-md border border-gray-100 p-6 shadow-md shadow-black/5";
    card.innerHTML = `
        <div class="flex justify-between mb-4">
            <div>
                <div class="flex items-center mb-1">
                    <div class="text-2xl font-semibold tooltip" data-tip="${opts.valueSection.currentValueTooltip}">${opts.valueSection.internationalizeCurrentValue ? internationalizeMoney(opts.valueSection.currentValue) : opts.valueSection.currentValue}</div>
                    ${!opts.valueSection.showPreviousValue ? "" : `<div class="p-1 rounded bg-emerald-500/10 ${valueChangeClass} text-[12px] font-semibold leading-none ml-2 tooltip" data-tip="${opts.valueSection.previousValueTooltip}">${valueChange}</div>`}
                </div>
                <div class="text-sm font-medium text-gray-400">${opts.valueSection.title}</div>
            </div>
        </div>

        <div class="flex justify-between mb-4">
            <div>
                <div class="flex items-center mb-1">
                    <div class="text-2xl font-semibold tooltip" data-tip="${opts.countSection.currentValueTooltip}">${opts.countSection.internationalizeCurrentValue ? internationalizeMoney(opts.countSection.currentValue) : opts.countSection.currentValue}</div>
                    ${!opts.countSection.showPreviousValue ? "" : `<div class="p-1 rounded bg-emerald-500/10 ${countChangeClass} text-[12px] font-semibold leading-none ml-2 tooltip" data-tip="${opts.countSection.previousValueTooltip}">${countChange}</div>`}
                </div>
                <div class="text-sm font-medium text-gray-400">${opts.countSection.title}</div>
            </div>
        </div>
    `;
    return card
}
import { PromiseClient } from "@connectrpc/connect";
import * as ui from "../../ui";
import { _returnInCents, convertBigIntTimestampToDate, convertCentsToMoney, destroyAlert, randomId, showSuccessAlert, toTitleCase } from "../../utilities";
import { VENDOR_ITEM_SORT_KEY, BOOL_FILTER, VENDOR_ITEM_STATUS, VendorsService, VendorItem } from "@kernelminds/scailo-sdk";
import { protoInt64 } from "@bufbuild/protobuf";
import { viewVendorMaterialHistory } from "../../tables";
import ApexCharts from 'apexcharts';

function renderPrimaryFiltersSection() {
    let { grid, contentGrid } = ui.renderFilterPrimarySubSection({ subsectionTitle: "Primary Filters", titleMdColSpan: 3 });

    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Search Key", inputType: "text", dataMapper: "searchKey", dataType: "string", value: "", mdColSpan: 6, helpText: "Enter a search key. Filter records by the value of this field. Leave it empty to ignore this field." }));
    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Vendor Material Code", inputType: "text", dataMapper: "vendorFamilyCode", dataType: "string", value: "", mdColSpan: 6, helpText: "Material code as per the vendor. Filter records by the value of this field. Leave it empty to ignore this field." }));
    
    return grid
}

/**Renders the status filters filter section */
function renderStatusFiltersSection() {
    let { grid, contentGrid } = ui.renderFilterPrimarySubSection({ subsectionTitle: "Status Filters", titleMdColSpan: 3 });

    contentGrid.appendChild(ui.renderSelectForEntireEnum({
        id: randomId(),
        label: "Status",
        nameSplitsAt: "VENDOR_ITEM_STATUS_",
        enumObject: VENDOR_ITEM_STATUS,
        dataMapper: "status",
        dataType: "number",
        value: "",
        mdColSpan: 6,
        helpText: "Filter by record status. Set it to 'Any' to ignore this field.",
        excludeZeroethValuedEnum: false,
        readonly: false,
    }));

    contentGrid.appendChild(ui.renderSelectForEntireEnum({
        id: randomId(),
        label: "Is Active",
        nameSplitsAt: "BOOL_FILTER_",
        enumObject: BOOL_FILTER,
        dataMapper: "isActive",
        dataType: "number",
        value: "",
        mdColSpan: 6,
        helpText: "Filter by active status. Set it to 'Any' to ignore this field. Set it to 'True' to only show active records. Set it to 'False' to only show inactive records.",
        excludeZeroethValuedEnum: false,
        readonly: false,
    }));

    return grid
}

export function _renderPageFilters(context: "Records" | "Insights", queryContext: "all" | "required") {
    let primaryFiltersSection = renderPrimaryFiltersSection();
    let statusFiltersSection = renderStatusFiltersSection();
    let sortFiltersSection = ui.renderSortFiltersSection();

    // Attach specific sort key
    sortFiltersSection.contentGrid.prepend(ui.renderSelectForPartialEnum({
        id: randomId(),
        label: "Sort By",
        nameSplitsAt: "VENDOR_ITEM_SORT_KEY_",
        enumObject: VENDOR_ITEM_SORT_KEY,
        enumValues: [
            VENDOR_ITEM_SORT_KEY.VENDOR_ITEM_SORT_KEY_CREATED_AT,
            VENDOR_ITEM_SORT_KEY.VENDOR_ITEM_SORT_KEY_MODIFIED_AT,
            VENDOR_ITEM_SORT_KEY.VENDOR_ITEM_SORT_KEY_APPROVED_ON,
            VENDOR_ITEM_SORT_KEY.VENDOR_ITEM_SORT_KEY_VENDOR_FAMILY_CODE,
            VENDOR_ITEM_SORT_KEY.VENDOR_ITEM_SORT_KEY_PRICE,
            VENDOR_ITEM_SORT_KEY.VENDOR_ITEM_SORT_KEY_MIN_ORDER_QTY,
            VENDOR_ITEM_SORT_KEY.VENDOR_ITEM_SORT_KEY_MAX_ORDER_QTY,
        ],
        dataMapper: "sortKey",
        dataType: "number",
        value: "",
        mdColSpan: 12,
        helpText: "Sort the response with the selected field.",
        excludeZeroethValuedEnum: false,
        readonly: false,
    }));

    let buttonsDiv = document.createElement("div");
    buttonsDiv.className = `col-span-12 pl-4 float-right`;

    let getButtonId = randomId();
    let getButton = document.createElement("button");
    getButton.id = getButtonId;
    getButton.className = "btn btn-outline btn-success btn-md mr-4";
    getButton.innerText = `Get ${context}`;
    buttonsDiv.append(getButton);

    let resetButtonId = randomId();
    let resetButton = document.createElement("button");
    resetButton.id = resetButtonId;
    resetButton.className = "btn btn-outline btn-info btn-md mr-4";
    resetButton.innerText = "Reset";
    buttonsDiv.append(resetButton);

    const formId = randomId();

    return {
        html: `
            <div class="grid grid-cols-1 gap-6 mb-6">
                <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
                    <div class="rounded-t mb-0 px-0 border-0">
                        ${ui.renderPageTitleSection({ title: `Search for ${toTitleCase(queryContext)} Materials` }).outerHTML}
                    </div>
                    <form id="${formId}">
                        ${primaryFiltersSection.outerHTML}
                        <hr class="m-5">
                        ${statusFiltersSection.outerHTML}
                        <hr class="m-5">
                        ${sortFiltersSection.grid.outerHTML}
                        <hr class="m-5">
                        ${buttonsDiv.outerHTML}
                    </form>
                </div>
            </div>
        `,
        formId,
        resetButtonId,
        getButtonId
    } 
}

/**Handles the event when the user wishes to view the history of each vendor material */
export async function setupVendorMaterialHistory(historyButtonClass: string, client: PromiseClient<typeof VendorsService>) {
    let buttons = document.getElementsByClassName(historyButtonClass);

    for (let i = 0; i < buttons.length; i++) {
        let btn = <HTMLButtonElement>buttons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();
            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;

            let notification = showSuccessAlert("Loading History...", { timeoutInMs: 100000 });

            try {
                let itemHistory = (await client.viewVendorItemHistory({ familyId: protoInt64.parse(btn.getAttribute("data-family-id") || "0"), uomId: protoInt64.parse(btn.getAttribute("data-uom-id") || "0") })).list;
                let table = await viewVendorMaterialHistory(itemHistory, protoInt64.parse(btn.getAttribute("data-family-id") || "0"));

                const chartsDivId = randomId();

                // Render the table inside a modal
                let dialog = document.createElement("dialog");
                dialog.className = "modal";
                dialog.id = randomId();
                dialog.innerHTML = `
                <div class="max-w-full modal-box text-gray-900 bg-gray-200">
                    <form method="dialog">
                    <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                    </form>
                    <div class="overflow-y-auto max-h-96">${table.table.outerHTML}</div>
        
                    <div id="${chartsDivId}" class="mt-8"></div>
                </div>          
                `;

                document.body.appendChild(dialog);
                dialog.showModal()
                renderChart(itemHistory, document.getElementById(chartsDivId) as HTMLDivElement);
            } catch (e) {

            } finally {
                destroyAlert(notification);
                btn.disabled = false;
                btn.innerHTML = originalButtonHTML;
            }
            
        });
    }
}

function renderChart(records: VendorItem[], chartsDiv: HTMLDivElement) {
    console.log("Rendering charts")
    if (records.length == 0) {
        return;
    }

    let localChartDiv = document.createElement("div");
    localChartDiv.classList.add("col-span-12");
    chartsDiv.appendChild(localChartDiv);

    const commonTheme = {
        palette: 'palette2',
    };

    let options = {
        title: {
            text: `Total Records: ${records.length}`,
            align: "center",
            margin: 10,
            offsetX: 0,
            offsetY: -10,
            floating: true,
            style: {
                fontSize: "20px",
                fontWeight: "light",
                color: "#263238"
            },
        },
        theme: commonTheme,
        series: [
            // Line Series
            {
                name: "Price Per Unit",
                type: "line",
                data: records.map(record => convertCentsToMoney(record.price)),
            }
        ],
        chart: {
            height: 500,
            type: "line",
        },
        stroke: {
            curve: "smooth"
        },
        fill: {
            type: "solid",
            opacity: [0.25, 1],
        },
        labels: records.map(record => {
            return convertBigIntTimestampToDate(record.metadata?.createdAt!);
        }),
        markers: {
            size: 2
        },
        legend: {
            position: "top"
        },
        yaxis: [
            {
                title: {
                    text: "Price Per Unit",
                },
            },
        ],

        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                let record = records[dataPointIndex];
                return `
                    <ul style='background-color: #424242; color: #F5F5F5; padding: 20px; margin: 0px;'>
                        <li>Index: ${dataPointIndex + 1}</li>
                        <li>Unit Price: ${convertCentsToMoney(record.price)}</li>
                        <li>Vendor Material Code: ${record.vendorFamilyCode}</li>
                        <li>Min Order Qty: ${convertCentsToMoney(record.minOrderQty)}</li>
                        <li>Max Order Qty: ${convertCentsToMoney(record.maxOrderQty)}</li>
                        <li>Step Internal: ${convertCentsToMoney(record.stepInterval)}</li>
                    </ul>
                `;
            }
        }
    };

    let chart = new ApexCharts(localChartDiv, options);
    chart.render();
}
import { context } from "./../../router";
import { PurchaseOrder, PurchasesOrdersService, PurchaseOrderAncillaryParameters, Currency, Family, UnitOfMaterial, TaxGroup, getClientForPurchasesOrdersService, getClientForCurrenciesService, getClientForLocationsService, getClientForAssociatesService, getClientForUnitsOfMaterialsService, getClientForTaxGroupsService, STANDARD_LIFECYCLE_STATUS, PermissionPurchaseOrderVerify, PermissionPurchaseOrderApprove, getClientForFamiliesService } from "@kernelminds/scailo-sdk";
import { emptyDiv, renderFilterPrimarySubSection, renderInput, renderPageTitleSection, renderSpan, returnFormFromFields } from "../../ui";
import { bgColor, checkForAnyPerm, convertBigIntTimestampToDate, convertCentsToMoney, decodeSLC, downloadData, getUserRole, internationalizeMoney, randomId, returnAssociateBadge, showFailureAlert, toTitleCase } from "../../utilities";
import { PromiseClient, Transport } from "@connectrpc/connect";
import { handlePurchaseOrderInventoryMatch } from "./purchaseorders.filters";
import { familiesListFromIDs, roleSelf } from "../../fetches";
import { getTransport, getWriteTransport } from "../../clients";
import { protoInt64 } from "@bufbuild/protobuf";
import { renderPurchaseOrderItemTrends } from "./trends";

const inventoryMatchClass = "__inventory-match-btn";
const downloadPDFButtonClass = "__download-pdf-btn";
const downloadCSVButtonClass = "__download-csv-btn";

const trendsLineItemButtonClass = "__trends-line-item-btn";

export async function handleIndividualPurchaseOrder(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    const transport = getTransport();
    const accessClient = getClientForPurchasesOrdersService(transport);
    const writeClient = getClientForPurchasesOrdersService(getWriteTransport());
    const [purchaseorder, ancillaryParams, userRole] = await Promise.all([
        accessClient.viewByUUID({ uuid: ctx.params.uuid }),
        accessClient.viewAncillaryParametersByUUID({ uuid: ctx.params.uuid }),
        getUserRole(),
    ]);
    const currenciesClient = getClientForCurrenciesService(transport);
    const currency = await currenciesClient.viewEssentialByID({ id: purchaseorder.currencyId });

    document.title = purchaseorder.approvalMetadata?.approvedOn! > 0 ? purchaseorder.finalRefNumber : purchaseorder.referenceId;

    let container = document.createElement("div");
    container.className = "overflow-x-auto";
    content.appendChild(container);

    const readonly = true;
    let { formGrid, buttonContainer } = await getForm(purchaseorder, ancillaryParams, currency, readonly, accessClient, transport);

    container.appendChild(formGrid);

    await handlePurchaseOrderInventoryMatch(inventoryMatchClass, container, accessClient, transport);

    // Setup PDF downloads
    let pdfDownloadButtons = container.getElementsByClassName(downloadPDFButtonClass);
    for (let i = 0; i < pdfDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>pdfDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await accessClient.downloadByUUID({ uuid: btn.getAttribute("data-uuid") || "" });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "pdf", file.name.replace(".pdf", ""));
        });
    }

    // Setup CSV downloads
    let csvDownloadButtons = container.getElementsByClassName(downloadCSVButtonClass);
    for (let i = 0; i < csvDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>csvDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await accessClient.downloadItemsAsCSV({ uuid: btn.getAttribute("data-uuid") || "" });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "csv", file.name.replace(".csv", ""));
        });
    }

    const familiesAccessClient = getClientForFamiliesService(transport);

    let trendsLineItemButtons = container.getElementsByClassName(trendsLineItemButtonClass);
    for (let i = 0; i < trendsLineItemButtons.length; i++) {
        let btn = <HTMLButtonElement>trendsLineItemButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();
            try {
                renderPurchaseOrderItemTrends(protoInt64.parse(btn.getAttribute("data-family-id") || "0"), btn.getAttribute("data-family-uuid") || "", accessClient, familiesAccessClient, currenciesClient, transport);
            } catch (e) {
                showFailureAlert("Something went wrong. Try again.");
            }
        });
    }

    let showRequestRevisionButton = false;

    buttonContainer = <HTMLDivElement>document.getElementById(buttonContainer.id);

    if (checkForAnyPerm(userRole, [PermissionPurchaseOrderVerify.Uid]) && purchaseorder.status == STANDARD_LIFECYCLE_STATUS.PREVERIFY) {
        // Display the verify button here if the user has the verification permission
        let verifyButton = document.createElement("button");
        verifyButton.id = randomId();
        verifyButton.className = `btn btn-primary btn-outline btn-sm mr-4`;
        verifyButton.innerText = "Verify";
        verifyButton.setAttribute("data-uuid", purchaseorder.metadata?.uuid!);
        verifyButton.setAttribute("data-id", purchaseorder.metadata!.id.toString());
        verifyButton.setAttribute("data-name", purchaseorder.approvalMetadata?.approvedOn! > 0 ? purchaseorder.finalRefNumber : purchaseorder.referenceId);
        buttonContainer.appendChild(verifyButton);

        verifyButton.addEventListener("click", async evt => {
            evt.preventDefault();
            const originalButtonHTML = verifyButton.innerHTML;
            verifyButton.disabled = true;
            verifyButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            await writeClient.verify({ uuid: verifyButton.getAttribute("data-uuid") || "" });
            verifyButton.disabled = false;
            verifyButton.innerHTML = originalButtonHTML;
        });

        // Display the request revision button
        showRequestRevisionButton = true;
    }

    if (checkForAnyPerm(userRole, [PermissionPurchaseOrderApprove.Uid]) && purchaseorder.status == STANDARD_LIFECYCLE_STATUS.VERIFIED) {
        // Display the approve button here if the user has the approval permission
        let approveButton = document.createElement("button");
        approveButton.id = randomId();
        approveButton.className = `btn btn-primary btn-outline btn-sm mr-4`;
        approveButton.innerText = "Approve";
        approveButton.setAttribute("data-uuid", purchaseorder.metadata?.uuid!);
        approveButton.setAttribute("data-id", purchaseorder.metadata!.id.toString());
        approveButton.setAttribute("data-name", purchaseorder.approvalMetadata?.approvedOn! > 0 ? purchaseorder.finalRefNumber : purchaseorder.referenceId);
        buttonContainer.appendChild(approveButton);

        approveButton.addEventListener("click", async evt => {
            evt.preventDefault();
            const originalButtonHTML = approveButton.innerHTML;
            approveButton.disabled = true;
            approveButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            await writeClient.approve({ uuid: approveButton.getAttribute("data-uuid") || "" });
            approveButton.disabled = false;
            approveButton.innerHTML = originalButtonHTML;
        });

        // Display the request revision button
        showRequestRevisionButton = true;
    }

    if (showRequestRevisionButton) {
        let requestRevisionButton = document.createElement("button");
        requestRevisionButton.id = randomId();
        requestRevisionButton.className = `btn btn-warning btn-outline btn-sm mr-4`;
        requestRevisionButton.innerText = "Request Revision";
        requestRevisionButton.setAttribute("data-uuid", purchaseorder.metadata?.uuid!);
        requestRevisionButton.setAttribute("data-id", purchaseorder.metadata!.id.toString());
        requestRevisionButton.setAttribute("data-name", purchaseorder.approvalMetadata?.approvedOn! > 0 ? purchaseorder.finalRefNumber : purchaseorder.referenceId);
        buttonContainer.appendChild(requestRevisionButton);

        requestRevisionButton.addEventListener("click", async evt => {
            evt.preventDefault();
            const originalButtonHTML = requestRevisionButton.innerHTML;
            requestRevisionButton.disabled = true;
            requestRevisionButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            await writeClient.sendForRevision({ uuid: requestRevisionButton.getAttribute("data-uuid") || "" });
            requestRevisionButton.disabled = false;
            requestRevisionButton.innerHTML = originalButtonHTML;
        });
    }
}

async function renderReferencesSection(purchaseorder: PurchaseOrder, ancillaryParams: PurchaseOrderAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof PurchasesOrdersService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "References", titleMdColSpan: 3 });

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Initial Reference", inputType: "text", dataMapper: "referenceId", dataType: "string", value: purchaseorder.referenceId, mdColSpan: 6, helpText: "Initial Reference of the record.", dataRegex: ".+" }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Final Reference", inputType: "text", dataMapper: "finalRefNumber", dataType: "string", value: purchaseorder.finalRefNumber, mdColSpan: 6, helpText: "Final Reference of the record that is automatically generated.", dataRegex: ".+" }));
    // Display status as well
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Status", inputType: "text", dataMapper: "status", dataType: "string", value: toTitleCase(decodeSLC(purchaseorder.status)), mdColSpan: 3, helpText: "Status of the record.", dataRegex: ".+" }));
    // Display amendment count
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Amendment Count", inputType: "number", dataMapper: "amendmentCount", dataType: "number", value: purchaseorder.amendmentCount.toString(), mdColSpan: 3, helpText: "The number of times that this record has been amended.", dataRegex: ".+" }));

    return grid;
}

async function renderBuyerAndConsigneeSection(purchaseorder: PurchaseOrder, ancillaryParams: PurchaseOrderAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof PurchasesOrdersService>, transport: Transport): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Buyer Information", titleMdColSpan: 3 });

    // Get buyer and consignee
    const locationsAccessClient = getClientForLocationsService(transport);
    const [buyer, consignee] = await Promise.all([
        locationsAccessClient.viewByUUID({ uuid: ancillaryParams.buyerLocationUuid }),
        locationsAccessClient.viewByUUID({ uuid: ancillaryParams.consigneeLocationUuid }),
    ]);

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Buyer", inputType: "text", dataMapper: "buyerLocationId", dataType: "string", value: `${buyer.code.length > 0 ? `(${buyer.code})` : ''} ${buyer.name}`, mdColSpan: 6, helpText: "The information of the buyer.", dataRegex: ".+" }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Consignee", inputType: "text", dataMapper: "consigneeLocationId", dataType: "string", value: `${consignee.code.length > 0 ? `(${consignee.code})` : ''} ${consignee.name}`, mdColSpan: 6, helpText: "The information of the consignee.", dataRegex: ".+" }));

    return grid;
}

async function renderDatesSection(purchaseorder: PurchaseOrder, ancillaryParams: PurchaseOrderAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof PurchasesOrdersService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Important Dates", titleMdColSpan: 3 });

    // Dates (creation and approval)
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Created On", inputType: "text", dataMapper: "createdAt", dataType: "string", value: convertBigIntTimestampToDate(purchaseorder.metadata?.createdAt!), mdColSpan: 3, helpText: "The creation date of this record.", dataRegex: ".*" }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Approved On", inputType: "text", dataMapper: "approvedOn", dataType: "string", value: convertBigIntTimestampToDate(purchaseorder.approvalMetadata?.approvedOn!), mdColSpan: 3, helpText: "The approval date of this record.", dataRegex: ".*" }));

    return grid;
}

async function renderPaymentTermsSection(purchaseorder: PurchaseOrder, currency: Currency, readonly: boolean, accessClient: PromiseClient<typeof PurchasesOrdersService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Payment Terms", titleMdColSpan: 3 });

    // Advance Payment
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Advance Payment", inputType: "text", dataMapper: "paymentAdvance", dataType: "string", value: `${currency.symbol} ${internationalizeMoney(parseFloat(convertCentsToMoney(purchaseorder.paymentAdvance)))}`, mdColSpan: 3, helpText: `The advance amount (in ${currency.symbol}) that is supposed to be paid.`, dataRegex: ".+" }));

    // Payment Cycle
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Payment Cycle", inputType: "text", dataMapper: "paymentCycleInDays", dataType: "string", value: `${purchaseorder.paymentCycleInDays.toString()} days`, mdColSpan: 3, helpText: `The payment cycle of this Purchase Order.`, dataRegex: ".+" }));

    return grid;
}

async function renderCostsSection(purchaseorder: PurchaseOrder, currency: Currency, readonly: boolean, accessClient: PromiseClient<typeof PurchasesOrdersService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Costs", titleMdColSpan: 3 });

    // Miscellaneous Costs
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Miscellaneous Cost", inputType: "text", dataMapper: "miscellaneousCost", dataType: "string", value: `${currency.symbol} ${internationalizeMoney(parseFloat(convertCentsToMoney(purchaseorder.miscellaneousCost)))}`, mdColSpan: 4, helpText: `Any miscellaneous costs (in ${currency.symbol}) on the Purchase Order.`, dataRegex: ".+" }));

    // Overall Discount
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Overall Discount", inputType: "text", dataMapper: "overallDiscount", dataType: "string", value: `${currency.symbol} ${internationalizeMoney(parseFloat(convertCentsToMoney(purchaseorder.overallDiscount)))}`, mdColSpan: 4, helpText: `The overall discount (in ${currency.symbol}) on the Purchase Order.`, dataRegex: ".+" }));

    // Round Off
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Round Off", inputType: "text", dataMapper: "roundOff", dataType: "string", value: `${currency.symbol} ${internationalizeMoney(parseFloat(convertCentsToMoney(purchaseorder.roundOff)))}`, mdColSpan: 4, helpText: `The round off (in ${currency.symbol}) applied on the Purchase Order.`, dataRegex: ".+" }));

    // Total
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Net Total", inputType: "text", dataMapper: "totalValue", dataType: "string", value: `${currency.symbol} ${internationalizeMoney(purchaseorder.totalValue)}`, mdColSpan: 6, helpText: `The net total (inclusive of applicable taxes, in ${currency.symbol}) of the Purchase Order.`, dataRegex: ".+" }));

    return grid;
}

async function renderContactsSection(purchaseorder: PurchaseOrder, readonly: boolean, accessClient: PromiseClient<typeof PurchasesOrdersService>, transport: Transport): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Contacts", titleMdColSpan: 3 });

    const associatesClient = getClientForAssociatesService(transport);

    let contacts = (await accessClient.viewPurchaseOrderContacts({ uuid: purchaseorder.metadata?.uuid })).list;
    let purchaseOrderAssociates = await Promise.all(contacts.map(c => c.associateUuid).map(uuid => {
        return associatesClient.viewByUUID({ uuid });
    }));

    purchaseOrderAssociates.forEach(contact => {
        if (contact) {
            contentGrid.appendChild(returnAssociateBadge(contact));
        }
    });

    return grid;
}

async function renderDynamicFormsSection(purchaseorder: PurchaseOrder, readonly: boolean, accessClient: PromiseClient<typeof PurchasesOrdersService>): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    if (purchaseorder.formData.length == 0) {
        return container;
    }

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Other Details` }));
    container.appendChild(containerTitle);

    let formFields = await returnFormFromFields(purchaseorder.formData, "purchases-orders", true, readonly);

    container.appendChild(formFields);

    return container;
}

async function renderFamiliesSection(purchaseorder: PurchaseOrder, currency: Currency, readonly: boolean, accessClient: PromiseClient<typeof PurchasesOrdersService>, transport: Transport): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Line Items` }));

    container.appendChild(containerTitle);

    let familiesMap: Map<bigint, Family> = new Map();
    (await familiesListFromIDs(Array.from(new Set(purchaseorder.list.map(p => p.familyId))))).forEach(family => {
        familiesMap.set(family.metadata?.id!, family);
    });

    const uomClient = getClientForUnitsOfMaterialsService(transport);
    let unitsList = purchaseorder.list.map(p => p.vendorUomId);
    familiesMap.forEach(family => {
        unitsList.push(family.uomId);
    });

    const uomsList = (await uomClient.viewFromIDs({ list: Array.from(new Set(unitsList)) })).list;
    let uomsMap: Map<bigint, UnitOfMaterial> = new Map();

    uomsList.forEach(uom => {
        uomsMap.set(uom.metadata!.id, uom);
    });

    const taxGroupClient = getClientForTaxGroupsService(transport);
    let taxGroupIDsList = purchaseorder.list.map(p => p.taxGroupId);
    familiesMap.forEach(family => {
        taxGroupIDsList.push(family.taxGroupId);
    });

    const taxGroupsList = (await taxGroupClient.viewFromIDs({ list: Array.from(new Set(taxGroupIDsList)) })).list;
    let taxGroupsMap: Map<bigint, TaxGroup> = new Map();

    taxGroupsList.forEach(taxGroup => {
        taxGroupsMap.set(taxGroup.metadata!.id, taxGroup);
    });

    purchaseorder.list.forEach((item, index) => {
        let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: `${index + 1}.`, titleMdColSpan: 1 });

        let fam = familiesMap.get(item.familyId) || new Family();

        let pretaxTotal = parseInt(String(item.vendorQuantity)) * parseInt(String(item.vendorUnitPrice)) / 100.0;

        const discount = parseInt(String(item.discount)) * pretaxTotal / 100.0 / 100.0;
        const postDiscountTotal = pretaxTotal - discount;

        let taxgroup = taxGroupsMap.get(item.taxGroupId) || new TaxGroup();
        let taxAmt = postDiscountTotal * taxgroup.cumulativeTaxPercentage / 100.0 / 100.0;
        let total = postDiscountTotal + taxAmt;

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Material Code", value: `${fam.code}`, mdColSpan: 3, helpText: `The code of the material` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Material Name", value: `${fam.name}`, mdColSpan: 9, helpText: `The name of the material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Vendor Quantity & UoM", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(item.vendorQuantity)))} (${uomsMap.get(item.vendorUomId)?.symbol}) ${uomsMap.get(item.vendorUomId)?.name}`, mdColSpan: 3, helpText: `The quantity of the material in Vendor's unit of material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Buyer Quantity & UoM", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(item.internalQuantity)))} (${uomsMap.get(fam.uomId)?.symbol}) ${uomsMap.get(fam.uomId)?.name}`, mdColSpan: 3, helpText: `The quantity of the material in Buyer's unit of material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Unit Price", value: `${currency.symbol} ${internationalizeMoney(parseFloat(convertCentsToMoney(item.vendorUnitPrice)))}/${uomsMap.get(item.vendorUomId)?.symbol}`, mdColSpan: 3, helpText: `The price per unit of the material in Vendor's unit` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Discount %", value: `${convertCentsToMoney(item.discount)}`, mdColSpan: 3, helpText: `The discount on unit price in percentage` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Delivery Date", value: `${item.deliveryDate}`, mdColSpan: 3, helpText: `The delivery date of this material` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Tax", value: `${taxGroupsMap.get(item.taxGroupId)?.name}`, mdColSpan: 3, helpText: `The applicable tax group` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Pre-Tax Total", value: `${currency.symbol} ${internationalizeMoney(postDiscountTotal / 100.0)}`, mdColSpan: 3, helpText: `The pre tax amount (after applicable discount percentage)` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Tax Amount", value: `${currency.symbol} ${internationalizeMoney(taxAmt / 100.0)}`, mdColSpan: 3, helpText: `The computed tax amount` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Net Total", value: `${currency.symbol} ${internationalizeMoney(total / 100.0)}`, mdColSpan: 3, helpText: `The total amount (inclusive of taxes)` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "HSN/SAC Code", value: `${fam.hsnSacCode}`, mdColSpan: 3, helpText: `The HSN/SAC code of the material` }));

        if (item.specifications.length > 0) {
            contentGrid.appendChild(renderSpan({ id: randomId(), label: "Specifications", value: `${item.specifications}`, mdColSpan: 12, helpText: `Any additional specifications that are provided to the Vendor` }));
        }

        let buttonsDiv = document.createElement("div");
        buttonsDiv.className = "col-span-12 pl-4";
        contentGrid.appendChild(buttonsDiv);
        contentGrid.appendChild(emptyDiv());

        // Trends button here
        let trendsItemButton = document.createElement("button");
        trendsItemButton.type = "button";
        trendsItemButton.className = `btn btn-sm btn-primary btn-outline text-white float-right mr-3 ${trendsLineItemButtonClass}`;
        trendsItemButton.innerHTML = `<i class='bx bx-line-chart'></i> Trends`;
        trendsItemButton.setAttribute("data-id", item.metadata?.id.toString()!);
        trendsItemButton.setAttribute("data-family-id", item.familyId?.toString()!);
        trendsItemButton.setAttribute("data-family-uuid", item.familyUuid!);

        buttonsDiv.appendChild(trendsItemButton);

        container.appendChild(grid);

        let hr = document.createElement("hr");
        hr.classList.add("m-5");
        container.appendChild(hr);
    });

    return container;
}

async function getForm(purchaseorder: PurchaseOrder, ancillaryParams: PurchaseOrderAncillaryParameters, currency: Currency, readonly: boolean, accessClient: PromiseClient<typeof PurchasesOrdersService>, transport: Transport) {
    let formGrid = document.createElement("div");
    formGrid.className = "grid grid-cols-1 gap-6 mb-6";
    const formId = randomId();

    const [
        referencesSection,
        buyerAndConsigneeSection,
        datesSection,
        paymentTermsSection,
        costsSection,
        contactsSection,
        dynamicFormsSection,
        familiesSection,
    ] = await Promise.all([
        renderReferencesSection(purchaseorder, ancillaryParams, readonly, accessClient),
        renderBuyerAndConsigneeSection(purchaseorder, ancillaryParams, readonly, accessClient, transport),
        renderDatesSection(purchaseorder, ancillaryParams, readonly, accessClient),
        renderPaymentTermsSection(purchaseorder, currency, readonly, accessClient),
        renderCostsSection(purchaseorder, currency, readonly, accessClient),
        renderContactsSection(purchaseorder, readonly, accessClient, transport),
        renderDynamicFormsSection(purchaseorder, readonly, accessClient),
        renderFamiliesSection(purchaseorder, currency, readonly, accessClient, transport)
    ]);

    let buttonContainer = document.createElement("div");
    buttonContainer.id = randomId();
    buttonContainer.classList.add("col-span-12", "flex", "justify-start", "md:justify-center", "overflow-x-auto");

    if (purchaseorder.approvalMetadata?.approvedOn! > 0) {
        let inventoryMatchButton = document.createElement("button");
        inventoryMatchButton.innerText = "View Inventory Match";
        inventoryMatchButton.className = `btn btn-info btn-outline btn-sm ${inventoryMatchClass} mr-4`;
        inventoryMatchButton.setAttribute("data-uuid", purchaseorder.metadata?.uuid!);
        inventoryMatchButton.setAttribute("data-name", purchaseorder.approvalMetadata?.approvedOn! > 0 ? purchaseorder.finalRefNumber : purchaseorder.referenceId);
        buttonContainer.appendChild(inventoryMatchButton);
    }

    // Download PDF button
    let downloadPDFButton = document.createElement("button");
    downloadPDFButton.id = randomId();
    downloadPDFButton.className = `btn btn-success btn-outline btn-sm ${downloadPDFButtonClass} mr-4`;
    downloadPDFButton.innerText = "Download PDF";
    downloadPDFButton.setAttribute("data-uuid", purchaseorder.metadata?.uuid!);
    downloadPDFButton.setAttribute("data-name", purchaseorder.approvalMetadata?.approvedOn! > 0 ? purchaseorder.finalRefNumber : purchaseorder.referenceId);
    buttonContainer.appendChild(downloadPDFButton);

    // Download CSV button
    let downloadCSVButton = document.createElement("button");
    downloadCSVButton.id = randomId();
    downloadCSVButton.className = `btn btn-success btn-outline btn-sm ${downloadCSVButtonClass} mr-4`;
    downloadCSVButton.innerText = "Download CSV";
    downloadCSVButton.setAttribute("data-uuid", purchaseorder.metadata?.uuid!);
    downloadCSVButton.setAttribute("data-name", purchaseorder.approvalMetadata?.approvedOn! > 0 ? purchaseorder.finalRefNumber : purchaseorder.referenceId);
    buttonContainer.appendChild(downloadCSVButton);

    formGrid.innerHTML = `
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words ${bgColor} w-full shadow-lg rounded">
            <div class="rounded-t mb-0 px-0 border-0">
                ${renderPageTitleSection({ title: `Purchase Order: ` + document.title }).outerHTML}
                ${buttonContainer.outerHTML}
            </div>
            <form id="${formId}">
                <hr class="m-5">
                ${referencesSection.outerHTML}
                <hr class="m-5">
                ${buyerAndConsigneeSection.outerHTML}
                <hr class="m-5">
                ${datesSection.outerHTML}
                <hr class="m-5">
                ${paymentTermsSection.outerHTML}
                <hr class="m-5">
                ${costsSection.outerHTML}
                <hr class="m-5">
                ${contactsSection.outerHTML}
                <hr class="m-5">
            </form>
        </div>
        ${dynamicFormsSection.childElementCount > 0 ?
            `<div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words ${bgColor} w-full shadow-lg rounded">
                ${dynamicFormsSection.outerHTML}
            </div>`
            : ''}
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words ${bgColor} w-full shadow-lg rounded">
            ${familiesSection.outerHTML}
        </div>
    `;

    return { formGrid, buttonContainer }
}

import { context } from "./../../router";
import { VendorInvoice, VendorInvoicesService, VendorInvoiceAncillaryParameters, Currency, Family, UnitOfMaterial, TaxGroup, PurchaseOrder, PurchasesOrdersService, PurchaseOrderAncillaryParameters, getClientForVendorInvoicesService, getClientForLocationsService, getClientForAssociatesService, getClientForUnitsOfMaterialsService, getClientForPurchasesOrdersService, PermissionVendorInvoiceDrafts, PermissionVendorInvoiceRevise, STANDARD_LIFECYCLE_STATUS, VendorInvoicesServiceUpdateRequest, VendorInvoicesServiceItemCreateRequest, getClientForCurrenciesService, getClientForTaxGroupsService, getClientForGoodsReceiptsService, VendorInvoicesServiceReferenceCreateRequest, GOODS_RECEIPT_BILLING_STATUS, VendorInvoicesServiceAutofillRequest, IdentifierUUIDWithFile, getClientForFamiliesService } from "@kernelminds/scailo-sdk";
import { emptyDiv, getLinkForGoodsReceipt, getLinkForPurchaseOrder, renderFilterPrimarySubSection, renderInput, renderPageTitleSection, renderSelect, renderSpan, returnFormFromFields } from "../../ui";
import { _returnInCents, checkForAnyPerm, convertBigIntTimestampToDate, convertCentsToMoney, createObjectFromForm, decodeSLC, downloadData, getDynamicFormData, handleCSVFileUpload, internationalizeMoney, randomId, returnAssociateBadge, showFailureAlert, showSuccessAlert, toSelectableDate, toTitleCase, validateForm } from "../../utilities";
import { PromiseClient, Transport } from "@connectrpc/connect";
import { familiesListFromIDs, purchaseorderFamily, roleSelf } from "../../fetches";
import { getTransport, getWriteTransport } from "../../clients";
import { setupFamilySearchable, setupGoodsReceiptSearchable } from "../../searchables";
import { protoInt64 } from "@bufbuild/protobuf";
import { renderVendorInvoiceItemTrends } from "./trends";

const downloadPDFButtonClass = "__download-pdf-btn";
const downloadCSVButtonClass = "__download-csv-btn";
const goToPurchaseOrderButtonClass = "__go-to-purchase-order-btn";
const updateButtonClass = "__update-btn";
const autofillButtonClass = "__autofill-btn";
const uploadCSVButtonClass = "__upload-csv-btn";
const addLineItemButtonClass = "__add-line-item-btn";
const deleteLineItemButtonClass = "__delete-line-item-btn";
const trendsLineItemButtonClass = "__trends-line-item-btn";

const addReferenceItemButtonClass = "__add-reference-item-btn";
const deleteReferenceItemButtonClass = "__delete-reference-item-btn";

export async function handleIndividualVendorInvoice(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    const transport = getTransport();
    const accessClient = getClientForVendorInvoicesService(transport);
    const [vendorinvoice, ancillaryParams, userRole] = await Promise.all([
        accessClient.viewByUUID({ uuid: ctx.params.uuid }),
        accessClient.viewAncillaryParametersByUUID({ uuid: ctx.params.uuid }),
        roleSelf(transport)
    ]);
    const currenciesClient = getClientForCurrenciesService(transport);

    const [currency] = await Promise.all([
        currenciesClient.viewByID({ id: vendorinvoice.currencyId })
    ]);

    document.title = vendorinvoice.approvalMetadata?.approvedOn! > 0 ? vendorinvoice.finalRefNumber : vendorinvoice.referenceId;

    let container = document.createElement("div");
    container.className = "overflow-x-auto";
    content.appendChild(container);

    let readonly = true;
    if (checkForAnyPerm(userRole, [PermissionVendorInvoiceDrafts]) && vendorinvoice.status == STANDARD_LIFECYCLE_STATUS.DRAFT) {
        readonly = false;
    } else if (checkForAnyPerm(userRole, [PermissionVendorInvoiceRevise]) && vendorinvoice.status == STANDARD_LIFECYCLE_STATUS.REVISION) {
        readonly = false;
    }

    let { formGrid, buttonContainer } = await getForm(vendorinvoice, ancillaryParams, currency, readonly, accessClient, transport);

    container.appendChild(formGrid);

    // Setup PDF downloads
    let pdfDownloadButtons = container.getElementsByClassName(downloadPDFButtonClass);
    for (let i = 0; i < pdfDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>pdfDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await accessClient.downloadByUUID({ uuid: btn.getAttribute("data-uuid") || "" });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "pdf", file.name.replace(".pdf", ""));
        });
    }

    // Setup CSV downloads
    let csvDownloadButtons = container.getElementsByClassName(downloadCSVButtonClass);
    for (let i = 0; i < csvDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>csvDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await accessClient.downloadAsCSV({ uuid: btn.getAttribute("data-uuid") || "" });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "csv", file.name.replace(".csv", ""));
        });
    }

    const familiesAccessClient = getClientForFamiliesService(transport);

    let trendsLineItemButtons = container.getElementsByClassName(trendsLineItemButtonClass);
    for (let i = 0; i < trendsLineItemButtons.length; i++) {
        let btn = <HTMLButtonElement>trendsLineItemButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();
            try {
                renderVendorInvoiceItemTrends(protoInt64.parse(btn.getAttribute("data-family-id") || "0"), btn.getAttribute("data-family-uuid") || "", accessClient, familiesAccessClient, currenciesClient, transport);
            } catch (e) {
                showFailureAlert("Something went wrong. Try again.");
            }
        });
    }

    if (!readonly) {
        const writeClient = getClientForVendorInvoicesService(getWriteTransport());
        const uomAccessClient = getClientForUnitsOfMaterialsService(transport);
        const taxgroupAccessClient = getClientForTaxGroupsService(transport);
        const purchaseOrderAccessClient = getClientForPurchasesOrdersService(transport);
        const goodsReceiptAccessClient = getClientForGoodsReceiptsService(transport);

        // Setup update button handler
        let updateButton = container.getElementsByClassName(updateButtonClass)[0] as HTMLButtonElement;
        updateButton.addEventListener("click", async evt => {
            evt.preventDefault();

            // Validate all the fields
            if (!validateForm(formGrid.id)) {
                return;
            }

            // Create the object
            let obj = <VendorInvoicesServiceUpdateRequest>createObjectFromForm(formGrid.id);
            obj.formData = getDynamicFormData();
            obj.id = vendorinvoice.metadata?.id!;
            obj.currencyId = vendorinvoice.currencyId;
            obj.notifyUsers = true;

            try {
                if (vendorinvoice.status == STANDARD_LIFECYCLE_STATUS.DRAFT) {
                    await writeClient.draftUpdate(obj);
                } else if (vendorinvoice.status == STANDARD_LIFECYCLE_STATUS.REVISION) {
                    await writeClient.revisionUpdate(obj);
                }
                showSuccessAlert(`Vendor Invoice: ${obj.referenceId} has been updated`);
                // No need to redirect, since the UI will auto update on receipt of notification from the server
            } catch (e) {
                showFailureAlert("Something went wrong. Try again.");
            }
        });

        // Setup autofill button handler
        let autofillButton = container.getElementsByClassName(autofillButtonClass)[0] as HTMLButtonElement;
        autofillButton.addEventListener("click", async evt => {
            evt.preventDefault();

            // Create the object
            let obj = <VendorInvoicesServiceAutofillRequest>{};
            obj.uuid = vendorinvoice.metadata?.uuid!;

            try {
                await writeClient.autofill(obj);
                showSuccessAlert(`Vendor Invoice: ${vendorinvoice.referenceId} has been autofilled`);
            } catch (e) {
                showFailureAlert("Something went wrong. Try again.");
            }
        });

        // Setup upload CSV button handler
        let uploadCSVButton = container.getElementsByClassName(uploadCSVButtonClass)[0] as HTMLButtonElement;
        uploadCSVButton.addEventListener("click", async evt => {
            evt.preventDefault();
            try {
                handleCSVFileUpload(async fileContent => {
                    // Create the object
                    let obj = <IdentifierUUIDWithFile>{};
                    obj.uuid = vendorinvoice.metadata?.uuid!;
                    obj.fileContent = fileContent;
                    await writeClient.uploadVendorInvoiceItems(obj);
                    showSuccessAlert(`Vendor Invoice: ${vendorinvoice.referenceId} has been uploaded from the given CSV file`);
                });
            } catch (e) {
                showFailureAlert("Something went wrong. Try again.");
            }
        });

        // Setup add line item button handlers
        let addLineItemButton = container.getElementsByClassName(addLineItemButtonClass)[0] as HTMLButtonElement;
        addLineItemButton.addEventListener("click", async evt => {
            evt.preventDefault();

            const familyIdElId = randomId();
            const internalQtyElId = randomId();
            const vendorUomElId = randomId();
            const vendorQtyElId = randomId();
            const vendorUnitPriceElId = randomId();
            const taxGroupIdElId = randomId();
            const roundOffElId = randomId();
            const specificationsElId = randomId();

            let submitBtnElId = randomId();

            let submitButton = document.createElement("button");
            submitButton.id = submitBtnElId;
            submitButton.innerText = "Submit";
            submitButton.className = "btn btn-success btn-outline btn-sm mt-3";

            // Show a dialog
            let dialog = document.createElement("dialog");
            dialog.className = "modal";
            dialog.id = randomId();
            dialog.innerHTML = `
            <div class="max-w-fit h-96 overflow-auto modal-box bg-white text-gray-700">
                <p class="text-2xl text-center">Add Line Item</p>
                <form method="dialog">
                    <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                </form>
                <div class="grid grid-cols-12 gap-2">
                    ${renderSelect({ id: familyIdElId, readonly: false, label: "Select Material", dataMapper: "familyId", dataType: "bigint", value: "", mdColSpan: 12, helpText: "Select the material to add", options: [] }).outerHTML}
                    ${renderInput({ id: vendorUomElId, readonly: true, label: "Vendor UoM", inputType: "text", dataMapper: "", dataType: "string", value: "", mdColSpan: 4, helpText: "Vendor UoM" }).outerHTML}
                    ${renderInput({ id: vendorQtyElId, readonly: false, label: "Vendor Quantity", inputType: "number", dataMapper: "vendorQuantity", dataType: "bigint_in_cents", value: "", mdColSpan: 4, helpText: "Vendor Quantity (Quantity in the Vendor's UoM)" }).outerHTML}
                    ${renderInput({ id: internalQtyElId, readonly: false, label: "Internal Quantity", inputType: "number", dataMapper: "internalQuantity", dataType: "bigint_in_cents", value: "", mdColSpan: 4, helpText: "Internal Quantity (Quantity in the primary UoM)" }).outerHTML}

                    ${renderInput({ id: vendorUnitPriceElId, readonly: false, label: "Unit Price", inputType: "number", dataMapper: "vendorUnitPrice", dataType: "bigint_in_cents", value: "", mdColSpan: 4, helpText: "Unit Price Per Vendor UoM" }).outerHTML}
                    ${renderInput({ id: taxGroupIdElId, readonly: true, label: "Tax Group", inputType: "text", dataMapper: "", dataType: "string", value: "", mdColSpan: 4, helpText: "Tax Group" }).outerHTML}
                    ${renderInput({ id: roundOffElId, readonly: false, label: "Round Off", inputType: "number", dataMapper: "roundOff", dataType: "bigint_in_cents", value: "", mdColSpan: 4, helpText: "Applicable Round Off" }).outerHTML}

                    ${renderInput({ id: specificationsElId, readonly: false, label: "Specifications", inputType: "text", dataMapper: "specifications", dataType: "string", value: "", mdColSpan: 12, helpText: "Write any further specifications here." }).outerHTML}

                    ${submitButton.outerHTML}
                </div>
            </div>
            `;

            document.body.appendChild(dialog);
            dialog.showModal();

            let internalQtyEl = (<HTMLInputElement>document.getElementById(internalQtyElId));
            let vendorQtyEl = (<HTMLInputElement>document.getElementById(vendorQtyElId));
            let vendorUomEl = (<HTMLInputElement>document.getElementById(vendorUomElId));

            let vendorUnitPriceEl = (<HTMLInputElement>document.getElementById(vendorUnitPriceElId));
            let taxGroupIdEl = (<HTMLInputElement>document.getElementById(taxGroupIdElId));
            let roundOffEl = (<HTMLInputElement>document.getElementById(roundOffElId));
            let specificationsEl = (<HTMLInputElement>document.getElementById(specificationsElId));

            setupFamilySearchable(familyIdElId, async (searchKey, choice) => {
                let results = await accessClient.viewProspectiveFamilies({
                    id: vendorinvoice.metadata?.id,
                    searchKey: searchKey
                });

                choice.setChoices(results.list.map(r => {
                    return { value: r.metadata?.id, label: `(${r.code}) ${r.name}`, disabled: false };
                }));

            }, async (value) => {
                let [item, purchaseorderItem] = await Promise.all([
                    accessClient.viewProspectiveVendorInvoiceItem({
                        vendorInvoiceId: vendorinvoice.metadata?.id!,
                        familyId: protoInt64.parse(value),
                    }),
                    purchaseorderFamily(vendorinvoice.refId, protoInt64.parse(value), purchaseOrderAccessClient)
                ]);

                let uom = new UnitOfMaterial({ symbol: "-", name: "-", metadata: { id: protoInt64.zero } });
                if (purchaseorderItem.vendorUomId > 0) {
                    uom = await uomAccessClient.viewByID({ id: purchaseorderItem.vendorUomId });
                }
                let taxgroup = new TaxGroup({ name: "-", metadata: { id: protoInt64.zero } });
                if (purchaseorderItem.taxGroupId > 0) {
                    taxgroup = await taxgroupAccessClient.viewByID({ id: purchaseorderItem.taxGroupId });
                }

                internalQtyEl.value = convertCentsToMoney(item.internalQuantity);
                vendorQtyEl.value = convertCentsToMoney(item.vendorQuantity);
                vendorUomEl.value = `(${uom.symbol}) ${uom.name}`;
                vendorUomEl.setAttribute("data-id", `${uom.metadata?.id}`);

                vendorUnitPriceEl.value = convertCentsToMoney(purchaseorderItem.vendorUnitPrice);

                taxGroupIdEl.value = `${taxgroup.name} (${convertCentsToMoney(taxgroup.cumulativeTaxPercentage)}%)`;
                taxGroupIdEl.setAttribute("data-id", `${taxgroup.metadata?.id}`);

                roundOffEl.value = "0.00";
                specificationsEl.value = purchaseorderItem.specifications;
            });

            (<HTMLButtonElement>document.getElementById(submitBtnElId)).addEventListener("click", async evt => {
                evt.preventDefault();

                if (!validateForm(dialog.id)) {
                    return;
                }
                let itemToAdd = new VendorInvoicesServiceItemCreateRequest({
                    vendorInvoiceId: vendorinvoice.metadata?.id,
                    familyId: protoInt64.parse((<HTMLInputElement>document.getElementById(familyIdElId)).value),
                    internalQuantity: protoInt64.parse(_returnInCents(parseFloat(internalQtyEl.value))),
                    vendorUomId: protoInt64.parse(vendorUomEl.getAttribute("data-id") || "0"),
                    vendorQuantity: protoInt64.parse(_returnInCents(parseFloat(vendorQtyEl.value))),
                    vendorUnitPrice: protoInt64.parse(_returnInCents(parseFloat(vendorUnitPriceEl.value))),
                    taxGroupId: protoInt64.parse(taxGroupIdEl.getAttribute("data-id") || "0"),
                    roundOff: protoInt64.parse(_returnInCents(parseFloat(roundOffEl.value))),
                    specifications: specificationsEl.value.trim()
                });

                try {
                    await writeClient.addVendorInvoiceItem(itemToAdd);
                    showSuccessAlert(`Item added to Vendor Invoice: ${vendorinvoice.referenceId}`);
                    dialog.close();
                    // No need to redirect, since the UI will auto update on receipt of notification from the server
                } catch (e) {
                    showFailureAlert("Something went wrong. Try again.");
                }
            });

        });

        let deleteLineItemButtons = container.getElementsByClassName(deleteLineItemButtonClass);
        for (let i = 0; i < deleteLineItemButtons.length; i++) {
            let btn = <HTMLButtonElement>deleteLineItemButtons[i];
            btn.addEventListener("click", async evt => {
                evt.preventDefault();

                try {
                    await writeClient.deleteVendorInvoiceItem({
                        id: protoInt64.parse(btn.getAttribute("data-id") || "0"),
                    });
                    showSuccessAlert(`Item deleted from Vendor Invoice: ${vendorinvoice.referenceId}`);
                    // No need to redirect, since the UI will auto update on receipt of notification from the server
                } catch (e) {
                    showFailureAlert("Something went wrong. Try again.");
                }
            });
        }

        // ---------------------------------------------------------------------------------------------------------
        // References
        // Setup add reference item button handlers
        let addReferenceItemButton = container.getElementsByClassName(addReferenceItemButtonClass)[0] as HTMLButtonElement;
        addReferenceItemButton.addEventListener("click", async evt => {
            evt.preventDefault();

            const goodsReceiptIdElId = randomId();
            let submitReferenceBtnElId = randomId();

            let submitButton = document.createElement("button");
            submitButton.id = submitReferenceBtnElId;
            submitButton.innerText = "Submit";
            submitButton.className = "btn btn-success btn-outline btn-sm mt-3";

            // Show a dialog
            let dialog = document.createElement("dialog");
            dialog.className = "modal";
            dialog.id = randomId();
            dialog.innerHTML = `
            <div class="max-w-fit h-96 overflow-auto modal-box bg-white text-gray-700">
                <p class="text-2xl text-center">Add Reference</p>
                <form method="dialog">
                    <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                </form>
                <div class="grid grid-cols-12 gap-2">
                    ${renderSelect({ id: goodsReceiptIdElId, readonly: false, label: "Select Goods Receipt", dataMapper: "goodsReceiptId", dataType: "bigint", value: "", mdColSpan: 12, helpText: "Select the goods receipt to associate", options: [] }).outerHTML}

                    ${submitButton.outerHTML}
                </div>
            </div>
            `;

            document.body.appendChild(dialog);
            dialog.showModal();

            setupGoodsReceiptSearchable(goodsReceiptIdElId, goodsReceiptAccessClient, {
                status: STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED,
                billingStatus: GOODS_RECEIPT_BILLING_STATUS.GOODS_RECEIPT_BILLING_STATUS_UNBILLED,
                refFrom: "purchase-order", refId: protoInt64.parse(vendorinvoice.refId)
            }, true);

            (<HTMLButtonElement>document.getElementById(submitReferenceBtnElId)).addEventListener("click", async evt => {
                evt.preventDefault();

                if (!validateForm(dialog.id)) {
                    return;
                }
                let itemToAdd = new VendorInvoicesServiceReferenceCreateRequest({
                    vendorInvoiceId: vendorinvoice.metadata?.id,
                    goodsReceiptId: protoInt64.parse((<HTMLInputElement>document.getElementById(goodsReceiptIdElId)).value),
                });

                try {
                    await writeClient.addVendorInvoiceReference(itemToAdd);
                    showSuccessAlert(`Reference added to Vendor Invoice: ${vendorinvoice.referenceId}`);
                    dialog.close();
                    // No need to redirect, since the UI will auto update on receipt of notification from the server
                } catch (e) {
                    showFailureAlert("Something went wrong. Try again.");
                }
            });

        });

        let deleteReferenceItemButtons = container.getElementsByClassName(deleteReferenceItemButtonClass);
        for (let i = 0; i < deleteReferenceItemButtons.length; i++) {
            let btn = <HTMLButtonElement>deleteReferenceItemButtons[i];
            btn.addEventListener("click", async evt => {
                evt.preventDefault();

                try {
                    await writeClient.deleteVendorInvoiceReference({
                        id: protoInt64.parse(btn.getAttribute("data-id") || "0"),
                    });
                    showSuccessAlert(`Reference deleted from Vendor Invoice: ${vendorinvoice.referenceId}`);
                    // No need to redirect, since the UI will auto update on receipt of notification from the server
                } catch (e) {
                    showFailureAlert("Something went wrong. Try again.");
                }
            });
        }

        // ---------------------------------------------------------------------------------------------------------
        buttonContainer = <HTMLDivElement>document.getElementById(buttonContainer.id);

        let sendForVerificationButton = document.createElement("button");
        sendForVerificationButton.id = randomId();
        sendForVerificationButton.className = `btn btn-warning btn-outline btn-sm mr-4`;
        sendForVerificationButton.innerText = "Send for Verification";
        sendForVerificationButton.setAttribute("data-uuid", vendorinvoice.metadata?.uuid!);
        sendForVerificationButton.setAttribute("data-id", vendorinvoice.metadata!.id.toString());
        sendForVerificationButton.setAttribute("data-name", vendorinvoice.approvalMetadata?.approvedOn! > 0 ? vendorinvoice.finalRefNumber : vendorinvoice.referenceId);
        buttonContainer.appendChild(sendForVerificationButton);

        sendForVerificationButton.addEventListener("click", async evt => {
            evt.preventDefault();
            const originalButtonHTML = sendForVerificationButton.innerHTML;
            sendForVerificationButton.disabled = true;
            sendForVerificationButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            await writeClient.sendForVerification({ uuid: sendForVerificationButton.getAttribute("data-uuid") || "" });
            sendForVerificationButton.disabled = false;
            sendForVerificationButton.innerHTML = originalButtonHTML;
        });
    }
}

async function renderReferencesSection(vendorinvoice: VendorInvoice, ancillaryParams: VendorInvoiceAncillaryParameters, purchaseOrder: PurchaseOrder, currency: Currency, readonly: boolean, accessClient: PromiseClient<typeof VendorInvoicesService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "References", titleMdColSpan: 3 });

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Initial Reference", inputType: "text", dataMapper: "referenceId", dataType: "string", value: vendorinvoice.referenceId, mdColSpan: 6, helpText: "Initial Reference of the record." }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Final Reference", inputType: "text", dataMapper: "finalRefNumber", dataType: "string", value: vendorinvoice.finalRefNumber, mdColSpan: 6, helpText: "Final Reference of the record that is automatically generated." }));
    // Display status as well
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Status", inputType: "text", dataMapper: "status", dataType: "string", value: toTitleCase(decodeSLC(vendorinvoice.status)), mdColSpan: 4, helpText: "Status of the record." }));

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Vendor Bill No", inputType: "text", dataMapper: "vendorBillNo", dataType: "string", value: vendorinvoice.vendorBillNo, mdColSpan: 4, helpText: "Vendor Bill No is the sequence number that was generated by the vendor." }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Vendor Bill Date", inputType: "date", dataMapper: "vendorBillDate", dataType: "string", value: toSelectableDate(vendorinvoice.vendorBillDate), mdColSpan: 4, helpText: "Vendor Bill Date is the date on which the bill has been generated." }));

    contentGrid.appendChild(emptyDiv());
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Purchase Order Reference", inputType: "text", dataMapper: "", dataType: "string", value: purchaseOrder.approvalMetadata?.approvedOn! > 0 ? purchaseOrder.finalRefNumber : purchaseOrder.referenceId, mdColSpan: 6, helpText: "Reference of the Purchase Order." }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Currency", inputType: "text", dataMapper: "", dataType: "string", value: `(${currency.symbol}) ${currency.name}`, mdColSpan: 6, helpText: "The currency of the invoice." }));

    return grid;
}

async function renderBuyerAndConsigneeSection(vendorinvoice: VendorInvoice, ancillaryParams: PurchaseOrderAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof VendorInvoicesService>, transport: Transport): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Buyer Information", titleMdColSpan: 3 });

    // Get buyer and consignee
    const locationsAccessClient = getClientForLocationsService(transport);
    const [buyer, consignee] = await Promise.all([
        locationsAccessClient.viewByUUID({ uuid: ancillaryParams.buyerLocationUuid }),
        locationsAccessClient.viewByUUID({ uuid: ancillaryParams.consigneeLocationUuid }),
    ]);

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Buyer", inputType: "text", dataMapper: "buyerLocationId", dataType: "string", value: `${buyer.code.length > 0 ? `(${buyer.code})` : ''} ${buyer.name}`, mdColSpan: 6, helpText: "The information of the buyer." }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Consignee", inputType: "text", dataMapper: "consigneeLocationId", dataType: "string", value: `${consignee.code.length > 0 ? `(${consignee.code})` : ''} ${consignee.name}`, mdColSpan: 6, helpText: "The information of the consignee." }));

    return grid;
}

async function renderDatesSection(vendorinvoice: VendorInvoice, ancillaryParams: VendorInvoiceAncillaryParameters, purchaseOrder: PurchaseOrder, readonly: boolean, accessClient: PromiseClient<typeof VendorInvoicesService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Important Dates", titleMdColSpan: 3 });

    // Dates (creation and approval)
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Vendor Invoice Created On", inputType: "text", dataMapper: "createdAt", dataType: "string", value: convertBigIntTimestampToDate(vendorinvoice.metadata?.createdAt!), mdColSpan: 3, helpText: "The creation date of this Vendor Invoice." }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Vendor Invoice Approved On", inputType: "text", dataMapper: "approvedOn", dataType: "string", value: convertBigIntTimestampToDate(vendorinvoice.approvalMetadata?.approvedOn!), mdColSpan: 3, helpText: "The approval date of this Vendor Invoice." }));

    contentGrid.appendChild(emptyDiv());

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Purchase Order Created On", inputType: "text", dataMapper: "createdAt", dataType: "string", value: convertBigIntTimestampToDate(purchaseOrder.metadata?.createdAt!), mdColSpan: 3, helpText: "The creation date of the associated Purchase Order." }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Purchase Order Approved On", inputType: "text", dataMapper: "approvedOn", dataType: "string", value: convertBigIntTimestampToDate(purchaseOrder.approvalMetadata?.approvedOn!), mdColSpan: 3, helpText: "The approval date of the associated Purchase Order." }));

    return grid;
}

async function renderPaymentTermsSection(purchaseorder: PurchaseOrder, currency: Currency, readonly: boolean): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Payment Terms", titleMdColSpan: 3 });

    // Advance Payment
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Advance Payment", inputType: "text", dataMapper: "paymentAdvance", dataType: "string", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(purchaseorder.paymentAdvance)))}`, mdColSpan: 3, helpText: `The advance amount (in ${currency.symbol}) that is supposed to be paid.` }));

    // Payment Cycle
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Payment Cycle", inputType: "text", dataMapper: "paymentCycleInDays", dataType: "string", value: `${purchaseorder.paymentCycleInDays.toString()} days`, mdColSpan: 3, helpText: `The payment cycle of this Purchase Order.` }));

    return grid;
}

async function renderCostsSection(vendorinvoice: VendorInvoice, currency: Currency, readonly: boolean, accessClient: PromiseClient<typeof VendorInvoicesService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Costs", titleMdColSpan: 3 });

    // Miscellaneous Costs
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Miscellaneous Cost", inputType: "text", dataMapper: "miscellaneousCost", dataType: "bigint_in_cents", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(vendorinvoice.miscellaneousCost)))}`, mdColSpan: 4, helpText: `Any miscellaneous costs (in ${currency.symbol}) on the Vendor Invoice.` }));

    // Overall Discount
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Overall Discount", inputType: "text", dataMapper: "overallDiscount", dataType: "bigint_in_cents", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(vendorinvoice.overallDiscount)))}`, mdColSpan: 4, helpText: `The overall discount (in ${currency.symbol}) on the Vendor Invoice.` }));

    // Round Off
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Round Off", inputType: "text", dataMapper: "roundOff", dataType: "bigint_in_cents", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(vendorinvoice.roundOff)))}`, mdColSpan: 4, helpText: `The round off (in ${currency.symbol}) applied on the Vendor Invoice.` }));

    // Total
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Net Total", inputType: "text", dataMapper: "", dataType: "number", value: `${internationalizeMoney(vendorinvoice.totalValue)}`, mdColSpan: 6, helpText: `The net total (inclusive of applicable taxes, in ${currency.symbol}) of the Vendor Invoice.` }));

    return grid;
}

async function renderContactsSection(purchaseorder: PurchaseOrder, readonly: boolean, accessClient: PromiseClient<typeof PurchasesOrdersService>, transport: Transport): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Contacts", titleMdColSpan: 3 });

    const associatesClient = getClientForAssociatesService(transport);

    let contacts = (await accessClient.viewPurchaseOrderContacts({ uuid: purchaseorder.metadata?.uuid })).list;
    let purchaseOrderAssociates = await Promise.all(contacts.map(c => c.associateUuid).map(uuid => {
        return associatesClient.viewByUUID({ uuid });
    }));

    purchaseOrderAssociates.forEach(contact => {
        if (contact) {
            contentGrid.appendChild(returnAssociateBadge(contact));
        }
    });

    return grid;
}

async function renderDynamicFormsSection(vendorinvoice: VendorInvoice, readonly: boolean, accessClient: PromiseClient<typeof VendorInvoicesService>): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    if (vendorinvoice.formData.length == 0) {
        return container;
    }

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Other Details` }));
    container.appendChild(containerTitle);

    let formFields = await returnFormFromFields(vendorinvoice.formData, "vendor-invoices", true, readonly);

    container.appendChild(formFields);

    return container;
}

async function renderReferenceItemsSection(vendorinvoice: VendorInvoice, readonly: boolean, accessClient: PromiseClient<typeof VendorInvoicesService>, transport: Transport): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `References` }));

    if (!readonly) {
        let button = document.createElement("button");
        button.type = "button";
        button.className = `btn btn-sm btn-primary text-white justify-right ${addReferenceItemButtonClass}`;
        button.innerHTML = `<i class='bx bx-plus'></i> Add Reference`;
        containerTitle.firstChild!.appendChild(button);
    }

    container.appendChild(containerTitle);

    const goodsReceiptAccessClient = getClientForGoodsReceiptsService(transport);

    let references = await accessClient.viewVendorInvoiceReferences({ id: vendorinvoice.metadata?.id });
    let associatedGoodsReceipts = await Promise.all(references.list.map(r => {
        return goodsReceiptAccessClient.viewByID({ id: r.goodsReceiptId })
    }));

    associatedGoodsReceipts.forEach((item, index) => {
        let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: `${index + 1}.`, titleMdColSpan: 1 });

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Goods Receipt Initial Reference", value: `${item.referenceId}`, mdColSpan: 6, helpText: `The initial reference of the Goods Receipt` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Goods Receipt Final Reference", value: `${item.finalRefNumber}`, mdColSpan: 6, helpText: `The final (auto generated) reference of the Goods Receipt` }));

        contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Vendor Bill No", inputType: "text", dataMapper: "", dataType: "string", value: item.vendorBillNo, mdColSpan: 4, helpText: "The Vendor Bill No as entered while creating the Goods Receipt." }));
        contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Vendor Bill Date", inputType: "date", dataMapper: "", dataType: "string", value: toSelectableDate(item.vendorBillDate), mdColSpan: 4, helpText: "The Vendor Bill Date as entered while creating the Goods Receipt." }));
        contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Status", inputType: "text", dataMapper: "", dataType: "string", value: toTitleCase(decodeSLC(item.status)), mdColSpan: 4, helpText: "The status of the Goods Receipt." }));

        let buttonsDiv = document.createElement("div");
        buttonsDiv.className = "col-span-12 pl-4 mt-4";

        if (!readonly) {
            let deleteItemButton = document.createElement("button");
            deleteItemButton.type = "button";
            deleteItemButton.className = `btn btn-sm btn-error text-white float-right ${deleteReferenceItemButtonClass}`;
            deleteItemButton.innerHTML = `<i class='bx bx-trash'></i> Delete`;
            deleteItemButton.setAttribute("data-id", references.list[index].metadata?.id.toString()!);

            buttonsDiv.appendChild(deleteItemButton);
        }

        // Add the link to the goods receipt
        let goodsReceiptLink = document.createElement("a");
        goodsReceiptLink.className = `btn btn-sm btn-outline btn-primary text-white float-right mr-3`;
        goodsReceiptLink.innerHTML = `<i class='bx bx-door-open'></i> View`;
        goodsReceiptLink.href = getLinkForGoodsReceipt(item.metadata?.uuid!);

        buttonsDiv.appendChild(goodsReceiptLink);

        contentGrid.appendChild(emptyDiv());
        contentGrid.appendChild(buttonsDiv);

        container.appendChild(grid);

        let hr = document.createElement("hr");
        hr.classList.add("m-5");
        container.appendChild(hr);
    });

    return container;
}

async function renderFamiliesSection(vendorinvoice: VendorInvoice, currency: Currency, readonly: boolean, accessClient: PromiseClient<typeof VendorInvoicesService>, transport: Transport): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Line Items` }));

    if (!readonly) {
        let button = document.createElement("button");
        button.type = "button";
        button.className = `btn btn-sm btn-primary text-white justify-right ${addLineItemButtonClass}`;
        button.innerHTML = `<i class='bx bx-plus'></i> Add Line Item`;
        containerTitle.firstChild!.appendChild(button);

        let autofillButton = document.createElement("button");
        autofillButton.id = randomId();
        autofillButton.className = `btn btn-sm btn-warning btn-outline justify-right ${autofillButtonClass} ml-4`;
        autofillButton.innerHTML = `<i class='bx bxs-magic-wand'></i> AutoFill`;
        autofillButton.setAttribute("data-uuid", vendorinvoice.metadata?.uuid!);
        autofillButton.setAttribute("data-id", vendorinvoice.metadata!.id.toString());
        autofillButton.setAttribute("data-name", vendorinvoice.approvalMetadata?.approvedOn! > 0 ? vendorinvoice.finalRefNumber : vendorinvoice.referenceId);
        containerTitle.firstChild!.appendChild(autofillButton);

        let uploadCSVButton = document.createElement("button");
        uploadCSVButton.id = randomId();
        uploadCSVButton.className = `btn btn-sm btn-info btn-outline justify-right ${uploadCSVButtonClass} ml-4`;
        uploadCSVButton.innerHTML = `<i class='bx bx-upload'></i> Upload from CSV`;
        uploadCSVButton.setAttribute("data-uuid", vendorinvoice.metadata?.uuid!);
        uploadCSVButton.setAttribute("data-id", vendorinvoice.metadata!.id.toString());
        uploadCSVButton.setAttribute("data-name", vendorinvoice.approvalMetadata?.approvedOn! > 0 ? vendorinvoice.finalRefNumber : vendorinvoice.referenceId);
        containerTitle.firstChild!.appendChild(uploadCSVButton);
    }

    container.appendChild(containerTitle);

    let familiesMap: Map<bigint, Family> = new Map();
    (await familiesListFromIDs(Array.from(new Set(vendorinvoice.list.map(p => p.familyId))))).forEach(family => {
        familiesMap.set(family.metadata?.id!, family);
    });

    const uomClient = getClientForUnitsOfMaterialsService(transport);
    let unitsList = vendorinvoice.list.map(p => p.vendorUomId);
    familiesMap.forEach(family => {
        unitsList.push(family.uomId);
    });

    let uomsList = await Promise.all(Array.from(new Set(unitsList)).map(id => {
        return uomClient.viewByID({ id });
    }));
    let uomsMap: Map<bigint, UnitOfMaterial> = new Map();

    uomsList.forEach(uom => {
        uomsMap.set(uom.metadata!.id, uom);
    });

    const taxGroupClient = getClientForTaxGroupsService(transport);
    let taxGroupIDsList = vendorinvoice.list.map(p => p.taxGroupId);
    familiesMap.forEach(family => {
        taxGroupIDsList.push(family.taxGroupId);
    });

    let taxGroupsList = await Promise.all(Array.from(new Set(taxGroupIDsList)).map(id => {
        return taxGroupClient.viewByID({ id });
    }));
    let taxGroupsMap: Map<bigint, TaxGroup> = new Map();

    taxGroupsList.forEach(taxGroup => {
        taxGroupsMap.set(taxGroup.metadata!.id, taxGroup);
    });

    vendorinvoice.list.forEach((item, index) => {
        let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: `${index + 1}.`, titleMdColSpan: 1 });

        let fam = familiesMap.get(item.familyId) || new Family();

        let pretaxTotal = parseInt(String(item.vendorQuantity)) * parseInt(String(item.vendorUnitPrice)) / 100.0;

        const postDiscountTotal = pretaxTotal;

        let taxgroup = taxGroupsMap.get(item.taxGroupId) || new TaxGroup();
        let taxAmt = postDiscountTotal * taxgroup.cumulativeTaxPercentage / 100.0 / 100.0;
        let total = postDiscountTotal + taxAmt;

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Material Code", value: `${fam.code}`, mdColSpan: 3, helpText: `The code of the material` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Material Name", value: `${fam.name}`, mdColSpan: 9, helpText: `The name of the material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Vendor Quantity & UoM", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(item.vendorQuantity)))} (${uomsMap.get(item.vendorUomId)?.symbol}) ${uomsMap.get(item.vendorUomId)?.name}`, mdColSpan: 3, helpText: `The quantity of the material in Vendor's unit of material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Buyer Quantity & UoM", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(item.internalQuantity)))} (${uomsMap.get(fam.uomId)?.symbol}) ${uomsMap.get(fam.uomId)?.name}`, mdColSpan: 3, helpText: `The quantity of the material in Buyer's unit of material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Unit Price", value: `${currency.symbol} ${internationalizeMoney(parseFloat(convertCentsToMoney(item.vendorUnitPrice)))}/${uomsMap.get(item.vendorUomId)?.symbol}`, mdColSpan: 3, helpText: `The price per unit of the material in Vendor's unit` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Tax", value: `${taxGroupsMap.get(item.taxGroupId)?.name}`, mdColSpan: 3, helpText: `The applicable tax group` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Pre-Tax Total", value: `${currency.symbol} ${internationalizeMoney(postDiscountTotal / 100.0)}`, mdColSpan: 3, helpText: `The pre tax amount (after applicable discount percentage)` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Tax Amount", value: `${currency.symbol} ${internationalizeMoney(taxAmt / 100.0)}`, mdColSpan: 3, helpText: `The computed tax amount` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Net Total", value: `${currency.symbol} ${internationalizeMoney(total / 100.0)}`, mdColSpan: 3, helpText: `The total amount (inclusive of taxes)` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "HSN/SAC Code", value: `${fam.hsnSacCode}`, mdColSpan: 3, helpText: `The HSN/SAC code of the material` }));

        if (item.specifications.length > 0) {
            contentGrid.appendChild(renderSpan({ id: randomId(), label: "Specifications", value: `${item.specifications}`, mdColSpan: 12, helpText: `Any additional specifications that are provided to the Vendor` }));
        }

        let buttonsDiv = document.createElement("div");
        buttonsDiv.className = "col-span-12 pl-4";
        contentGrid.appendChild(buttonsDiv);
        contentGrid.appendChild(emptyDiv());

        if (!readonly) {
            let deleteItemButton = document.createElement("button");
            deleteItemButton.type = "button";
            deleteItemButton.className = `btn btn-sm btn-error text-white float-right ${deleteLineItemButtonClass}`;
            deleteItemButton.innerHTML = `<i class='bx bx-trash'></i> Delete`;
            deleteItemButton.setAttribute("data-id", item.metadata?.id.toString()!);

            buttonsDiv.appendChild(deleteItemButton);
        }

        // Trends button here
        let trendsItemButton = document.createElement("button");
        trendsItemButton.type = "button";
        trendsItemButton.className = `btn btn-sm btn-primary btn-outline text-white float-right mr-3 ${trendsLineItemButtonClass}`;
        trendsItemButton.innerHTML = `<i class='bx bx-line-chart'></i> Trends`;
        trendsItemButton.setAttribute("data-id", item.metadata?.id.toString()!);
        trendsItemButton.setAttribute("data-family-id", item.familyId?.toString()!);
        trendsItemButton.setAttribute("data-family-uuid", item.familyUuid!);

        buttonsDiv.appendChild(trendsItemButton);

        container.appendChild(grid);

        let hr = document.createElement("hr");
        hr.classList.add("m-5");
        container.appendChild(hr);
    });

    return container;
}

async function getForm(vendorinvoice: VendorInvoice, ancillaryParams: VendorInvoiceAncillaryParameters, currency: Currency, readonly: boolean, accessClient: PromiseClient<typeof VendorInvoicesService>, transport: Transport) {
    let formGrid = document.createElement("div");
    formGrid.className = "grid grid-cols-1 gap-6 mb-6";
    formGrid.id = randomId();

    const purchasesOrdersClient = getClientForPurchasesOrdersService(transport);
    const [purchaseOrder, purchaseOrderAncillaryParams] = await Promise.all([
        purchasesOrdersClient.viewByUUID({ uuid: ancillaryParams.refUuid }),
        purchasesOrdersClient.viewAncillaryParametersByUUID({ uuid: ancillaryParams.refUuid }),
    ]);

    const [
        referencesSection,
        buyerAndConsigneeSection,
        datesSection,
        paymentTermsSection,
        costsSection,
        contactsSection,
        dynamicFormsSection,
        referenceItemsSection,
        familiesSection,
    ] = await Promise.all([
        renderReferencesSection(vendorinvoice, ancillaryParams, purchaseOrder, currency, readonly, accessClient),
        renderBuyerAndConsigneeSection(vendorinvoice, purchaseOrderAncillaryParams, readonly, accessClient, transport),
        renderDatesSection(vendorinvoice, ancillaryParams, purchaseOrder, readonly, accessClient),
        renderPaymentTermsSection(purchaseOrder, currency, readonly),
        renderCostsSection(vendorinvoice, currency, readonly, accessClient),
        renderContactsSection(purchaseOrder, readonly, purchasesOrdersClient, transport),
        renderDynamicFormsSection(vendorinvoice, readonly, accessClient),
        renderReferenceItemsSection(vendorinvoice, readonly, accessClient, transport),
        renderFamiliesSection(vendorinvoice, currency, readonly, accessClient, transport)
    ]);

    let buttonContainer = document.createElement("div");
    buttonContainer.id = randomId();
    buttonContainer.classList.add("col-span-12", "flex", "justify-center", "overflow-x-auto");

    if (!readonly) {
        let updateButton = document.createElement("button");
        updateButton.id = randomId();
        updateButton.className = `btn btn-warning btn-outline btn-sm ${updateButtonClass} mr-4`;
        updateButton.innerText = "Update";
        updateButton.setAttribute("data-uuid", vendorinvoice.metadata?.uuid!);
        updateButton.setAttribute("data-id", vendorinvoice.metadata!.id.toString());
        updateButton.setAttribute("data-name", vendorinvoice.approvalMetadata?.approvedOn! > 0 ? vendorinvoice.finalRefNumber : vendorinvoice.referenceId);
        buttonContainer.appendChild(updateButton);
    }

    // Download PDF button
    let downloadPDFButton = document.createElement("button");
    downloadPDFButton.id = randomId();
    downloadPDFButton.className = `btn btn-success btn-outline btn-sm ${downloadPDFButtonClass} mr-4`;
    downloadPDFButton.innerText = "Download PDF";
    downloadPDFButton.setAttribute("data-uuid", vendorinvoice.metadata?.uuid!);
    downloadPDFButton.setAttribute("data-name", vendorinvoice.approvalMetadata?.approvedOn! > 0 ? vendorinvoice.finalRefNumber : vendorinvoice.referenceId);
    buttonContainer.appendChild(downloadPDFButton);

    // Download CSV button
    let downloadCSVButton = document.createElement("button");
    downloadCSVButton.id = randomId();
    downloadCSVButton.className = `btn btn-success btn-outline btn-sm ${downloadCSVButtonClass} mr-4`;
    downloadCSVButton.innerText = "Download CSV";
    downloadCSVButton.setAttribute("data-uuid", vendorinvoice.metadata?.uuid!);
    downloadCSVButton.setAttribute("data-name", vendorinvoice.approvalMetadata?.approvedOn! > 0 ? vendorinvoice.finalRefNumber : vendorinvoice.referenceId);
    buttonContainer.appendChild(downloadCSVButton);

    let goToPurchaseOrderButton = document.createElement("a");
    goToPurchaseOrderButton.className = `btn btn-info btn-outline btn-sm ${goToPurchaseOrderButtonClass} mr-4`;
    goToPurchaseOrderButton.innerText = "Go To Purchase Order";
    goToPurchaseOrderButton.href = getLinkForPurchaseOrder(purchaseOrder.metadata!.uuid)
    buttonContainer.appendChild(goToPurchaseOrderButton);

    formGrid.innerHTML = `
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
            <div class="rounded-t mb-0 px-0 border-0">
                ${renderPageTitleSection({ title: `Vendor Invoice: ` + document.title }).outerHTML}
                ${buttonContainer.outerHTML}
            </div>
            <form>
                <hr class="m-5">
                ${referencesSection.outerHTML}
                <hr class="m-5">
                ${buyerAndConsigneeSection.outerHTML}
                <hr class="m-5">
                ${datesSection.outerHTML}
                <hr class="m-5">
                ${paymentTermsSection.outerHTML}
                <hr class="m-5">
                ${costsSection.outerHTML}
                <hr class="m-5">
                ${contactsSection.outerHTML}
                <hr class="m-5">
            </form>
        </div>
        ${dynamicFormsSection.childElementCount > 0 ?
            `<div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
                ${dynamicFormsSection.outerHTML}
            </div>`
            : ''}

        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
            ${referenceItemsSection.outerHTML}
        </div>

        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
            ${familiesSection.outerHTML}
        </div>
    `;

    return { formGrid, buttonContainer }
}

import { _returnInCents, convertBigIntTimestampToDate, convertCentsToMoney, createObjectFromForm, internationalizeMoney, round, showFailureAlert } from "../../utilities";
import { context } from "./../../router";
import { Family, getClientForCurrenciesService, getClientForPurchasesOrdersService, getClientForPurchasesPaymentsService, PurchasePayment, PurchasesPaymentsServiceFilterReq, STANDARD_LIFECYCLE_STATUS } from "@kernelminds/scailo-sdk";
import { _renderPageFilters } from "./searchcomponents";
import { protoInt64 } from "@bufbuild/protobuf";
import ApexCharts from 'apexcharts';
import { familiesListFromIDs } from "../../fetches";
import { getTransport } from "../../clients";
import { setupCurrencySearchable, setupPurchaseOrderSearchable } from "../../searchables";

export function handlePurchasePaymentInsights(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    let { html, formId, resetButtonId, getButtonId, refIdElementId, currencyReferenceId } = _renderPageFilters("Insights");
    let insightsHtml = document.createElement("div");
    insightsHtml.innerHTML = html

    content.appendChild(insightsHtml);

    // Setup searchable
    const transport = getTransport();
    const currenciesClient = getClientForCurrenciesService(transport);
    const purchaseOrdersClient = getClientForPurchasesOrdersService(transport);
    setupPurchaseOrderSearchable(refIdElementId, purchaseOrdersClient, { status: STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED });
    setupCurrencySearchable(currencyReferenceId, currenciesClient, {});

    let chartsContainer = document.createElement("div");
    // chartsContainer.className = "grid grid-cols-12";
    content.appendChild(chartsContainer);

    (<HTMLButtonElement>document.getElementById(resetButtonId)).addEventListener("click", async evt => {
        evt.preventDefault();
        handlePurchasePaymentInsights(ctx);
    });

    let getButton = (<HTMLButtonElement>document.getElementById(getButtonId));

    getButton.addEventListener("click", async evt => {
        evt.preventDefault();
        while (chartsContainer.firstChild) {
            chartsContainer.removeChild(chartsContainer.firstChild);
        }

        getButton.disabled = true;
        getButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;

        let client = getClientForPurchasesPaymentsService(transport);
        let [filterResp] = await Promise.all([
            client.filter(new PurchasesPaymentsServiceFilterReq(createObjectFromForm(formId))),
        ]);

        getButton.disabled = false;
        getButton.innerText = `Get Insights`;

        const insightRecords = filterResp.list;

        if (!insightRecords.length) {
            showFailureAlert("No Records Found");
            return;
        }
        
        let chartsDiv = document.createElement("div");
        chartsDiv.className = "overflow-x-auto p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded grid grid-cols-12";
        chartsContainer.appendChild(chartsDiv);

        const commonTheme = {
            palette: 'palette2',
        };
        // Display all the insights here
        displayAllRelevantCharts(insightRecords, chartsDiv, commonTheme);
    });
}

/**Displays all the charts relevant to purchases payments */
export function displayAllRelevantCharts(purchasespayments: PurchasePayment[], chartsDiv: HTMLDivElement, commonTheme: Object) {
    renderApprovedPaymentChart(purchasespayments, chartsDiv, commonTheme);
}

function renderApprovedPaymentChart(purchasespayments: PurchasePayment[], chartsDiv: HTMLDivElement, commonTheme: Object) {
    let localChartDiv = document.createElement("div");
    localChartDiv.classList.add("col-span-12");
    chartsDiv.appendChild(localChartDiv);

    let creditsValue = protoInt64.zero;
    let debitsValue = protoInt64.zero;
    purchasespayments.forEach(purchasepayment => {
        if (purchasepayment.transactionType == "credit") {
            creditsValue += purchasepayment.amountNet;
        } else if (purchasepayment.transactionType == "debit") {
            debitsValue += purchasepayment.amountNet;
        }
    });
    let netAmount = creditsValue - debitsValue;

    let options = {
        title: {
            text: `Payments To Be Made: ${internationalizeMoney(parseFloat(convertCentsToMoney(netAmount)))}`,
            align: "center",
            margin: 10,
            offsetX: 0,
            offsetY: -10,
            floating: true,
            style: {
                fontSize: "20px",
                fontWeight: "light",
                color: "#263238"
            },
        },
        theme: commonTheme,
        series: [parseFloat(convertCentsToMoney(creditsValue)), parseFloat(convertCentsToMoney(debitsValue))],
        chart: {
            height: 500,
            type: 'donut',
        },
        stroke: {
            width: 0,
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            showAlways: true,
                            show: true,
                            formatter: function (w) {
                                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                return internationalizeMoney(parseFloat(convertCentsToMoney(netAmount)));
                            }
                        }
                    }
                }
            }
        },
        labels: ["To Pay", "To Deduct"],
        dataLabels: {
            dropShadow: {
                blur: 10,
                opacity: 0.9
            }
        },
        fill: {
            type: 'solid',
            opacity: 1,
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'top'
                }
            }
        }],
        tooltip: {
            custom: function ({ series, seriesIndex }) {
                let contextualTotal = series[seriesIndex];
                return `
                    <ul style='background-color: #424242; color: #F5F5F5; padding: 20px; margin: 0px;'>
                        <li>Total: ${internationalizeMoney(contextualTotal)}</li>
                    </ul>
                `;
            }
        }
    };
    let chart = new ApexCharts(localChartDiv, options);
    chart.render();
}
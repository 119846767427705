import { _returnInCents, bgColor, colorizeInvertedCentsToMoney, convertCentsToMoney, createObjectFromForm, downloadButtonClass, downloadData, randomId, renderTableWithCSVHeadersAndRows, round, setupDownloadForFilters, showFailureAlert, showSuccessAlert } from "../../utilities";
import { context } from "./../../router";
import { Family, getClientForCurrenciesService, getClientForLocationsService, getClientForPurchasesOrdersService, getClientForUnitsOfMaterialsService, PurchaseOrderInventoryMatch, PurchasesOrdersService, PurchasesOrdersServiceCountReq, PurchasesOrdersServiceFilterReq, UnitOfMaterial } from "@kernelminds/scailo-sdk";
import { failureIcon, successIcon, viewPurchasesOrders } from "../../tables";
import { _renderPageFilters } from "./searchcomponents";
import { PromiseClient, Transport } from "@connectrpc/connect";
import { familiesListFromIDs, purchaseorderInventoryMatch } from "../../fetches";
import { protoInt64 } from "@bufbuild/protobuf";
import { getTransport } from "../../clients";
import { setupCurrencySearchable, setupLocationSearchable } from "../../searchables";

export function handlePurchaseOrderFilters(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    let { html, formId, resetButtonId, getButtonId, consigneeLocationId, buyerLocationId, currencyReferenceId } = _renderPageFilters("Records");
    let filtersHtml = document.createElement("div");
    filtersHtml.innerHTML = html

    content.appendChild(filtersHtml);

    // Setup searchable
    const transport = getTransport();
    const currenciesClient = getClientForCurrenciesService(transport);
    const locationsClient = getClientForLocationsService(transport);
    setupLocationSearchable(buyerLocationId, locationsClient, {});
    setupLocationSearchable(consigneeLocationId, locationsClient, {});
    setupCurrencySearchable(currencyReferenceId, currenciesClient, {});

    let tableContainer = document.createElement("div");
    content.appendChild(tableContainer);

    (<HTMLButtonElement>document.getElementById(resetButtonId)).addEventListener("click", async evt => {
        evt.preventDefault();
        handlePurchaseOrderFilters(ctx);
    });

    let getButton = (<HTMLButtonElement>document.getElementById(getButtonId));
    
    getButton.addEventListener("click", async evt => {
        evt.preventDefault();
        while (tableContainer.firstChild) {
            tableContainer.removeChild(tableContainer.firstChild);
        }

        getButton.disabled = true;
        getButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;

        let client = getClientForPurchasesOrdersService(transport);
        let [filterResp, countResp] = await Promise.all([
            client.filter(new PurchasesOrdersServiceFilterReq(createObjectFromForm(formId))),
            client.count(new PurchasesOrdersServiceCountReq(createObjectFromForm(formId)))
        ]);

        getButton.disabled = false;
        getButton.innerText = `Get Records`;

        const filterRecords = filterResp.list;

        if (!filterRecords.length) {
            showFailureAlert("No Records Found");
            return;
        }

        showSuccessAlert(`${countResp.count} records match the filter criteria`);

        let tableDiv = document.createElement("div");
        tableDiv.className = `overflow-x-auto p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words ${bgColor} w-full shadow-lg rounded`;
        tableContainer.appendChild(tableDiv);

        const inventoryMatchClass = randomId();

        const recordsDiv = await viewPurchasesOrders(filterRecords, { inventoryMatchClass });
        setupDownloadForFilters(recordsDiv, tableDiv, "Purchase Orders", 3);

        // Setup PDF downloads
        let pdfDownloadButtons = tableDiv.getElementsByClassName(downloadButtonClass);
        for (let i = 0; i < pdfDownloadButtons.length; i++) {
            let btn = <HTMLButtonElement>pdfDownloadButtons[i];
            btn.addEventListener("click", async evt => {
                evt.preventDefault();

                const originalButtonHTML = btn.innerHTML;
                btn.disabled = true;
                btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
                let file = await client.downloadByUUID({ uuid: btn.getAttribute("data-uuid") || "" });

                btn.disabled = false;
                btn.innerHTML = originalButtonHTML;

                downloadData(file.content, "pdf", file.name.replace(".pdf", ""));
            });
        }

        // Setup inventory match
        handlePurchaseOrderInventoryMatch(inventoryMatchClass, tableDiv, client, transport);
    });
}

/**Renders the inventory match for the purchase order in a modal */
export async function handlePurchaseOrderInventoryMatch(inventoryMatchClass: string, tableDiv: HTMLDivElement, client: PromiseClient<typeof PurchasesOrdersService>, transport: Transport) {
    let buttons = tableDiv.getElementsByClassName(inventoryMatchClass);
    for (let i = 0; i < buttons.length; i++) {
        let btn = <HTMLButtonElement>buttons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let matchList = await purchaseorderInventoryMatch(btn.getAttribute("data-uuid") || "", client);
            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            let table = await getPurchaseOrderInventoryMatchTable(matchList, `Inventory Match for ${btn.getAttribute("data-name") || ""}`, transport);

            // Render the table inside a modal
            let dialog = document.createElement("dialog");
            dialog.className = "modal";
            dialog.id = randomId();
            dialog.innerHTML = `
            <div class="max-w-full modal-box bg-gray-50 text-gray-700">
                <form method="dialog">
                <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                </form>
                ${table.table.outerHTML}
            </div>          
            `;

            document.body.appendChild(dialog);
            dialog.showModal()
            dialog.addEventListener("close", () => {
                document.body.removeChild(dialog);
            });
        });
    }
}

export async function getPurchaseOrderInventoryMatchTable(matchList: PurchaseOrderInventoryMatch[], title: string, transport: Transport) {
    let familiesMap: Map<bigint, Family> = new Map();
    (await familiesListFromIDs(Array.from(new Set(matchList.map(m => {
        return m.familyId;
    }))))).forEach(fam => {
        familiesMap.set(fam.metadata!.id, fam);
    });

    let uomIds = <bigint[]>[];
    familiesMap.forEach(fam => {
        uomIds.push(fam.uomId);
    });

    matchList.forEach(m => {
        uomIds.push(m.vendorUomId);
    });

    const uomClient = getClientForUnitsOfMaterialsService(transport);
    const uomsList = await uomClient.viewFromIDs({ list: Array.from(new Set(uomIds)) });
    let uomsMap: Map<bigint, UnitOfMaterial> = new Map();
    uomsList.list.forEach(uom => {
        uomsMap.set(uom.metadata!.id, uom);
    });

    // Create a table here
    const headers = ["S.No.", "Code", "Material Name", "Ordered Qty", "Received Qty", "Returned Qty", "To Receive Qty", "Received Qty Match", "Invoiced Qty", "Debit Note Qty", "To Invoice Qty", "Invoiced Qty Match"]
    let rows = matchList.map((m, i) => {
        let fam = familiesMap.get(m.familyId) as Family;
        const toRecvPrimaryQty = m.orderedPrimary - m.receivedPrimary + m.returnedPrimary;
        const toRecvSecondaryQty = m.orderedSecondary - m.receivedSecondary + m.returnedSecondary;
        const toInvoicePrimaryQty = m.orderedPrimary - m.invoicedPrimary + m.debitedPrimary;
        const toInvoiceSecondaryQty = m.orderedSecondary - m.invoicedSecondary + m.debitedSecondary;
        return [
            `${i+1}.`,
            fam.code,
            fam.name,
            `${convertCentsToMoney(m.orderedPrimary)} ${uomsMap.get(fam.uomId)?.symbol}/${convertCentsToMoney(m.orderedSecondary)} ${uomsMap.get(m.vendorUomId)?.symbol}`,
            `${convertCentsToMoney(m.receivedPrimary)} ${uomsMap.get(fam.uomId)?.symbol}/${convertCentsToMoney(m.receivedSecondary)} ${uomsMap.get(m.vendorUomId)?.symbol}`,
            `${convertCentsToMoney(m.returnedPrimary)} ${uomsMap.get(fam.uomId)?.symbol}/${convertCentsToMoney(m.returnedSecondary)} ${uomsMap.get(m.vendorUomId)?.symbol}`,
            `${convertCentsToMoney(toRecvPrimaryQty)} ${uomsMap.get(fam.uomId)?.symbol}/${convertCentsToMoney(toRecvSecondaryQty)} ${uomsMap.get(m.vendorUomId)?.symbol}`,
            `${toRecvPrimaryQty == protoInt64.zero ? successIcon : failureIcon}/${toRecvSecondaryQty == protoInt64.zero ? successIcon : failureIcon}`,
            
            `${convertCentsToMoney(m.invoicedPrimary)} ${uomsMap.get(fam.uomId)?.symbol}/${convertCentsToMoney(m.invoicedSecondary)} ${uomsMap.get(m.vendorUomId)?.symbol}`,
            `${convertCentsToMoney(m.debitedPrimary)} ${uomsMap.get(fam.uomId)?.symbol}/${convertCentsToMoney(m.debitedSecondary)} ${uomsMap.get(m.vendorUomId)?.symbol}`,
            `${convertCentsToMoney(toInvoicePrimaryQty)} ${uomsMap.get(fam.uomId)?.symbol}/${convertCentsToMoney(toInvoiceSecondaryQty)} ${uomsMap.get(m.vendorUomId)?.symbol}`,

            `${toInvoicePrimaryQty == protoInt64.zero ? successIcon : failureIcon}/${toInvoiceSecondaryQty == protoInt64.zero ? successIcon : failureIcon}`,
        ];
    });

    let table = renderTableWithCSVHeadersAndRows({ title, headers_array: headers, rows_array: rows, responsive: false });
    return table
}

function renderFilters() {
    return `
    <div class="grid grid-cols-1 gap-6 mb-6">
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-gray-50 w-full shadow-lg rounded">
            <div class="rounded-t mb-0 px-0 border-0">
                <form class="">
                    <div class="grid grid-cols-12 place-items-center">
                        <div class="col-span-12 md:col-span-9 pl-4">
                            <div class="grid grid-cols-12">
                                <div class="col-span-12 md:col-span-3 pl-4">
                                    <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">First name</label>
                                    <div class="mt-2">
                                        <input type="text" name="first-name" id="first-name" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <div class="bg-gray-70 border rounded text-gray-600 text-sm font-medium pl-2 overflow-y-scroll max-h-8">
                                            <div class="hover:text-blue-500 cursor-pointer">Create landing page</div>
                                            <div class="hover:text-blue-500 cursor-pointer">Create landing page</div>
                                            <div class="hover:text-blue-500 cursor-pointer">Create landing page</div>
                                            <div class="hover:text-blue-500 cursor-pointer">Create landing page</div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    `
}
import { context } from "./../../router";
import { DebitNote, DebitNotesService, DebitNoteAncillaryParameters, Currency, Family, UnitOfMaterial, TaxGroup, PurchaseOrder, PurchasesOrdersService, PurchaseOrderAncillaryParameters, getClientForDebitNotesService, getClientForCurrenciesService, getClientForLocationsService, getClientForAssociatesService, getClientForUnitsOfMaterialsService, getClientForTaxGroupsService, getClientForPurchasesOrdersService } from "@kernelminds/scailo-sdk";
import { emptyDiv, getLinkForPurchaseOrder, renderFilterPrimarySubSection, renderInput, renderPageTitleSection, renderSpan, returnFormFromFields } from "../../ui";
import { convertBigIntTimestampToDate, convertCentsToMoney, decodeSLC, downloadData, internationalizeMoney, randomId, returnAssociateBadge, toTitleCase } from "../../utilities";
import { PromiseClient, Transport } from "@connectrpc/connect";
import { familiesListFromIDs } from "../../fetches";
import { getTransport } from "../../clients";

const downloadPDFButtonClass = "__download-pdf-btn";
// const downloadCSVButtonClass = "__download-csv-btn";
const goToPurchaseOrderButtonClass = "__go-to-purchase-order-btn";

export async function handleIndividualDebitNote(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    const transport = getTransport();
    const accessClient = getClientForDebitNotesService(transport);
    const [debitnote, ancillaryParams] = await Promise.all([
        accessClient.viewByUUID({ uuid: ctx.params.uuid }),
        accessClient.viewAncillaryParametersByUUID({ uuid: ctx.params.uuid }),
    ]);
    const currenciesClient = getClientForCurrenciesService(transport);
    
    const [currency] = await Promise.all([
        currenciesClient.viewByID({ id: debitnote.currencyId })
    ]);

    document.title = debitnote.approvalMetadata?.approvedOn! > 0 ? debitnote.finalRefNumber : debitnote.referenceId;

    let container = document.createElement("div");
    container.className = "overflow-x-auto";
    content.appendChild(container);

    const readonly = true;
    const { formGrid } = await getForm(debitnote, ancillaryParams, currency, readonly, accessClient, transport);

    container.appendChild(formGrid);

    // Setup PDF downloads
    let pdfDownloadButtons = container.getElementsByClassName(downloadPDFButtonClass);
    for (let i = 0; i < pdfDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>pdfDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await accessClient.downloadByUUID({ uuid: btn.getAttribute("data-uuid") || "" });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "pdf", file.name.replace(".pdf", ""));
        });
    }

    // Setup CSV downloads
    // let csvDownloadButtons = container.getElementsByClassName(downloadCSVButtonClass);
    // for (let i = 0; i < csvDownloadButtons.length; i++) {
    //     let btn = <HTMLButtonElement>csvDownloadButtons[i];
    //     btn.addEventListener("click", async evt => {
    //         evt.preventDefault();

    //         const originalButtonHTML = btn.innerHTML;
    //         btn.disabled = true;
    //         btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
    //         let file = await accessClient.downloadAsCSV({ uuid: btn.getAttribute("data-uuid") || "" });

    //         btn.disabled = false;
    //         btn.innerHTML = originalButtonHTML;

    //         downloadData(file.content, "csv", file.name.replace(".csv", ""));
    //     });
    // }

}

async function renderReferencesSection(debitnote: DebitNote, ancillaryParams: DebitNoteAncillaryParameters, purchaseOrder: PurchaseOrder, readonly: boolean, accessClient: PromiseClient<typeof DebitNotesService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "References", titleMdColSpan: 3 });

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Initial Reference", inputType: "text", dataMapper: "referenceId", dataType: "string", value: debitnote.referenceId, mdColSpan: 6, helpText: "Initial Reference of the record." }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Final Reference", inputType: "text", dataMapper: "finalRefNumber", dataType: "string", value: debitnote.finalRefNumber, mdColSpan: 6, helpText: "Final Reference of the record that is automatically generated." }));
    // Display status as well
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Status", inputType: "text", dataMapper: "status", dataType: "string", value: toTitleCase(decodeSLC(debitnote.status)), mdColSpan: 3, helpText: "Status of the record." }));

    contentGrid.appendChild(emptyDiv());
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Purchase Order Reference", inputType: "text", dataMapper: "refId", dataType: "string", value: purchaseOrder.approvalMetadata?.approvedOn! > 0 ? purchaseOrder.finalRefNumber : purchaseOrder.referenceId, mdColSpan: 6, helpText: "Reference of the Purchase Order." }));

    return grid;
}

async function renderBuyerAndConsigneeSection(debitnote: DebitNote, ancillaryParams: PurchaseOrderAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof DebitNotesService>, transport: Transport): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Buyer Information", titleMdColSpan: 3 });

    // Get buyer and consignee
    const locationsAccessClient = getClientForLocationsService(transport);
    const [buyer, consignee] = await Promise.all([
        locationsAccessClient.viewByUUID({ uuid: ancillaryParams.buyerLocationUuid }),
        locationsAccessClient.viewByUUID({ uuid: ancillaryParams.consigneeLocationUuid }),
    ]);

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Buyer", inputType: "text", dataMapper: "buyerLocationId", dataType: "string", value: `${buyer.code.length > 0 ? `(${buyer.code})` : ''} ${buyer.name}`, mdColSpan: 6, helpText: "The information of the buyer." }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Consignee", inputType: "text", dataMapper: "consigneeLocationId", dataType: "string", value: `${consignee.code.length > 0 ? `(${consignee.code})` : ''} ${consignee.name}`, mdColSpan: 6, helpText: "The information of the consignee." }));

    return grid;
}

async function renderDatesSection(debitnote: DebitNote, ancillaryParams: DebitNoteAncillaryParameters, purchaseOrder: PurchaseOrder, readonly: boolean, accessClient: PromiseClient<typeof DebitNotesService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Important Dates", titleMdColSpan: 3 });

    // Dates (creation and approval)
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Debit Note Created On", inputType: "text", dataMapper: "createdAt", dataType: "string", value: convertBigIntTimestampToDate(debitnote.metadata?.createdAt!), mdColSpan: 3, helpText: "The creation date of this Debit Note." }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Debit Note Approved On", inputType: "text", dataMapper: "approvedOn", dataType: "string", value: convertBigIntTimestampToDate(debitnote.approvalMetadata?.approvedOn!), mdColSpan: 3, helpText: "The approval date of this Debit Note." }));

    contentGrid.appendChild(emptyDiv());

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Purchase Order Created On", inputType: "text", dataMapper: "createdAt", dataType: "string", value: convertBigIntTimestampToDate(purchaseOrder.metadata?.createdAt!), mdColSpan: 3, helpText: "The creation date of the associated Purchase Order." }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Purchase Order Approved On", inputType: "text", dataMapper: "approvedOn", dataType: "string", value: convertBigIntTimestampToDate(purchaseOrder.approvalMetadata?.approvedOn!), mdColSpan: 3, helpText: "The approval date of the associated Purchase Order." }));

    return grid;
}

async function renderPaymentTermsSection(purchaseorder: PurchaseOrder, currency: Currency, readonly: boolean): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Payment Terms", titleMdColSpan: 3 });

    // Advance Payment
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Advance Payment", inputType: "text", dataMapper: "paymentAdvance", dataType: "string", value: `${currency.symbol} ${internationalizeMoney(parseFloat(convertCentsToMoney(purchaseorder.paymentAdvance)))}`, mdColSpan: 3, helpText: `The advance amount (in ${currency.symbol}) that is supposed to be paid.` }));

    // Payment Cycle
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Payment Cycle", inputType: "text", dataMapper: "paymentCycleInDays", dataType: "string", value: `${purchaseorder.paymentCycleInDays.toString()} days`, mdColSpan: 3, helpText: `The payment cycle of this Purchase Order.` }));

    return grid;
}

async function renderCostsSection(debitnote: DebitNote, currency: Currency, readonly: boolean, accessClient: PromiseClient<typeof DebitNotesService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Costs", titleMdColSpan: 3 });

    // Miscellaneous Costs
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Miscellaneous Cost", inputType: "text", dataMapper: "miscellaneousCost", dataType: "string", value: `${currency.symbol} ${internationalizeMoney(parseFloat(convertCentsToMoney(debitnote.miscellaneousCost)))}`, mdColSpan: 4, helpText: `Any miscellaneous costs (in ${currency.symbol}) on the Debit Note.` }));

    // Overall Discount
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Overall Discount", inputType: "text", dataMapper: "overallDiscount", dataType: "string", value: `${currency.symbol} ${internationalizeMoney(parseFloat(convertCentsToMoney(debitnote.overallDiscount)))}`, mdColSpan: 4, helpText: `The overall discount (in ${currency.symbol}) on the Debit Note.` }));

    // Round Off
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Round Off", inputType: "text", dataMapper: "roundOff", dataType: "string", value: `${currency.symbol} ${internationalizeMoney(parseFloat(convertCentsToMoney(debitnote.roundOff)))}`, mdColSpan: 4, helpText: `The round off (in ${currency.symbol}) applied on the Debit Note.` }));

    // Total
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Net Total", inputType: "text", dataMapper: "totalValue", dataType: "string", value: `${currency.symbol} ${internationalizeMoney(debitnote.totalValue)}`, mdColSpan: 6, helpText: `The net total (inclusive of applicable taxes, in ${currency.symbol}) of the Debit Note.` }));

    return grid;
}

async function renderContactsSection(purchaseorder: PurchaseOrder, readonly: boolean, accessClient: PromiseClient<typeof PurchasesOrdersService>, transport: Transport): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Contacts", titleMdColSpan: 3 });

    const associatesClient = getClientForAssociatesService(transport);

    let contacts = (await accessClient.viewPurchaseOrderContacts({ uuid: purchaseorder.metadata?.uuid })).list;
    let purchaseOrderAssociates = await Promise.all(contacts.map(c => c.associateUuid).map(uuid => {
        return associatesClient.viewByUUID({ uuid });
    }));

    purchaseOrderAssociates.forEach(contact => {
        if (contact) {
            contentGrid.appendChild(returnAssociateBadge(contact));
        }
    });

    return grid;
}

async function renderDynamicFormsSection(debitnote: DebitNote, readonly: boolean, accessClient: PromiseClient<typeof DebitNotesService>): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    if (debitnote.formData.length == 0) {
        return container;
    }

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Other Details` }));
    container.appendChild(containerTitle);

    let formFields = await returnFormFromFields(debitnote.formData, "debit-notes", true, readonly);

    container.appendChild(formFields);

    return container;
}

async function renderFamiliesSection(debitnote: DebitNote, currency: Currency, readonly: boolean, accessClient: PromiseClient<typeof DebitNotesService>, transport: Transport): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Line Items` }));

    container.appendChild(containerTitle);

    let familiesMap: Map<bigint, Family> = new Map();
    (await familiesListFromIDs(Array.from(new Set(debitnote.list.map(p => p.familyId))))).forEach(family => {
        familiesMap.set(family.metadata?.id!, family);
    });
    
    const uomClient = getClientForUnitsOfMaterialsService(transport);
    let unitsList = debitnote.list.map(p => p.vendorUomId);
    familiesMap.forEach(family => {
        unitsList.push(family.uomId);
    });

    let uomsList = await Promise.all(Array.from(new Set(unitsList)).map(id => {
        return uomClient.viewByID({ id });
    }));
    let uomsMap: Map<bigint, UnitOfMaterial> = new Map();

    uomsList.forEach(uom => {
        uomsMap.set(uom.metadata!.id, uom);
    });

    const taxGroupClient = getClientForTaxGroupsService(transport);
    let taxGroupIDsList = debitnote.list.map(p => p.taxGroupId);
    familiesMap.forEach(family => {
        taxGroupIDsList.push(family.taxGroupId);
    });

    let taxGroupsList = await Promise.all(Array.from(new Set(taxGroupIDsList)).map(id => {
        return taxGroupClient.viewByID({ id });
    }));
    let taxGroupsMap: Map<bigint, TaxGroup> = new Map();

    taxGroupsList.forEach(taxGroup => {
        taxGroupsMap.set(taxGroup.metadata!.id, taxGroup);
    });

    debitnote.list.forEach((item, index) => {
        let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: `${index + 1}.`, titleMdColSpan: 1 });

        let fam = familiesMap.get(item.familyId) || new Family();

        let pretaxTotal = parseInt(String(item.vendorQuantity)) * parseInt(String(item.vendorUnitPrice)) / 100.0;

        const postDiscountTotal = pretaxTotal;

        let taxgroup = taxGroupsMap.get(item.taxGroupId) || new TaxGroup();
        let taxAmt = postDiscountTotal * taxgroup.cumulativeTaxPercentage / 100.0 / 100.0;
        let total = postDiscountTotal + taxAmt;

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Material Code", value: `${fam.code}`, mdColSpan: 3, helpText: `The code of the material` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Material Name", value: `${fam.name}`, mdColSpan: 9, helpText: `The name of the material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Vendor Quantity & UoM", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(item.vendorQuantity)))} (${uomsMap.get(item.vendorUomId)?.symbol}) ${uomsMap.get(item.vendorUomId)?.name}`, mdColSpan: 3, helpText: `The quantity of the material in Vendor's unit of material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Buyer Quantity & UoM", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(item.internalQuantity)))} (${uomsMap.get(fam.uomId)?.symbol}) ${uomsMap.get(fam.uomId)?.name}`, mdColSpan: 3, helpText: `The quantity of the material in Buyer's unit of material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Unit Price", value: `${currency.symbol} ${internationalizeMoney(parseFloat(convertCentsToMoney(item.vendorUnitPrice)))}/${uomsMap.get(item.vendorUomId)?.symbol}`, mdColSpan: 3, helpText: `The price per unit of the material in Vendor's unit` }));
        
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Tax", value: `${taxGroupsMap.get(item.taxGroupId)?.name}`, mdColSpan: 3, helpText: `The applicable tax group` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Pre-Tax Total", value: `${currency.symbol} ${internationalizeMoney(postDiscountTotal / 100.0)}`, mdColSpan: 3, helpText: `The pre tax amount (after applicable discount percentage)` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Tax Amount", value: `${currency.symbol} ${internationalizeMoney(taxAmt / 100.0)}`, mdColSpan: 3, helpText: `The computed tax amount` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Net Total", value: `${currency.symbol} ${internationalizeMoney(total / 100.0)}`, mdColSpan: 3, helpText: `The total amount (inclusive of taxes)` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "HSN/SAC Code", value: `${fam.hsnSacCode}`, mdColSpan: 3, helpText: `The HSN/SAC code of the material` }));

        if (item.specifications.length > 0) {
            contentGrid.appendChild(renderSpan({ id: randomId(), label: "Specifications", value: `${item.specifications}`, mdColSpan: 12, helpText: `Any additional specifications that are provided to the Vendor` }));
        }

        container.appendChild(grid);

        let hr = document.createElement("hr");
        hr.classList.add("m-5");
        container.appendChild(hr);
    });

    return container;
}

async function getForm(debitnote: DebitNote, ancillaryParams: DebitNoteAncillaryParameters, currency: Currency, readonly: boolean, accessClient: PromiseClient<typeof DebitNotesService>, transport: Transport) {
    let formGrid = document.createElement("div");
    formGrid.className = "grid grid-cols-1 gap-6 mb-6";
    const formId = randomId();

    const purchasesOrdersClient = getClientForPurchasesOrdersService(transport);
    const [purchaseOrder, purchaseOrderAncillaryParams] = await Promise.all([
        purchasesOrdersClient.viewByUUID({ uuid: ancillaryParams.refUuid }),
        purchasesOrdersClient.viewAncillaryParametersByUUID({ uuid: ancillaryParams.refUuid }),
    ]);

    const [
        referencesSection,
        buyerAndConsigneeSection,
        datesSection,
        paymentTermsSection,
        costsSection,
        contactsSection,
        dynamicFormsSection,
        familiesSection,
    ] = await Promise.all([
        renderReferencesSection(debitnote, ancillaryParams, purchaseOrder, readonly, accessClient),
        renderBuyerAndConsigneeSection(debitnote, purchaseOrderAncillaryParams, readonly, accessClient, transport),
        renderDatesSection(debitnote, ancillaryParams, purchaseOrder, readonly, accessClient),
        renderPaymentTermsSection(purchaseOrder, currency, readonly),
        renderCostsSection(debitnote, currency, readonly, accessClient),
        renderContactsSection(purchaseOrder, readonly, purchasesOrdersClient, transport),
        renderDynamicFormsSection(debitnote, readonly, accessClient),
        renderFamiliesSection(debitnote, currency, readonly, accessClient, transport)
    ]);

    let buttonContainer = document.createElement("div");
    buttonContainer.classList.add("col-span-12", "flex", "justify-center", "overflow-x-auto");

    // Download PDF button
    let downloadPDFButton = document.createElement("button");
    downloadPDFButton.id = randomId();
    downloadPDFButton.className = `btn btn-success btn-outline btn-sm ${downloadPDFButtonClass} mr-4`;
    downloadPDFButton.innerText = "Download PDF";
    downloadPDFButton.setAttribute("data-uuid", debitnote.metadata?.uuid!);
    downloadPDFButton.setAttribute("data-name", debitnote.approvalMetadata?.approvedOn! > 0 ? debitnote.finalRefNumber : debitnote.referenceId);
    buttonContainer.appendChild(downloadPDFButton);

    // Download CSV button
    // let downloadCSVButton = document.createElement("button");
    // downloadCSVButton.id = randomId();
    // downloadCSVButton.className = `btn btn-success btn-outline btn-sm ${downloadCSVButtonClass} mr-4`;
    // downloadCSVButton.innerText = "Download CSV";
    // downloadCSVButton.setAttribute("data-uuid", debitnote.metadata?.uuid!);
    // downloadCSVButton.setAttribute("data-name", debitnote.approvalMetadata?.approvedOn! > 0 ? debitnote.finalRefNumber : debitnote.referenceId);
    // buttonContainer.appendChild(downloadCSVButton);

    let goToPurchaseOrderButton = document.createElement("a");
    goToPurchaseOrderButton.className = `btn btn-info btn-outline btn-sm ${goToPurchaseOrderButtonClass} mr-4`;
    goToPurchaseOrderButton.innerText = "Go To Purchase Order";
    goToPurchaseOrderButton.href = getLinkForPurchaseOrder(purchaseOrder.metadata!.uuid)
    buttonContainer.appendChild(goToPurchaseOrderButton);

    formGrid.innerHTML = `
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
            <div class="rounded-t mb-0 px-0 border-0">
                ${renderPageTitleSection({ title: `Debit Note: ` + document.title }).outerHTML}
                ${buttonContainer.outerHTML}
            </div>
            <form id="${formId}">
                <hr class="m-5">
                ${referencesSection.outerHTML}
                <hr class="m-5">
                ${buyerAndConsigneeSection.outerHTML}
                <hr class="m-5">
                ${datesSection.outerHTML}
                <hr class="m-5">
                ${paymentTermsSection.outerHTML}
                <hr class="m-5">
                ${costsSection.outerHTML}
                <hr class="m-5">
                ${contactsSection.outerHTML}
                <hr class="m-5">
            </form>
        </div>
        ${dynamicFormsSection.childElementCount > 0 ? 
            `<div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
                ${dynamicFormsSection.outerHTML}
            </div>` 
        : ''}
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
            ${familiesSection.outerHTML}
        </div>
    `;

    return { formGrid }
}

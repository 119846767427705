import { createPromiseClient, PromiseClient, Transport } from "@connectrpc/connect";
import { getTransport } from "./clients";
import { ApprovalMetadata, BOOL_FILTER, CurrenciesService, EmployeeMetadata, Family, FORM_TYPE, FormsSectionsService, getClientForFamiliesService, getClientForFormsFieldsService, getClientForRolesService, getClientForUsersService, GoodsReceiptsService, IdentifierUUID, OutwardJobsService, PURCHASE_ORDER_ITEM_SORT_KEY, PURCHASE_ORDER_ITEM_STATUS, PurchaseOrderItem, PurchasesOrdersService, PurchasesReturnsService, SORT_ORDER, User, VENDOR_ITEM_SORT_KEY, VENDOR_ITEM_STATUS, VendorItem, VendorItemsSearchRequest, VendorsService } from "@kernelminds/scailo-sdk";
import { PartialMessage, protoInt64 } from "@bufbuild/protobuf";

/**Returns the currency with the given id */
export async function currency(id: bigint, client: PromiseClient<typeof CurrenciesService>) {
    return client.viewByID({ id });
}

/**Returns the inventory statistics of the purchase order with the given uuid */
export async function purchaseorderInventoryStatistics(uuid: string, client: PromiseClient<typeof PurchasesOrdersService>) {
    return client.viewInventoryStatistics({ uuid });
}

/**Returns the purchase family associated with the given purchaseorderID and familyID */
export async function purchaseorderFamily(purchaseorderID: bigint, familyID: bigint, client: PromiseClient<typeof PurchasesOrdersService>): Promise<PurchaseOrderItem> {
    let resp = <PurchaseOrderItem[]>[];
    resp = (await client.viewPaginatedApprovedPurchaseOrderItems({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        count: protoInt64.parse(1),
        sortKey: PURCHASE_ORDER_ITEM_SORT_KEY.PURCHASE_ORDER_ITEM_SORT_KEY_MODIFIED_AT,
        sortOrder: SORT_ORDER.DESCENDING,
        status: PURCHASE_ORDER_ITEM_STATUS.PURCHASE_ORDER_ITEM_STATUS_APPROVED,
        purchaseOrderId: purchaseorderID,
        familyId: familyID,
        searchKey: "",
    })).payload;
    if (resp.length == 0) {
        return new PurchaseOrderItem();
    }
    return resp[0];
}

/**Returns the billing statistics of the purchase order with the given uuid */
export async function purchaseorderBillingStatistics(uuid: string, client: PromiseClient<typeof PurchasesOrdersService>) {
    return client.viewBillingStatistics({ uuid });
}

/**Returns the inventory match of the purchase order with the given uuid */
export async function purchaseorderInventoryMatch(uuid: string, client: PromiseClient<typeof PurchasesOrdersService>) {
    return (await client.viewInventoryMatch({ uuid })).list;
}

/**Returns if the goods receipt has been billed */
export async function goodsreceiptBillingStatus(goodsreceiptUuid: string, client: PromiseClient<typeof GoodsReceiptsService>) {
    return (await client.isBilled({ uuid: goodsreceiptUuid })).value;
}

/**Returns if the purchase return has been billed */
export async function purchasereturnBillingStatus(goodsreceiptID: bigint, client: PromiseClient<typeof PurchasesReturnsService>) {
    return (await client.isBilled({ id: goodsreceiptID })).value;
}

/**Returns if the outward job can be marked as completed */
export async function outwardjobCompletableStatus(outwardjobID: bigint, client: PromiseClient<typeof OutwardJobsService>): Promise<boolean> {
    return (await client.isCompletable({ id: outwardjobID })).value;
}

/**Returns if the outward job has been dispatched */
export async function outwardjobDispatchedStatus(outwardjobID: bigint, client: PromiseClient<typeof OutwardJobsService>): Promise<boolean> {
    return (await client.isDispatched({ id: outwardjobID })).value;
}

/**Returns if the outward job has been ordered */
export async function outwardjobOrderedStatus(outwardjobID: bigint, client: PromiseClient<typeof OutwardJobsService>): Promise<boolean> {
    return (await client.isOrdered({ id: outwardjobID })).value;
}

/**Returns the list of families from the given IDs */
export async function familiesListFromIDs(ids: bigint[]): Promise<Family[]> {
    let readClient = getClientForFamiliesService(getTransport());
    return (await readClient.viewFromIDs({
        list: ids
    })).list;
}

/**Returns the family info associated with a particular vendor */
export async function vendorAssociatedFamilies(filter: PartialMessage<VendorItemsSearchRequest>, client: PromiseClient<typeof VendorsService>): Promise<VendorItem[]> {
    return (await client.searchItemsWithPagination({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        count: protoInt64.parse(-1),
        sortKey: VENDOR_ITEM_SORT_KEY.VENDOR_ITEM_SORT_KEY_MODIFIED_AT,
        sortOrder: SORT_ORDER.DESCENDING,
        status: VENDOR_ITEM_STATUS.VENDOR_ITEM_STATUS_APPROVED,
        ...filter,
    })).payload;
}

/**Returns an empty user */
export function emptyUser() {
    return new User({ name: "-", username: "-", code: "-", metadata: new EmployeeMetadata({ id: protoInt64.zero }), approvalMetadata: new ApprovalMetadata({ approvedByUserId: protoInt64.zero, approvedOn: protoInt64.zero, approverRoleId: protoInt64.zero }) });
}

/**Returns the users map from the given list of usernames */
export async function usersMapFromUsernames(usernames: string[]): Promise<Map<string, User>> {
    let readClient = getClientForUsersService(getTransport());
    let usersMap: Map<string, User> = new Map();

    let users = (await readClient.viewFromUsernames({ list: Array.from(new Set(usernames)) })).list;
    users.forEach(user => {
        usersMap.set(user.username, user);
    });
    return usersMap;
}

/**Returns the users map from the given list of IDs */
export async function usersMapFromIDs(userIDs: bigint[]): Promise<Map<bigint, User>> {
    let readClient = getClientForUsersService(getTransport());
    let usersMap: Map<bigint, User> = new Map();

    let users = (await readClient.viewFromIDs({ list: Array.from(new Set(userIDs)) })).list;
    users.forEach(user => {
        usersMap.set(user.metadata?.id!, user);
    });
    // Set for 0
    usersMap.set(protoInt64.zero, emptyUser());
    return usersMap;
}

/**Returns the users map from the given list of UUIDs */
export async function usersMapFromUUIDs(userUUIDs: string[]): Promise<Map<string, User>> {
    let readClient = getClientForUsersService(getTransport());
    let usersMap: Map<string, User> = new Map();

    let users = (await readClient.viewFromUUIDs({ list: Array.from(new Set(userUUIDs)).map(uuid => new IdentifierUUID({ uuid: uuid })) })).list;
    users.forEach(user => {
        usersMap.set(user.metadata?.uuid!, user);
    });
    return usersMap;
}

/**Returns the form section with the given id */
export async function formSection(id: bigint, client: PromiseClient<typeof FormsSectionsService>) {
    return (await client.viewByID({ id }));
}

/**Returns all the forms fields of the given formType */
export async function getFormsFields(formType: FORM_TYPE, transport: Transport) {
    let readClient = getClientForFormsFieldsService(transport);
    return ((await readClient.filter({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        count: protoInt64.parse(-1),
        type: formType,
    }))).list;
}

/**Returns the configured role for self */
export async function roleSelf(transport: Transport) {
    let readClient = getClientForRolesService(transport);
    return readClient.viewSelf({});
}

/**Makes a POST request without increasing the request count, and returns the result */
export async function PostData(url: string, body: Object, respType?: "json" | "arraybuffer"): Promise<Object> {
    if (respType == null || respType == undefined) {
        respType = "json";
    }

    let resp = await makeReq(url, body, respType);
    return resp
}

/**Internal function that makes the actual request */
async function makeReq(url: string, body: Object, respType: "json" | "arraybuffer"): Promise<Object> {
    var headers = <HeadersInit>{
        'Content-Type': 'application/json',
    }

    return fetch(`${location.protocol}//${location.host}` + url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
    }).then(response => {
        if (response.status == 200) {
            if (respType == "json") {
                return response.json();
            } else if (respType == "arraybuffer") {
                return response.arrayBuffer();
            }
        } else {
            throw new Error(response.statusText);
        }
    }).catch((e) => {
        throw e
    });
}
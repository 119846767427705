import { outwardJobFIMRFiltersMenu, outwardJobFIMRIndividualMenu, outwardJobFIMRInsightsMenu } from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router, context } from "../../router";
import { handleOutwardJobFreeIssueMaterialReturnFilters } from "./freeissuereturns.filters";
import { handleOutwardJobFreeIssueMaterialReturnInsights } from "./freeissuereturns.insights";
import { handleIndividualOutwardJobFreeIssueMaterialReturn } from "./individual";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(outwardJobFIMRFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, outwardJobFIMRFiltersMenu)) {
            handleOutwardJobFreeIssueMaterialReturnFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });

    r.add(outwardJobFIMRInsightsMenu.href, async (ctx) => {
        if (await interceptPage(ctx, outwardJobFIMRInsightsMenu)) {
            handleOutwardJobFreeIssueMaterialReturnInsights(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(outwardJobFIMRIndividualMenu.href, async (ctx) => {
        if (await interceptPage(ctx, outwardJobFIMRFiltersMenu)) {
            handleIndividualOutwardJobFreeIssueMaterialReturn(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}
import { BOOL_FILTER, CurrenciesService, Currency, FamiliesService, getClientForCurrenciesService, getClientForUnitsOfMaterialsService, UnitOfMaterial, OUTWARD_JOB_INWARD_ITEM_STATUS, OutwardJob, OutwardJobInwardItem, OutwardJobsService, Family, OUTWARD_JOB_OUTWARD_ITEM_STATUS, OutwardJobOutwardItem } from "@kernelminds/scailo-sdk";
import ApexCharts from 'apexcharts';
import { convertBigIntTimestampToDate, convertCentsToMoney, dateToStr, decodeSLC, destroyAlert, internationalizeMoney, randomId, renderTableWithCSVHeadersAndRows, round, showFailureAlert, showSuccessAlert, toTitleCase } from "../../utilities";
import { PromiseClient, Transport } from "@connectrpc/connect";
import { protoInt64 } from "@bufbuild/protobuf";
import { getLinkForOutwardJob } from "../../ui";

// Inward Items
export async function renderOutwardJobInwardItemTrends(familyId: bigint, familyUuid: string, accessClient: PromiseClient<typeof OutwardJobsService>, familiesAccessClient: PromiseClient<typeof FamiliesService>, transport: Transport) {
    let notificationId = showSuccessAlert(`Gathering trends`, { timeoutInMs: 100000 });
    let records = (await accessClient.searchInwardItemsWithPagination({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        count: protoInt64.parse(-1),
        status: OUTWARD_JOB_INWARD_ITEM_STATUS.OUTWARD_JOB_INWARD_ITEM_STATUS_ANY_UNSPECIFIED,
        familyId: familyId,
    })).payload;

    let family = await familiesAccessClient.viewEssentialByUUID({ uuid: familyUuid });

    let outwardJobs = await Promise.all(Array.from(new Set(records.map(r => r.outwardJobUuid))).map(r => {
        return accessClient.viewByUUID({ uuid: r });
    }));
    const unitOfMaterialAccessClient = getClientForUnitsOfMaterialsService(transport);
    const uoms = (await unitOfMaterialAccessClient.viewFromIDs({ list: Array.from(new Set([family.uomId])) })).list;

    let outwardJobsMap = new Map(outwardJobs.map(r => [r.metadata?.uuid!, r]));
    let unitsMap = new Map(uoms.map(r => [r.metadata?.id!, r]));

    destroyAlert(notificationId);

    // Table
    const headers = ["S.No.", "Outward Job", "Status", "UoM", "Internal Qty", "Created On"];
    let rows = records.map((m, i) => {
        let outwardjob = outwardJobsMap.get(m.outwardJobUuid) || new OutwardJob();
        let uom = unitsMap.get(family.uomId) || new UnitOfMaterial();
        let outwardJobLink = document.createElement("a");
        outwardJobLink.target = "_blank";
        outwardJobLink.className = `btn btn-wide btn-outline btn-primary text-white truncate`;
        outwardJobLink.innerHTML = `<i class='bx bx-door-open'></i> ${outwardjob.approvalMetadata?.approvedOn! > 0 ? outwardjob.finalRefNumber : outwardjob.referenceId}`;
        outwardJobLink.href = getLinkForOutwardJob(outwardjob.metadata?.uuid!);
        outwardJobLink.innerText = outwardjob.approvalMetadata?.approvedOn! > 0 ? outwardjob.finalRefNumber : outwardjob.referenceId;

        return [
            `${i+1}.`,
            outwardJobLink.outerHTML,
            toTitleCase(decodeSLC(outwardjob.status)),
            `(${uom.symbol}) ${uom.name}`,
            convertCentsToMoney(m.internalQuantity),
            convertBigIntTimestampToDate(m.metadata?.createdAt!),
        ];
    });

    let table = renderTableWithCSVHeadersAndRows({ title: `Trends for (${family.code}) ${family.name}`, headers_array: headers, rows_array: rows, responsive: false });

    if (!records.length) {
        showFailureAlert("No Records Found");
        return;
    }

    const chartsDivId = randomId();

    // Render the content inside a dialog
    let dialog = document.createElement("dialog");
    dialog.className = "modal";
    dialog.id = randomId();
    dialog.innerHTML = `
    <div class="max-w-full modal-box text-gray-900 bg-gray-200">
        <form method="dialog" class="m-3">
            <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
        </form>
        <div class="overflow-y-auto max-h-96">${table.table.outerHTML}</div>
        
        <div id="${chartsDivId}" class="mt-8"></div>
    </div>
    `;

    document.body.appendChild(dialog);
    dialog.showModal();
    dialog.addEventListener("close", () => {
        document.body.removeChild(dialog);
    });

    renderInwardItemsChart(records, family, outwardJobsMap, unitsMap, document.getElementById(chartsDivId) as HTMLDivElement);
}

function renderInwardItemsChart(
    records: OutwardJobInwardItem[], 
    family: Family,
    outwardJobsMap: Map<string, OutwardJob>,
    unitsMap: Map<bigint, UnitOfMaterial>,
    chartsDiv: HTMLDivElement,
) {
    if (records.length == 0) {
        return;
    }

    let localChartDiv = document.createElement("div");
    localChartDiv.classList.add("col-span-12");
    chartsDiv.appendChild(localChartDiv);

    const commonTheme = {
        palette: 'palette2',
    };

    let cumulativeVendorQtySeriesData = <number[]>[];
    let incrementingInternalQty = 0;

    const divisor = 100;
    
    records.forEach(record => {
        incrementingInternalQty += parseInt(String(record.internalQuantity))/100;
        cumulativeVendorQtySeriesData.push(incrementingInternalQty);
    });

    let options = {
        title: {
            text: `Total Records: ${records.length}`,
            align: "left",
            margin: 10,
            offsetX: 0,
            offsetY: -10,
            floating: true,
            style: {
                fontSize: "20px",
                fontWeight: "light",
                color: "#263238"
            },
        },
        theme: commonTheme,
        series: [
            // Area Series
            {
                name: "Cumulative Qty",
                type: "area",
                data: cumulativeVendorQtySeriesData
            },
            // Line Series
            {
                name: "Individual Qty",
                type: "line",
                data: records.map(record => parseFloat(round(parseInt(String((record.internalQuantity)))/divisor)))
            }
        ],
        chart: {
            height: 500,
            type: "line",
        },
        stroke: {
            curve: "smooth"
        },
        fill: {
            type: "solid",
            opacity: [0.25, 1],
        },
        labels: records.map(record => {
            let outwardJob = outwardJobsMap.get(record.outwardJobUuid);
            return outwardJob?.approvalMetadata?.approvedOn! > 0 ? outwardJob?.finalRefNumber : outwardJob?.referenceId;
        }),
        markers: {
            size: 2
        },
        legend: {
            position: "top"
        },
        yaxis: [
            {
                title: {
                    text: "Cumulative Value",
                },
            },
            {
                opposite: true,
                title: {
                    text: "Individual Record Value",
                },
            },
        ],

        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                let record = records[dataPointIndex];
                let outwardJob = outwardJobsMap.get(record.outwardJobUuid);
                let uom = unitsMap.get(family.uomId!);
                return `
                    <ul style='background-color: #424242; color: #F5F5F5; padding: 20px; margin: 0px;'>
                        <li>Index: ${dataPointIndex + 1}</li>
                        <li>Outward Job: ${outwardJob?.approvalMetadata?.approvedOn! > 0 ? outwardJob?.finalRefNumber : outwardJob?.referenceId}</li>
                        <li>Item Qty: ${internationalizeMoney(parseInt(String(record.internalQuantity))/100)} ${uom?.symbol}</li>
                        <li>Approved Date: ${convertBigIntTimestampToDate(record.approvalMetadata!.approvedOn)}</li>
                        <li>Cumulative Qty: ${internationalizeMoney(cumulativeVendorQtySeriesData[dataPointIndex])} ${uom?.symbol}</li>
                    </ul>
                `;
            }
        }
    };

    let chart = new ApexCharts(localChartDiv, options);
    chart.render();
}

// Outward Items
export async function renderOutwardJobOutwardItemTrends(familyId: bigint, familyUuid: string, accessClient: PromiseClient<typeof OutwardJobsService>, familiesAccessClient: PromiseClient<typeof FamiliesService>, transport: Transport) {
    let notificationId = showSuccessAlert(`Gathering trends`, { timeoutInMs: 100000 });
    let records = (await accessClient.searchOutwardItemsWithPagination({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        count: protoInt64.parse(-1),
        status: OUTWARD_JOB_OUTWARD_ITEM_STATUS.OUTWARD_JOB_OUTWARD_ITEM_STATUS_ANY_UNSPECIFIED,
        familyId: familyId,
    })).payload;

    let family = await familiesAccessClient.viewEssentialByUUID({ uuid: familyUuid });

    let outwardJobs = await Promise.all(Array.from(new Set(records.map(r => r.outwardJobUuid))).map(r => {
        return accessClient.viewByUUID({ uuid: r });
    }));
    const unitOfMaterialAccessClient = getClientForUnitsOfMaterialsService(transport);
    const uoms = (await unitOfMaterialAccessClient.viewFromIDs({ list: Array.from(new Set([family.uomId])) })).list;

    let outwardJobsMap = new Map(outwardJobs.map(r => [r.metadata?.uuid!, r]));
    let unitsMap = new Map(uoms.map(r => [r.metadata?.id!, r]));

    destroyAlert(notificationId);

    // Table
    const headers = ["S.No.", "Outward Job", "Status", "UoM", "Internal Qty", "Created On"];
    let rows = records.map((m, i) => {
        let outwardjob = outwardJobsMap.get(m.outwardJobUuid) || new OutwardJob();
        let uom = unitsMap.get(family.uomId) || new UnitOfMaterial();
        let outwardJobLink = document.createElement("a");
        outwardJobLink.target = "_blank";
        outwardJobLink.className = `btn btn-wide btn-outline btn-primary text-white truncate`;
        outwardJobLink.innerHTML = `<i class='bx bx-door-open'></i> ${outwardjob.approvalMetadata?.approvedOn! > 0 ? outwardjob.finalRefNumber : outwardjob.referenceId}`;
        outwardJobLink.href = getLinkForOutwardJob(outwardjob.metadata?.uuid!);
        outwardJobLink.innerText = outwardjob.approvalMetadata?.approvedOn! > 0 ? outwardjob.finalRefNumber : outwardjob.referenceId;

        return [
            `${i+1}.`,
            outwardJobLink.outerHTML,
            toTitleCase(decodeSLC(outwardjob.status)),
            `(${uom.symbol}) ${uom.name}`,
            convertCentsToMoney(m.internalQuantity),
            convertBigIntTimestampToDate(m.metadata?.createdAt!),
        ];
    });

    let table = renderTableWithCSVHeadersAndRows({ title: `Trends for (${family.code}) ${family.name}`, headers_array: headers, rows_array: rows, responsive: false });

    if (!records.length) {
        showFailureAlert("No Records Found");
        return;
    }

    const chartsDivId = randomId();

    // Render the content inside a dialog
    let dialog = document.createElement("dialog");
    dialog.className = "modal";
    dialog.id = randomId();
    dialog.innerHTML = `
    <div class="max-w-full modal-box text-gray-900 bg-gray-200">
        <form method="dialog" class="m-3">
            <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
        </form>
        <div class="overflow-y-auto max-h-96">${table.table.outerHTML}</div>
        
        <div id="${chartsDivId}" class="mt-8"></div>
    </div>
    `;

    document.body.appendChild(dialog);
    dialog.showModal();
    dialog.addEventListener("close", () => {
        document.body.removeChild(dialog);
    });

    renderOutwardItemsChart(records, family, outwardJobsMap, unitsMap, document.getElementById(chartsDivId) as HTMLDivElement);
}

function renderOutwardItemsChart(
    records: OutwardJobOutwardItem[], 
    family: Family,
    outwardJobsMap: Map<string, OutwardJob>,
    unitsMap: Map<bigint, UnitOfMaterial>,
    chartsDiv: HTMLDivElement,
) {
    if (records.length == 0) {
        return;
    }

    let localChartDiv = document.createElement("div");
    localChartDiv.classList.add("col-span-12");
    chartsDiv.appendChild(localChartDiv);

    const commonTheme = {
        palette: 'palette2',
    };

    let cumulativeVendorQtySeriesData = <number[]>[];
    let incrementingInternalQty = 0;

    const divisor = 100;
    
    records.forEach(record => {
        incrementingInternalQty += parseInt(String(record.internalQuantity))/100;
        cumulativeVendorQtySeriesData.push(incrementingInternalQty);
    });

    let options = {
        title: {
            text: `Total Records: ${records.length}`,
            align: "left",
            margin: 10,
            offsetX: 0,
            offsetY: -10,
            floating: true,
            style: {
                fontSize: "20px",
                fontWeight: "light",
                color: "#263238"
            },
        },
        theme: commonTheme,
        series: [
            // Area Series
            {
                name: "Cumulative Qty",
                type: "area",
                data: cumulativeVendorQtySeriesData
            },
            // Line Series
            {
                name: "Individual Qty",
                type: "line",
                data: records.map(record => parseFloat(round(parseInt(String((record.internalQuantity)))/divisor)))
            }
        ],
        chart: {
            height: 500,
            type: "line",
        },
        stroke: {
            curve: "smooth"
        },
        fill: {
            type: "solid",
            opacity: [0.25, 1],
        },
        labels: records.map(record => {
            let outwardJob = outwardJobsMap.get(record.outwardJobUuid);
            return outwardJob?.approvalMetadata?.approvedOn! > 0 ? outwardJob?.finalRefNumber : outwardJob?.referenceId;
        }),
        markers: {
            size: 2
        },
        legend: {
            position: "top"
        },
        yaxis: [
            {
                title: {
                    text: "Cumulative Value",
                },
            },
            {
                opposite: true,
                title: {
                    text: "Individual Record Value",
                },
            },
        ],

        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                let record = records[dataPointIndex];
                let outwardJob = outwardJobsMap.get(record.outwardJobUuid);
                let uom = unitsMap.get(family.uomId!);
                return `
                    <ul style='background-color: #424242; color: #F5F5F5; padding: 20px; margin: 0px;'>
                        <li>Index: ${dataPointIndex + 1}</li>
                        <li>Outward Job: ${outwardJob?.approvalMetadata?.approvedOn! > 0 ? outwardJob?.finalRefNumber : outwardJob?.referenceId}</li>
                        <li>Item Qty: ${internationalizeMoney(parseInt(String(record.internalQuantity))/100)} ${uom?.symbol}</li>
                        <li>Approved Date: ${convertBigIntTimestampToDate(record.approvalMetadata!.approvedOn)}</li>
                        <li>Cumulative Qty: ${internationalizeMoney(cumulativeVendorQtySeriesData[dataPointIndex])} ${uom?.symbol}</li>
                    </ul>
                `;
            }
        }
    };

    let chart = new ApexCharts(localChartDiv, options);
    chart.render();
}
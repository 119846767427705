import { context } from "./../../router";
import { SupplyOffer, SupplyOffersService, SupplyOfferAncillaryParameters, Currency, Family, UnitOfMaterial, TaxGroup, getClientForSupplyOffersService, getClientForLocationsService, getClientForAssociatesService, getClientForUnitsOfMaterialsService, getClientForPurchasesOrdersService, PermissionSupplyOfferDrafts, PermissionSupplyOfferRevise, STANDARD_LIFECYCLE_STATUS, SupplyOffersServiceUpdateRequest, getClientForFamiliesService, SupplyOffersServiceItemCreateRequest, getClientForCurrenciesService, getClientForTaxGroupsService, getClientForGoodsReceiptsService, GOODS_RECEIPT_BILLING_STATUS, SupplyOffersServiceAutofillRequest, IdentifierUUIDWithFile, getClientForVendorsService, VendorItem } from "@kernelminds/scailo-sdk";
import { emptyDiv, renderFilterPrimarySubSection, renderInput, renderPageTitleSection, renderSelect, renderSpan, returnFormFromFields } from "../../ui";
import { _returnInCents, checkForAnyPerm, convertBigIntTimestampToDate, convertCentsToMoney, createObjectFromForm, dateToStr, decodeSLC, downloadData, getDynamicFormData, handleCSVFileUpload, internationalizeMoney, randomId, returnAssociateBadge, showFailureAlert, showSuccessAlert, toSelectableDate, toTitleCase, validateForm } from "../../utilities";
import { PromiseClient, Transport } from "@connectrpc/connect";
import { familiesListFromIDs, purchaseorderFamily, roleSelf, vendorAssociatedFamilies } from "../../fetches";
import { getTransport, getWriteTransport } from "../../clients";
import { setupFamilySearchable } from "../../searchables";
import { protoInt64 } from "@bufbuild/protobuf";
import { renderSupplyOfferItemTrends } from "./trends";

const downloadPDFButtonClass = "__download-pdf-btn";
const downloadCSVButtonClass = "__download-csv-btn";
const updateButtonClass = "__update-btn";
const autofillButtonClass = "__autofill-btn";
const uploadCSVButtonClass = "__upload-csv-btn";
const addLineItemButtonClass = "__add-line-item-btn";
const deleteLineItemButtonClass = "__delete-line-item-btn";
const trendsLineItemButtonClass = "__trends-line-item-btn";

export async function handleIndividualSupplyOffer(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    const transport = getTransport();
    const accessClient = getClientForSupplyOffersService(transport);
    const [supplyoffer, ancillaryParams, userRole] = await Promise.all([
        accessClient.viewByUUID({ uuid: ctx.params.uuid }),
        accessClient.viewAncillaryParametersByUUID({ uuid: ctx.params.uuid }),
        roleSelf(transport)
    ]);
    const currenciesClient = getClientForCurrenciesService(transport);

    const [currency] = await Promise.all([
        currenciesClient.viewByID({ id: supplyoffer.currencyId })
    ]);

    document.title = supplyoffer.approvalMetadata?.approvedOn! > 0 ? supplyoffer.finalRefNumber : supplyoffer.referenceId;

    let container = document.createElement("div");
    container.className = "overflow-x-auto";
    content.appendChild(container);

    let readonly = true;
    if (checkForAnyPerm(userRole, [PermissionSupplyOfferDrafts]) && supplyoffer.status == STANDARD_LIFECYCLE_STATUS.DRAFT) {
        readonly = false;
    } else if (checkForAnyPerm(userRole, [PermissionSupplyOfferRevise]) && supplyoffer.status == STANDARD_LIFECYCLE_STATUS.REVISION) {
        readonly = false;
    }

    const { formGrid } = await getForm(supplyoffer, ancillaryParams, currency, readonly, accessClient, transport);

    container.appendChild(formGrid);

    // Setup PDF downloads
    let pdfDownloadButtons = container.getElementsByClassName(downloadPDFButtonClass);
    for (let i = 0; i < pdfDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>pdfDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await accessClient.downloadByUUID({ uuid: btn.getAttribute("data-uuid") || "" });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "pdf", file.name.replace(".pdf", ""));
        });
    }

    // Setup CSV downloads
    let csvDownloadButtons = container.getElementsByClassName(downloadCSVButtonClass);
    for (let i = 0; i < csvDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>csvDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await accessClient.downloadAsCSV({ uuid: btn.getAttribute("data-uuid") || "" });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "csv", file.name.replace(".csv", ""));
        });
    }

    const familiesAccessClient = getClientForFamiliesService(transport);

    let trendsLineItemButtons = container.getElementsByClassName(trendsLineItemButtonClass);
    for (let i = 0; i < trendsLineItemButtons.length; i++) {
        let btn = <HTMLButtonElement>trendsLineItemButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();
            try {
                renderSupplyOfferItemTrends(protoInt64.parse(btn.getAttribute("data-family-id") || "0"), btn.getAttribute("data-family-uuid") || "", accessClient, familiesAccessClient, currenciesClient, transport);
            } catch (e) {
                showFailureAlert("Something went wrong. Try again.");
            }
        });
    }

    if (!readonly) {
        const writeClient = getClientForSupplyOffersService(getWriteTransport());
        const uomAccessClient = getClientForUnitsOfMaterialsService(transport);
        const taxgroupAccessClient = getClientForTaxGroupsService(transport);
        const vendorAccessClient = getClientForVendorsService(transport);

        // Setup update button handler
        let updateButton = container.getElementsByClassName(updateButtonClass)[0] as HTMLButtonElement;
        updateButton.addEventListener("click", async evt => {
            evt.preventDefault();

            // Validate all the fields
            if (!validateForm(formGrid.id)) {
                return;
            }

            // Create the object
            let obj = <SupplyOffersServiceUpdateRequest>createObjectFromForm(formGrid.id);
            obj.formData = getDynamicFormData();
            obj.id = supplyoffer.metadata?.id!;
            obj.currencyId = supplyoffer.currencyId;
            obj.notifyUsers = true;
            obj.vaultFolderId = supplyoffer.vaultFolderId;
            obj.consigneeLocationId = supplyoffer.consigneeLocationId;
            obj.buyerLocationId = supplyoffer.buyerLocationId;
            obj.projectId = supplyoffer.projectId;

            try {
                if (supplyoffer.status == STANDARD_LIFECYCLE_STATUS.DRAFT) {
                    await writeClient.draftUpdate(obj);
                } else if (supplyoffer.status == STANDARD_LIFECYCLE_STATUS.REVISION) {
                    await writeClient.revisionUpdate(obj);
                }
                showSuccessAlert(`Supply Offer: ${obj.referenceId} has been updated`);
                // No need to redirect, since the UI will auto update on receipt of notification from the server
            } catch (e) {
                showFailureAlert("Something went wrong. Try again.");
            }
        });

        // Setup autofill button handler
        let autofillButton = container.getElementsByClassName(autofillButtonClass)[0] as HTMLButtonElement;
        autofillButton.addEventListener("click", async evt => {
            evt.preventDefault();

            // Create the object
            let obj = <SupplyOffersServiceAutofillRequest>{};
            obj.uuid = supplyoffer.metadata?.uuid!;

            try {
                await writeClient.autofill(obj);
                showSuccessAlert(`Supply Offer: ${supplyoffer.referenceId} has been autofilled`);
            } catch (e) {
                showFailureAlert("Something went wrong. Try again.");
            }
        });

        // Setup upload CSV button handler
        let uploadCSVButton = container.getElementsByClassName(uploadCSVButtonClass)[0] as HTMLButtonElement;
        uploadCSVButton.addEventListener("click", async evt => {
            evt.preventDefault();
            try {
                handleCSVFileUpload(async fileContent => {
                    // Create the object
                    let obj = <IdentifierUUIDWithFile>{};
                    obj.uuid = supplyoffer.metadata?.uuid!;
                    obj.fileContent = fileContent;
                    await writeClient.uploadSupplyOfferItems(obj);
                    showSuccessAlert(`Supply Offer: ${supplyoffer.referenceId} has been uploaded from the given CSV file`);
                });
            } catch (e) {
                showFailureAlert("Something went wrong. Try again.");
            }
        });

        // Setup add line item button handlers
        let addLineItemButton = container.getElementsByClassName(addLineItemButtonClass)[0] as HTMLButtonElement;
        addLineItemButton.addEventListener("click", async evt => {
            evt.preventDefault();

            const familyIdElId = randomId();
            const internalQtyElId = randomId();
            const internalUomElId = randomId();
            const vendorUomElId = randomId();
            const vendorQtyElId = randomId();
            const vendorUnitPriceElId = randomId();
            const taxGroupIdElId = randomId();
            const discountElId = randomId();
            const deliveryDateElId = randomId();
            const specificationsElId = randomId();

            let submitBtnElId = randomId();

            let submitButton = document.createElement("button");
            submitButton.id = submitBtnElId;
            submitButton.innerText = "Submit";
            submitButton.className = "btn btn-success btn-outline btn-sm mt-3";

            // Show a dialog
            let dialog = document.createElement("dialog");
            dialog.className = "modal";
            dialog.id = randomId();
            dialog.innerHTML = `
            <div class="max-w-fit h-2/3 overflow-auto modal-box bg-white text-gray-700">
                <p class="text-2xl text-center">Add Line Item</p>
                <form method="dialog">
                    <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                </form>
                <div class="grid grid-cols-12 gap-2">
                    ${renderSelect({ id: familyIdElId, readonly: false, label: "Select Material", dataMapper: "familyId", dataType: "bigint", value: "", mdColSpan: 12, helpText: "Select the material to add", options: [] }).outerHTML}
                    
                    ${renderSelect({ id: vendorUomElId, readonly: false, label: "Vendor UoM", dataMapper: "", dataType: "string", value: "", mdColSpan: 6, helpText: "Vendor UoM", options: [] }).outerHTML}
                    ${renderInput({ id: vendorQtyElId, readonly: false, label: "Vendor Quantity", inputType: "number", dataMapper: "vendorQuantity", dataType: "bigint_in_cents", value: "0.00", mdColSpan: 6, helpText: "Vendor Quantity (Quantity in the Vendor's UoM)" }).outerHTML}
                    ${renderSelect({ id: internalUomElId, readonly: false, label: "Internal UoM", dataMapper: "", dataType: "string", value: "", mdColSpan: 6, helpText: "Internal UoM", options: [] }).outerHTML}
                    ${renderInput({ id: internalQtyElId, readonly: false, label: "Internal Quantity", inputType: "number", dataMapper: "internalQuantity", dataType: "bigint_in_cents", value: "0.00", mdColSpan: 6, helpText: "Internal Quantity (Quantity in the primary UoM)" }).outerHTML}

                    ${renderInput({ id: vendorUnitPriceElId, readonly: false, label: "Unit Price", inputType: "number", dataMapper: "vendorUnitPrice", dataType: "bigint_in_cents", value: "0.00", mdColSpan: 4, helpText: "Unit Price Per Vendor UoM" }).outerHTML}
                    ${renderSelect({ id: taxGroupIdElId, readonly: true, label: "Tax Group", dataMapper: "", dataType: "string", value: "", mdColSpan: 4, helpText: "Tax Group", options: [] }).outerHTML}
                    ${renderInput({ id: discountElId, readonly: false, label: "Discount %", inputType: "number", dataMapper: "discount", dataType: "bigint_in_cents", value: "0.00", mdColSpan: 4, helpText: "Applicable Discount Percentage" }).outerHTML}

                    ${renderInput({ id: deliveryDateElId, readonly: false, label: "Delivery Date", inputType: "date", dataMapper: "deliveryDate", dataType: "string", value: "", mdColSpan: 4, helpText: "The delivery date of the line item." }).outerHTML}

                    ${renderInput({ id: specificationsElId, readonly: false, label: "Specifications", inputType: "text", dataMapper: "specifications", dataType: "string", value: "", mdColSpan: 12, helpText: "Write any further specifications here." }).outerHTML}

                    ${submitButton.outerHTML}
                </div>
            </div>
            `;

            document.body.appendChild(dialog);
            dialog.showModal();

            const familyEl = (<HTMLSelectElement>document.getElementById(familyIdElId));

            let internalQtyEl = (<HTMLInputElement>document.getElementById(internalQtyElId));
            let internalUomEl = (<HTMLSelectElement>document.getElementById(internalUomElId));
            let vendorQtyEl = (<HTMLInputElement>document.getElementById(vendorQtyElId));
            let vendorUomEl = (<HTMLSelectElement>document.getElementById(vendorUomElId));

            let vendorUnitPriceEl = (<HTMLInputElement>document.getElementById(vendorUnitPriceElId));
            let taxGroupIdEl = (<HTMLInputElement>document.getElementById(taxGroupIdElId));

            let discountEl = (<HTMLInputElement>document.getElementById(discountElId));
            let deliveryDateEl = (<HTMLInputElement>document.getElementById(deliveryDateElId));

            let specificationsEl = (<HTMLInputElement>document.getElementById(specificationsElId));

            setupFamilySearchable(familyIdElId, async (searchKey, choice) => {
                let results = await accessClient.viewProspectiveFamilies({
                    id: supplyoffer.metadata?.id,
                    searchKey: searchKey
                });

                choice.setChoices(results.list.map(r => {
                    return {
                        value: r.metadata?.id, label: `(${r.code}) ${r.name}`, disabled: false, customProperties: {
                            uomId: r.uomId.toString()
                        }
                    };
                }));

            }, async (value) => {
                let [item, vendorFams] = await Promise.all([
                    accessClient.viewProspectiveSupplyOfferItem({
                        supplyOfferId: supplyoffer.metadata?.id!,
                        familyId: protoInt64.parse(value),
                    }),
                    vendorAssociatedFamilies({ familyId: protoInt64.parse(value) }, vendorAccessClient)
                ]);

                while (vendorUomEl.firstChild) {
                    vendorUomEl.removeChild(vendorUomEl.firstChild);
                }

                (await Promise.all(vendorFams.map(f => {
                    return uomAccessClient.viewByID({ id: f.uomId });
                }))).map(uom => {
                    let opt = document.createElement("option");
                    opt.value = uom.metadata?.id.toString()!;
                    opt.innerText = `(${uom.symbol}) ${uom.name}`;
                    return opt;
                }).forEach(opt => vendorUomEl.appendChild(opt));

                vendorUomEl.dispatchEvent(new Event("change"));

                // Set the internal uom
                while (internalUomEl.firstChild) {
                    internalUomEl.removeChild(internalUomEl.firstChild);
                }

                const familyUom = protoInt64.parse(JSON.parse(familyEl.selectedOptions[0].getAttribute("data-custom-properties")!)["uomId"]);
                let internalUom = await uomAccessClient.viewByID({ id: familyUom });
                internalUomEl.appendChild(new Option(`(${internalUom.symbol}) ${internalUom.name}`, `${internalUom.metadata?.id}`));
            });

            vendorUomEl.addEventListener("change", async evt => {
                evt.preventDefault();
                const vendorUomId = protoInt64.parse(vendorUomEl.value);

                let vendorFams = await vendorAssociatedFamilies({ familyId: protoInt64.parse(familyEl.value), uomId: vendorUomId, count: protoInt64.parse(1) }, vendorAccessClient)
                if (vendorFams.length == 0) {
                    return;
                }
                const vendorFam = vendorFams[0];
                vendorUnitPriceEl.value = convertCentsToMoney(vendorFam.price);

                // Clear the tax group selections
                while (taxGroupIdEl.firstChild) {
                    taxGroupIdEl.removeChild(taxGroupIdEl.firstChild);
                }
                let taxGroup = await taxgroupAccessClient.viewByID({ id: vendorFam.taxGroupId });
                taxGroupIdEl.appendChild(new Option(`${taxGroup.name} (${convertCentsToMoney(taxGroup.cumulativeTaxPercentage)}%)`, `${taxGroup.metadata?.id}`));

            });

            (<HTMLButtonElement>document.getElementById(submitBtnElId)).addEventListener("click", async evt => {
                evt.preventDefault();

                if (!validateForm(dialog.id)) {
                    return;
                }
                let itemToAdd = new SupplyOffersServiceItemCreateRequest({
                    supplyOfferId: supplyoffer.metadata?.id,
                    familyId: protoInt64.parse((<HTMLInputElement>document.getElementById(familyIdElId)).value),
                    internalQuantity: protoInt64.parse(_returnInCents(parseFloat(internalQtyEl.value))),
                    vendorUomId: protoInt64.parse(vendorUomEl.value || "0"),
                    vendorQuantity: protoInt64.parse(_returnInCents(parseFloat(vendorQtyEl.value))),
                    vendorUnitPrice: protoInt64.parse(_returnInCents(parseFloat(vendorUnitPriceEl.value))),
                    taxGroupId: protoInt64.parse(taxGroupIdEl.value || "0"),
                    discount: protoInt64.parse(_returnInCents(parseFloat(discountEl.value))),
                    deliveryDate: dateToStr(deliveryDateEl.value),
                    specifications: specificationsEl.value.trim()
                });

                try {
                    await writeClient.addSupplyOfferItem(itemToAdd);
                    showSuccessAlert(`Item added to Supply Offer: ${supplyoffer.referenceId}`);
                    dialog.close();
                    // No need to redirect, since the UI will auto update on receipt of notification from the server
                } catch (e) {
                    showFailureAlert("Something went wrong. Try again.");
                }
            });
        });

        let deleteLineItemButtons = container.getElementsByClassName(deleteLineItemButtonClass);
        for (let i = 0; i < deleteLineItemButtons.length; i++) {
            let btn = <HTMLButtonElement>deleteLineItemButtons[i];
            btn.addEventListener("click", async evt => {
                evt.preventDefault();

                try {
                    await writeClient.deleteSupplyOfferItem({
                        id: protoInt64.parse(btn.getAttribute("data-id") || "0"),
                    });
                    showSuccessAlert(`Item deleted from Supply Offer: ${supplyoffer.referenceId}`);
                    // No need to redirect, since the UI will auto update on receipt of notification from the server
                } catch (e) {
                    showFailureAlert("Something went wrong. Try again.");
                }
            });
        }
    }
}

async function renderReferencesSection(supplyoffer: SupplyOffer, ancillaryParams: SupplyOfferAncillaryParameters, currency: Currency, readonly: boolean, accessClient: PromiseClient<typeof SupplyOffersService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "References", titleMdColSpan: 3 });

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Initial Reference", inputType: "text", dataMapper: "referenceId", dataType: "string", value: supplyoffer.referenceId, mdColSpan: 6, helpText: "Initial Reference of the record." }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Final Reference", inputType: "text", dataMapper: "finalRefNumber", dataType: "string", value: supplyoffer.finalRefNumber, mdColSpan: 6, helpText: "Final Reference of the record that is automatically generated." }));
    // Display status as well
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Status", inputType: "text", dataMapper: "status", dataType: "string", value: toTitleCase(decodeSLC(supplyoffer.status)), mdColSpan: 4, helpText: "Status of the record." }));
    // Display amendment count
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Amendment Count", inputType: "number", dataMapper: "amendmentCount", dataType: "number", value: supplyoffer.amendmentCount.toString(), mdColSpan: 3, helpText: "The number of times that this record has been amended." }));

    contentGrid.appendChild(emptyDiv());
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Currency", inputType: "text", dataMapper: "", dataType: "string", value: `(${currency.symbol}) ${currency.name}`, mdColSpan: 6, helpText: "The currency of the Supply Offer." }));

    return grid;
}

async function renderBuyerAndConsigneeSection(supplyoffer: SupplyOffer, ancillaryParams: SupplyOfferAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof SupplyOffersService>, transport: Transport): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Buyer Information", titleMdColSpan: 3 });

    // Get buyer and consignee
    const locationsAccessClient = getClientForLocationsService(transport);
    const [buyer, consignee] = await Promise.all([
        locationsAccessClient.viewByUUID({ uuid: ancillaryParams.buyerLocationUuid }),
        locationsAccessClient.viewByUUID({ uuid: ancillaryParams.consigneeLocationUuid }),
    ]);

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Buyer", inputType: "text", dataMapper: "buyerLocationId", dataType: "string", value: `${buyer.code.length > 0 ? `(${buyer.code})` : ''} ${buyer.name}`, mdColSpan: 6, helpText: "The information of the buyer." }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Consignee", inputType: "text", dataMapper: "consigneeLocationId", dataType: "string", value: `${consignee.code.length > 0 ? `(${consignee.code})` : ''} ${consignee.name}`, mdColSpan: 6, helpText: "The information of the consignee." }));

    return grid;
}

async function renderDatesSection(supplyoffer: SupplyOffer, ancillaryParams: SupplyOfferAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof SupplyOffersService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Important Dates", titleMdColSpan: 3 });

    // Dates (creation and approval)
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Supply Offer Created On", inputType: "text", dataMapper: "createdAt", dataType: "string", value: convertBigIntTimestampToDate(supplyoffer.metadata?.createdAt!), mdColSpan: 3, helpText: "The creation date of this Supply Offer." }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Supply Offer Approved On", inputType: "text", dataMapper: "approvedOn", dataType: "string", value: convertBigIntTimestampToDate(supplyoffer.approvalMetadata?.approvedOn!), mdColSpan: 3, helpText: "The approval date of this Supply Offer." }));

    return grid;
}

async function renderPaymentTermsSection(supplyoffer: SupplyOffer, currency: Currency, readonly: boolean, accessClient: PromiseClient<typeof SupplyOffersService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Payment Terms", titleMdColSpan: 3 });

    // Advance Payment
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Advance Payment", inputType: "text", dataMapper: "paymentAdvance", dataType: "bigint_in_cents", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(supplyoffer.paymentAdvance)))}`, mdColSpan: 3, helpText: `The advance amount (in ${currency.symbol}) that is supposed to be paid.` }));

    // Payment Cycle
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Payment Cycle (In Days)", inputType: "text", dataMapper: "paymentCycleInDays", dataType: "number", value: `${supplyoffer.paymentCycleInDays.toString()}`, mdColSpan: 3, helpText: `The payment cycle of this Supply Offer in days.` }));

    return grid;
}

async function renderCostsSection(supplyoffer: SupplyOffer, currency: Currency, readonly: boolean, accessClient: PromiseClient<typeof SupplyOffersService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Costs", titleMdColSpan: 3 });

    // Miscellaneous Costs
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Miscellaneous Cost", inputType: "text", dataMapper: "miscellaneousCost", dataType: "bigint_in_cents", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(supplyoffer.miscellaneousCost)))}`, mdColSpan: 4, helpText: `Any miscellaneous costs (in ${currency.symbol}) on the Supply Offer.` }));

    // Overall Discount
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Overall Discount", inputType: "text", dataMapper: "overallDiscount", dataType: "bigint_in_cents", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(supplyoffer.overallDiscount)))}`, mdColSpan: 4, helpText: `The overall discount (in ${currency.symbol}) on the Supply Offer.` }));

    // Round Off
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Round Off", inputType: "text", dataMapper: "roundOff", dataType: "bigint_in_cents", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(supplyoffer.roundOff)))}`, mdColSpan: 4, helpText: `The round off (in ${currency.symbol}) applied on the Supply Offer.` }));

    // Total
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: true, label: "Net Total", inputType: "text", dataMapper: "", dataType: "number", value: `${internationalizeMoney(supplyoffer.totalValue)}`, mdColSpan: 6, helpText: `The net total (inclusive of applicable taxes, in ${currency.symbol}) of the Supply Offer.` }));

    return grid;
}

async function renderDynamicFormsSection(supplyoffer: SupplyOffer, readonly: boolean, accessClient: PromiseClient<typeof SupplyOffersService>): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    if (supplyoffer.formData.length == 0) {
        return container;
    }

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Other Details` }));
    container.appendChild(containerTitle);

    let formFields = await returnFormFromFields(supplyoffer.formData, "supply-offers", true, readonly);

    container.appendChild(formFields);

    return container;
}

async function renderFamiliesSection(supplyoffer: SupplyOffer, currency: Currency, readonly: boolean, accessClient: PromiseClient<typeof SupplyOffersService>, transport: Transport): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Line Items` }));

    if (!readonly) {
        let button = document.createElement("button");
        button.type = "button";
        button.className = `btn btn-sm btn-primary text-white justify-right ${addLineItemButtonClass}`;
        button.innerHTML = `<i class='bx bx-plus'></i> Add Line Item`;
        containerTitle.firstChild!.appendChild(button);

        let autofillButton = document.createElement("button");
        autofillButton.id = randomId();
        autofillButton.className = `btn btn-sm btn-warning btn-outline justify-right ${autofillButtonClass} ml-4`;
        autofillButton.innerHTML = `<i class='bx bxs-magic-wand'></i> AutoFill`;
        autofillButton.setAttribute("data-uuid", supplyoffer.metadata?.uuid!);
        autofillButton.setAttribute("data-id", supplyoffer.metadata!.id.toString());
        autofillButton.setAttribute("data-name", supplyoffer.approvalMetadata?.approvedOn! > 0 ? supplyoffer.finalRefNumber : supplyoffer.referenceId);
        containerTitle.firstChild!.appendChild(autofillButton);

        let uploadCSVButton = document.createElement("button");
        uploadCSVButton.id = randomId();
        uploadCSVButton.className = `btn btn-sm btn-info btn-outline justify-right ${uploadCSVButtonClass} ml-4`;
        uploadCSVButton.innerHTML = `<i class='bx bx-upload'></i> Upload from CSV`;
        uploadCSVButton.setAttribute("data-uuid", supplyoffer.metadata?.uuid!);
        uploadCSVButton.setAttribute("data-id", supplyoffer.metadata!.id.toString());
        uploadCSVButton.setAttribute("data-name", supplyoffer.approvalMetadata?.approvedOn! > 0 ? supplyoffer.finalRefNumber : supplyoffer.referenceId);
        containerTitle.firstChild!.appendChild(uploadCSVButton);
    }

    container.appendChild(containerTitle);

    let familiesMap: Map<bigint, Family> = new Map();
    (await familiesListFromIDs(Array.from(new Set(supplyoffer.list.map(p => p.familyId))))).forEach(family => {
        familiesMap.set(family.metadata?.id!, family);
    });

    const uomClient = getClientForUnitsOfMaterialsService(transport);
    let unitsList = supplyoffer.list.map(p => p.vendorUomId);
    familiesMap.forEach(family => {
        unitsList.push(family.uomId);
    });

    let uomsList = await Promise.all(Array.from(new Set(unitsList)).map(id => {
        return uomClient.viewByID({ id });
    }));
    let uomsMap: Map<bigint, UnitOfMaterial> = new Map();

    uomsList.forEach(uom => {
        uomsMap.set(uom.metadata!.id, uom);
    });

    const taxGroupClient = getClientForTaxGroupsService(transport);
    let taxGroupIDsList = supplyoffer.list.map(p => p.taxGroupId);
    familiesMap.forEach(family => {
        taxGroupIDsList.push(family.taxGroupId);
    });

    let taxGroupsList = await Promise.all(Array.from(new Set(taxGroupIDsList)).map(id => {
        return taxGroupClient.viewByID({ id });
    }));
    let taxGroupsMap: Map<bigint, TaxGroup> = new Map();

    taxGroupsList.forEach(taxGroup => {
        taxGroupsMap.set(taxGroup.metadata!.id, taxGroup);
    });

    supplyoffer.list.forEach((item, index) => {
        let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: `${index + 1}.`, titleMdColSpan: 1 });

        let fam = familiesMap.get(item.familyId) || new Family();

        let pretaxTotal = parseInt(String(item.vendorQuantity)) * parseInt(String(item.vendorUnitPrice)) / 100.0;

        const discount = parseInt(String(item.discount)) * pretaxTotal / 100.0 / 100.0;
        const postDiscountTotal = pretaxTotal - discount;

        let taxgroup = taxGroupsMap.get(item.taxGroupId) || new TaxGroup();
        let taxAmt = postDiscountTotal * taxgroup.cumulativeTaxPercentage / 100.0 / 100.0;
        let total = postDiscountTotal + taxAmt;

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Material Code", value: `${fam.code}`, mdColSpan: 3, helpText: `The code of the material` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Material Name", value: `${fam.name}`, mdColSpan: 9, helpText: `The name of the material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Vendor Quantity & UoM", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(item.vendorQuantity)))} (${uomsMap.get(item.vendorUomId)?.symbol}) ${uomsMap.get(item.vendorUomId)?.name}`, mdColSpan: 3, helpText: `The quantity of the material in Vendor's unit of material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Buyer Quantity & UoM", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(item.internalQuantity)))} (${uomsMap.get(fam.uomId)?.symbol}) ${uomsMap.get(fam.uomId)?.name}`, mdColSpan: 3, helpText: `The quantity of the material in Buyer's unit of material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Unit Price", value: `${currency.symbol} ${internationalizeMoney(parseFloat(convertCentsToMoney(item.vendorUnitPrice)))}/${uomsMap.get(item.vendorUomId)?.symbol}`, mdColSpan: 3, helpText: `The price per unit of the material in Vendor's unit` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Discount %", value: `${convertCentsToMoney(item.discount)}`, mdColSpan: 3, helpText: `The discount on unit price in percentage` }));


        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Delivery Date", value: `${item.deliveryDate}`, mdColSpan: 3, helpText: `The delivery date of this material` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Tax", value: `${taxGroupsMap.get(item.taxGroupId)?.name}`, mdColSpan: 3, helpText: `The applicable tax group` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Pre-Tax Total", value: `${currency.symbol} ${internationalizeMoney(postDiscountTotal / 100.0)}`, mdColSpan: 3, helpText: `The pre tax amount (after applicable discount percentage)` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Tax Amount", value: `${currency.symbol} ${internationalizeMoney(taxAmt / 100.0)}`, mdColSpan: 3, helpText: `The computed tax amount` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Net Total", value: `${currency.symbol} ${internationalizeMoney(total / 100.0)}`, mdColSpan: 3, helpText: `The total amount (inclusive of taxes)` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "HSN/SAC Code", value: `${fam.hsnSacCode}`, mdColSpan: 3, helpText: `The HSN/SAC code of the material` }));

        if (item.specifications.length > 0) {
            contentGrid.appendChild(renderSpan({ id: randomId(), label: "Specifications", value: `${item.specifications}`, mdColSpan: 12, helpText: `Any additional specifications that are provided by the Vendor` }));
        }

        let buttonsDiv = document.createElement("div");
        buttonsDiv.className = "col-span-12 pl-4";
        contentGrid.appendChild(buttonsDiv);
        contentGrid.appendChild(emptyDiv());

        if (!readonly) {
            let deleteItemButton = document.createElement("button");
            deleteItemButton.type = "button";
            deleteItemButton.className = `btn btn-sm btn-error text-white float-right ${deleteLineItemButtonClass}`;
            deleteItemButton.innerHTML = `<i class='bx bx-trash'></i> Delete`;
            deleteItemButton.setAttribute("data-id", item.metadata?.id.toString()!);

            buttonsDiv.appendChild(deleteItemButton);
        }

        // Trends button here
        let trendsItemButton = document.createElement("button");
        trendsItemButton.type = "button";
        trendsItemButton.className = `btn btn-sm btn-primary btn-outline text-white float-right mr-3 ${trendsLineItemButtonClass}`;
        trendsItemButton.innerHTML = `<i class='bx bx-line-chart'></i> Trends`;
        trendsItemButton.setAttribute("data-id", item.metadata?.id.toString()!);
        trendsItemButton.setAttribute("data-family-id", item.familyId?.toString()!);
        trendsItemButton.setAttribute("data-family-uuid", item.familyUuid!);

        buttonsDiv.appendChild(trendsItemButton);

        container.appendChild(grid);

        let hr = document.createElement("hr");
        hr.classList.add("m-5");
        container.appendChild(hr);
    });

    return container;
}

async function getForm(supplyoffer: SupplyOffer, ancillaryParams: SupplyOfferAncillaryParameters, currency: Currency, readonly: boolean, accessClient: PromiseClient<typeof SupplyOffersService>, transport: Transport) {
    let formGrid = document.createElement("div");
    formGrid.className = "grid grid-cols-1 gap-6 mb-6";
    formGrid.id = randomId();

    const [
        referencesSection,
        buyerAndConsigneeSection,
        datesSection,
        paymentTermsSection,
        costsSection,
        dynamicFormsSection,
        familiesSection,
    ] = await Promise.all([
        renderReferencesSection(supplyoffer, ancillaryParams, currency, readonly, accessClient),
        renderBuyerAndConsigneeSection(supplyoffer, ancillaryParams, readonly, accessClient, transport),
        renderDatesSection(supplyoffer, ancillaryParams, readonly, accessClient),
        renderPaymentTermsSection(supplyoffer, currency, readonly, accessClient),
        renderCostsSection(supplyoffer, currency, readonly, accessClient),
        renderDynamicFormsSection(supplyoffer, readonly, accessClient),
        renderFamiliesSection(supplyoffer, currency, readonly, accessClient, transport)
    ]);

    let buttonContainer = document.createElement("div");
    buttonContainer.classList.add("col-span-12", "flex", "justify-center", "overflow-x-auto");

    if (!readonly) {
        let updateButton = document.createElement("button");
        updateButton.id = randomId();
        updateButton.className = `btn btn-warning btn-outline btn-sm ${updateButtonClass} mr-4`;
        updateButton.innerText = "Update";
        updateButton.setAttribute("data-uuid", supplyoffer.metadata?.uuid!);
        updateButton.setAttribute("data-id", supplyoffer.metadata!.id.toString());
        updateButton.setAttribute("data-name", supplyoffer.approvalMetadata?.approvedOn! > 0 ? supplyoffer.finalRefNumber : supplyoffer.referenceId);
        buttonContainer.appendChild(updateButton);
    }

    // Download PDF button
    let downloadPDFButton = document.createElement("button");
    downloadPDFButton.id = randomId();
    downloadPDFButton.className = `btn btn-success btn-outline btn-sm ${downloadPDFButtonClass} mr-4`;
    downloadPDFButton.innerText = "Download PDF";
    downloadPDFButton.setAttribute("data-uuid", supplyoffer.metadata?.uuid!);
    downloadPDFButton.setAttribute("data-name", supplyoffer.approvalMetadata?.approvedOn! > 0 ? supplyoffer.finalRefNumber : supplyoffer.referenceId);
    buttonContainer.appendChild(downloadPDFButton);

    // Download CSV button
    let downloadCSVButton = document.createElement("button");
    downloadCSVButton.id = randomId();
    downloadCSVButton.className = `btn btn-success btn-outline btn-sm ${downloadCSVButtonClass} mr-4`;
    downloadCSVButton.innerText = "Download CSV";
    downloadCSVButton.setAttribute("data-uuid", supplyoffer.metadata?.uuid!);
    downloadCSVButton.setAttribute("data-name", supplyoffer.approvalMetadata?.approvedOn! > 0 ? supplyoffer.finalRefNumber : supplyoffer.referenceId);
    buttonContainer.appendChild(downloadCSVButton);

    formGrid.innerHTML = `
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
            <div class="rounded-t mb-0 px-0 border-0">
                ${renderPageTitleSection({ title: `Supply Offer: ` + document.title }).outerHTML}
                ${buttonContainer.outerHTML}
            </div>
            <form>
                <hr class="m-5">
                ${referencesSection.outerHTML}
                <hr class="m-5">
                ${buyerAndConsigneeSection.outerHTML}
                <hr class="m-5">
                ${datesSection.outerHTML}
                <hr class="m-5">
                ${paymentTermsSection.outerHTML}
                <hr class="m-5">
                ${costsSection.outerHTML}
                <hr class="m-5">
            </form>
        </div>
        ${dynamicFormsSection.childElementCount > 0 ?
            `<div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
                ${dynamicFormsSection.outerHTML}
            </div>`
            : ''}

        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
            ${familiesSection.outerHTML}
        </div>
    `;

    return { formGrid }
}

import { outwardJobFiltersMenu, outwardJobIndividualMenu, outwardJobInsightsMenu } from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router, context } from "../../router";
import { handleOutwardJobFilters } from "./outwardjobs.filters";
import { handleOutwardJobInsights } from "./outwardjobs.insights";
import { handleIndividualOutwardJob } from "./individual";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(outwardJobFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, outwardJobFiltersMenu)) {
            handleOutwardJobFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(outwardJobInsightsMenu.href, async (ctx) => {
        if (await interceptPage(ctx, outwardJobInsightsMenu)) {
            handleOutwardJobInsights(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(outwardJobIndividualMenu.href, async (ctx) => {
        if (await interceptPage(ctx, outwardJobFiltersMenu)) {
            handleIndividualOutwardJob(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}